import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const ToolbarViewContainer = styled.div`
  display: flex;
  flex-direction: column;

  background: ${Colors.tabBackground};
  background: linear-gradient(0deg, ${Colors.tabBackground} 20%, ${Colors.toolbarBackground} 90%);
`;
