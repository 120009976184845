import EProjectMessagesStatus from '../../enums/Project/project-messages-status.enum';



export const statusMessagesArray = [
  {
    id: 1,
    label: 'Sucesso',
    value: EProjectMessagesStatus.SUCCESS,
  },
  {
    id: 2,
    label: 'Atenção',
    value: EProjectMessagesStatus.WARNING,
  },
  {
    id: 3,
    label: 'Erro',
    value: EProjectMessagesStatus.ERROR,
  },
];
