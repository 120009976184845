import ApiWS from '../index';



export const GetAllAntiFakeModeles = async (payload: string) => {
  const url = '/support/project/anti-fake-modele';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const ArchiveModeleAntiFake = async (idMedia: string) => {
  const url = `/support/project/anti-fake-modele/archive/${idMedia}`;

  const response = await ApiWS.put(url);
  return response.data;
};



export const ChangeRevisedModeleAntiFake = async (idMedia: string) => {
  const url = `/support/project/anti-fake-modele/revised/revised/${idMedia}`;

  const response = await ApiWS.put(url);
  return response.data;
};



export const DeleteModeleAntiFake = async (idComment: string) => {
  const url = `/support/project/anti-fake-modele/${idComment}`;

  const response = await ApiWS.delete(url);
  return response.data;
};
