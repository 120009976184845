import styled,
{
  css,
} from 'styled-components';

import {
  Card,
} from '../../../../components/Composh/web';

import InputCheckbox from '../../../../components/Inputs/InputCheckbox';
import InputSelect from '../../../../components/Inputs/InputSelect';
import InputSwitch from '../../../../components/Inputs/InputSwitch';
import InputText from '../../../../components/Inputs/InputText';

import {
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
}



export const CardContactsForm = styled(Card.View)`
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;



export const DetailsTopViewItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
`;

export const DetailsBottomViewItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
`;



export const ContactsInputsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
`;

export const InputStyle = css`
  margin-left: 5px;
  margin-right: 5px;
`;

export const InputDdiTextStyled = styled(InputSelect)`
  ${InputStyle};
  
  @media ${PlatformDevices.maxTablet} {
    width: 100%;
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(30% - 10px);
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(calc(100% / 3) - 10px);
  }

  @media ${PlatformDevices.minTabletM} {
    width: calc(25% - 10px);
  }
`;

export const InputPhoneStyled = styled(InputText)`
  ${InputStyle};
  
  @media ${PlatformDevices.maxTablet} {
    width: 100%;
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(70% - 10px);
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(calc(100% / 3) - 10px);
  }

  @media ${PlatformDevices.minTabletM} {
    width: calc(25% - 10px);
  }
`;

export const InputTextStyled = styled(InputText)`
  ${InputStyle};
  
  @media ${PlatformDevices.maxMobileM} {
    width: 100%;
  }

  @media ${PlatformDevices.minMobileM} {
    width: calc(50% - 10px);
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(calc(100% / 3) - 10px);
  }

  @media ${PlatformDevices.minTabletM} {
    width: calc(25% - 10px);
  }
`;


export const InputButtonCheckboxStyled = styled(InputCheckbox)`
  ${InputStyle};
  
  @media ${PlatformDevices.maxTablet} {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(30% - 10px);
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(calc(100% / 3) - 10px);
  }

  @media ${PlatformDevices.minTabletM} {
    width: calc(25% - 10px);
  }
`;

export const InputSwitchStyled = styled(InputSwitch)`
  ${InputStyle};
  
  @media ${PlatformDevices.maxTablet} {
    width: 100%;
    margin-top: 10px;
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(30% - 10px);
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(calc(100% / 3) - 10px);
  }

  @media ${PlatformDevices.minTabletM} {
    width: calc(25% - 10px);
  }
`;



export const ContactsAttendanceOptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;
