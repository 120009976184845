import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../../shared/utils/platforms.utils';



export const ImageContentRightHome = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${Sizes.contentMargin}px;
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: 5px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer}px;

  @media ${PlatformDevices.maxLaptopL} {
    margin-left: 0;
  }

  @media ${PlatformDevices.minLaptop} {
    width: ${Sizes.contentRightHomeWidth}px;
  }
`;


export const WrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;


export const WarningApproveText = styled.p`
  margin-bottom: 20px;
  color: #fff9;
  font-size: 18px;
`;



export const ApproveSplitView = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 12px 0;
  align-items: center;
`;

export const ApproveSplitIconView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 0.7em;
  background-color: ${Colors.primary};
  border-radius: 50%;
`;

export const ApproveSplitText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 500;
`;



export const ApproveItemInfoView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApproveItemSplitText = styled.p`
  position: relative;
  margin-bottom: 6px;
  color: ${Colors.white};
  margin-left: 5px;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  
  :before{
    content: '';
    position: absolute;
    top: 8px;
    left: -2px;
    width: 5px;
    height: 5px;
    background-color: #ff0000;
    border-radius: 9999px;
  }
`;

export const ApproveSubItemSplitText = styled.p`
  margin-top: 2px;
  margin-bottom: 12px;
  padding-left: 20px;
  color: #fff9;
  font-size: 13px;
`;
