import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useParams,
} from 'react-router-dom';

import {
  GetModeleReportsData,
} from '../../../../apis/endpoints/modeles-reports.endpoints';

import {
  Content,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import ItemDescription from '../../../../components/Details/ItemDescription';
import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconAccessLogin,
  IconMember,
  IconMessages,
  IconPhone,
  IconProfileEdit,
  IconSocialWhatsApp,
  IconUserFollow,
  IconView,
} from '../../../../components/Icons';

import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../../components/Titles/SectionTitle';

import {
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Values,
} from '../../../../shared/constants';

import {
  FormatExtenseDate,
} from '../../../../shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  CharWrapperFirstContent,
  CharItemContainer,
} from './styled';



export interface IRouteParams {
  useralias: string;
}



const ModeleDataProfileScreen: React.FC = () => {
  const dispatch = useDispatch();

  const route = useParams<IRouteParams>();
  const paramsRoute = route as IRouteParams;

  const { useralias } = paramsRoute;
  const userIdSearch = useralias;


  const [userData, setUserData] = useState<any>(null);


  const charIconsSize = 26;

  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function renderPersonalItem(icon: any, title: string, text: string, description?: string, onPress?: any) {
    return (

      <ItemDescription
        disabled
        icon={icon}
        title={title}
        text={text || '-'}
        description={description}
        onPress={onPress}
      />

    );
  }


  async function getReportsModele() {
    try {
      const response = await GetModeleReportsData(userIdSearch);
      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      setUserData(responseModele);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
  }


  async function updateForm() {
    await getReportsModele();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.black}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonBack
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Title
            color={Colors.black}>
            Dados do perfil profissional
          </Title>
        }
      />



      <Content>

        {!mounted && (
          renderLoading()
        )}



        {mounted && (
          <ContentPadder>

            <CharWrapperFirstContent>
              <SectionTitle
                title={'Perfil'}
              />


              <CharItemContainer>

                {renderPersonalItem(
                  <IconUserFollow
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  'Seguidores',
                  userData?.reports?.totalFollow,
                )}

              </CharItemContainer>
            </CharWrapperFirstContent>



            <CharWrapperFirstContent>
              <SectionTitle
                title={'Estatísticas'}
              />


              <CharItemContainer>

                {renderPersonalItem(
                  <IconView
                    color={Colors.black}
                    size={charIconsSize}
                  />,
                  'Visitas de perfil',
                  userData?.reports?.appViews,
                )}


                {renderPersonalItem(
                  <IconAccessLogin
                    color={Colors.black}
                    size={charIconsSize}
                  />,
                  'Último Login',
                  userData?.reports?.lastLogin ? FormatExtenseDate(userData?.reports?.lastLogin) : '',
                )}


                {renderPersonalItem(
                  <IconMember
                    color={Colors.black}
                    size={charIconsSize}
                  />,
                  'Membro desde',
                  userData?.reports?.createdAt ? FormatExtenseDate(userData?.reports?.createdAt) : '',
                )}


                {renderPersonalItem(
                  <IconProfileEdit
                    color={Colors.black}
                    size={charIconsSize}
                  />,
                  'Perfil alterado em',
                  userData?.reports?.updatedAt ? FormatExtenseDate(userData?.reports?.updatedAt) : '',
                )}

              </CharItemContainer>
            </CharWrapperFirstContent>



            <CharWrapperFirstContent>
              <SectionTitle
                title={'Contatos'}
              />


              <CharItemContainer>

                {renderPersonalItem(
                  <IconPhone
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  'Cliques no telefone',
                  userData?.reports?.contactNumber,
                )}


                {renderPersonalItem(
                  <IconMessages
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  'Cliques para enviar mensagem',
                  userData?.reports?.contactMessage,
                )}


                {renderPersonalItem(
                  <IconSocialWhatsApp
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  'Cliques no WhatsApp',
                  userData?.reports?.contactWhatsapp,
                )}

              </CharItemContainer>
            </CharWrapperFirstContent>

          </ContentPadder>
        )}



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default ModeleDataProfileScreen;
