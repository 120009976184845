import React from 'react';

import {
  TableTotalSpan,
} from './styled';



export interface IProps {
  color?: string;
  from?: number;
  to?: number;
  size?: number;
}



const TableTotal: React.FC<IProps> = (props: IProps) => {
  const tableTotalColor = props.color;


  return (

    <TableTotalSpan
      color={tableTotalColor}>
      Mostrando os itens {props.from || '0'} a {props.to || '0'}, de {props.size || '0'} resultados
    </TableTotalSpan>

  );
};



export default TableTotal;
