import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  CreateProjectPlanPrices,
  EditProjectPlanPrices,
} from '../../../../apis/endpoints/project-plans-prices.endpoints';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Body,
  Content,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../../components/Titles/SectionTitle';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  InputTextStyled,
  ProjectParamsActiveItemContent,
  ProjectParamsContentCenterFlex,
  ProjectParamsWrapperItemContent,
} from './styled';



const PlansPricesFormScreen: React.FC = () => {
  const dispatch = useDispatch();


  const formik = useRef<FormikProps<any>>(null);

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function setStatesParams(itemReceived: any) {
    //
  }


  async function getEndpointPlansPrices() {
    try {
      setLoading(true);

      const response = await CreateProjectPlanPrices('');

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.attention, 'Nenhum parâmetro encontrado');
        return;
      }

      setStatesParams(response?.data);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);

      return null;
    }
    finally {
      setLoading(false);
    }
  };


  async function saveItemGroup(payload: any) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await EditProjectPlanPrices(payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      // setStatesParams(response?.data);

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  useEffect(() => {
    getEndpointPlansPrices();
  }, []);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={(values) => {
        // KeyboardModal.dismiss();
        saveItemGroup(values);
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <HeaderScreen
            leftIcon={
              <ButtonDrawer
                color={Colors.black}
                size={27}
              />
            }
            centerContent={
              <Body>
                <Title
                  color={Colors.black}>
                  Planos e valores
                </Title>

                <SubTitle
                  color={Colors.gray}>
                  Todo o projeto
                </SubTitle>
              </Body>
            }
          />



          <Content>

            {loading && (
              <LoadingScreen
                type={'SCREEN'}
                color={Colors.secondary}
              />
            )}



            {!loading && (
              <ContentPadder>

                <ProjectParamsContentCenterFlex
                  backgroundColor={Colors.formBackground}
                  borderRadius={Sizes.cardRadius}>

                  <ProjectParamsWrapperItemContent>

                    <SectionTitle
                      title={'País'}
                    />

                    <ProjectParamsActiveItemContent>

                      <InputTextStyled
                        disabled={loading}
                        type={'TEXT'}
                        labelText={'País'}
                        placeholderText={'País'}
                        errorText={errors.age}
                        countLimit={Values.priceCount}
                        value={values?.age}
                        onChange={handleChange('age')}
                        onBlur={handleBlur('age')}
                      />

                    </ProjectParamsActiveItemContent>

                  </ProjectParamsWrapperItemContent>



                  <ProjectParamsWrapperItemContent>

                    <SectionTitle
                      title={'Planos - Usuários'}
                    />

                    <ProjectParamsActiveItemContent>

                      <InputTextStyled
                        disabled={loading}
                        type={'TEXT'}
                        labelText={'Plus'}
                        placeholderText={'Plus'}
                        errorText={errors.age}
                        countLimit={Values.priceCount}
                        value={values?.age}
                        onChange={handleChange('age')}
                        onBlur={handleBlur('age')}
                      />


                      <InputTextStyled
                        disabled={loading}
                        type={'TEXT'}
                        labelText={'Premium'}
                        placeholderText={'Premium'}
                        errorText={errors.age}
                        countLimit={Values.priceCount}
                        value={values?.age}
                        onChange={handleChange('age')}
                        onBlur={handleBlur('age')}
                      />

                    </ProjectParamsActiveItemContent>

                  </ProjectParamsWrapperItemContent>



                  <ProjectParamsWrapperItemContent>

                    <SectionTitle
                      title={'Planos - Modelos'}
                    />

                    <ProjectParamsActiveItemContent>

                      <InputTextStyled
                        disabled={loading}
                        type={'TEXT'}
                        labelText={'Bronze'}
                        placeholderText={'Bronze'}
                        errorText={errors.age}
                        countLimit={Values.priceCount}
                        value={values?.age}
                        onChange={handleChange('age')}
                        onBlur={handleBlur('age')}
                      />



                      <InputTextStyled
                        disabled={loading}
                        type={'TEXT'}
                        labelText={'Prata'}
                        placeholderText={'Prata'}
                        errorText={errors.age}
                        countLimit={Values.priceCount}
                        value={values?.age}
                        onChange={handleChange('age')}
                        onBlur={handleBlur('age')}
                      />



                      <InputTextStyled
                        disabled={loading}
                        type={'TEXT'}
                        labelText={'Ouro'}
                        placeholderText={'Ouro'}
                        errorText={errors.age}
                        countLimit={Values.priceCount}
                        value={values?.age}
                        onChange={handleChange('age')}
                        onBlur={handleBlur('age')}
                      />



                      <InputTextStyled
                        disabled={loading}
                        type={'TEXT'}
                        labelText={'Platina'}
                        placeholderText={'Platina'}
                        errorText={errors.age}
                        countLimit={Values.priceCount}
                        value={values?.age}
                        onChange={handleChange('age')}
                        onBlur={handleBlur('age')}
                      />



                      <InputTextStyled
                        disabled={loading}
                        type={'TEXT'}
                        labelText={'Diamante'}
                        placeholderText={'Diamante'}
                        errorText={errors.age}
                        countLimit={Values.priceCount}
                        value={values?.age}
                        onChange={handleChange('age')}
                        onBlur={handleBlur('age')}
                      />

                    </ProjectParamsActiveItemContent>

                  </ProjectParamsWrapperItemContent>



                  <ProjectParamsWrapperItemContent>

                    <SectionTitle
                      title={'Parâmetros'}
                    />

                    <ProjectParamsActiveItemContent>

                      <InputTextStyled
                        disabled={loading}
                        type={'TEXT'}
                        labelText={'Dias - Teste grátis Ouro'}
                        placeholderText={'Dias'}
                        errorText={errors.age}
                        countLimit={Values.priceCount}
                        value={values?.age}
                        onChange={handleChange('age')}
                        onBlur={handleBlur('age')}
                      />

                    </ProjectParamsActiveItemContent>

                  </ProjectParamsWrapperItemContent>



                  <ProjectParamsWrapperItemContent>

                    <SectionTitle
                      title={'Anúncios'}
                    />

                    <ProjectParamsActiveItemContent>

                      <InputTextStyled
                        disabled={loading}
                        type={'TEXT'}
                        labelText={'Nível Acompanhantes'}
                        placeholderText={'Nível Acompanhantes'}
                        errorText={errors.age}
                        countLimit={Values.priceCount}
                        value={values?.age}
                        onChange={handleChange('age')}
                        onBlur={handleBlur('age')}
                      />


                      <InputTextStyled
                        disabled={loading}
                        type={'TEXT'}
                        labelText={'Chamar ateção'}
                        placeholderText={'Chamar ateção'}
                        errorText={errors.age}
                        countLimit={Values.priceCount}
                        value={values?.age}
                        onChange={handleChange('age')}
                        onBlur={handleBlur('age')}
                      />

                    </ProjectParamsActiveItemContent>

                  </ProjectParamsWrapperItemContent>



                  <ContentButtonForm>
                    <ButtonAction
                      width={Sizes.buttonMinWidth}
                      title={'Salvar'}
                      onPress={() => {
                        handleSubmit();
                      }}
                    />
                  </ContentButtonForm>

                </ProjectParamsContentCenterFlex>

              </ContentPadder>
            )}

          </Content>

        </Screen>

      )}
    </Formik>

  );
};



export default PlansPricesFormScreen;
