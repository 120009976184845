import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  ApproveModeleProfile,
  GetModeleProfissionalPanel,
} from '../../../../apis/endpoints/modeles.endpoints';

import {
  IInputSelect,
} from '../../../../apis/interfaces/App/input-select.interface';

import AlertMessage from '../../../../components/Alerts';
import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Body,
  Container,
  Content,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentButtonForm,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../../components/Titles/SectionTitle';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  countriesOption,
} from '../../../../shared/arrays/App/countries.array';

import {
  Colors,
  Images,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  TranslateConfig,
} from '../../../../shared/translations';

import {
  returnCityArray,
  returnStateArray,
} from '../../../../shared/utils/address.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  stringRawDataText,
  validateString,
} from '../../../../shared/utils/string.utils';

import {
  CompanyApprovePadder,
  ApproveSectionInfoCompanyView,
  DetailsApproveViewBackground,
  HeaderUserBackgroundOverlay,
  DetailsApproveBackground,
  DetailsApproveViewImage,
  DetailsApproveImage,
  DetailsApproveContainer,
  DetailsSectionView,
  CompanyInfoContent,
  CompanyUserActiveText,
  CompanyUserActiveBoldText,
  ApproveSectionStatusCompanyView,
  ApproveAdsImageButton,
  CompanyToActiveContent,
  CompanyItemToActiveContent,
  CompanyItemToActiveView,
  CompanyItemToActiveText,
  CompanyItemToActiveDescription,
  CompanyItemDescStatusText,
  CompanyItemStatusText,
  CompanyItemWarningText,
} from './styled';



export interface IRouteParams {
  useralias: string;
}



const ModeleVisibleProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const route = useParams<IRouteParams>();
  const paramsRoute = route as IRouteParams;

  const { useralias } = paramsRoute;
  const userIdSearch = useralias;


  const [modeleDetails, setModeleDetails] = useState<any>(null);

  const [countryModele, setCountryModele] = useState<string>('');
  const [stateModele, setStateModele] = useState<string>('');
  const [cityModele, setCityModele] = useState<string>('');

  const [profilePictureStatus, setProfilePictureStatus] = useState<boolean>(false);
  const [profileCoverStatus, setProfileCoverStatus] = useState<boolean>(false);
  const [galleryMediaStatus, setGalleryMediaStatus] = useState<boolean>(false);
  const [addressStatus, setAddressStatus] = useState<boolean>(false);
  const [serviceStatus, setServiceStatus] = useState<boolean>(false);

  const [phoneNumberStatus, setPhoneNumberStatus] = useState<boolean>(false);
  const [clientNumberStatus, setClientNumberStatus] = useState<boolean>(false);

  const [showAlertModal, setShowAlertModal] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function treatModeleValidations(modeleResponse: any) {
    setModeleDetails(modeleResponse);

    if (modeleResponse?.picture) {
      setProfilePictureStatus(true);;
    }

    if (modeleResponse?.pictureCover) {
      setProfileCoverStatus(true);;
    }

    const visibleAndRevisedImages = modeleResponse?.pictureGalleryImages?.filter((image) =>
      image.visible === 'VISIBLE' && image.revised === true);
    if (visibleAndRevisedImages && visibleAndRevisedImages.length >= 3) {
      setGalleryMediaStatus(true);
    }
    else {
      setGalleryMediaStatus(false);
    }

    if (modeleResponse?.location?.country &&
      modeleResponse?.location?.state &&
      modeleResponse?.location?.city
    ) {
      setAddressStatus(true);;
    }

    if (modeleResponse?.params?.rent ||
      modeleResponse?.params?.fanClub ||
      modeleResponse?.params?.live ||
      modeleResponse?.params?.massage ||
      modeleResponse?.params?.stream
    ) {
      setServiceStatus(true);;
    }

    if (modeleResponse?.contacts &&
      modeleResponse?.contacts?.phoneDdi &&
      modeleResponse?.contacts?.phoneNumber
    ) {
      setPhoneNumberStatus(true);
    }

    if (modeleResponse?.clientNumber) {
      setClientNumberStatus(true);
    }
  }


  function renderLocationAprrove(locationReceived): void {
    const countryUser = locationReceived?.country;
    const filterCountry = countriesOption.find((country) => stringRawDataText(country.code) === stringRawDataText(countryUser));
    setCountryModele(filterCountry?.label);

    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((item: IInputSelect) => item.value === locationReceived?.state);
    const stateUser = findState?.label;
    setStateModele(stateUser);

    const getCityArray = returnCityArray(countryUser, findState?.value);
    const findCity = getCityArray?.find((item: IInputSelect) => item.value === locationReceived?.city);
    const cityUser = findCity?.label;
    setCityModele(cityUser);
  }


  async function getActualModele() {
    try {
      setLoading(true);

      const response = await GetModeleProfissionalPanel(userIdSearch);
      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      treatModeleValidations(responseModele?.modele);
      renderLocationAprrove(responseModele?.modele?.location);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  async function onApproveCompany() {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await ApproveModeleProfile(modeleDetails?.useralias);

      if (!response) {
        showSnackBarProps(Colors.danger, 'Erro ao publicar perfil');
        return;
      }

      showSnackBarProps(Colors.accept, 'perfil ativado com sucesso');
      history.goBack();
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function verifyStatusToVisible(): boolean {
    if (!profilePictureStatus || !profileCoverStatus || !galleryMediaStatus || !addressStatus || !serviceStatus) {
      return true;
    }

    return false;
  }



  function renderModalTitleVisible(): string {
    if (modeleDetails?.visible) {
      return 'Desativar perfil?';
    }

    if (!modeleDetails?.clientNumber) {
      return 'Número de cliente pendentes';
    }

    return '';
  }


  function renderModalDescriptionVisible(): string {
    if (modeleDetails?.visible) {
      return 'O perfil pode ser ativado novamente';
    }

    if (!modeleDetails?.clientNumber) {
      return 'Não há um número de cliente atrelado a esse perfil. Ativar mesmo assim?';
    }

    return '';
  }


  async function updateForm() {
    await getActualModele();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  useEffect(() => {
    updateForm();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          leftIcon={
            <ButtonBack
              color={Colors.black}
            />
          }
          centerContent={
            <Body>
              <Title
                color={Colors.black}>
                Visibilidade do perfil
              </Title>

              <SubTitle
                color={Colors.black}>
                @{userIdSearch || '-'}
              </SubTitle>
            </Body>
          }
        />



        <Content>

          {!mounted && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.secondary}
            />
          )}



          {mounted && (
            <CompanyApprovePadder>

              <ApproveSectionInfoCompanyView
                backgroundColor={Colors.formBackground}
                borderRadius={Sizes.cardRadius}>

                <DetailsApproveViewBackground>
                  <HeaderUserBackgroundOverlay />

                  <DetailsApproveBackground
                    src={validateString(modeleDetails?.pictureCover) || Images.bannerDetails}
                  />
                </DetailsApproveViewBackground>



                <DetailsApproveViewImage>
                  <DetailsApproveImage
                    src={validateString(modeleDetails?.picture) || Images.avatarUserImage}
                  />
                </DetailsApproveViewImage>



                <DetailsApproveContainer>

                  <DetailsSectionView>

                    <SectionTitle
                      title={'Dados cadastrados do perfil'}
                    />


                    <CompanyInfoContent>

                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Número de cliente:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {modeleDetails?.clientNumber || '-'}
                      </CompanyUserActiveText>



                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Nome:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {modeleDetails?.alias || '-'}
                      </CompanyUserActiveText>



                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Nome de usuário:
                        </CompanyUserActiveBoldText>
                        {' '}
                        @{modeleDetails?.useralias || '-'}
                      </CompanyUserActiveText>



                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Mostra o rosto:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {modeleDetails?.showFace ? 'Sim' : 'Não'}
                      </CompanyUserActiveText>

                    </CompanyInfoContent>

                  </DetailsSectionView>



                  <DetailsSectionView>

                    <SectionTitle
                      title={'Contato'}
                    />


                    <CompanyInfoContent>

                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Telefone:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {modeleDetails?.contacts?.phoneDdi && modeleDetails?.contacts?.phoneNumber
                          ? `${modeleDetails?.contacts?.phoneDdi} ${modeleDetails?.contacts?.phoneNumber}`
                          : '-'
                        }
                      </CompanyUserActiveText>

                    </CompanyInfoContent>

                  </DetailsSectionView>



                  <DetailsSectionView>

                    <SectionTitle
                      title={'Localização'}
                    />


                    <CompanyInfoContent>

                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          País:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {translate(countryModele) || '-'}
                      </CompanyUserActiveText>



                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Estado:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {stateModele || '-'}
                      </CompanyUserActiveText>



                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Cidade:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {cityModele || '-'}
                      </CompanyUserActiveText>

                    </CompanyInfoContent>

                  </DetailsSectionView>



                  <DetailsSectionView>

                    <SectionTitle
                      title={'Serviços ativos'}
                    />


                    <CompanyInfoContent>

                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Acompanhante:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {modeleDetails?.params?.rent ? 'Sim' : 'Não'}
                      </CompanyUserActiveText>



                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Fã-clube:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {modeleDetails?.params?.fanClub ? 'Sim' : 'Não'}
                      </CompanyUserActiveText>



                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Live:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {modeleDetails?.params?.live ? 'Sim' : 'Não'}
                      </CompanyUserActiveText>



                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Massagem:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {modeleDetails?.params?.massage ? 'Sim' : 'Não'}
                      </CompanyUserActiveText>



                      <CompanyUserActiveText>
                        <CompanyUserActiveBoldText>
                          Stream:
                        </CompanyUserActiveBoldText>
                        {' '}
                        {modeleDetails?.params?.stream ? 'Sim' : 'Não'}
                      </CompanyUserActiveText>

                    </CompanyInfoContent>

                  </DetailsSectionView>


                </DetailsApproveContainer>

              </ApproveSectionInfoCompanyView>



              <ApproveSectionStatusCompanyView>

                <ApproveAdsImageButton
                  backgroundColor={Colors.formBackground}
                  borderRadius={Sizes.cardRadius}>

                  <SectionTitle
                    title={'Status para publicar perfil'}
                  />


                  <CompanyToActiveContent>

                    <CompanyItemToActiveContent>

                      <CompanyItemToActiveView>
                        <CompanyItemToActiveText>
                          Foto de perfil:
                        </CompanyItemToActiveText>

                        <CompanyItemToActiveDescription
                          required={true}>
                          Item obrigatório
                        </CompanyItemToActiveDescription>
                      </CompanyItemToActiveView>


                      <CompanyItemStatusText
                        enabled={profilePictureStatus ? true : false}>
                        {profilePictureStatus ? 'Sim' : 'Não'}
                      </CompanyItemStatusText>

                    </CompanyItemToActiveContent>



                    <CompanyItemToActiveContent>

                      <CompanyItemToActiveView>
                        <CompanyItemToActiveText>
                          Foto de capa:
                        </CompanyItemToActiveText>

                        <CompanyItemToActiveDescription
                          required={true}>
                          Item obrigatório
                        </CompanyItemToActiveDescription>
                      </CompanyItemToActiveView>


                      <CompanyItemStatusText
                        enabled={profileCoverStatus ? true : false}>
                        {profileCoverStatus ? 'Sim' : 'Não'}
                      </CompanyItemStatusText>

                    </CompanyItemToActiveContent>



                    <CompanyItemToActiveContent>

                      <CompanyItemToActiveView>
                        <CompanyItemToActiveText>
                          Imagens na galeria:
                        </CompanyItemToActiveText>

                        <CompanyItemToActiveDescription
                          required={true}>
                          Item obrigatório
                        </CompanyItemToActiveDescription>

                        <CompanyItemDescStatusText>
                          Ao menos 3 imagens revisadas
                        </CompanyItemDescStatusText>
                      </CompanyItemToActiveView>


                      <CompanyItemStatusText
                        enabled={galleryMediaStatus}>
                        {galleryMediaStatus ? 'Sim' : 'Não'}
                      </CompanyItemStatusText>

                    </CompanyItemToActiveContent>



                    <CompanyItemToActiveContent>

                      <CompanyItemToActiveView>
                        <CompanyItemToActiveText>
                          Endereço:
                        </CompanyItemToActiveText>

                        <CompanyItemToActiveDescription
                          required={true}>
                          Item obrigatório
                        </CompanyItemToActiveDescription>
                      </CompanyItemToActiveView>


                      <CompanyItemStatusText
                        enabled={addressStatus ? true : false}>
                        {addressStatus ? 'Sim' : 'Não'}
                      </CompanyItemStatusText>

                    </CompanyItemToActiveContent>



                    <CompanyItemToActiveContent>

                      <CompanyItemToActiveView>
                        <CompanyItemToActiveText>
                          Serviço ativo:
                        </CompanyItemToActiveText>

                        <CompanyItemToActiveDescription
                          required={true}>
                          Item obrigatório
                        </CompanyItemToActiveDescription>

                        <CompanyItemDescStatusText>
                          Ao menos 1 serviço ativo, pondendo ser acompanhante, fã-clube, stream ou outros.
                        </CompanyItemDescStatusText>
                      </CompanyItemToActiveView>


                      <CompanyItemStatusText
                        enabled={serviceStatus ? true : false}>
                        {serviceStatus ? 'Sim' : 'Não'}
                      </CompanyItemStatusText>

                    </CompanyItemToActiveContent>



                    <CompanyItemToActiveContent>

                      <CompanyItemToActiveView>
                        <CompanyItemToActiveText>
                          Telefone:
                        </CompanyItemToActiveText>
                      </CompanyItemToActiveView>


                      <CompanyItemWarningText
                        enabled={phoneNumberStatus ? true : false}>
                        {phoneNumberStatus ? 'Sim' : 'Não'}
                      </CompanyItemWarningText>

                    </CompanyItemToActiveContent>



                    <CompanyItemToActiveContent>

                      <CompanyItemToActiveView>
                        <CompanyItemToActiveText>
                          Número de cliente:
                        </CompanyItemToActiveText>
                      </CompanyItemToActiveView>


                      <CompanyItemWarningText
                        enabled={clientNumberStatus ? true : false}>
                        {clientNumberStatus ? 'Sim' : 'Não'}
                      </CompanyItemWarningText>

                    </CompanyItemToActiveContent>

                  </CompanyToActiveContent>


                  <ContentButtonForm>
                    <ButtonAction
                      disabled={loading || verifyStatusToVisible()}
                      width={'100%'}
                      title={modeleDetails?.visible ? 'Desativar perfil' : 'Ativar perfil'}
                      onPress={() => {
                        if (loading) {
                          return;
                        }

                        if (!modeleDetails?.clientNumber) {
                          setShowAlertModal(true);
                          return;
                        }

                        if (verifyStatusToVisible()) {
                          return;
                        }

                        onApproveCompany();
                      }}
                    />
                  </ContentButtonForm>

                </ApproveAdsImageButton>

              </ApproveSectionStatusCompanyView>

            </CompanyApprovePadder>
          )}



          <FooterApp />

        </Content>

      </Container>



      <AlertMessage
        visible={showAlertModal}
        title={renderModalTitleVisible()}
        description={renderModalDescriptionVisible()}
        cancelText={translate(TranslateConfig.ACTION_NO)}
        cancelPress={() => {
          setShowAlertModal(false);
        }}
        okText={translate(TranslateConfig.ACTION_YES)}
        okPress={() => {
          setShowAlertModal(false);
          onApproveCompany();
        }}
      />

    </Screen>

  );
};



export default ModeleVisibleProfileScreen;
