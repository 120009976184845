export const DRAWER_ROUTES = {
  HomeScreen:                       '/home',
  RankingsScreen:                   '/ranking',
  AppDashboardScreen:               '/app/dashboard',
  ShopDashboardScreen:              '/shop/dashboard',

  ProjectParamsFormScreen:          '/project/params',
  ProjectMessagesFormScreen:        '/project/messages',
  PlansPricesShowAllScreen:         '/project/plans',

  ModelesAllListScreen:             '/project/modele',
  ModelesToPublishScreen:           '/project/modele-publish',

  GendersHighlightsScreen:          '/app/genders-highlights',
  RentServicesScreen:               '/app/rent/services',
  AvaliationsToReviseScreen:        '/app/publish/reviews',
  ProfileMediasApproveScreen:       '/app/publish/profile-medias',
  PicturesAproveAllScreen:          '/app/publish/pictures',
  VideosAproveAllScreen:            '/app/publish/videos',
  AntiFakeAllScreen:                '/app/publish/anti-fake',
  DeletedMediasScreen:              '/app/deleted/medias',

  CategoriesShowAllScreen:          '/stream/categories',
  SubCategoriesShowAllScreen:       '/stream/subcategories',
  ChannelsShowAllScreen:            '/stream/channels',
  StarsShowAllScreen:               '/stream/stars',
  VideosShowAllScreen:              '/stream/videos',
  StreamVideosAdsScreen:            '/stream/videos/ads',

  SocialScreen:                     '/marketing/social',
  WhatsAppAdsScreen:                '/marketing/whatsapp-ads',

  HelpFaqCategoriesShowScreen:      '/project/help/faq-categories',
  HelpFaqQuestionsShowScreen:       '/project/help/faq-questions',
  HelpRequestsShowScreen:           '/project/help/requests',

  ShopProductsScreen:               '/shop/products',

  SettingsAppScreen:                '/settings',
  HelpFaqScreen:                    '/help',
};


export const APP_ROUTES = {
  AccessScreen:                     '/',
  
  AppDownScreen:                    '/down',
  Error404Screen:                   '/404',
  
  CreateProjectMessageScreen:       '/project/messages/form',
  PlansPricesFormScreen:            '/project/plans/form',

  HelpFaqCategoriesFormScreen:      '/project/help/faq-categories/form',
  HelpFaqQuestionsFormScreen:       '/project/help/faq-questions/form',

  CreateModeleFormScreen:           '/user/form',
  EditParamsModeleFormScreen:       '/user/:useralias/chars',


  // App
  GenderHighlightsFormScreen:       '/app/genders-highlights/form',
  SupportRentServicesFormScreen:    '/app/rent/services/form',


  // Modeles Profile
  ModeleVisibleProfileScreen:       '/app/modele/:useralias/visible',
  ModeleLinkAccountScreen:          '/app/modele/:useralias/link-account',

  RentProfileModeleScreen:          '/app/modele/:useralias/rent-edit',

  PanelModeleScreen:                '/app/modele/:useralias',
  ModeleDataProfileScreen:          '/app/modele/:useralias/modele-data',
  ModeleLocationFormSection:        '/app/modele/:useralias/location',
  ModeleTravelSeeScreen:            '/app/modele/:useralias/travel',
  ModeleMediaAddScreen:             '/app/modele/:useralias/medias',
  ModeleMediasLotScreen:            '/app/modele/:useralias/medias-lot',
  ContactsModeleScreen:             '/app/modele/:useralias/contacts',
  ReviewModeleScreen:               '/app/modele/:useralias/reviews',
  VisibilityModeleScreen:           '/app/modele/:useralias/visibility-profile',

  
  // Stream
  CategoriesCreateScreen:           '/stream/categories/create',
  SubCategoriesCreateScreen:        '/stream/subcategories/create',
  ChannelsCreateScreen:             '/stream/channels/create',
  StarsDetailsScreen:               '/stream/stars/details',
  VideosCreateScreen:               '/stream/videos/create',
};



const NameRoutes = {
  ...DRAWER_ROUTES,
  ...APP_ROUTES,
};



export default NameRoutes;
