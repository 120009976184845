import IAppRoute from '../../apis/interfaces/App/app-route.interface';

import AccessScreen from '../../screens/Access';
import Error404Screen from '../../screens/Errors/404';
import AppDownScreen from '../../screens/Errors/AppDown';
import HelpFaqCategoriesFormScreen from '../../screens/Help/FAQ/Categories/Forms';
import HelpFaqQuestionsFormScreen from '../../screens/Help/FAQ/Questions/Forms';
import CreateProjectMessageScreen from '../../screens/Project/Messages/Forms';
import PlansPricesFormScreen from '../../screens/Project/Plans/Forms';
import GenderHighlightsFormScreen from '../../screens/Secretio/GendersHighlights/Forms';

import ContactsModeleScreen from '../../screens/Secretio/Modeles/Contacts';
import ModeleDataProfileScreen from '../../screens/Secretio/Modeles/DataProfile';
import ModeleLinkAccountScreen from '../../screens/Secretio/Modeles/LinkAccount';
import ModeleLocationFormSection from '../../screens/Secretio/Modeles/Location';
import ModeleMediaAddScreen from '../../screens/Secretio/Modeles/Medias';
import ModeleMediasLotScreen from '../../screens/Secretio/Modeles/MediasLot';
import PanelModeleScreen from '../../screens/Secretio/Modeles/Panel';
import RentProfileModeleScreen from '../../screens/Secretio/Modeles/RentProfile';
import ReviewModeleScreen from '../../screens/Secretio/Modeles/Reviews';
import ModeleTravelSeeScreen from '../../screens/Secretio/Modeles/Travels';
import VisibilityModeleScreen from '../../screens/Secretio/Modeles/VisibilityModele';
import ModeleVisibleProfileScreen from '../../screens/Secretio/Modeles/VisibleProfile';
import SupportRentServicesFormScreen from '../../screens/Secretio/RentService/Forms';

import CategoriesCreateScreen from '../../screens/Stream/Categories/Forms';
import SubCategoriesCreateScreen from '../../screens/Stream/CategoriesSub/Forms';
import ChannelsCreateScreen from '../../screens/Stream/Channels/Forms';
import StarsDetailsScreen from '../../screens/Stream/Stars/Details';
import VideosCreateScreen from '../../screens/Stream/Videos/Forms';

import CharactersProfileScreen from '../../screens/Users/CharactersProfile';
import CreateModeleFormScreen from '../../screens/Users/MainData';


import NameRoutes from '../names';



const ScreenRoutes: IAppRoute[] = [

  {
    path: NameRoutes.AccessScreen,
    page: AccessScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.AppDownScreen,
    page: AppDownScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.Error404Screen,
    page: Error404Screen,
    isPublic: true,
    exact: true,
  },



  // Projects

  {
    path: NameRoutes.CreateProjectMessageScreen,
    page: CreateProjectMessageScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.PlansPricesFormScreen,
    page: PlansPricesFormScreen,
    isPublic: false,
    exact: true,
  },



  // Help

  {
    path: NameRoutes.HelpFaqCategoriesFormScreen,
    page: HelpFaqCategoriesFormScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.HelpFaqQuestionsFormScreen,
    page: HelpFaqQuestionsFormScreen,
    isPublic: false,
    exact: true,
  },



  // Modeles

  {
    path: NameRoutes.CreateModeleFormScreen,
    page: CreateModeleFormScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.EditParamsModeleFormScreen,
    page: CharactersProfileScreen,
    isPublic: false,
    exact: true,
  },



  // App

  {
    path: NameRoutes.GenderHighlightsFormScreen,
    page: GenderHighlightsFormScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.SupportRentServicesFormScreen,
    page: SupportRentServicesFormScreen,
    isPublic: false,
    exact: true,
  },



  // Modeles Profile

  {
    path: NameRoutes.ModeleVisibleProfileScreen,
    page: ModeleVisibleProfileScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleLinkAccountScreen,
    page: ModeleLinkAccountScreen,
    isPublic: false,
    exact: true,
  },



  {
    path: NameRoutes.RentProfileModeleScreen,
    page: RentProfileModeleScreen,
    isPublic: false,
    exact: true,
  },



  {
    path: NameRoutes.PanelModeleScreen,
    page: PanelModeleScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleDataProfileScreen,
    page: ModeleDataProfileScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.ModeleLocationFormSection,
    page: ModeleLocationFormSection,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleTravelSeeScreen,
    page: ModeleTravelSeeScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleMediaAddScreen,
    page: ModeleMediaAddScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModeleMediasLotScreen,
    page: ModeleMediasLotScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ContactsModeleScreen,
    page: ContactsModeleScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ReviewModeleScreen,
    page: ReviewModeleScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.VisibilityModeleScreen,
    page: VisibilityModeleScreen,
    isPublic: true,
    exact: true,
  },



  // Streams

  {
    path: NameRoutes.CategoriesCreateScreen,
    page: CategoriesCreateScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.SubCategoriesCreateScreen,
    page: SubCategoriesCreateScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ChannelsCreateScreen,
    page: ChannelsCreateScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.StarsDetailsScreen,
    page: StarsDetailsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.VideosCreateScreen,
    page: VideosCreateScreen,
    isPublic: false,
    exact: true,
  },

];



export default ScreenRoutes;
