import ApiWS from '../index';



export const GetProjectPlanPrices = async (payload: any) => {
  const url = '/support/project/plans-prices';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const CreateProjectPlanPrices = async (payload: any) => {
  const url = '/support/project/plans-prices';

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const EditProjectPlanPrices = async (payload: any) => {
  const url = '/support/project/plans-prices';

  const response = await ApiWS.put(url, payload);
  return response.data;
};
