import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  PublishedContainer,
  PublishedViewContainer,
  SwitchStyled,
  PublishedHelpText,
} from './styled';



export interface IProps {
  disabled?: boolean;
  selected?: boolean;
  textOn?: string;
  textOff?: string;
  color?: string;
  helpText?: string;
  helpColor?: string;
  onPress?: any;
}



const ButtonEnable: React.FC<IProps> = (props: IProps) => {
  const accentGender = props.color || Colors.secondary;



  return (

    <PublishedContainer
      {...props}>

      <PublishedViewContainer>

        <SwitchStyled
          disabled={props.disabled}
          selected={props.selected}
          textOn={props.textOn}
          textOff={props.textOff}
          textColor={Colors.inputLabel}
          color={props.selected ? accentGender : Colors.inputCount}
          onPress={() => {
            if (!props.disabled && props.onPress) {
              props.onPress();
            }
          }}
        />


        {props.helpText && (
          <PublishedHelpText
            helpText={props.helpText}
            helpTextStyle={{
              color: props.helpColor || Colors.danger,
            }}
          />
        )}

      </PublishedViewContainer>

    </PublishedContainer>

  );
};



export default ButtonEnable;
