import styled from 'styled-components';

import {
  Card,
  Input,
} from '../../../../components/Composh/web';

import InputBoolean from '../../../../components/Inputs/InputBoolean';
import InputText from '../../../../components/Inputs/InputText';

import {
  Sizes,
} from '../../../../shared/constants';



export const CardContactsForm = styled(Card.View)`
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;



export const InputTextStyled = styled(InputText)`
`;

export const InputViewStyled = styled(Input.View)`
`;

export const InputBooleanStyled = styled(InputBoolean)`
`;

export const InputChipView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;



export const StarListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 7px;
`;

export const StarListHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 7px;
`;

export const StarListHeaderLabel = styled(Input.Label)`
`;

export const StarChipThumb = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
`;
