import styled from 'styled-components';

import {
  Images,
} from '../../../shared/constants';

import {
  Screen as ScreenComposh,
} from '../../Composh/web';



export const Screen = styled(ScreenComposh)`
  background: url(${Images.backgroundApp}) left top no-repeat;
  background-size: cover;
  background-attachment: fixed;
`;
