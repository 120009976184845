import React from 'react';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PanelItemsInfoView,
} from './styled';



export interface IProps {
  accent?: boolean;
  disabled?: boolean;
  onPress?: any;
  children?: any;
}



const ButtonPanelHover: React.FC<IProps> = (props: IProps) => {
  const disabled = props.disabled;



  return (

    <PanelItemsInfoView
      {...props}
      disabled={disabled}
      accent={props.accent}
      color={Colors.secondary}
      backgroundColor={Colors.cardBackground}
      borderRadius={Sizes.cardRadius}
      onPress={() => {
        if (!disabled && props.onPress) {
          props.onPress();
        }
      }}>

      {props.children}

    </PanelItemsInfoView>

  );
};



export default ButtonPanelHover;
