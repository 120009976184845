import styled from 'styled-components';

import {
  Sizes,
} from '../../../shared/constants';

import {
  Input,
  Switch,
} from '../../Composh/web';



export const PublishedContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 3px;
  padding-right: 3px;
`;

export const PublishedViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SwitchStyled = styled(Switch)`
  display: flex;
  min-height: ${Sizes.buttonHeight}px;
`;

export const PublishedHelpText = styled(Input.Help)`
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
`;
