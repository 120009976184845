import ApiWS from '../index';



export const EditCharUser = async (useralias: string, payload: any) => {
  const url = `/support/user/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const GetUserByUseralias = async (clientNumber: string) => {
  const url = `/support/user/${clientNumber}`;

  const response = await ApiWS.get(url);
  return response.data;
};
