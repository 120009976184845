import React,
{
  useEffect,
  useState,
} from 'react';

import BadgeApp from '../../../../../../components/Badges/BadgeApp';
import CardAddMedia from '../../../../../../components/Cards/CardAddMedia/CardAddMedia';
import CardPublishVideo from '../../../../../../components/Cards/CardPublishVideo';
import ChipApp from '../../../../../../components/Chip';

import {
  Content,
} from '../../../../../../components/Composh/web';

import EmptyContent from '../../../../../../components/Empty';
import FooterApp from '../../../../../../components/Footers/FooterApp';
import HeaderHelp from '../../../../../../components/Headers/HeaderHelp';

import {
  IconWorld,
  IconFire,
  IconLocked,
  IconMediaVideo,
} from '../../../../../../components/Icons';

import {
  Colors,
  Images,
} from '../../../../../../shared/constants';

import EAppMediaVisibility from '../../../../../../shared/enums/Status/app-media-visibility.enum';

import {
  MaskNotificationsBadge,
} from '../../../../../../shared/utils/masks.utils';

import RightVideoInfo from '../../Infos/InfoVideos';

import {
  ContentWrapperRowFull,
  ContentCenterFlex,
  MediaHeaderPadderTop,
  MediaPhotoGridView,
  MediaTypeContent,
} from './styled';



export interface IProps {
  modeleReceived?: any;
  onShowModal?: any;
  onOpenVideoModal?: any;
  tabArchivePress?: (index: string) => void;
  tabVisibleOrRevisedPress?: (index: string) => void;
  tabEditPress?: (index: string) => void;
  tabDeletePress?: (index: string) => void;
}



const TabAddVideos: React.FC<IProps> = (props: IProps) => {
  const modeleData = props.modeleReceived;


  const photosArray = modeleData?.pictureGalleryVideos || [];

  const [filteredPhotos, setFilteredPhotos] = useState<any[]>([]);
  const [typeImage, setTypeImage] = useState<EAppMediaVisibility | ''>('');


  const imagesOptions = [
    {
      title: 'Todas',
      typeImage: '',
      icon: (
        <IconMediaVideo
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.length,
    },
    {
      title: 'Abertas',
      typeImage: EAppMediaVisibility.PUBLIC,
      icon: (
        <IconWorld
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.filter((photo: any) => photo.visibility === EAppMediaVisibility.PUBLIC).length,
    },
    {
      title: 'FanClub',
      typeImage: EAppMediaVisibility.FAN_CLUB,
      icon: (
        <IconFire
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.filter((photo: any) => photo.visibility === EAppMediaVisibility.FAN_CLUB).length,
    },
    {
      title: 'Privadas',
      typeImage: EAppMediaVisibility.PRIVATE,
      icon: (
        <IconLocked
          color={Colors.white}
          size={18}
        />
      ),
      badgeCount: photosArray?.filter((photo: any) => photo.visibility === EAppMediaVisibility.PRIVATE).length,
    },
  ];



  function renderChipChooses(item: any, index: number) {
    return (

      <ChipApp
        key={index}
        title={item?.title}
        selected={typeImage === item?.typeImage}
        thumb={item?.icon}
        icon={
          <BadgeApp
            text={MaskNotificationsBadge(item?.badgeCount)}
            backgroundColor={Colors.black}
            color={Colors.white}
          />
        }
        onPress={() => {
          setTypeImage(item?.typeImage);
        }}
      />

    );
  }


  function renderItem(item: any, index: number) {
    return (

      <CardPublishVideo
        key={index}
        type={'MODELE'}

        image={item?.picture}
        visible={item?.visible}
        visibility={item?.visibility}
        revised={item?.revised}
        canBuy={item?.canBuy}
        duration={item?.duration}
        isHD={item?.isHD}

        onArchivePress={() => {
          props.tabArchivePress(item?.id);
        }}
        onVisibleOrRevisedPress={() => {
          props.tabVisibleOrRevisedPress(item?.id);
        }}
        onEditPress={() => {
          props.tabEditPress(item?.id);
        }}
        onDeletePress={() => {
          props.tabDeletePress(item?.id);
        }}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noImage}
        title={'Não há itens ainda!'}
      />

    );
  }



  useEffect(() => {
    if (typeImage === '') {
      setFilteredPhotos(photosArray);
    }
    else {
      const filtered = photosArray.filter((photo) => photo.visibility === typeImage);
      setFilteredPhotos(filtered);
    }
  }, [typeImage, photosArray]);



  return (

    <Content>

      <ContentWrapperRowFull>

        <ContentCenterFlex>

          <HeaderHelp
            title={'Publicar vídeo simples'}
            onHelpPress={() => {
              props.onShowModal();
            }}>


            <CardAddMedia
              title={'Adicionar vídeo simples'}
              onPress={() => {
                props.onOpenVideoModal();
              }}
            />

          </HeaderHelp>



          <MediaHeaderPadderTop>
            {imagesOptions.map((chip, index) => (
              renderChipChooses(chip, index)
            ))}
          </MediaHeaderPadderTop>



          <MediaPhotoGridView
            noScroll
            data={filteredPhotos}
            renderItem={renderItem}
            listEmptyComponent={listEmptyComponent()}
          />

        </ContentCenterFlex>



        <MediaTypeContent>
          <RightVideoInfo />
        </MediaTypeContent>

      </ContentWrapperRowFull>



      <FooterApp />

    </Content>

  );
};



export default TabAddVideos;
