import ApiWS from '../index';



export const GetStreamVideos = async (payload: any) => {
  const url = '/support/stream/videos';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const GetStreamParamsVideos = async () => {
  const url = '/support/stream/videos/params';

  const response = await ApiWS.get(url);
  return response.data;
};



export const CreateStreamVideos = async (payload: any) => {
  const url = '/support/stream/videos';

  const response = await ApiWS.post(url, payload);
  return response.data;
};



export const EditStreamVideos = async (id: string, payload: any) => {
  const url = `/support/stream/videos/${id}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const DeleteStreamVideos = async (id: any) => {
  const url = `/support/stream/videos/${id}`;

  const response = await ApiWS.put(url);
  return response.data;
};
