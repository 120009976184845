import React,
{
  useEffect,
  useState,
} from 'react';

import {
  IInputSelect,
} from '../../../../../apis/interfaces/App/input-select.interface';

import CardStarList from '../../../../../components/Cards/CardStarList';

import {
  Overlay,
  Title,
} from '../../../../../components/Composh/web';

import InputText from '../../../../../components/Composh/web/Inputs/InputText';

import {
  ButtonBack,
} from '../../../../../components/Controls';

import EmptyContent from '../../../../../components/Empty';
import HeaderScreen from '../../../../../components/Headers/HeaderScreen';

import {
  IconClose,
} from '../../../../../components/Icons';

import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import {
  Colors,
  Values,
} from '../../../../../shared/constants';

import {
  normalizeRawText,
} from '../../../../../shared/utils/string.utils';

import {
  StarSelectedContainer,
  FilteredInput,
  BankClearButton,
  AllModelesFlatList,
} from './styled';



export interface IProps {
  visible?: boolean;
  data: Array<IInputSelect & { picture: string }>;
  starList: Array<string>;
  onStarListChange: (updatedStarList: string[]) => void;
  onClose?: () => void;
}



const VideoSelectUserModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const starsData = props.data;

  const [loading, setLoading] = useState(false);

  const [filterCategory, setFilterCategory] = useState('');

  const [allCategories, setAllCategories] = useState<Array<any>>([]);
  const [filterArrayCategories, setFilterArrayCategories] = useState<Array<any>>([]);



  function handleUserSelect(id) {
    const updatedStarList = props.starList.includes(id)
      ? props.starList.filter((userId) => userId !== id) // Remove o ID se já estiver na lista
      : [...props.starList, id]; // Adiciona o ID se não estiver na lista

    // Atualiza a lista de usuários selecionados no componente pai
    props.onStarListChange(updatedStarList);
  }


  function renderItem(item: any, index: number) {
    return (

      <CardStarList
        key={index}
        name={item?.label}
        image={item?.picture}
        selected={props.starList.includes(item?.value)}
        onPress={() => {
          handleUserSelect(item?.value);
        }}
      />

    );
  }


  function returnListFiltered() {
    const getAllTofilterBankArray = allCategories?.filter((bankFilter: any) => normalizeRawText(bankFilter.label)?.toLowerCase().includes(normalizeRawText(filterCategory)?.toLowerCase() || '') || filterCategory === '');
    setFilterArrayCategories(getAllTofilterBankArray);
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.secondary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={'Images.logoIconAppWhite'}
        title={'Não há modelos ainda!'}
      />

    );
  }



  useEffect(() => {
    setLoading(true);

    setAllCategories(starsData);
    setFilterArrayCategories(starsData);

    setTimeout(() => {
      setLoading(false);
    }, Values.mountTime);
  }, []);



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={() => {
        if (props.onClose) {
          props.onClose();
        }
      }}>

      <StarSelectedContainer>

        <HeaderScreen
          leftIcon={
            <ButtonBack
              close
              color={Colors.black}
              onPress={() => {
                props.onClose();
              }}
            />
          }
          centerContent={
            <Title
              color={Colors.black}>
              {'Selecione os modelos'}
            </Title>
          }>

          <FilteredInput>
            <InputText
              disabled={loading}
              type={'TEXT'}
              iconRight={filterCategory === ''
                ? null
                : (
                  <BankClearButton
                    onClick={() => {
                      setFilterCategory('');
                    }}>
                    <IconClose
                      color={Colors.black}
                      size={28}
                    />
                  </BankClearButton>
                )
              }
              placeholderText={'Nome'}
              value={filterCategory}
              // helpText={errorMessage}
              // countLimit={Values.nameItem}
              onChange={(rawText: string) => {
                setFilterCategory(rawText);
                returnListFiltered();
              }}
            // onBlur={handleBlur('cpf')}
            />
          </FilteredInput>

        </HeaderScreen>



        <AllModelesFlatList
          data={filterArrayCategories}
          renderItem={renderItem}
          listLoadingComponent={renderLoading()}
          listEmptyComponent={listEmptyComponent()}
        />

      </StarSelectedContainer>

    </Overlay>

  );
};



export default VideoSelectUserModal;
