import React,
{
  useEffect,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  Title,
  Content,
  Body,
  SubTitle,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ButtonDrawer,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  APP_MENU_SELECTED_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
} from '../../../../shared/constants';

import {
  EDrawerMenu,
} from '../../../../shared/enums';



const ProfileMediasApproveScreen: React.FC = () => {
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.PROFILE_MEDIAS_TO_PUBLISH });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.black}>
              Mídias do perfil
            </Title>

            <SubTitle
              color={Colors.gray}>
              App
            </SubTitle>
          </Body>
        }
      />



      <Content>



      </Content>

    </Screen>

  );
};



export default ProfileMediasApproveScreen;
