import styled,
{
  css,
} from 'styled-components';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  ButtonAdd,
} from '../../../../components/Controls';

import InputSelect from '../../../../components/Inputs/InputSelect';
import InputText from '../../../../components/Inputs/InputText';
import SectionTitle from '../../../../components/Titles/SectionTitle';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export const TravelWrapperFull = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-top: ${Sizes.paddingContainer + 7}px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer + 7}px;

  @media ${PlatformDevices.maxLaptop} {
    padding-top: ${Sizes.paddingFlatList + 7}px;
    padding-left: ${Sizes.paddingFlatList}px;
    padding-right: ${Sizes.paddingFlatList}px;
    padding-bottom: ${Sizes.paddingFlatList + 7}px;
  }
`;



export const TravelHeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

export const TravelSectionTitle = styled(SectionTitle)`
  flex: 1;
  margin-bottom: 0;
`;

export const TravelHeaderButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
`;

export const TravelHeaderButtonAdd = styled(ButtonAdd)`
  @media ${PlatformDevices.minLaptopL} {
    display: none;
  }
`;



export const ContentRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  margin-top: 10px;
`;


export const TravelContentTabs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background-color: ${Colors.cardFooterOverlay};
  border-color: ${Colors.tabBackground};
  border-style: solid;
  border-width: 2px;
  border-radius: ${Sizes.cardRadius}px;

  @media ${PlatformDevices.minLaptopL} {
    border-width: 3px;
  }
`;



export const TravelInformationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 450px;
  min-width: 450px;
  margin-left: ${Sizes.contentMargin}px;
  border-radius: ${Sizes.cardRadius}px;

  @media ${PlatformDevices.maxLaptopL} {
    display: none;
  }
`;



export const TravelInformationsForm = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  background-color: ${Colors.formBackground};
`;



export const TravelHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const TravelHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  color: ${Colors.black};
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
`;

export const TravelHeaderButton = styled.a`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-bottom: 10px;
`;



export const InputmarginStyle = css`
  margin-left: 5px;
  margin-right: 5px;
`;

export const InputTextStyled = styled(InputText)`
  ${InputmarginStyle};
  width: calc(50% - 10px);
`;

export const InputSelectStyled = styled(InputSelect)`
  ${InputmarginStyle};
  width: calc(50% - 10px);
`;

export const InputObsTextStyled = styled(InputText)`
  ${InputmarginStyle};
  width: calc(100% - 10px);
`;



export const TravelLocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TravelColumnPlaceView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TravelRowDateView = styled.div`
  display: flex;
  flex-direction: row;
`;



export const TravelSwitchButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TravelSwitchText = styled.p`
  color: ${Colors.black};
  font-size: 14px;
  text-align: left;
`;



export const TravelButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TravelButtonsCloeAction = styled(ButtonAction)`
  margin-top: 15px;
`;
