import EAppMediaTypeRelation from '../../enums/Picture/app-media-type-relation.enum';



export const picureMediaTypeRelationArray = [
  {
    id: 1,
    value: EAppMediaTypeRelation.USER_IMAGE,
    label: 'Perfil',
  },
  {
    id: 2,
    value: EAppMediaTypeRelation.USER_COVER,
    label: 'Capa',
  },
  {
    id: 3,
    value: EAppMediaTypeRelation.GALLERY_IMAGES,
    label: 'Galeria',
  },
  {
    id: 4,
    value: EAppMediaTypeRelation.GALLERY_VIDEOS,
    label: 'Galeria',
  },
];
