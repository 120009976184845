import styled from 'styled-components';

import Color from 'color';

import {
  Colors,
} from '../../../shared/constants';



export interface IProps {
  backgroundColor?: string;
}


export const DrawerViewTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  margin-bottom: 10px;
  padding: 0 12px;
  background-color: ${(props: IProps) => props.backgroundColor
    ? Color(props.backgroundColor).darken(0.4).toString()
    : Colors.cardBackground};
  box-shadow: inset 0 8px 15px -8px ${Colors.cardOverlay}, inset 0 -4px 8px -4px ${Colors.cardOverlay};
`;

export const MenuEpigraphSection = styled.h2`
  color: ${Colors.white};
  font-size: 15px;
  font-weight: normal;
`;
