import React from 'react';

import {
  Colors,
} from '../../shared/constants';

import {
  Chip,
} from '../Composh/web';

import {
  IconAdd,
  IconCheck,
} from '../Icons';



export interface IProps {
  thumb?: any;
  hideIcon?: boolean;
  icon?: any;
  disabled?: boolean;
  selected?: boolean;
  title?: any;
  backgroundColor?: string;
  color?: string;
  selectedBackgroundColor?: string;
  selectedColor?: string;
  onPress?: any;
}



const ChipApp: React.FC<IProps> = (props: IProps) => {
  const accentGender = props.selectedBackgroundColor || Colors.secondaryDark;



  return (

    <Chip
      onCloseDisabled
      disabled={props.disabled}
      selected={props.selected}
      title={props.title}
      textColor={props.color || Colors.inputPlaceholder}
      backgroundColor={props.backgroundColor || Colors.inputBackground}
      selectedTextColor={props.selectedColor || Colors.white}
      selectedBackgroundColor={accentGender}
      thumb={props.thumb}
      icon={props.hideIcon
        ? null
        : props.icon
          ? props.icon
          : props.selected
            ? (
              <IconCheck
                size={18}
                color={Colors.white}
              />
            )
            : (
              <IconAdd
                size={18}
                color={Colors.inputPlaceholder}
              />
            )
      }
      onPress={props.onPress}
    />

  );
};



export default ChipApp;
