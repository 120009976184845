import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  IconPlane,
} from '../../Icons';

import {
  PanelLocationsContainer,
  PanelLocationsIcon,
  PanelLocationsView,
  PanelLocationsTitle,
  PanelLocationsText,
} from './styled';



export interface IProps {
  title?: any;
  travelCount?: any;
  onPress?: any;
}



const ButtonPanelTravels: React.FC<IProps> = (props: IProps) => {
  function renderTravelTitle(): string {
    if (props.travelCount <= 0) {
      return 'Sem viagens futuras';
    }

    if (props.travelCount === 1) {
      return '1 viagem futura';
    }

    if (props.travelCount > 1) {
      return `${props.travelCount} viagens futuras`;
    }

    return '';
  }



  return (

    <PanelLocationsContainer
      onPress={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <PanelLocationsView>

        <PanelLocationsIcon>
          <IconPlane
            color={Colors.black}
            size={27}
          />
        </PanelLocationsIcon>


        <PanelLocationsTitle>
          {props.title || '-'}
        </PanelLocationsTitle>

      </PanelLocationsView>



      <PanelLocationsText>
        {renderTravelTitle()}
      </PanelLocationsText>

    </PanelLocationsContainer>

  );
};



export default ButtonPanelTravels;
