import React from 'react';

import LoadingProgress from '../LoadingProgress';

import {
  LoadingContainer,
} from './styled';



export interface IProps {
  color?: string;
  type?: 'FOOTER' | 'SCREEN';
}



const LoadingScreen: React.FC<IProps> = (props: IProps) => {
  const sizeLoad = 40;
  const colorLoad = props.color;


  return (

    <LoadingContainer
      type={props.type}>

      <LoadingProgress
        size={sizeLoad}
        color={colorLoad}
      />

    </LoadingContainer>

  );
};



LoadingScreen.defaultProps = {
  type: 'FOOTER',
};



export default LoadingScreen;
