import React,
{
  useEffect,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import {
  TryRevalidateSession,
} from '../../apis/endpoints/auth.endpoints';

import AlertMessage from '../../components/Alerts';
import ModeleBrandPromoterModal from '../../modals/ModeleBrandPromoter';

import {
  RootState,
} from '../../redux';

import {
  APP_DRAWER_ACTION,
  USER_BRAND_PROMOTER_MODAL,
} from '../../redux/reducers/app-modals.store';

import {
  USER_SET,
  USER_LOGOUT_STATE,
  USER_LOGGED,
} from '../../redux/reducers/user.store';

import {
  Colors,
} from '../../shared/constants';

import {
  ETokenAsync,
} from '../../shared/enums';

import {
  TranslateConfig,
} from '../../shared/translations';

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../shared/utils/local-storage.utils';

import DrawerMenu from '../drawer/drawer.menu';

import NameRoutes from '../names';

import DrawerRoutes from './drawer.routes';
import ScreenRoutes from './screens.routes';



function AppRoutes() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { t: translate } = useTranslation();


  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const logout = useSelector((state: RootState) => state.user.logout);
  const drawerState = useSelector((state: RootState) => state.appModals.drawer);

  const openVerifiedModal = useSelector((state: RootState) => state.appModals.brandPromoterModal);


  const ScreensApp = [
    ...DrawerRoutes,
    ...ScreenRoutes,
  ];


  function setValues(idUser: string, newToken: string): void {
    try {
      setLocalStorageItem(ETokenAsync.USER_ID, idUser);
      setLocalStorageItem(ETokenAsync.USER_TOKEN, newToken);
      // setLocalStorageItem(ETokenAsync.USER_DATA, JSON.stringify(data)); // FIXME

      dispatch({ type: USER_LOGGED, payload: true });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function clearValues(): void {
    try {
      localStorage.clear();
      dispatch({ type: USER_LOGGED, payload: false });
      dispatch({ type: USER_LOGOUT_STATE, payload: false });
      dispatch({ type: USER_SET, payload: {} });
      // dispatch({ type: USER_UPDATE, payload: {} });
      dispatch({ type: APP_DRAWER_ACTION, payload: false });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function logoffAccount() {
    clearValues();

    console.log('Logoff');
  }


  // Try to validate user and logged in
  async function tryValidateAndLogin() {
    const userId = getLocalStorageItem(ETokenAsync.USER_ID);
    const token = getLocalStorageItem(ETokenAsync.USER_TOKEN);

    const cssText = `color: ${Colors.danger}`;

    try {
      if (!loggedIn && userId && token) {
        const data = await TryRevalidateSession(token);
        const newToken = data?.token;
        const idUser = data?.user?.id;

        if (newToken) {
          setValues(idUser, newToken);
        }
        else {
          clearValues();
          console.log(`%c${'NOT_AUTHORIZED'}`, cssText);
        }
      }
      else if (!loggedIn && (!userId || !token)) {
        clearValues();
        console.log(`%c${'NOT_AUTHORIZED'}`, cssText);
      }
    }
    catch (error: any) {
      console.error(error);

      clearValues();
      console.log(`%c${'NOT_AUTHORIZED'}`, cssText);
    }
  }



  useEffect(() => {
    tryValidateAndLogin();
  }, []);



  return (

    <>

      <Switch>

        {ScreensApp.map(
          ({ path, page: Page, exact, isPublic }, index) => {
            return isPublic
              ? (
                <Route
                  key={index}
                  path={path}
                  exact={exact}>

                  <Page />

                </Route>
              )
              : (
                <Route
                  key={index}
                  path={path}
                  exact={exact}
                  render={({ location }) => {
                    if (loggedIn) {
                      return <Page />;
                    }

                    // Redirect to Login Page
                    return (
                      <Redirect
                        to={{
                          pathname: NameRoutes.AccessScreen,
                          state: { from: location },
                        }}
                      />
                    );
                  }}
                />
              );
          },
        )}


        <Route>
          <Redirect to={NameRoutes.Error404Screen} />
        </Route>

      </Switch>



      <DrawerMenu
        open={drawerState}
        onOpen={() => {
          dispatch({ type: APP_DRAWER_ACTION, payload: true });
        }}
        onClose={() => {
          dispatch({ type: APP_DRAWER_ACTION, payload: false });
        }}
      />



      <ModeleBrandPromoterModal
        visible={openVerifiedModal}
        onClose={() => {
          dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: false });
        }}
      />



      <AlertMessage
        visible={logout}
        title={translate(TranslateConfig.QUESTION_EXIT)}
        cancelText={translate(TranslateConfig.NO)}
        cancelPress={() => {
          dispatch({ type: USER_LOGOUT_STATE, payload: false });
        }}
        okText={translate(TranslateConfig.YES)}
        okPress={() => {
          logoffAccount();
        }}
      />

    </>

  );
}



export default AppRoutes;
