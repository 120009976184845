import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  TableBottomStyled,
  TableTopStyled,
} from '../../../styles/table.layout';



export interface IProps {
  color?: string;
  accentColor?: string;
  selected?: boolean;
  topBorder?: boolean;
  bottomBorder?: boolean;

  displayNone?: boolean;
}



export const TableControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;

  ${(props: IProps) => props.topBorder && TableTopStyled};
  ${(props: IProps) => props.bottomBorder && TableBottomStyled};

  @media ${PlatformDevices.maxTabletL} {
    flex-direction: column;
  }
`;

export const TableControlsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  @media ${PlatformDevices.maxTabletL} {
    width: 100%;
  }
`;



export const ButtonNormal = css`
  background-color: ${(props: IProps) => props.color};
  border: 1px solid rgba(0, 0, 0, .125) !important;
`;

export const ButtonSelected = css`
  background-color: ${(props: IProps) => props.accentColor};
  border: 1px solid ${Colors.primary};
`;

export const TableButtonControl = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 7px;
  ${(props: IProps) => props.selected ? ButtonSelected : ButtonNormal};
  border: 1px solid transparent;
  border-radius: 0.25rem;

  /* :hover {
    color: ${Colors.white} !important;
    background-color: ${Colors.secondary};
    border-color: ${Colors.secondary};
  } */
`;

export const TableSpanControl = styled.p`
  color: ${(props: IProps) => props.selected ? props.color : props.accentColor};
  font-size: 13px;
  font-weight: ${(props: IProps) => props.selected ? 'bold' : '500'};
`;


export const TableFooterControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 8px;

  @media ${PlatformDevices.maxTabletL} {
    justify-content: flex-end;
  }
`;

export const TableTotalSpan = styled.span`
  color: ${(props: IProps) => props.color};
  font-size: 15px;
  line-height: 19px;
  
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
  text-overflow: ellipsis;
`;



export const TablePaginationHeaderView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-height: 30px;
  margin-left: 10px;

  @media ${PlatformDevices.maxTabletL} {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }
`;


export const TablePaginationFooterView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  min-height: 30px;
`;
