import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  CreateStreamChannels,
  EditStreamChannels,
} from '../../../../apis/endpoints/stream-channels.endpoints';

import {
  StreamMediasPicture,
} from '../../../../apis/endpoints/stream-medias.endpoints';

import {
  IInputSelect,
} from '../../../../apis/interfaces/App/input-select.interface';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Container,
  Content,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import InputSelect from '../../../../components/Inputs/InputSelect';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import PictureBackground from '../../../../components/Picture/PictureBackground';
import PictureOrderItem from '../../../../components/Picture/PictureOrderItem';
import RequiredText from '../../../../components/Titles/TextRequired';

import DataSelectModal from '../../../../modals/DataSelect';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import { typeGenre } from '../../../../shared/arrays/Stream/genre.array';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  EUploadType,
} from '../../../../shared/enums';

import EStreamMediaRelation from '../../../../shared/enums/Picture/stream-media-relation.enum';
import EStreamMediaTypeRelation from '../../../../shared/enums/Picture/stream-media-type-relation.enum';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  stringFullWordCapitalize,
} from '../../../../shared/utils/string.utils';

import {
  CarnivalCabinHeaderView,
  CarnivalCabinBackgroundView,
  CarnivalCabinBackgroundText,
  CarnivalCabinImagesContent,
  CardContactsForm,
  InputBooleanStyled,
  InputTextStyled,
} from './styled';



export interface IRouteLocation {
  edit: boolean;
  item?: any;
}



const CategoriesCreateScreen: React.FC = () => {
  const dispatch = useDispatch();

  const routeLocation = useLocation();
  const params = routeLocation?.state as IRouteLocation;


  const formik = useRef<FormikProps<any>>(null);

  const editForm = params?.edit;
  const [itemToEdit, setItemToEdit] = useState<any | any>(params?.item);

  const [picture, setPicture] = useState<any | null>(null);
  const [itemPicture, setItemPicture] = useState<any | null>(null);

  const [background, setBackground] = useState<any | null>(null);
  const [itemBackground, setItemBackground] = useState<any | null>(null);

  const [needRevision, setNeedRevision] = useState<boolean>(false);
  const [visibleItem, setVisibleItem] = useState<boolean>(false);

  const allGenreTypes: Array<IInputSelect> = typeGenre;
  const [selectedGenreType, setSelectedGenreType] = useState<IInputSelect | null>(null);
  const [genreTypeSelectModal, setGenreTypeSelectModal] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    if (editForm && formik.current) {
      setItemPicture(itemToEdit?.picture);
      setItemBackground(itemToEdit?.background);

      formik.current?.setFieldValue('name', itemToEdit?.name);
      formik.current?.setFieldValue('username', itemToEdit?.username);
      formik.current?.setFieldValue('description', itemToEdit?.description);

      const findGenre = allGenreTypes?.find((item) => item.value === itemToEdit?.genre);
      formik.current?.setFieldValue('genre', findGenre?.value);
      setSelectedGenreType(findGenre || null);

      setNeedRevision(itemToEdit?.needRevision ? itemToEdit?.needRevision : false);
      formik.current?.setFieldValue('needRevision', itemToEdit?.needRevision);

      setVisibleItem(itemToEdit?.visible ? itemToEdit?.visible : false);
      formik.current?.setFieldValue('visible', itemToEdit?.visible);

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function resetForm() {
    setMounted(false);

    setPicture(null);
    setItemPicture(null);
    setBackground(null);
    setItemBackground(null);

    setSelectedGenreType(null);

    setNeedRevision(false);
    setVisibleItem(false);

    formik.current?.resetForm();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  };


  function mountPayload() {
    const values = formik?.current?.values;

    const payload: any = {
      name: stringFullWordCapitalize(values.name),
      username: values.username,
      description: values.description,
      genre: values.genre,
      needRevision: values.needRevision,
      visible: values.visible,
    };

    return payload;
  }


  async function savePicture(id: string, statusResponse: boolean) {
    const payload = {
      relation: EStreamMediaRelation.CHANNEL,
      typeRelation: EStreamMediaTypeRelation.CHANNEL_IMAGE,
    };

    const idItemCreated = id;
    const imgUpdated = await StreamMediasPicture(idItemCreated, picture, payload);

    if (statusResponse && imgUpdated) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
    }
    else if (statusResponse && !imgUpdated) {
      showSnackBarProps(Colors.danger, 'Item salvo mas a imagem não foi salva');
    }
    else {
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function saveBackground(id: string, statusResponse: boolean) {
    const payload = {
      relation: EStreamMediaRelation.CHANNEL,
      typeRelation: EStreamMediaTypeRelation.CHANNEL_COVER,
    };

    const idItemCreated = id;
    const imgUpdated = await StreamMediasPicture(idItemCreated, background, payload);

    if (statusResponse && imgUpdated) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
    }
    else if (statusResponse && !imgUpdated) {
      showSnackBarProps(Colors.danger, 'Item salvo mas a imagem não foi salva');
    }
    else {
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function createEvent() {
    const payload = mountPayload();

    const response = await CreateStreamChannels(payload);

    if (response) {
      if (picture && background) {
        await savePicture(response?.data?.id, response);
        await saveBackground(response?.data?.id, response);
      }
      else if (picture) {
        await savePicture(response?.data?.id, response);
      }
      else if (background) {
        await saveBackground(response?.data?.id, response);
      }
      else {
        showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
      }
      resetForm();
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function editEvent() {
    const payload = mountPayload();

    const itemToSave = {
      id: itemToEdit?.id,
      ...payload,
    };

    setItemToEdit(itemToSave);

    const response = await EditStreamChannels(itemToEdit?.id, payload);

    if (response) {
      if (picture && background) {
        await savePicture(itemToEdit?.id, response);
        await saveBackground(itemToEdit?.id, response);
      }
      else if (picture) {
        await savePicture(itemToEdit?.id, response);
      }
      else if (background) {
        await saveBackground(itemToEdit?.id, response);
      }
      else {
        showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
      }
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function saveEvent() {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      if (editForm) {
        await editEvent();
      }
      else {
        await createEvent();
      }
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function onPressPictureChange(event: any) {
    const eventImageFile = event.target.files[0];

    if (event) {
      const link = window.URL.createObjectURL(eventImageFile);
      setPicture(eventImageFile);
      setItemPicture(link);
    }
    else {
      setPicture(null);
      setItemPicture(null);
    }
  }


  function onPressBackgroundChange(event: any) {
    const eventImageFile = event.target.files[0];

    if (event) {
      setBackground(eventImageFile);
      const link = window.URL.createObjectURL(eventImageFile);
      setItemBackground(link);
    }
    else {
      setBackground(null);
      setItemBackground(null);
    }
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveEvent();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <Container>

            <HeaderScreen
              leftIcon={
                <ButtonBack
                  color={Colors.black}
                  size={27}
                />
              }
              centerContent={
                <Title
                  color={Colors.black}>
                  {editForm
                    ? 'Editar canal'
                    : 'Criar canal'
                  }
                </Title>
              }
            />



            <Content>

              {!mounted && (
                renderLoading()
              )}



              {mounted && (
                <ContentPadder>

                  <CardContactsForm
                    backgroundColor={Colors.formBackground}
                    borderRadius={Sizes.cardRadius}>

                    <CarnivalCabinHeaderView>

                      <CarnivalCabinBackgroundView>

                        <PictureBackground
                          idButton={EUploadType.CHANNEL_cover}
                          disabled={loading}
                          fileImage={background}
                          image={itemBackground}
                          onPress={(event: any) => {
                            onPressBackgroundChange(event);
                          }}
                        />


                        <CarnivalCabinBackgroundText>
                          Clique para alterar o plano de fundo
                        </CarnivalCabinBackgroundText>


                      </CarnivalCabinBackgroundView>


                      <CarnivalCabinImagesContent>

                        <PictureOrderItem
                          idButton={EUploadType.CHANNEL_IMAGE}
                          disabled={loading}
                          fileImage={picture}
                          image={itemPicture}
                          onPress={(event: any) => {
                            onPressPictureChange(event);
                          }}
                        />

                      </CarnivalCabinImagesContent>

                    </CarnivalCabinHeaderView>



                    <RequiredText>
                      * {'Campos obrigatórios'}
                    </RequiredText>



                    <InputTextStyled
                      disabled={loading}
                      autoCorrect
                      autoCapitalize={'words'}
                      type={'TEXT'}
                      labelText={`${'Nome do canal'} *`}
                      placeholderText={`${'Nome'} *`}
                      errorText={errors.name}
                      countLimit={Values.nameMaxCount}
                      value={values.name}
                      onChange={handleChange('name')}
                      onBlur={handleBlur('name')}
                    />



                    <InputTextStyled
                      disabled={loading}
                      autoCorrect
                      autoCapitalize={'words'}
                      type={'TEXT'}
                      labelText={`${'Nome de usuário do canal'} *`}
                      placeholderText={`${'Nome de usuário'} *`}
                      errorText={errors.username}
                      countLimit={Values.nameMaxCount}
                      value={values.username}
                      onChange={handleChange('username')}
                      onBlur={handleBlur('username')}
                    />



                    <InputTextStyled
                      disabled={loading}
                      autoCorrect
                      autoCapitalize={'words'}
                      type={'TEXT'}
                      labelText={`${'Descrição do canal'} *`}
                      placeholderText={`${'Descrição'} *`}
                      errorText={errors.description}
                      countLimit={Values.nameMaxCount}
                      value={values.description}
                      onChange={handleChange('description')}
                      onBlur={handleBlur('description')}
                    />



                    <InputSelect
                      disabled={loading}
                      labelText={`${'Gênero do canal'} *`}
                      placeholderText={selectedGenreType?.label}
                      onPress={() => {
                        setGenreTypeSelectModal(true);
                      }}
                    />



                    <InputBooleanStyled
                      disabled={loading}
                      labelText={'Precisa de revisão?'}
                      selected={needRevision}
                      onPress={(state: boolean) => {
                        setFieldValue('needRevision', state);
                        setNeedRevision(state);

                        if (state) {
                          setFieldValue('visible', false);
                          setVisibleItem(false);
                        }
                      }}
                    />



                    <InputBooleanStyled
                      disabled={loading || needRevision}
                      labelText={'Visível'}
                      selected={visibleItem}
                      onPress={(state: boolean) => {
                        setFieldValue('visible', state);
                        setVisibleItem(state);
                      }}
                    />



                    <ContentButtonForm>
                      <ButtonAction
                        width={Sizes.buttonMinWidth}
                        title={'Salvar'}
                        onPress={() => {
                          handleSubmit();
                        }}
                      />
                    </ContentButtonForm>

                  </CardContactsForm>

                </ContentPadder>
              )}



              <FooterApp />

            </Content>

          </Container>



          <DataSelectModal
            title={'Gênero do canal'}
            visible={genreTypeSelectModal}
            data={allGenreTypes}
            selectedValue={selectedGenreType?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedGenreType(typeReturn);
              setFieldValue('genre', typeReturn?.value);
            }}
            onClose={() => {
              setGenreTypeSelectModal(false);
            }}
          />

        </Screen>

      )}
    </Formik>

  );
};



export default CategoriesCreateScreen;
