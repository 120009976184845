import styled from 'styled-components';



// font-family: 'Flirty Font', 'Mont Bold', sans-serif !important;
export const RankingTitle = styled.h2`
font-family: 'Flirty Font', 'Mont Bold', sans-serif !important;
font-size: 3em;
color: #ebd487;
`;



export const FflirtyAwardsSingle = styled.div`
overflow: hidden;
max-width: 520px;
border-radius: 10px;
margin-bottom: 40px;
background-color: #f0922a;
`;


export const FaContestTop = styled.div`
display: flex;
flex-direction: column;
position: relative;
padding-bottom: 25px;
background: #f0922a;
`;

export const FaContestTopImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


export const FaContestInfo = styled.div`
display: flex;
flex-direction: column;
padding: 15px 10px 20px;
z-index: 5;
`;

export const FaContestInfoTitle = styled.h3`
color: #fff;
font-family: 'Mont Bold', sans-serif !important;
font-size: 24px;
text-shadow: 0px 2px 3px rgba(0,0,0,0.3);
`;

export const FaContestInfoDescription = styled.p`
color: #fff;
font-size: 14px;
font-weight: 600;
text-shadow: 0px 2px 3px rgba(0,0,0,0.2);
min-height: 60px;
`;

export const FaContestFinalResultText = styled.p`
color: #fff;
text-align: center;
padding: 12px 0 10px;
margin-bottom: 10px;
text-transform: uppercase;
background-color: rgba(0,0,0,0.3);
font-size: 18px;
text-shadow: 0px 2px 3px rgba(0,0,0,0.3);
z-index: 5;
`;



export const FaContestTopThreeView = styled.div`
display: flex;
justify-content: space-around;
align-items: center;
text-align: center;
`;


export const FaRank1 = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 40%;
order: 2;
`;

export const FaRank1ModelImageView = styled.a`
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
font-family: Tahoma,Verdana;
line-height: normal;
text-align: center;
font-size: 100%;
color: #9e0b0f;
margin: 0 auto;
background: #444444;
border-radius: 50%;
position: relative;
display: flex;
text-decoration: none;
box-shadow: inset 0px 0px 20px rgba(0,0,0,0.5);
width: 150px;
height: 150px;
background-position: center;
border: 5px solid #e0c15a;
`;

export const FaRank1ModelImage = styled.img`
position: absolute;
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 50%;
`;

export const FaRank1FaPrizeAmount = styled.div`
display: flex;
flex-direction: row;
align-items: flex-end;
justify-content: center;
width: 100%;
margin-bottom: 12%;
`;

export const FaRank1FaPrizeAmountView = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
background: rgba(0,0,0,0.5);
z-index: 9;
display: flex;
width: 77%;
border-bottom-left-radius: 50px;
border-bottom-right-radius: 50px;
`;

export const FaRank1FaPrizeAmountText = styled.p`
color: #fff;
text-shadow: 1px 0px 2px rgba(0,0,0,0.69);
font-size: 18px;
`;

export const FaRank1Shield = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: -28px;
  width: 100%;
  filter: drop-shadow(0px 2px 2px rgba(0,0,0,0.2));
`;

export const FaRank1ShieldImage = styled.img`
  width: 135px;
  object-fit: contain;
`;

export const FaRank1ModelInformation = styled.div`
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
font-family: Tahoma,Verdana;
line-height: normal;
color: #fff;
text-align: center;
padding: 0;
outline: 0;
border: 0;
background: transparent;
vertical-align: baseline;
font-size: 100%;
margin: 25px 0 0;
z-index: 5;
`;

export const FaRank1ModelInfoView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FaRank1ModelOnline = styled.div`
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: green;
  border-radius: 50%;
`;

export const FaRank1ModelName = styled.h1`
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
line-height: normal;
color: #fff;
text-align: center;
margin: 0;
padding: 0;
outline: 0;
border: 0;
background: transparent;
vertical-align: baseline;
font-family: 'Mont Bold', sans-serif !important;
font-size: 14px;
text-shadow: 0px 2px 3px rgba(0,0,0,0.3);
text-transform: capitalize;
display: flex;
align-items: center;
justify-content: center;
`;

export const FaRank1ModelVote = styled.p`
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
line-height: normal;
color: #fff;
text-align: center;
padding: 0;
outline: 0;
border: 0;
background: transparent;
vertical-align: baseline;
font-size: 100%;
font-family: 'Mont Semi Bold', sans-serif !important;
margin: 3px 0;
text-shadow: 0px 2px 3px rgba(0,0,0,0.3);
font-style: italic;
`;

export const FaRank1ModelVoteButton = styled.a`
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
line-height: normal;
text-align: center;
font-size: 100%;
position: relative;
cursor: not-allowed;
text-decoration: none;
padding: 7px 25px;
display: inline-flex;
margin: 5px 0 0;
border-radius: 20px;
font-family: 'Mont Bold';
background-color: rgba(134,82,24,0.7);
color: rgba(255,255,255,0.7);
`;

export const FaRank1ModelVoteText = styled.p`
`;



export const FaRank2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  order: 3;
`;

export const FaRank2ModelImageView = styled.a`
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
font-family: Tahoma,Verdana;
line-height: normal;
text-align: center;
font-size: 100%;
color: #9e0b0f;
width: 110px;
height: 110px;
margin: 0 auto;
background: #444444;
border-radius: 50%;
position: relative;
background-size: cover;
background-position: center;
display: flex;
text-decoration: none;
box-shadow: inset 0px 0px 20px rgba(0,0,0,0.5);
border: 5px solid #b2bdc0;
background-image: url('https://cdn4.vscdns.com/images/models/samples-640x480/3874462.jpg');
`;

export const FaRank2FaPrizeAmountText = styled.p`
  color: #fff;
  font-size: 14px;
  text-shadow: 1px 0px 2px rgba(0,0,0,0.69);
`;

export const FaRank2ShieldImage = styled.img`
  width: 110px;
  object-fit: contain;
`;



export const FaRank3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  order: 1;
`;

export const FaRank3ModelImageView = styled.a`
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
font-family: Tahoma,Verdana;
line-height: normal;
text-align: center;
font-size: 100%;
color: #9e0b0f;
margin: 0 auto;
background: #444444;
border-radius: 50%;
position: relative;
background-size: cover;
background-position: center;
display: flex;
text-decoration: none;
box-shadow: inset 0px 0px 20px rgba(0,0,0,0.5);
width: 100px;
height: 100px;
border: 5px solid #af5443;
background-image: url('https://cdn4.vscdns.com/images/models/samples-640x480/4018637.jpg');
`;


export const FaContentBottom = styled.div`
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
font-family: Tahoma,Verdana;
line-height: normal;
color: #fff;
margin: 0;
padding: 0;
outline: 0;
border: 0;
background: transparent;
vertical-align: baseline;
font-size: 100%;
`;


