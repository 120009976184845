import React from 'react';

import { reviewAmbienceArray } from '../../../shared/arrays/Reviews/review-ambience.array';
import { reviewMediasArray } from '../../../shared/arrays/Reviews/review-medias.array';
import { reviewMeetArray } from '../../../shared/arrays/Reviews/review-meet.array';
import { reviewRecommendArray } from '../../../shared/arrays/Reviews/review-recommend.array';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  EPlansUser,
} from '../../../shared/enums';

import EStatusVisible from '../../../shared/enums/Status/status-visibility.enum';

import {
  FormatIsoDateWithPattern,
} from '../../../shared/utils/date.utils';

import UserAvatar from '../../Contents/UserAvatar';

import {
  IconArchiveOn,
  IconCheck,
  IconClose,
  IconDelete,
  IconEdit,
  IconEyeOff,
  IconEyeOn,
  IconStar,
} from '../../Icons';

import {
  CardReviewContainer,
  UserReviewContainer,
  UserReviewsBoxHeader,
  UserReviewsInfoBoxHeader,
  UserReviewsInfoHeader,
  UserReviewUserPhoto,
  UserReviewInfoContent,
  UserReviewInfoTitle,
  UserReviewInfoPlaceText,
  UserReviewTimeText,
  UserReviewsBoxHeaderRight,
  UserStarRatingBox,
  UserReviewMakeContainer,
  UserReviewMakeLabel,
  UserReviewMakeInfoContainer,
  UserReviewMakeInfoView,
  UserReviewMakeAliasText,
  UserReviewDescriptionText,
  UserReviewTypeContent,
  UserReviewTypeItemContent,
  UserReviewTypeItemTitle,
  UserReviewTypeItemText,
  ReviewActionsContainer,
  ReviewActionsButton,
  ReviewActionsIcon,
  ReviewActionsText,
} from './styled';



export interface IProps {
  edit?: boolean;
  type?: 'MODELE' | 'USER' | 'SUPPORT';

  visible?: EStatusVisible;
  revised?: boolean;
  alias?: any;
  plan?: EPlansUser;
  image?: any;
  createdAt?: string;
  rating?: string;
  dateMeet?: string;
  comment?: string;
  meet?: string;
  ambience?: string;
  medias?: string;
  recommend?: string;

  makeAlias?: string;
  makePlan?: EPlansUser;
  makeImage?: string;
  onImagePress?: any;

  onRespondPress?: any; // Modele
  onVisibleOrRevisdPress?: any; // Modele, Support

  onEditPress?: any; // User
  onDeletePress?: any; // Modele, Support

  onArchivePress?: any; // Modele, Support
}



const CardComment: React.FC<IProps> = (props) => {
  const colorIcon = Colors.secondaryDark;
  const sizeIcon = 18;



  function renderEditComments(enabled: boolean, title: string, icon: any, onPress: any) {
    return (

      <ReviewActionsButton
        disabled={enabled}
        onClick={() => {
          if (enabled && onPress) {
            onPress();
          }
        }}>

        <ReviewActionsIcon>
          {icon}
        </ReviewActionsIcon>


        <ReviewActionsText>
          {title}
        </ReviewActionsText>

      </ReviewActionsButton>

    );
  }



  return (

    <CardReviewContainer>

      <UserReviewContainer>

        <UserReviewsBoxHeader>

          <UserReviewUserPhoto
            onClick={() => {
              if (props.onImagePress) {
                props.onImagePress();
              }
            }}>
            <UserAvatar
              plan={props.plan}
              width={65}
              image={props.image
                ? props.image
                : Images.noImage
              }
            />
          </UserReviewUserPhoto>



          <UserReviewsInfoBoxHeader>

            <UserReviewsInfoHeader>

              <UserReviewInfoContent>

                <UserReviewInfoTitle
                  onClick={() => {
                    if (props.onImagePress) {
                      props.onImagePress();
                    }
                  }}>
                  {props.alias || '-'}
                </UserReviewInfoTitle>


                <UserReviewInfoPlaceText>
                  {props.dateMeet ? `Em: ${FormatIsoDateWithPattern(props.dateMeet, 'dd/MM/yyyy')}` : '-'}
                </UserReviewInfoPlaceText>

              </UserReviewInfoContent>



              <UserReviewsBoxHeaderRight>
                <IconStar
                  color={Colors.secondary}
                  size={22}
                />

                <UserStarRatingBox>
                  {props.rating || '0'}
                </UserStarRatingBox>
              </UserReviewsBoxHeaderRight>

            </UserReviewsInfoHeader>



            <UserReviewTimeText>
              {props.createdAt ? `Comentado em: ${FormatIsoDateWithPattern(props.createdAt, 'dd/MM/yyyy')}` : '-'}
            </UserReviewTimeText>

          </UserReviewsInfoBoxHeader>

        </UserReviewsBoxHeader>



        {props.makeAlias && (
          <UserReviewMakeContainer>

            <UserReviewMakeLabel>
              Comentário feito por:
            </UserReviewMakeLabel>


            <UserReviewMakeInfoContainer>

              <UserAvatar
                plan={props.makePlan}
                width={45}
                image={props.makeImage
                  ? props.makeImage
                  : Images.noImage
                }
              />


              <UserReviewMakeInfoView>

                <UserReviewMakeAliasText>
                  {props.makeAlias || '-'}
                </UserReviewMakeAliasText>

              </UserReviewMakeInfoView>

            </UserReviewMakeInfoContainer>

          </UserReviewMakeContainer>
        )}



        <UserReviewDescriptionText>
          {props.comment || '-'}
        </UserReviewDescriptionText>



        <UserReviewTypeContent>

          <UserReviewTypeItemContent>
            <UserReviewTypeItemTitle>
              Encontro
            </UserReviewTypeItemTitle>

            <UserReviewTypeItemText>
              {reviewMeetArray?.find((itemArray) => itemArray.value === props.meet)?.label || '-'}
            </UserReviewTypeItemText>
          </UserReviewTypeItemContent>


          <UserReviewTypeItemContent>
            <UserReviewTypeItemTitle>
              Ambiente
            </UserReviewTypeItemTitle>

            <UserReviewTypeItemText>
              {reviewAmbienceArray?.find((itemArray) => itemArray.value === props.ambience)?.label || '-'}
            </UserReviewTypeItemText>
          </UserReviewTypeItemContent>


          <UserReviewTypeItemContent>
            <UserReviewTypeItemTitle>
              As fotos são precisas?
            </UserReviewTypeItemTitle>

            <UserReviewTypeItemText>
              {reviewMediasArray?.find((itemArray) => itemArray.value === props.medias)?.label || '-'}
            </UserReviewTypeItemText>
          </UserReviewTypeItemContent>


          <UserReviewTypeItemContent>
            <UserReviewTypeItemTitle>
              Recomendaria?
            </UserReviewTypeItemTitle>

            <UserReviewTypeItemText>
              {reviewRecommendArray?.find((itemArray) => itemArray.value === props.recommend)?.label || '-'}
            </UserReviewTypeItemText>
          </UserReviewTypeItemContent>

        </UserReviewTypeContent>

      </UserReviewContainer>



      {props.type === 'USER' && props.edit && (
        <ReviewActionsContainer>

          {renderEditComments(
            true,
            'Editar',
            <IconEdit
              color={colorIcon}
              size={sizeIcon}
            />,
            props.onEditPress,
          )}


          {renderEditComments(
            true,
            'Deletar',
            <IconDelete
              color={colorIcon}
              size={sizeIcon}
            />,
            props.onDeletePress,
          )}

        </ReviewActionsContainer>
      )}


      {props.type === 'MODELE' && props.edit && (
        <ReviewActionsContainer>

          {renderEditComments(
            true,
            props.visible === EStatusVisible.VISIBLE
              ? 'Esconder do perfil'
              : 'Mostrar no perfil',
            props.visible === EStatusVisible.VISIBLE
              ? (
                <IconEyeOff
                  color={colorIcon}
                  size={sizeIcon}
                />
              )
              : (
                <IconEyeOn
                  color={colorIcon}
                  size={sizeIcon}
                />
              ),
            props.onVisibleOrRevisdPress,
          )}

        </ReviewActionsContainer>
      )}


      {props.type === 'SUPPORT' && props.edit && (
        <ReviewActionsContainer>

          {renderEditComments(
            true,
            'Arquivar',
            <IconArchiveOn
              color={colorIcon}
              size={sizeIcon}
            />,
            props.onArchivePress,
          )}


          {renderEditComments(
            true,
            props.revised
              ? 'Revisado'
              : 'Marcar como revisado',
            props.revised
              ? (
                <IconClose
                  color={colorIcon}
                  size={sizeIcon}
                />
              )
              : (
                <IconCheck
                  color={colorIcon}
                  size={sizeIcon}
                />
              ),
            props.onVisibleOrRevisdPress,
          )}


          {renderEditComments(
            true,
            'Deletar',
            <IconDelete
              color={colorIcon}
              size={sizeIcon}
            />,
            props.onDeletePress,
          )}

        </ReviewActionsContainer>
      )}

    </CardReviewContainer>

  );
};



export default CardComment;
