import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetAllModeles,
} from '../../../apis/endpoints/modeles.endpoints';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import {
  IPagination,
} from '../../../apis/interfaces/App/pagination.interface';

import CardModelApprove from '../../../components/Cards/CardModelApprove';

import {
  Content,
  Title,
} from '../../../components/Composh/web';

import {
  Screen,
} from '../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import SubHeader from '../../../components/Headers/SubHeader';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import TableChildren from '../../../components/Table/TableChildren';

import NameRoutes from '../../../navigation/names';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../shared/constants';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';
import EAppMediaTypeRelation from '../../../shared/enums/Picture/app-media-type-relation.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  returnCityArray,
  returnStateArray,
} from '../../../shared/utils/address.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  TableListHeaderContainer,
  TableListButtonAction,
} from '../../../styles/form.styled';

import {
  AllModelesRightContent,
  AllModelesFlatList,
} from './styled';



const ModelesAllListScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const perPageArray = [12, 24, 36];

  const [pageSelected, setPageSelected] = useState<number>(Values.firstPage);
  const [tableProviders, setTableProviders] = useState<any>(null);
  const [modelesArray, setModelesArray] = useState<Array<any>>([]);
  const [modelesPagination, setModelesPagination] = useState<IPagination | null>(null);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function mountPayload() {
    const payload = {
      pageNumber: tableProviders?.pageNumber || Values.firstPage,
      itemsPerPage: tableProviders?.itemsPerPage || perPageArray[0],
      // sortOrder: '',
      // sortColumn: '',
    } as any;

    // if (genderChoose) {
    //   payload.gender = genderChoose;
    // };

    return payload;
  }


  const getAllModeles = async () => {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      const payload = mountPayload();

      const response = await GetAllModeles(payload);

      if (!Array.isArray(response?.data) || !response?.data) {
        setModelesArray([]);
        return;
      }

      setModelesArray(response?.data);
      setPageSelected(response?.pagination?.pageCurrent);
      setModelesPagination(response?.pagination);
    }
    catch (error: any) {
      console.error(error);
      setModelesArray([]);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  function listHeaderComponent() {
    return (

      <SubHeader
        title={'Um título'}
        color={Colors.secondaryDark}>

        <TableListHeaderContainer>

          <TableListButtonAction
            title={'Criar perfil'}
            onPress={() => {
              history.push(NameRoutes.CreateModeleFormScreen);
            }}
          />


          <TableListButtonAction
            title={'Filtros'}
            onPress={() => {
              if (!loading) {
                // TODO
              }
            }}
          />

        </TableListHeaderContainer>

      </SubHeader>

    );
  }


  function renderItem(item: any, index: number) {
    const hasLocation = item?.location
      && item?.location?.country
      && item?.location?.state
      && item?.location?.city;

    const countryUser = item?.location?.country;

    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((itemFind: IInputSelect) => itemFind.value === item?.location?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(countryUser, findState?.value);
    const findCity = getCityArray?.find((itemFind: IInputSelect) => itemFind.value === item?.location?.city);
    const cityUser = findCity?.label;


    return (

      <CardModelApprove
        key={index}
        alias={item?.alias}
        useralias={item?.useralias}
        image={item?.picture}

        cock={item?.characters?.cockSize}
        position={item?.characters?.position}

        rent={item?.params?.rent}
        fanClub={item?.params?.fanClub}
        live={item?.params?.live}
        massage={item?.params?.massage}
        stream={item?.params?.stream}

        online={item?.available}
        verified={item?.verified}
        promoter={item?.brandPromoter}
        exclusive={item?.exclusive}
        pornstar={item?.pornstar}

        country={hasLocation ? countryUser : ''}
        place={hasLocation
          ? `${cityUser}, ${stateUser}`
          : 'Sem localização definida'
        }
        about={item?.appAbout?.aboutMe}
        gender={item?.gender}
        visible={item?.visible}
        status={item?.status}
        plan={item?.plan?.planCurrent}

        statusMediaAntiFake={item?.statusMediaAntiFake}
        statusProfile={item?.statusProfile}
        statusImages={item?.statusImages}
        statusVideos={item?.statusVideos}
        statusReviews={item?.statusReviews}

        onPress={() => {
          history.push(NameRoutes.PanelModeleScreen.replace(
            ':useralias',
            item?.useralias,
          ));
        }}
        onAntiFakePress={() => {
          // history.push(NameRoutes.AntiFakeAllScreen);
        }}
        onProfilePress={() => {
          // history.push(NameRoutes.AntiFakeAllScreen);
        }}
        onPicturePress={() => {
          history.push({
            pathname: NameRoutes.ModeleMediaAddScreen.replace(
              ':useralias',
              item?.useralias,
            ),
            state: {
              tab: EAppMediaTypeRelation.GALLERY_IMAGES,
            },
          });
        }}
        onVideoPress={() => {
          history.push({
            pathname: NameRoutes.ModeleMediaAddScreen.replace(
              ':useralias',
              item?.useralias,
            ),
            state: {
              tab: EAppMediaTypeRelation.GALLERY_VIDEOS,
            },
          });
        }}
        onReviewsPress={() => {
          history.push(
            NameRoutes.ReviewModeleScreen.replace(
              ':useralias',
              item?.useralias,
            ),
          );
        }}
      />

    );
  };


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.secondary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={'Images.logoIconAppWhite'}
        title={'Não há modelos ainda!'}
      />

    );
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.ALL_MODELES });
  }, []);


  useEffect(() => {
    getAllModeles();
  }, [tableProviders]);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet>
        <title>
          {`Todos os modelos | ${translate(TranslateConfig.NAME_APP)}`}
        </title>
      </Helmet>



      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
          />
        }
        centerContent={
          <Title
            color={Colors.black}>
            Todos os modelos
          </Title>
        }
      />



      <Content>

        <ContentPadder>

          {listHeaderComponent()}



          <AllModelesRightContent
            backgroundColor={Colors.formBackground}
            borderRadius={Sizes.cardRadius}>

            <TableChildren
              headerTopBorder
              headerBottomBorder
              color={Colors.white}
              accentColor={Colors.secondaryDark}
              sizePerPageList={perPageArray}
              paginationProviders={(providers) => {
                // setPageSelected(providers?.page);
                setTableProviders({
                  pageNumber: providers?.page,
                  itemsPerPage: providers?.size,
                });
              }}
              paginationProps={{
                pageCurrent: pageSelected,
                pagesTotal: modelesPagination?.pagesTotal,
                from: modelesPagination?.fromCount,
                to: modelesPagination?.toCount,
                dataSize: modelesPagination?.itemsTotal,
              }}>

              <AllModelesFlatList
                data={modelesArray}
                renderItem={renderItem}
                listLoadingComponent={renderLoading()}
                listEmptyComponent={listEmptyComponent()}
              />

            </TableChildren>

          </AllModelesRightContent>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default ModelesAllListScreen;
