import styled from 'styled-components';

import {
  Colors,
  Images,
} from '../../../../../shared/constants';



export const HolidayCountdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-height: 40px;
  background: linear-gradient(180deg, #e01035 0%, #640812 100%);
`;

export const HolidayCountdownTitle = styled.p`
  margin-right: 10px;
  color: ${Colors.white};
  font-size: 20px;
  line-height: 1.4em;
  text-shadow: 1px 2px 5px #440916;
`;


export const HolidayCountdownInner = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HolidayCountdownNumberInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-right: 10px;
`;

export const HolidayCountdownNumberText = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  line-height: 1.4em;
`;

export const HolidayCountdownDateText = styled.p`
  margin-left: 5px;
  padding-bottom: 5px;
  color: ${Colors.white};
  font-size: 12px;
  text-shadow: 1px 2px 5px #440916;
`;



export const PromoHeaderHolidayPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 309px;
  position: relative;
  background: linear-gradient(0deg, #091d54 0%, #103976 100%);
  box-shadow: 0px 0px 20px rgba(0,0,0,0.4);
`;

export const PromoHeaderHolidayImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;



export const PromoHeaderSeparatorPanel = styled.img`
  position: relative;
  display: flex;
  width: 100%;
  background-color: #B20000;
`;



export const PromoYearSelectContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  min-height: 60px;
  background-color: #c8942f;
`;

export const PromoYearSelectTitle = styled.p`
  margin-left: 2px;
  margin-right: 2px;
  color: ${Colors.black};
  font-size: 24px;
  text-align: center;
`;



export const PromoDetailsPanel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  background-color: #0d1226;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-image: url(${Images.rankingContents});
`;


export const PromoDetailsContentPanel = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 2200px;
  margin-bottom: 32px;
`;

export const PromoDetailsWantedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 58.31%;
  margin-right: 32px;
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  background: linear-gradient(180deg, #18325f 0%, #0f1c3e 100%);
  box-shadow: 0px 0px 20px rgba(0,0,0,0.4);
  border-radius: 10px;
`;

export const PromoWantedTitle = styled.p`
  color: ${Colors.white};
  font-weight: normal;
  text-align: center;
  margin-bottom: 16px;
  font-size: 35px;
`;

export const PromoWantedText = styled.p`
  margin-top: 6px;
  margin-bottom: 6px;
  color: ${Colors.white};
  font-size: 15px;
  line-height: 21px;
`;

export const PromoWantedImage = styled.img`
  width: 130px;
  height: auto;
  object-fit: contain;
`;

export const PromoWantedVideoContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 630px;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
`;

export const PromoWantedItemVideo = styled.video`
  width: 100%;
  height: 100%;
`;



export const PromoRankingContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  background-color: #58121c;
  box-shadow: 0 0 20px rgba(0,0,0,0.4);
  border-radius: 10px;
`;


export const PromoRankingHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem 0;
  padding-top: 40px;
  background:
  url(${Images.rankingStripesLeft}) left center no-repeat,
  url(${Images.rankingStripesRight}) right center no-repeat;
  background-color: #73131f;
`;


export const PromoRankingHeaderView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: 94%;
  background:
  url(${Images.rankingStarsLeft}) left center no-repeat,
  url(${Images.rankingStarsRight}) right center no-repeat;
  background-size: 450px, 450px;
`;


export const PromoRankingTrophyView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 71px;
  height: 71px;
  padding: 8px;
  background-color: #12244a;
  border: 5px solid #c8942f;
  border-radius: 50%;
`;

export const PromoRankingTrophyImage = styled.img`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const PromoRankingTrophyText = styled.p`
  margin-left: 16px;
  color: ${Colors.white};
  font-size: 36px;
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
`;



export const PromoRankingInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const PromoRankingInfoTitle = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
  color: ${Colors.white};
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 24px;
`;

export const PromoRankingInfoText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  line-height: 15px;
`;

export const PromoRankingInfoSpan = styled.span`
  line-height: 1.4em;
  font-size: 15px;
  color: ${Colors.white};
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-weight: 700;
  text-transform: uppercase;
`;



export const PromoRankingTableContent = styled.div`
  line-height: 1.4em;
  font-size: 15px;
  color: ${Colors.white};
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 10px;
  padding: 0 1.5rem 1.5rem 1.5rem;
  /* height: 700px; */
  overflow-y: scroll;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(90deg, #7c011c 0%, #570113 100%);
`;

export const PromoRankingTableComponent = styled.table`
  border: 0;
  padding: 0;
  max-width: 100%;
  border-spacing: 0;
  font-size: calc(12px + .1vw);
  border-collapse: collapse;
  width: 100%;

  tr:not(:last-child) {
  box-shadow: 0 1px 0 0 ${Colors.white};
}

thead  {
  
  border-bottom: 2px solid ${Colors.white};
}
thead th {
  padding-top: 1rem;
  color: ${Colors.white};
}

th {
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: capitalize;
}
`;

export const RankingsTdRank = styled.td`
  border-spacing: 0;
  border-collapse: collapse;
  padding: 14px;
  border-bottom: 1px solid rgba(150,150,150,0.1);
  text-align: center;
  color: ${Colors.white};
  font-size: 1.5rem;
  font-weight: 700;
`;

export const RankingsTdModelImage = styled.td`
  position: relative;
  border-spacing: 0;
  font-size: calc(12px + .1vw);
  border-collapse: collapse;
  padding: 14px;
  border-bottom: 1px solid rgba(150,150,150,0.1);
  text-align: center;
  color: ${Colors.white};
`;

export const RankingsModelButtonImage = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  border-spacing: 0;
  font-size: calc(12px + .1vw);
  border-collapse: collapse;
  text-align: center;
  text-decoration: underline;
  position: relative;
  display: block;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin: 0 auto;
  color: ${Colors.white};
`;

export const RankingsModelImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const RankingsModelOnlineText = styled.p`
  border-spacing: 0;
  border-collapse: collapse;
  outline: 0;
  background: transparent;
  display: inline-block;
  border: 0;
  text-decoration: none;
  transition: background-color .3s;
  text-align: center;
  margin: 3px 0 3px 0px;
  border-radius: 5px;
  line-height: normal;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 4px 10px 4px 10px;
  font-size: calc(.6em + .2vw);
  color: ${Colors.white};
  background-color: #3e9b3e;
  animation-name: successGlow;
`;

export const RankingsTdName = styled.td`
  border-spacing: 0;
  font-size: calc(12px + .1vw);
  border-collapse: collapse;
  padding: 14px;
  border-bottom: 1px solid rgba(150,150,150,0.1);
  text-align: center;
  color: ${Colors.white};
  font-weight: 700;
`;

export const RankingsModelName = styled.a`
  border-spacing: 0;
  font-size: calc(12px + .1vw);
  border-collapse: collapse;
  padding: 14px;
  border-bottom: 1px solid rgba(150,150,150,0.1);
  text-align: center;
  color: ${Colors.white};
  font-weight: 700;
`;

export const RankingsTdCollectibles = styled.td`
  padding: 14px;
  text-align: center;
  color: ${Colors.white};
  font-size: 1.5rem;
  font-weight: 700;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px;
  text-shadow: 3px 0 #7c011c, 0 3px #7c011c, 3px 0 #7c011c, 0 3px #7c011c;
  background-image: url(${Images.rankingFirwork});
  /* filter: hue-rotate(238deg); */
`;

export const RankingsTdPrize = styled.td`
  border-spacing: 0;
  border-collapse: collapse;
  padding: 14px;
  border-bottom: 1px solid rgba(150,150,150,0.1);
  text-align: center;
  color: ${Colors.white};
  font-size: 1.5rem;
  font-weight: 700;
`;
