import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  GetAppDashboardData,
} from '../../../apis/endpoints/support-dashboard.endpoints';

import {
  Title,
  Content,
  Body,
  SubTitle,
} from '../../../components/Composh/web';

import {
  Screen,
} from '../../../components/Containers/ScreenContainer';

import {
  ButtonDrawer,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
} from '../../../shared/constants';

import {
  EDrawerMenu,
} from '../../../shared/enums';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';



const AppDashboardScreen: React.FC = () => {
  const dispatch = useDispatch();


  const [periodArray, setModelesArray] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getActualModele() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      const response = await GetAppDashboardData();

      if (!response?.data) {
        return;
      }

      setModelesArray(response?.data?.top10Views);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);

      return null;
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.APP_DASHBOARD });
  }, []);


  useEffect(() => {
    getActualModele();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.black}>
              Dashboard
            </Title>

            <SubTitle
              color={Colors.gray}>
              App
            </SubTitle>
          </Body>
        }
      />



      <Content>

        <p>

          {JSON.stringify(periodArray)}

        </p>


      </Content>

    </Screen>

  );
};



export default AppDashboardScreen;
