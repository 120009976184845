import ApiWS from '../index';



export const GetAllTravelsModele = async (idModele: string) => {
  const url = `/support/modeles/location/travels/${idModele}`;

  const response = await ApiWS.get(url);
  return response.data;
};



export const CreateTravelsModele = async (idTravel: string, payload: any) => {
  const url = `/support/modeles/location/travels/${idTravel}`;

  const response = await ApiWS.post(url, payload);
  return response.data;
};



export const DeleteTravelsModele = async (idTravel: number | string, idUserReceived: string) => {
  const url = `/support/modeles/location/travels/${idTravel}`;

  const payload = {
    idUser: idUserReceived,
  };

  const response = await ApiWS.delete(url, {
    data: payload,
  });
  return response.data;
};
