import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  StreamMediasPicture,
  StreamMediasVideos,
} from '../../../../apis/endpoints/stream-medias.endpoints';

import {
  CreateStreamVideos,
  EditStreamVideos,
  GetStreamParamsVideos,
} from '../../../../apis/endpoints/stream-videos.endpoints';

import {
  IInputSelect,
} from '../../../../apis/interfaces/App/input-select.interface';

import ButtonAction from '../../../../components/Buttons/ButtonAction';
import ChipApp from '../../../../components/Chip';

import {
  Container,
  Content,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import InputSelect from '../../../../components/Inputs/InputSelect';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import PictureOrderItem from '../../../../components/Picture/PictureOrderItem';
import StreamVideoItem from '../../../../components/Picture/StreamVideoItem';
import RequiredText from '../../../../components/Titles/TextRequired';

import DataSelectModal from '../../../../modals/DataSelect';

import NameRoutes from '../../../../navigation/names';
import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import { typeGenre } from '../../../../shared/arrays/Stream/genre.array';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  EUploadType,
} from '../../../../shared/enums';

import EStreamMediaRelation from '../../../../shared/enums/Picture/stream-media-relation.enum';
import EStreamMediaTypeRelation from '../../../../shared/enums/Picture/stream-media-type-relation.enum';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import VideoSelectUserModal from './SelectStars';

import {
  CardContactsForm,
  InputTextStyled,
  InputViewStyled,
  InputChipView,
  InputBooleanStyled,
  StarListContainer,
  StarListHeaderContainer,
  StarListHeaderLabel,
  StarChipThumb,
} from './styled';



export interface IRouteLocation {
  edit: boolean;
  item?: any;
}



const VideosCreateScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const routeLocation = useLocation();
  const params = routeLocation?.state as IRouteLocation;


  const formik = useRef<FormikProps<any>>(null);

  const editForm = params?.edit;
  const [itemToEdit, setItemToEdit] = useState<any | any>(params?.item);

  const [picture, setPicture] = useState<any | null>(null);
  const [itemPicture, setItemPicture] = useState<any | null>(null);

  const [video, setVideo] = useState<any | null>(null);
  const [itemVideo, setItemVideo] = useState<any | null>(null);

  const [videoAds, setVideoAds] = useState<any | null>(null);
  const [itemVideoAds, setItemVideoAds] = useState<any | null>(null);

  const [needRevision, setNeedRevision] = useState<boolean>(false);
  const [visibleItem, setVisibleItem] = useState<boolean>(false);

  const allGenreTypes: Array<IInputSelect> = typeGenre;
  const [selectedGenreType, setSelectedGenreType] = useState<IInputSelect | null>(null);
  const [genreTypeSelectModal, setGenreTypeSelectModal] = useState<boolean>(false);

  const [channelsArray, setChannelsArray] = useState<Array<IInputSelect>>([]);
  const [selectedChannels, setSelectedChannels] = useState<IInputSelect | null>(null);
  const [channelsSelectModal, setChannelsSelectModal] = useState<boolean>(false);

  const [subcategoryArray, setSubcategoryArray] = useState<Array<IInputSelect>>([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);

  const [starsArray, setStarsArray] = useState<Array<IInputSelect & { picture: string }>>([]);
  const [starList, setStarList] = useState([]);
  const [starSelectModal, setStarSelectModal] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function formatResponseData(dataArray: Array<any>, labelKey: string) {
    if (dataArray.length > 0) {
      return dataArray.map((item) => ({
        id: item?.id,
        label: item[labelKey],
        value: item?.id,
      }));
    }
    return null;
  }


  function formatResponseStar(dataArray: Array<any>) {
    if (dataArray.length > 0) {
      return dataArray.map((item) => ({
        id: item?.id,
        label: item?.name,
        value: item?.id,
        picture: item?.picture,
      }));
    }
    return null;
  }


  async function getParamsVideo() {
    try {
      const result = await GetStreamParamsVideos();

      if (!result?.data) {
        showSnackBarProps(Colors.danger, 'Dados não encontrados');
        return;
      }

      // Formata os usuários, subcategorias e canais
      const formattedChannels = formatResponseData(result?.data.channels, 'name');
      const formattedSubCategories = formatResponseData(result?.data.subCategories, 'name');
      const formattedUsers = formatResponseStar(result?.data.users);

      setChannelsArray(formattedChannels);
      setSubcategoryArray(formattedSubCategories);

      setStarsArray(formattedUsers);

      const objectFormated = {
        users: formattedUsers,
        subCategories: formattedSubCategories,
        channels: formattedChannels,
      };

      return objectFormated;
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);

      return null;
    }
  }


  async function updateForm() {
    const receivedTreatedItems = await getParamsVideo();

    if (editForm && formik.current) {
      setItemPicture(itemToEdit?.picture);
      setItemVideo(itemToEdit?.video);
      setItemVideoAds(itemToEdit?.ads);

      formik.current?.setFieldValue('name', itemToEdit?.name);
      formik.current?.setFieldValue('description', itemToEdit?.description);

      const findGenre = allGenreTypes?.find((item) => item?.value === itemToEdit?.genre);
      formik.current?.setFieldValue('genre', findGenre?.value);
      setSelectedGenreType(findGenre || null);

      const findCircuit = receivedTreatedItems?.channels?.find((item) => item?.id === itemToEdit?.idChannel);
      formik.current?.setFieldValue('idChannel', findCircuit?.value);
      setSelectedChannels(findCircuit || null);

      setNeedRevision(itemToEdit?.needRevision ? itemToEdit?.needRevision : false);
      formik.current?.setFieldValue('needRevision', itemToEdit?.needRevision);

      setVisibleItem(itemToEdit?.visible ? itemToEdit?.visible : false);
      formik.current?.setFieldValue('visible', itemToEdit?.visible);

      formik.current?.setFieldValue('idChannel', itemToEdit?.idChannel);


      if (itemToEdit?.subCategories && itemToEdit?.subCategories?.length > 0) {
        const subCategoriesIds = itemToEdit?.subCategories.map((user) => user?.id);
        setSubCategoriesList(subCategoriesIds);
      }


      if (itemToEdit?.users && itemToEdit?.users?.length > 0) {
        const userIds = itemToEdit?.users.map((user) => user?.id);
        setStarList(userIds);
      }

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function resetForm() {
    setMounted(false);

    setPicture(null);
    setItemPicture(null);

    setVideo(null);
    setItemVideo(null);

    setVideoAds(null);
    setItemVideoAds(null);

    setSelectedGenreType(null);

    setSelectedChannels(null);

    setSubCategoriesList([]);

    setStarList([]);

    setNeedRevision(false);
    setVisibleItem(false);

    formik.current?.resetForm();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  };


  function mountPayload() {
    const values = formik?.current?.values;

    const payload: any = {
      name: values.name,
      description: values.description,
      genre: values.genre,
      needRevision: values.needRevision,
      visible: values.visible,
      idChannel: values.idChannel,
      categories: subCategoriesList,
      stars: starList,
    };

    return payload;
  }


  async function savePicture(id: string, statusResponse: boolean) {
    const payload = {
      relation: EStreamMediaRelation.VIDEO,
      typeRelation: EStreamMediaTypeRelation.VIDEO_IMAGE,
    };

    const idItemCreated = id;
    const imgUpdated = await StreamMediasPicture(idItemCreated, picture, payload);

    if (statusResponse && imgUpdated) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
    }
    else if (statusResponse && !imgUpdated) {
      showSnackBarProps(Colors.danger, 'Item salvo mas a imagem não foi salva');
    }
    else {
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function saveBackground(id: string, statusResponse: boolean) {
    const payload = {
      relation: EStreamMediaRelation.VIDEO,
      typeRelation: EStreamMediaTypeRelation.VIDEO_DATA,
    };

    const idItemCreated = id;
    const imgUpdated = await StreamMediasVideos(idItemCreated, video, payload);

    if (statusResponse && imgUpdated) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
    }
    else if (statusResponse && !imgUpdated) {
      showSnackBarProps(Colors.danger, 'Item salvo mas a imagem não foi salva');
    }
    else {
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function saveVideoAds(id: string, statusResponse: boolean) {
    const payload = {
      relation: EStreamMediaRelation.VIDEO,
      typeRelation: EStreamMediaTypeRelation.VIDEO_ADS,
    };

    const idItemCreated = id;
    const imgUpdated = await StreamMediasVideos(idItemCreated, videoAds, payload);

    if (statusResponse && imgUpdated) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
    }
    else if (statusResponse && !imgUpdated) {
      showSnackBarProps(Colors.danger, 'Item salvo mas a imagem não foi salva');
    }
    else {
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function createEvent() {
    const payload = mountPayload();

    const response = await CreateStreamVideos(payload);

    if (response) {
      if (picture) {
        await savePicture(response?.data?.id, response);
      }

      if (video) {
        await saveBackground(response?.data?.id, response);
      }

      if (videoAds) {
        await saveVideoAds(response?.data?.id, response);
      }

      if (!picture && !video && !videoAds) {
        showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
      }

      resetForm();
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function editEvent() {
    const payload = mountPayload();

    const itemToSave = {
      id: itemToEdit?.id,
      ...payload,
    };

    setItemToEdit(itemToSave);

    const response = await EditStreamVideos(itemToEdit?.id, payload);

    if (response) {
      if (picture) {
        await savePicture(itemToEdit?.id, response);
      }

      if (video) {
        await saveBackground(itemToEdit?.id, response);
      }

      if (videoAds) {
        await saveVideoAds(itemToEdit?.id, response);
      }

      if (!picture && !video && !videoAds) {
        showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
      }
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function saveEvent() {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      if (editForm) {
        await editEvent();
      }
      else {
        await createEvent();
      }
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function onPressPictureChange(event: any) {
    const eventImageFile = event.target.files[0];

    if (event) {
      const link = window.URL.createObjectURL(eventImageFile);
      setPicture(eventImageFile);
      setItemPicture(link);
    }
    else {
      setPicture(null);
      setItemPicture(null);
    }
  }


  function onPressBackgroundChange(event: any) {
    const eventImageFile = event.target.files[0];

    if (event) {
      setVideo(eventImageFile);
      const link = window.URL.createObjectURL(eventImageFile);
      setItemVideo(link);
    }
    else {
      setVideo(null);
      setItemVideo(null);
    }
  }


  function onPressAdsVideoChange(event: any) {
    const eventImageFile = event.target.files[0];

    if (event) {
      setVideoAds(eventImageFile);
      const link = window.URL.createObjectURL(eventImageFile);
      setItemVideoAds(link);
    }
    else {
      setVideoAds(null);
      setItemVideoAds(null);
    }
  }


  const onSubCategoryPress = (subcategoryId) => {
    if (subCategoriesList.includes(subcategoryId)) {
      setSubCategoriesList((prevList) => prevList.filter((id) => id !== subcategoryId));
    }
    else {
      setSubCategoriesList((prevList) => [...prevList, subcategoryId]);
    }
  };


  function renderChipTribes(item: any, index: number) {
    const verifyItem = subCategoriesList.some((valueItem) => valueItem === item?.value);


    return (

      <ChipApp
        key={index}
        disabled={loading}
        selected={verifyItem}
        title={item?.label}
        onPress={() => {
          onSubCategoryPress(item?.id);
        }}
      />

    );
  }


  function renderChipUser(item: any, index: number) {
    const userFind = starsArray.find((user) => user.id === item);

    return (

      <ChipApp
        key={index}
        hideIcon
        thumb={
          <StarChipThumb
            alt={userFind?.label}
            src={userFind?.picture}
          />
        }
        disabled={loading}
        title={userFind?.label}
        onPress={() => {
          onSubCategoryPress(item?.id);
        }}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveEvent();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <Container>

            <HeaderScreen
              leftIcon={
                <ButtonBack
                  color={Colors.black}
                  size={27}
                />
              }
              centerContent={
                <Title
                  color={Colors.black}>
                  {editForm
                    ? 'Editar vídeo'
                    : 'Criar vídeo'
                  }
                </Title>
              }
            />



            <Content>

              {!mounted && (
                renderLoading()
              )}



              {mounted && (
                <ContentPadder>

                  <CardContactsForm
                    backgroundColor={Colors.formBackground}
                    borderRadius={Sizes.cardRadius}>

                    <RequiredText>
                      * {'Campos obrigatórios'}
                    </RequiredText>



                    <PictureOrderItem
                      idButton={EUploadType.VIDEO_IMAGE}
                      disabled={loading}
                      fileImage={picture}
                      image={itemPicture}
                      onPress={(event: any) => {
                        onPressPictureChange(event);
                      }}
                    />



                    <StreamVideoItem
                      idButton={EUploadType.VIDEO_DATA}
                      disabled={loading}
                      fileImage={video}
                      image={itemVideo}
                      onPress={(event: any) => {
                        onPressBackgroundChange(event);
                      }}
                    />



                    <StreamVideoItem
                      idButton={EUploadType.VIDEO_ADS}
                      disabled={loading}
                      fileImage={videoAds}
                      image={itemVideoAds}
                      onPress={(event: any) => {
                        onPressAdsVideoChange(event);
                      }}
                    />



                    <InputTextStyled
                      disabled={loading}
                      autoCorrect
                      autoCapitalize={'words'}
                      type={'TEXT'}
                      labelText={`${'Nome do vídeo'} *`}
                      placeholderText={`${'Nome'} *`}
                      errorText={errors.name}
                      countLimit={Values.nameMaxCount}
                      value={values.name}
                      onChange={handleChange('name')}
                      onBlur={handleBlur('name')}
                    />



                    <InputTextStyled
                      disabled={loading}
                      autoCorrect
                      autoCapitalize={'words'}
                      type={'TEXT'}
                      labelText={'Descrição do vídeo'}
                      placeholderText={'Descrição'}
                      errorText={errors.description}
                      countLimit={Values.nameMaxCount}
                      value={values.description}
                      onChange={handleChange('description')}
                      onBlur={handleBlur('description')}
                    />



                    <InputSelect
                      disabled={loading}
                      labelText={`${'Gênero do vídeo'} *`}
                      placeholderText={selectedGenreType?.label}
                      onPress={() => {
                        setGenreTypeSelectModal(true);
                      }}
                    />



                    <InputSelect
                      disabled={loading}
                      labelText={`${'Canal'} *`}
                      placeholderText={selectedChannels?.label}
                      onPress={() => {
                        setChannelsSelectModal(true);
                      }}
                    />



                    <InputViewStyled
                      noShadow
                      labelText={`${'Sub-categorias'} *`}
                      helpText={errors.gender}
                      backgroundColor={Colors.transparent}
                      labelColor={Colors.inputLabel}
                      helpColor={Colors.danger}
                      countLimit={'Marque um ou vários'}>

                      <InputChipView>
                        {subcategoryArray.map((item: any, index: number) => (
                          renderChipTribes(item, index)
                        ))}
                      </InputChipView>

                    </InputViewStyled>



                    <StarListContainer>

                      <StarListHeaderContainer>

                        <StarListHeaderLabel
                          labelText={'Modelos'}
                          labelColor={Colors.inputLabel}
                        />

                        <ButtonAction
                          title='Adicionar'
                          onPress={() => {
                            setStarSelectModal(true);
                          }}
                        />

                      </StarListHeaderContainer>



                      <InputChipView>
                        {starList.map((item, index) => (
                          renderChipUser(item, index)
                        ))}
                      </InputChipView>

                    </StarListContainer>



                    <InputBooleanStyled
                      disabled={loading}
                      labelText={'Precisa de revisão?'}
                      selected={needRevision}
                      onPress={(state: boolean) => {
                        setFieldValue('needRevision', state);
                        setNeedRevision(state);

                        if (state) {
                          setFieldValue('visible', false);
                          setVisibleItem(false);
                        }
                      }}
                    />



                    <InputBooleanStyled
                      disabled={loading || needRevision}
                      labelText={'Visível'}
                      selected={visibleItem}
                      onPress={(state: boolean) => {
                        setFieldValue('visible', state);
                        setVisibleItem(state);
                      }}
                    />



                    <ContentButtonForm>
                      <ButtonAction
                        width={Sizes.buttonMinWidth}
                        title={'Salvar'}
                        onPress={() => {
                          handleSubmit();
                        }}
                      />
                    </ContentButtonForm>

                  </CardContactsForm>

                </ContentPadder>
              )}



              <FooterApp />

            </Content>

          </Container>



          <DataSelectModal
            title={'Gênero do vídeo'}
            visible={genreTypeSelectModal}
            data={allGenreTypes}
            selectedValue={selectedGenreType?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedGenreType(typeReturn);
              setFieldValue('genre', typeReturn?.value);
            }}
            onClose={() => {
              setGenreTypeSelectModal(false);
            }}
          />



          <DataSelectModal
            title={'Canal'}
            visible={channelsSelectModal}
            data={channelsArray}
            selectedValue={selectedChannels?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedChannels(typeReturn);
              setFieldValue('idChannel', typeReturn?.value);
            }}
            onClose={() => {
              setChannelsSelectModal(false);
            }}
            onOkText={'Adicionar novo canal'}
            onOkPress={() => {
              history.push(NameRoutes.ChannelsCreateScreen);
            }}
          />



          <VideoSelectUserModal
            visible={starSelectModal}
            data={starsArray}
            starList={starList}
            onStarListChange={(array) => {
              setStarList(array);
            }}
            onClose={() => {
              setStarSelectModal(false);
            }}
          />

        </Screen>

      )}
    </Formik>

  );
};



export default VideosCreateScreen;
