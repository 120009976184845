import ApiWS from '../index';



export const GetProjectParams = async () => {
  const url = '/support/project/params';

  const response = await ApiWS.get(url);
  return response.data;
};



export const EditProjectParams = async (payload: any) => {
  const url = '/support/project/params';

  const response = await ApiWS.put(url, payload);
  return response.data;
};
