import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  ContentChipTop,
  FilterLabel,
  FilterChipsContainer,
  FilterChipSearch,
} from './styled';



export interface IProps {
  children?: any;
}



const FilterChipView: React.FC<IProps> = (props: IProps) => {
  return (

    <ContentChipTop>

      <FilterLabel
        labelText={'Filtros selecionados'}
        labelColor={Colors.black}
        labelStyle={{
          fontSize: 16,
        }}
      />


      <FilterChipsContainer>
        {props.children}
      </FilterChipsContainer>

    </ContentChipTop>

  );
};



export default {
  View: FilterChipView,
  Chip: FilterChipSearch,
};
