import ApiWS from '../index';



export const GetProjectMessages = async (payload: string) => {
  const url = '/support/project/messages';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const CreateProjectMessages = async (payload: any) => {
  const url = '/support/project/messages';

  const response = await ApiWS.post(url, payload);
  return response.data;
};



export const EditProjectMessages = async (id: string, payload: any) => {
  const url = `/support/project/messages/${id}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const DeleteProjectMessages = async (payload: any) => {
  const url = '/support/project/messages';

  const response = await ApiWS.put(url, payload);
  return response.data;
};
