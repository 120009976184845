import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  StreamMediasPicture,
} from '../../../../apis/endpoints/stream-medias.endpoints';

import {
  CreateStreamSubCategories,
  EditStreamSubCategories,
} from '../../../../apis/endpoints/stream-subcategories.endpoints';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Content,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import PictureOrderItem from '../../../../components/Picture/PictureOrderItem';
import RequiredText from '../../../../components/Titles/TextRequired';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  EUploadType,
} from '../../../../shared/enums';

import EStreamMediaRelation from '../../../../shared/enums/Picture/stream-media-relation.enum';
import EStreamMediaTypeRelation from '../../../../shared/enums/Picture/stream-media-type-relation.enum';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  CardContactsForm,
  InputTextStyled,
  InputBooleanStyled,
} from './styled';



export interface IRouteLocation {
  edit: boolean;
  item?: any;
}



const CategoriesCreateScreen: React.FC = () => {
  const dispatch = useDispatch();

  const routeLocation = useLocation();
  const params = routeLocation?.state as IRouteLocation;


  const formik = useRef<FormikProps<any>>(null);

  const editForm = params?.edit;
  const [itemToEdit, setItemToEdit] = useState<any | any>(params?.item);

  const [visibleItem, setVisibleItem] = useState<boolean>(false);

  const [picture, setPicture] = useState<any | null>(null);
  const [itemPicture, setItemPicture] = useState<any | null>(null);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    if (editForm && formik.current) {
      setItemPicture(itemToEdit?.picture);

      formik.current?.setFieldValue('idCategory', itemToEdit?.idCategory);
      formik.current?.setFieldValue('name', itemToEdit?.name);
      formik.current?.setFieldValue('description', itemToEdit?.description);
      formik.current?.setFieldValue('genre', itemToEdit?.genre);

      setVisibleItem(itemToEdit?.visible ? itemToEdit?.visible : false);
      formik.current?.setFieldValue('visible', itemToEdit?.visible);

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function resetForm() {
    setMounted(false);

    setVisibleItem(false);

    setPicture(null);
    setItemPicture(null);

    formik.current?.resetForm();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  };


  function mountPayload() {
    const values = formik?.current?.values;

    const payload: any = {
      idCategory: values.idCategory,
      name: values.name ? String(values.name).trim() : '',
      description: values.description ? String(values.description).trim() : '',
      genre: values.genre,
      visible: values.visible,
    };

    return payload;
  }


  async function savePicture(id: string, statusResponse: boolean) {
    const payload = {
      relation: EStreamMediaRelation.SUBCATEGORY,
      typeRelation: EStreamMediaTypeRelation.SUBCATEGORY_IMAGE,
    };

    const idItemCreated = id;
    const imgUpdated = await StreamMediasPicture(idItemCreated, picture, payload);

    if (statusResponse && imgUpdated) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
    }
    else if (statusResponse && !imgUpdated) {
      showSnackBarProps(Colors.danger, 'Item salvo mas a imagem não foi salva');
    }
    else {
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function createEvent() {
    const payload = mountPayload();

    const response = await CreateStreamSubCategories(payload);

    if (response) {
      if (picture) {
        await savePicture(response?.data?.id, response);
      }
      else {
        showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
      }
      resetForm();
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function editEvent() {
    const payload = mountPayload();

    const itemToSave = {
      id: itemToEdit?.id,
      ...payload,
    };

    setItemToEdit(itemToSave);

    const response = await EditStreamSubCategories(itemToEdit?.id, payload);

    if (response) {
      if (picture) {
        await savePicture(itemToEdit?.id, response);
      }
      else {
        showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
      }
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function saveEvent() {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      if (editForm) {
        await editEvent();
      }
      else {
        await createEvent();
      }
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function onPressPictureChange(event: any) {
    const eventImageFile = event.target.files[0];

    if (event) {
      const link = window.URL.createObjectURL(eventImageFile);
      setPicture(eventImageFile);
      setItemPicture(link);
    }
    else {
      setPicture(null);
      setItemPicture(null);
    }
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveEvent();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <HeaderScreen
            leftIcon={
              <ButtonBack
                color={Colors.black}
                size={27}
              />
            }
            centerContent={
              <Title
                color={Colors.black}>
                {editForm
                  ? 'Editar Sub-categoria'
                  : 'Criar Sub-categoria'
                }
              </Title>
            }
          />



          <Content>

            {!mounted && (
              renderLoading()
            )}



            {mounted && (
              <ContentPadder>

                <CardContactsForm
                  backgroundColor={Colors.formBackground}
                  borderRadius={Sizes.cardRadius}>

                  <RequiredText>
                    * {'Campos obrigatórios'}
                  </RequiredText>



                  <PictureOrderItem
                    idButton={EUploadType.SUBCATEGORY_IMAGE}
                    disabled={loading}
                    fileImage={picture}
                    image={itemPicture}
                    onPress={(event: any) => {
                      onPressPictureChange(event);
                    }}
                  />



                  <InputTextStyled
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={`${'Id da categoria'} *`}
                    placeholderText={`${'Id'} *`}
                    errorText={errors.idCategory}
                    countLimit={Values.nameMaxCount}
                    value={values.idCategory}
                    onChange={handleChange('idCategory')}
                    onBlur={handleBlur('idCategory')}
                  />



                  <InputTextStyled
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={`${'Nome da sub-categoria'} *`}
                    placeholderText={`${'Nome'} *`}
                    errorText={errors.name}
                    countLimit={Values.nameMaxCount}
                    value={values.name}
                    onChange={handleChange('name')}
                    onBlur={handleBlur('name')}
                  />



                  <InputTextStyled
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={'Descrição da sub-categoria'}
                    placeholderText={'Descrição'}
                    errorText={errors.description}
                    countLimit={Values.nameMaxCount}
                    value={values.description}
                    onChange={handleChange('description')}
                    onBlur={handleBlur('description')}
                  />



                  <InputTextStyled
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={`${'Gênero da sub-categoria'} *`}
                    placeholderText={`${'Gênero'} *`}
                    errorText={errors.genre}
                    countLimit={Values.nameMaxCount}
                    value={values.genre}
                    onChange={handleChange('genre')}
                    onBlur={handleBlur('genre')}
                  />



                  <InputBooleanStyled
                    disabled={loading}
                    labelText={'Visível'}
                    selected={visibleItem}
                    onPress={(state: boolean) => {
                      setFieldValue('visible', state);
                      setVisibleItem(state);
                    }}
                  />



                  <ContentButtonForm>
                    <ButtonAction
                      width={Sizes.buttonMinWidth}
                      title={'Salvar'}
                      onPress={() => {
                        handleSubmit();
                      }}
                    />
                  </ContentButtonForm>

                </CardContactsForm>

              </ContentPadder>
            )}



            <FooterApp />

          </Content>

        </Screen>

      )}
    </Formik>

  );
};



export default CategoriesCreateScreen;
