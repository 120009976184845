import ApiWS from '../index';



export const StreamMediasPicture = async (
  idMedia: string,
  picture: any,
  payload: any,
) => {
  const requestConfig = {
    headers: {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  const form = new FormData();
  form.append('name', picture?.name || 'VideoImage');
  form.append('type', picture?.type || '');
  form.append('image', picture);

  payload?.relation && form.append('relation', payload?.relation);
  payload?.typeRelation && form.append('typeRelation', payload?.typeRelation);

  const url = `/support/stream/medias/image/${idMedia}`;

  const pictureResponse = await ApiWS.post(url, form, requestConfig);
  return pictureResponse.data;
};



export const StreamMediasVideos = async (
  idMedia: string,
  video: any,
  payload: any,
) => {
  const requestConfig = {
    headers: {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  const form = new FormData();
  form.append('name', video?.name || 'VideoData');
  form.append('type', video?.type || '');
  form.append('image', video);

  payload?.relation && form.append('relation', payload?.relation);
  payload?.typeRelation && form.append('typeRelation', payload?.typeRelation);

  const url = `/support/stream/medias/video/${idMedia}`;

  const pictureResponse = await ApiWS.post(url, form, requestConfig);
  return pictureResponse.data;
};
