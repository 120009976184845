import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  FormatIsoDateWithPattern,
} from '../../../shared/utils/date.utils';

import {
  IconCalendar,
  IconDelete,
  IconMapPin,
} from '../../Icons';

import {
  CardTravelContainer,
  TravelTabButton,
  TravelHeaderView,
  TravelTabButtonText,
  TravelDeleteButton,
  TravelDateView,
  TravelDateIcon,
  TravelDateText,
  TravelInfoView,
  TravelPlaceText,
  TravelPlaceObsContainer,
  TravelPlacePlaceBaseText,
} from './styled';



export interface IProps {
  canDelete?: boolean;
  number?: number;
  dateStart?: string;
  dateEnd?: string;
  city?: string;
  state?: string;
  country?: string;
  observation?: string;
  showAdMyPlace?: boolean;
  onDeletePress?: any;
}



const CardTravel: React.FC<IProps> = (props: IProps) => {
  return (

    <CardTravelContainer>
      <TravelTabButton>

        <TravelHeaderView>

          <TravelTabButtonText>
            Viagem: {props.number}
          </TravelTabButtonText>


          {props.canDelete && (
            <TravelDeleteButton
              onClick={() => {
                if (props.onDeletePress) {
                  props.onDeletePress();
                }
              }}>
              <IconDelete
                size={20}
                color={Colors.danger}
              />
            </TravelDeleteButton>
          )}

        </TravelHeaderView>



        <TravelDateView>

          <IconCalendar
            size={30}
            color={Colors.black}
          />


          <TravelDateIcon>
            <TravelDateText>
              Início: {FormatIsoDateWithPattern(props.dateStart, 'dd/MM/yyyy')}
            </TravelDateText>

            <TravelDateText>
              Final: {FormatIsoDateWithPattern(props.dateEnd, 'dd/MM/yyyy')}
            </TravelDateText>

          </TravelDateIcon>

        </TravelDateView>



        <TravelDateView>

          <IconMapPin
            size={30}
            color={Colors.black}
          />


          <TravelInfoView>
            <TravelPlaceText>
              {props.city}
            </TravelPlaceText>


            <TravelPlaceText>
              {props.state}
            </TravelPlaceText>


            <TravelPlaceText>
              {props.country}
            </TravelPlaceText>
          </TravelInfoView>

        </TravelDateView>



        <TravelPlaceObsContainer>

          <TravelPlaceText>
            {props.observation}
          </TravelPlaceText>


          <TravelPlacePlaceBaseText>
            {props.showAdMyPlace
              ? 'Exibir anúncio na minha localização base'
              : 'Anúncio escondido na minha localização base'
            }
          </TravelPlacePlaceBaseText>

        </TravelPlaceObsContainer>

      </TravelTabButton>
    </CardTravelContainer>

  );
};



export default CardTravel;
