import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  Card,
} from '../../Composh/web';



export interface IProps {
  color?: string;
}



export const CardModeleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 6px;
  padding-bottom: 6px;

  @media ${PlatformDevices.maxTabletL} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minTabletL} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media ${PlatformDevices.minLaptop} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
    padding-left: 5px;
    padding-right: 5px;
  }
  
  @media ${PlatformDevices.minLaptopL} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    padding-left: 5px;
    padding-right: 5px;
  }
`;


export const CardModeleContent = styled(Card.View)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  border-color: ${Colors.cardBackground};
  border-style: solid;
  border-width: 2px;
  transition: all 0.2s ease;

  :hover{
    border-color: ${Colors.secondary};
  }
`;

export const CardModeleUserContent = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding-right: 13px;
`;


export const CardModeleGold = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  z-index: 2;
  top: -5px;
  left: -5px;
`;

export const Cardaphotoh = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  flex: 1;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  background-color: #333;
  border-top-left-radius: ${Sizes.cardRadius}px;
`;

export const Cardphotobigtall = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Cardaphotodimmer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 8px;
  background: -webkit-linear-gradient(bottom,rgba(0,0,0,0.7),rgba(0,0,0,0));
  z-index: 1;
`;

export const CardOndimmer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CardModfeonline = styled.div`
  display: flex;
  flex-direction: column;
  width: 7px;
  height: 7px;
  margin: 0 7px 0 2px;
  background-color: #6c3;
  border: 1px solid #390;
  border-radius: 100px;
  box-shadow: 0 0 5px rgba(102,204,52,.75);
`;

export const CardModeffeGold = styled.p`
  color: #3c0;
  text-align: center;
  font-size: 12px;
  text-shadow: 0 0 3px #000;
`;



export const CardMffodeleGoldInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 250px;
  min-height: 250px;
  max-height: 250px;
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
`;

export const CardAllModeleInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardAllModeleHeaderName = styled.p`
  margin-bottom: 3px;
  color: ${Colors.black};
  font-weight: 400;
  font-size: 19px;
  cursor: pointer;
`;


export const HomeIdentiyViewUsername = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

export const HomeIdentiyUsername = styled.p`
  color: ${Colors.gray};
  font-size: 14px;
`;

export const HomeIdentiyIcon = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;



export const CardModeleDetaisContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardModeleDetaisItem = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

export const CardAllModeleHeaderIcon = styled.div`
   display: flex;
  flex-direction: column;
  max-width: 14px;
  max-height: 14px;
  margin-right: 5px;
`;

export const ModelesPlaceCountry = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const CafrdModeleGold = styled.p`
  color: #999;
  font-weight: 400;
  font-size: 13px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
`;

export const ModelAllAboutContent = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CaffrdModeleGold = styled.p`
  color: #666;
  margin: 7px 0;
  font-size: 13px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
`;

export const CardMffodeleGolfd = styled.p`
  margin-top: 2px;
  margin-bottom: 5px;
  line-height: 1.3em;
  font-size: 13px;
  color: ${(props: IProps) => props.color || Colors.black};
  cursor: pointer;
`;



export const CardBadgesContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
  padding-bottom: 2px;
`;

export const CardbadgesContainerabsolute = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CardbadgesIconse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25px;
  min-height: 25px;
`;

export const CardModelfeGold = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% / 3);
  min-width: calc(100% / 3);
  max-width: calc(100% / 3);
  height: 55px;
  margin-top: 5px;
  margin-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
`;

export const CardIconUserText = styled.div`
  margin-top: 4px;
  color: ${(props: IProps) => props.color || Colors.black};
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
`;



export const CardAllModeleFooterActions = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.grayLight};
  overflow: hidden;
  border-bottom-left-radius: ${Sizes.cardRadius}px;
  border-bottom-right-radius: ${Sizes.cardRadius}px;
`;

export const CardAllModeleActionItem = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 7px;
  padding-bottom: 7px;
`;

export const CardAllModeleActionIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
`;

export const CardAllModeleActionText = styled.p`
  color: ${Colors.black};
  font-size: 11px;
  line-height: 14px;
  text-align: center;
`;
