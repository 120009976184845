import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetProjectPlanPrices,
} from '../../../../apis/endpoints/project-plans-prices.endpoints';

import {
  IPagination,
} from '../../../../apis/interfaces/App/pagination.interface';

import {
  Body,
  Content,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import SubHeader from '../../../../components/Headers/SubHeader';

import {
  IconEdit,
} from '../../../../components/Icons';

import TableRow from '../../../../components/Table/TableRow';

import {
  FormatMoney,
} from '../../../../config/mask.config';

import NameRoutes from '../../../../navigation/names';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import EDrawerMenu from '../../../../shared/enums/App/drawer-menu.enum';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  TableListHeaderContainer,
  TableListButtonAction,
  TableListRowItemButton,
} from '../../../../styles/form.styled';

import {
  ProjectParamsContentCenterFlex,
} from './styled';



const PlansPricesShowAllScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const perPageArray = [10, 20, 30];

  const [pageSelected, setPageSelected] = useState<number>(Values.firstPage);
  const [tableProviders, setTableProviders] = useState<any>(null);
  const [periodArray, setModelesArray] = useState<Array<any>>([]);
  const [modelesPagination, setModelesPagination] = useState<IPagination | null>(null);

  const [loading, setLoading] = useState<boolean>(false);


  const columns = [
    {
      dataField: 'id',
      label: 'ID',
      sort: true,
    },
    {
      dataField: 'country',
      label: 'País',
      sort: true,
    },
    // <a>
    //   Abrir getway de pagamento
    // </a>
    {
      dataField: 'priceUserPlus',
      label: 'User - Plus',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.priceUserPlus ? FormatMoney(row?.priceUserPlus, row?.country) : '-';
      },
    },
    {
      dataField: 'priceUserPremium',
      label: 'User - Premium',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.priceUserPremium ? FormatMoney(row?.priceUserPremium, row?.country) : '-';
      },
    },
    {
      dataField: 'priceUserBronze',
      label: 'Modelo - Bronze',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.priceUserBronze ? FormatMoney(row?.priceUserBronze, row?.country) : '-';
      },
    },
    {
      dataField: 'priceUserSilver',
      label: 'Modelo - Silver',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.priceUserSilver ? FormatMoney(row?.priceUserSilver, row?.country) : '-';
      },
    },
    {
      dataField: 'priceUserGold',
      label: 'Modelo - Gold',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.priceUserGold ? FormatMoney(row?.priceUserGold, row?.country) : '-';
      },
    },
    {
      dataField: 'priceUserPlatinum',
      label: 'Modelo - Platinum',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.priceUserPlatinum ? FormatMoney(row?.priceUserPlatinum, row?.country) : '-';
      },
    },
    {
      dataField: 'priceUserDiamond',
      label: 'Modelo - Diamond',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.priceUserDiamond ? FormatMoney(row?.priceUserDiamond, row?.country) : '-';
      },
    },
    {
      dataField: 'daysUserGoldFreemium',
      label: 'Dias do Gold Freemium',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.daysUserGoldFreemium ? `${row?.daysUserGoldFreemium} dias` : '-';
      },
    },
    {
      dataField: 'rentLevelPrice',
      label: 'Acompanhante - Nível',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.rentLevelPrice ? FormatMoney(row?.rentLevelPrice, row?.country) : '-';
      },
    },
    {
      dataField: 'rentAttentionPrice',
      label: 'Acompanhante - Atenção',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.rentAttentionPrice ? FormatMoney(row?.rentAttentionPrice, row?.country) : '-';
      },
    },
    {
      dataField: 'edit',
      label: 'Editar',
      sort: false,
      formatter: (_cell: any, row: any) => {
        return (
          <TableListRowItemButton
            onClick={() => {
              if (!loading) {
                history.push({
                  pathname: NameRoutes.PlansPricesFormScreen,
                  state: {
                    edit: true,
                    item: row,
                  },
                });
              }
            }}>
            <IconEdit
              color={Colors.black}
              size={20}
            />
          </TableListRowItemButton>
        );
      },
    },
  ];



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getActualModele() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      const payload = {
        pageNumber: tableProviders?.pageNumber || Values.firstPage,
        itemsPerPage: tableProviders?.itemsPerPage || perPageArray[0],
        // sortOrder: '',
        // sortColumn: '',
      } as any;

      // if (genderChoose) {
      //   payload.gender = genderChoose;
      // };

      const response = await GetProjectPlanPrices(payload);

      if (!Array.isArray(response?.data) || !response?.data) {
        setModelesArray([]);
        return;
      }

      setModelesArray(response?.data);
      setPageSelected(response?.pagination?.pageCurrent);
      setModelesPagination(response?.pagination);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);

      return null;
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  function listHeaderComponent() {
    return (

      <SubHeader
        title={'Um título'}
        color={Colors.secondaryDark}>

        <TableListHeaderContainer>

          <TableListButtonAction
            title={'Criar valores'}
            onPress={() => {
              history.push(NameRoutes.PlansPricesFormScreen);
            }}
          />


          <TableListButtonAction
            title={'Filtros'}
            onPress={() => {
              // TODO
            }}
          />

        </TableListHeaderContainer>

      </SubHeader>

    );
  }


  function renderHeaderTable() {
    return (

      <div>

        <p>
          Taxa de transferencia da carteira:
          A taxa não pode ser menor que a do cartao em 3x
        </p>


        <a>
          Editar taxa de tranferencia
        </a>

      </div>

    );
  }



  useEffect(() => {
    getActualModele();
  }, [tableProviders]);


  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.PROJECT_PLANS });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.black}>
              Planos e valores
            </Title>

            <SubTitle
              color={Colors.gray}>
              Todo o projeto
            </SubTitle>
          </Body>
        }
      />



      <Content>

        <ContentPadder>

          {listHeaderComponent()}



          <ProjectParamsContentCenterFlex
            backgroundColor={Colors.formBackground}
            borderRadius={Sizes.cardRadius}>

            <TableRow
              headerTable={renderHeaderTable()}
              data={periodArray}
              columns={columns}
              headerTopBorder
              headerBottomBorder
              color={Colors.white}
              accentColor={Colors.secondaryDark}
              sizePerPageList={perPageArray}
              paginationProviders={(providers) => {
                // setPageSelected(providers?.page);
                setTableProviders({
                  pageNumber: providers?.page,
                  itemsPerPage: providers?.size,
                });
              }}
              paginationProps={{
                pageCurrent: pageSelected,
                pagesTotal: modelesPagination?.pagesTotal,
                from: modelesPagination?.fromCount,
                to: modelesPagination?.toCount,
                dataSize: modelesPagination?.itemsTotal,
              }}
            />

          </ProjectParamsContentCenterFlex>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default PlansPricesShowAllScreen;
