import React from 'react';
import { Breadcrumbs } from '@material-ui/core';
import { BreadcrumbLink, BreadcrumbSeparator, BreadcrumbText } from './styled';

// import { Container } from './styles';

const Breadcrumb: React.FC = () => {
  return (

    <Breadcrumbs separator={<BreadcrumbSeparator color={'gray'} size={18} />}>
      <BreadcrumbLink
        to="/home"
      // onClick={breadcrumbClick}
      >
        <BreadcrumbText>Dashboard</BreadcrumbText>
      </BreadcrumbLink>

      <BreadcrumbText>Parceiros</BreadcrumbText>
    </Breadcrumbs>

  );
};



export default Breadcrumb;
