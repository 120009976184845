import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  Title,
  Content,
} from '../../../components/Composh/web';

import {
  Screen,
} from '../../../components/Containers/ScreenContainer';

import {
  ButtonDrawer,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconDashboard,
} from '../../../components/Icons';

import TabBarStyled from '../../../components/TabBar';

import {
  APP_MENU_SELECTED_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
} from '../../../shared/constants';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';
import TabMonthlyCrush from './Tabs/MonthlyCrush';
import TabYearlyCrush from './Tabs/YearlyCrush';



const RankingsScreen: React.FC = () => {
  const dispatch = useDispatch();


  const accentGender = Colors.secondary;
  const iconTabBar = 23;


  const [stateIndex, setStateIndex] = useState(0);


  const screenTabs = [
    {
      visible: true,
      name: 'RANKING',
      title: 'Acompanhante',
      icon: (
        <IconDashboard
          color={stateIndex === 0 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <div />
      ),
    },
    {
      visible: true,
      name: 'MONTHLY_CRUSH',
      title: 'Crushs do mês',
      icon: (
        <IconDashboard
          color={stateIndex === 1 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabMonthlyCrush />
      ),
    },
    {
      visible: true,
      name: 'YEARLY_CRUSH',
      title: 'Crushs do ano',
      icon: (
        <IconDashboard
          color={stateIndex === 2 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabYearlyCrush />
      ),
    },
    {
      visible: true,
      name: 'HALL_FAME',
      title: 'Hall da fama',
      icon: (
        <IconDashboard
          color={stateIndex === 3 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <div />
      ),
    },
    {
      visible: true,
      name: 'FAME1',
      title: 'Y1',
      icon: (
        <IconDashboard
          color={stateIndex === 4 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <div />
      ),
    },
  ];



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.RANKING });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Title
            color={Colors.black}>
            Rankings
          </Title>
        }
        noSubToolbarPadding>


        <TabBarStyled
          color={accentGender}
          tabs={screenTabs}
          selected={stateIndex}
          onPress={(tab) => {
            setStateIndex(tab?.index);
          }}
        />

      </HeaderScreen>



      <Content>

        {stateIndex === 0 && screenTabs[0].component}

        {stateIndex === 1 && screenTabs[1].component}

        {stateIndex === 2 && screenTabs[2].component}

        {stateIndex === 3 && screenTabs[3].component}

        {stateIndex === 4 && screenTabs[4].component}

      </Content>

    </Screen>

  );
};



export default RankingsScreen;
