import styled from 'styled-components';
import { ListFlat } from '../../../../../components/Composh/web';



export const ProfileScrollCardsContent = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 8,
    paddingLeft: 5,
    paddingRight: 5,
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
