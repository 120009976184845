import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  CreateModeleProfile,
  EditModeleMainProfile,
} from '../../../apis/endpoints/modeles.endpoints';

import ButtonAction from '../../../components/Buttons/ButtonAction';

import {
  Content,
  Title,
} from '../../../components/Composh/web';

import {
  Screen,
} from '../../../components/Containers/ScreenContainer';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../components/Titles/SectionTitle';
import RequiredText from '../../../components/Titles/TextRequired';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import { typeGenders } from '../../../shared/arrays/Gender/gender.array';

import {
  Colors,
  Sizes,
  Values,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  normalizeRawText,
  stringFullWordCapitalize,
} from '../../../shared/utils/string.utils';

import {
  DetailsTopViewItems,
  DetailsBottomViewItems,
  InputTextStyled,
  InputButtonCheckboxStyled,
  InputViewStyled,
  CardContactsForm,
  ContactsAttendanceOptionsContent,
  GenderUserRadio,
} from './styled';



export interface IRouteLocation {
  edit: boolean;
  item?: any;
}



const CreateModeleFormScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const routeLocation = useLocation();
  const paramsLocation = routeLocation?.state as IRouteLocation;


  const formik = useRef<FormikProps<any>>(null);

  const editForm = paramsLocation?.edit;
  const [itemToEdit, setItemToEdit] = useState<any | any>(paramsLocation?.item);

  const [verifiedUser, setVerifiedUser] = useState<boolean>(false);
  const [brandPromoter, setBrandPromoter] = useState<boolean>(false);

  const [userGender, setUserGender] = useState('');
  const [initialGender, setInitialGender] = useState<any>(null);

  const [exclusiveUser, setExclusiveUser] = useState<boolean>(false);
  const [pornstarUser, setPornstarUser] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    if (editForm && formik.current) {
      formik.current?.setFieldValue('alias', itemToEdit?.alias);
      formik.current?.setFieldValue('useralias', itemToEdit?.useralias);

      formik.current?.setFieldValue('gender', itemToEdit?.gender);
      if (itemToEdit?.gender) {
        const indexGender = typeGenders.find((itemGender: any) => itemGender.value === itemToEdit?.gender);

        if (indexGender) {
          setUserGender(indexGender.value);
          setInitialGender(indexGender.id);
        }
        else {
          setUserGender(itemToEdit?.gender);
          setInitialGender(itemToEdit?.gender);
        }
      }

      setVerifiedUser(itemToEdit?.verified ? itemToEdit?.verified : false);
      formik.current?.setFieldValue('verified', itemToEdit?.verified);

      setBrandPromoter(itemToEdit?.brandPromoter ? itemToEdit?.brandPromoter : false);
      formik.current?.setFieldValue('brandPromoter', itemToEdit?.brandPromoter);

      setExclusiveUser(itemToEdit?.exclusive ? itemToEdit?.exclusive : false);
      formik.current?.setFieldValue('exclusive', itemToEdit?.exclusive);

      setPornstarUser(itemToEdit?.pornstar ? itemToEdit?.pornstar : false);
      formik.current?.setFieldValue('pornstar', itemToEdit?.pornstar);

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function mountPayload() {
    const values = formik?.current?.values;

    const payload: any = {
      alias: stringFullWordCapitalize(values.alias).trim(),
      useralias: normalizeRawText(values.useralias).toLowerCase().trim(),
      verified: values.verified,
      brandPromoter: values.brandPromoter,
      gender: userGender,
      exclusive: values.exclusive,
      pornstar: values.pornstar,
    };

    return payload;
  }


  function resetForm() {
    setMounted(false);

    setUserGender('');
    setInitialGender(null);

    setVerifiedUser(false);
    setBrandPromoter(false);
    setExclusiveUser(false);
    setPornstarUser(false);

    formik.current?.resetForm();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  };


  async function createEvent() {
    const payload = mountPayload();

    const response = await CreateModeleProfile(payload);

    if (response) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
      resetForm();
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function editEvent() {
    const payload = mountPayload();

    const itemToSave = {
      id: itemToEdit?.id,
      ...payload,
    };

    setItemToEdit(itemToSave);

    const response = await EditModeleMainProfile(itemToEdit?.id, payload);

    if (response) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function saveEvent() {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      if (editForm) {
        await editEvent();
      }
      else {
        await createEvent();
      }
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveEvent();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>


          <Helmet>
            <title>
              {`Dados principais | ${translate(TranslateConfig.NAME_APP)}`}
            </title>
          </Helmet>



          <HeaderScreen
            leftIcon={
              <ButtonBack
                color={Colors.black}
                size={27}
              />
            }
            centerContent={
              <Title
                color={Colors.black}>
                {editForm
                  ? 'Editar dados principais'
                  : 'Criar dados principais'
                }
              </Title>
            }
          />



          <Content>

            {!mounted && (
              renderLoading()
            )}



            {mounted && (
              <ContentPadder>

                <CardContactsForm
                  backgroundColor={Colors.formBackground}
                  borderRadius={Sizes.cardRadius}>

                  <RequiredText>
                    * {'Campos obrigatórios'}
                  </RequiredText>



                  <DetailsTopViewItems>

                    <SectionTitle
                      title={'Dados principais'}
                    />


                    <ContactsAttendanceOptionsContent>

                      <InputTextStyled
                        disabled={loading}
                        autoCorrect
                        autoCapitalize={'words'}
                        type={'TEXT'}
                        labelText={`${'Nome'} *`}
                        placeholderText={`${'Nome'} *`}
                        errorText={errors.alias}
                        countLimit={Values.nameMaxCount}
                        value={values.alias}
                        onChange={handleChange('alias')}
                        onBlur={handleBlur('alias')}
                      />



                      <InputTextStyled
                        disabled={loading}
                        autoCorrect
                        autoCapitalize={'words'}
                        type={'TEXT'}
                        labelText={`${'Nome de usuário'} *`}
                        placeholderText={`${'Nome de usuário'} *`}
                        errorText={errors.useralias}
                        countLimit={Values.usernameMaxCount}
                        value={values.useralias}
                        onChange={handleChange('useralias')}
                        onBlur={handleBlur('useralias')}
                      />


                      <InputViewStyled
                        noShadow
                        labelText={`${'Genero'} *`}
                        labelColor={Colors.inputLabel}
                        backgroundColor={Colors.transparent}
                        helpText={errors.gender}
                        helpColor={Colors.danger}
                        countLimit={'Marque apenas um'}>

                        <GenderUserRadio
                          data={typeGenders}
                          initial={initialGender}
                          circleSize={16}
                          boxDirection={'row'}
                          direction={'column'}
                          activeColor={Colors.secondaryDark}
                          deactiveColor={Colors.inputPlaceholder}
                          textStyle={{
                            marginTop: 5,
                          }}
                          onPress={(item: any) => {
                            setFieldValue('gender', item?.value);
                            setUserGender(item.value);
                          }}
                        />

                      </InputViewStyled>

                    </ContactsAttendanceOptionsContent>

                  </DetailsTopViewItems>



                  <DetailsBottomViewItems>

                    <SectionTitle
                      title={'Verificações'}
                    />


                    <ContactsAttendanceOptionsContent>

                      <InputButtonCheckboxStyled
                        title={'Verificado'}
                        checked={verifiedUser}
                        onPress={() => {
                          setVerifiedUser(!verifiedUser);
                          setFieldValue('verified', !verifiedUser);
                        }}
                      />


                      <InputButtonCheckboxStyled
                        title={'Promoter'}
                        checked={brandPromoter}
                        onPress={() => {
                          setBrandPromoter(!brandPromoter);
                          setFieldValue('brandPromoter', !brandPromoter);
                        }}
                      />

                    </ContactsAttendanceOptionsContent>

                  </DetailsBottomViewItems>



                  <DetailsBottomViewItems>

                    <SectionTitle
                      title={'Marcações'}
                    />


                    <ContactsAttendanceOptionsContent>

                      <InputButtonCheckboxStyled
                        title={'Exclusivo'}
                        checked={exclusiveUser}
                        onPress={() => {
                          setExclusiveUser(!exclusiveUser);
                          setFieldValue('exclusive', !exclusiveUser);
                        }}
                      />


                      <InputButtonCheckboxStyled
                        title={'Pornstar'}
                        checked={pornstarUser}
                        onPress={() => {
                          setPornstarUser(!pornstarUser);
                          setFieldValue('pornstar', !pornstarUser);
                        }}
                      />

                    </ContactsAttendanceOptionsContent>

                  </DetailsBottomViewItems>



                  <ContentButtonForm>
                    <ButtonAction
                      width={Sizes.buttonMinWidth}
                      title={'Salvar'}
                      onPress={() => {
                        handleSubmit();
                      }}
                    />
                  </ContentButtonForm>

                </CardContactsForm>

              </ContentPadder>
            )}



            <FooterApp />

          </Content>

        </Screen>

      )}
    </Formik>

  );
};



export default CreateModeleFormScreen;
