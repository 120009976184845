enum EUploadType {
  PICTURE_USER_UPLOAD = 'user-picture-upload',
  PICTURE_COMPANY_UPLOAD = 'company-picture-upload',
  HIGHLIGHT_VERTICAL = 'gender-picture-highlight-vertical',
  HIGHLIGHT_HORIZONTAL = 'gender-picture-highlight-horizontal',
  HIGHLIGHT_USER_UPGRADE = 'gender-picture-highlight-user-upgrade',
  HIGHLIGHT_MODELE_UPGRADE = 'gender-picture-highlight-modele-upgrade',
  HIGHLIGHT_PREMIUN = 'gender-picture-highlight-premium',

  SUBCATEGORY_IMAGE = 'subcategory-image',
  CHANNEL_IMAGE = 'channel-image',
  CHANNEL_cover = 'channel-cover',
  VIDEO_IMAGE = 'video-image',
  VIDEO_DATA = 'video-data',
  VIDEO_ADS = 'video-ads',
}



export default EUploadType;
