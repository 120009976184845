import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  DeleteModeleImage,
  GetAllIDeletedMedias,
} from '../../../../apis/endpoints/publish-deleted-medias.endpoints';

import {
  IPagination,
} from '../../../../apis/interfaces/App/pagination.interface';

import AlertMessage from '../../../../components/Alerts';
import CardPublishImage from '../../../../components/Cards/CardPublishImage';
import CardPublishVideo from '../../../../components/Cards/CardPublishVideo';

import {
  Body,
  Container,
  Content,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import SubHeader from '../../../../components/Headers/SubHeader';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import TableSectionFilter from '../../../../components/Table/Filter';
import TableChildren from '../../../../components/Table/TableChildren';

import NameRoutes from '../../../../navigation/names';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import { EDrawerMenu } from '../../../../shared/enums';
import EResponseStatus from '../../../../shared/enums/Response/response-status.enum';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  TableListButtonAction,
  TableListHeaderContainer,
} from '../../../../styles/form.styled';

import {
  AllModelesRightContent,
  AllModelesFlatList,
} from './styled';



const DeletedMediasScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const perPageArray = [12, 24, 36];

  const [pageSelected, setPageSelected] = useState<number>(Values.firstPage);
  const [tableProviders, setTableProviders] = useState<any>(null);
  const [modelesArray, setModelesArray] = useState<Array<any>>([]);
  const [modelesPagination, setModelesPagination] = useState<IPagination | null>(null);

  const [openDeleteModal, setOpenDeleteModal] = useState<string>('');

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function mountPayload() {
    const payload = {
      pageNumber: tableProviders?.pageNumber || Values.firstPage,
      itemsPerPage: tableProviders?.itemsPerPage || perPageArray[0],
      // sortOrder: '',
      // sortColumn: '',
    } as any;

    return payload;
  }


  const getAllModelesMedias = async () => {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      const payload = mountPayload();

      const response = await GetAllIDeletedMedias(payload);

      if (!Array.isArray(response?.data) || !response?.data) {
        setModelesArray([]);
        return;
      }

      setModelesArray(response?.data);
      setPageSelected(response?.pagination?.pageCurrent);
      setModelesPagination(response?.pagination);
    }
    catch (error: any) {
      console.error(error);
      setModelesArray([]);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  async function deleteMedia(idMedia: string) {
    setOpenDeleteModal(null);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await DeleteModeleImage(idMedia);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao deletar mídia');
        return;
      }

      showSnackBarProps(Colors.accept, 'Mídia deletada com sucesso');
      await getAllModelesMedias();
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function listHeaderComponent() {
    return (

      <SubHeader
        title={'Um título'}
        color={Colors.secondaryDark}>

        <TableListHeaderContainer>

          <TableListButtonAction
            title={'Filtros'}
            onPress={() => {
              if (!loading) {
                // TODO
              }
            }}
          />

        </TableListHeaderContainer>

      </SubHeader>

    );
  }


  function renderItem(item: any, index: number) {
    if (String(item?.type).includes('video')) {
      return (

        <CardPublishVideo
          key={index}
          type={'DELETED'}

          image={item?.picture}
          typeRelation={item?.typeRelation}
          visible={item?.visible}
          visibility={item?.visibility}
          revised={item?.revised}
          canBuy={item?.canBuy}

          duration={item?.duration}
          isHD={item?.isHD}

          modeleName={item?.user?.alias}
          modeleUsername={item?.user?.useralias}
          modelePlan={item?.user?.plan?.planCurrent}
          modeleImage={item?.user?.picture}
          verified={item?.user?.verified}
          brandPromoter={item?.user?.brandPromoter}

          onUserPress={() => {
            history.push(NameRoutes.PanelModeleScreen.replace(
              ':useralias',
              item?.user?.useralias,
            ));
          }}
          onDeletePress={() => {
            setOpenDeleteModal(item?.id);
          }}
        />

      );
    }


    return (

      <CardPublishImage
        key={index}
        type={'DELETED'}

        image={item?.picture}
        typeRelation={item?.typeRelation}
        visible={item?.visible}
        visibility={item?.visibility}
        revised={item?.revised}
        canBuy={item?.canBuy}

        modeleName={item?.user?.alias}
        modeleUsername={item?.user?.useralias}
        modelePlan={item?.user?.plan?.planCurrent}
        modeleImage={item?.user?.picture}
        verified={item?.user?.verified}
        brandPromoter={item?.user?.brandPromoter}

        onUserPress={() => {
          history.push(NameRoutes.PanelModeleScreen.replace(
            ':useralias',
            item?.user?.useralias,
          ));
        }}
        onDeletePress={() => {
          setOpenDeleteModal(item?.id);
        }}
      />

    );
  };


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.secondary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={'Images.logoIconAppWhite'}
        title={'Não há modelos ainda!'}
      />

    );
  }


  useEffect(() => {
    getAllModelesMedias();
  }, [tableProviders]);



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.DELETED_MEDIAS });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          leftIcon={
            <ButtonDrawer
              color={Colors.black}
              size={27}
            />
          }
          centerContent={
            <Body>
              <Title
                color={Colors.black}>
                Mídias deletadas
              </Title>

              <SubTitle
                color={Colors.gray}>
                App
              </SubTitle>
            </Body>
          }
        />



        <Content>

          <ContentPadder>

            {listHeaderComponent()}



            <AllModelesRightContent
              backgroundColor={Colors.formBackground}
              borderRadius={Sizes.cardRadius}>

              <TableSectionFilter.View>

                <TableSectionFilter.Chip>
                  Revisado: {mountPayload()?.revised ? 'Sim' : 'Não'}
                </TableSectionFilter.Chip>

              </TableSectionFilter.View>



              <TableChildren
                headerTopBorder
                headerBottomBorder
                color={Colors.white}
                accentColor={Colors.secondaryDark}
                sizePerPageList={perPageArray}
                paginationProviders={(providers) => {
                  // setPageSelected(providers?.page);
                  setTableProviders({
                    pageNumber: providers?.page,
                    itemsPerPage: providers?.size,
                  });
                }}
                paginationProps={{
                  pageCurrent: pageSelected,
                  pagesTotal: modelesPagination?.pagesTotal,
                  from: modelesPagination?.fromCount,
                  to: modelesPagination?.toCount,
                  dataSize: modelesPagination?.itemsTotal,
                }}>

                <AllModelesFlatList
                  data={modelesArray}
                  renderItem={renderItem}
                  listLoadingComponent={renderLoading()}
                  listEmptyComponent={listEmptyComponent()}
                />

              </TableChildren>

            </AllModelesRightContent>

          </ContentPadder>



          <FooterApp />

        </Content>

      </Container>



      <AlertMessage
        visible={Boolean(openDeleteModal)}
        title={'Deseja deletar esse item?'}
        cancelText={'Não'}
        cancelPress={() => {
          setOpenDeleteModal(null);
        }}
        okText={'Sim'}
        okPress={() => {
          deleteMedia(openDeleteModal);
        }}
      />

    </Screen>

  );
};


export default DeletedMediasScreen;
