import React from 'react';

// import {
//   ActivityIndicator,
// } from 'react-native';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  ButtonActionContainer,
  IconLeftView,
  IconRightView,
  ButtonActionText,
  ButtonChildrenAction,
} from './styled';



export interface IProps {
  type?: 'button' | 'submit' | 'reset' | undefined;
  bold?: boolean;
  disabled?: boolean;
  loading?: boolean;

  width?: number | string;
  height?: number;

  backgroundColor?: string;
  color?: string;

  title: string;
  iconLeft?: any;
  iconRight?: any;

  onPress?: any;
  children?: any;
}



const ButtonAction: React.FC<IProps> = (props: IProps) => {
  const className = { ...props } as any;

  const backgroundColor = props.backgroundColor || Colors.secondaryDark;
  const color = props.color || Colors.white;



  return (

    <ButtonActionContainer
      className={className?.className}

      type={props.type}
      width={props.width}
      height={props.height}
      disabled={props.disabled}
      backgroundColor={props.disabled ? Colors.disabled : backgroundColor}
      onClick={() => {
        if (!props.disabled && props.onPress) {
          props.onPress();
        }
      }}>

      {props.iconLeft && !props.loading && (
        <IconLeftView>
          {props.iconLeft}
        </IconLeftView>
      )}



      <ButtonChildrenAction>
        {props.title && !props.loading && (
          <ButtonActionText
            bold={props.bold}
            color={color}>
            {props.title}
          </ButtonActionText>
        )}

        {/*
        {props.loading && (
          <ActivityIndicator
            color={'white'}
            size={'small'}
          />
        )}
        */}
      </ButtonChildrenAction>



      {props.iconRight && (
        <IconRightView>
          {props.iconRight}
        </IconRightView>
      )}

    </ButtonActionContainer>

  );
};



ButtonAction.defaultProps = {
  type: 'button',
  disabled: false,
  loading: false,

  height: Sizes.buttonHeight,
};



export default ButtonAction;
