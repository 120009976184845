import ApiWS from '../index';



export const GetStreamSubCategories = async (payload: any) => {
  const url = '/support/stream/subcategories';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const CreateStreamSubCategories = async (payload: any) => {
  const url = '/support/stream/subcategories';

  const response = await ApiWS.post(url, payload);
  return response.data;
};



export const EditStreamSubCategories = async (id: string, payload: any) => {
  const url = `/support/stream/subcategories/${id}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const DeleteStreamSubCategories = async (id: any) => {
  const url = `/support/stream/subcategories/${id}`;

  const response = await ApiWS.put(url);
  return response.data;
};
