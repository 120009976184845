import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  validateString,
} from '../../../shared/utils/string.utils';

import {
  CardStarListContainer,
  CardStarListImage,
  CardStarListInfoView,
  CardStarListInfoName,
  CardStarListSelectedText,
} from './styled';



export interface IProps {
  name?: string;
  image?: string;
  selected?: boolean;
  onPress?: any;
}



const CardStarList: React.FC<IProps> = (props: IProps) => {
  return (

    <CardStarListContainer
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <CardStarListImage
        alt={props.name}
        src={validateString(props.image) || Images.avatarUserImage}
      />



      <CardStarListInfoView>

        <CardStarListInfoName>
          {props.name}
        </CardStarListInfoName>


        {props.selected && (
          <CardStarListSelectedText>
            Selecionado
          </CardStarListSelectedText>
        )}

      </CardStarListInfoView>

    </CardStarListContainer>

  );
};



export default CardStarList;
