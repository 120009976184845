import ApiWS from '../index';



export const GetAppRentServices = async (payload: any) => {
  const url = '/support/app/rent-services';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const CreateAppRentServices = async (payload: any) => {
  const url = '/support/app/rent-services';

  const response = await ApiWS.post(url, payload);
  return response.data;
};



export const EditAppRentServices = async (id: string, payload: any) => {
  const url = `/support/app/rent-services/${id}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const DeleteAppRentServices = async (id: any) => {
  const url = `/support/app/rent-services/${id}`;

  const response = await ApiWS.put(url);
  return response.data;
};
