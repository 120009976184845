import ApiWS from '../index';



export const GetStreamCategories = async (payload: any) => {
  const url = '/support/stream/categories';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const CreateStreamCategories = async (payload: any) => {
  const url = '/support/stream/categories';

  const response = await ApiWS.post(url, payload);
  return response.data;
};



export const EditStreamCategories = async (id: string, payload: any) => {
  const url = `/support/stream/categories/${id}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const DeleteStreamCategories = async (id: any) => {
  const url = `/support/stream/categories/${id}`;

  const response = await ApiWS.put(url);
  return response.data;
};
