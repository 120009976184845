import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

// import {
//   GetAllGenders,
// } from '../../apis/endpoints/genders.endpoint';

import {
  Screen,
} from '../../components/Composh/web';

// import {
//   USER_GENDER_CHOOSE_ACTION,
// } from '../../redux/reducers/choose.store';

// import {
//   USER_LOCATION,
// } from '../../redux/reducers/localities.store';

// import {
//   MODELES_HIGHLIGHTS,
// } from '../../redux/reducers/modeles.store';

import {
  arrayModelesHighlights,
} from '../../shared/arrays/Gender/app-gender-choose.array';

import {
  Colors,
  Images,
} from '../../shared/constants';

// import {
//   getLocalStorageItem,
//   setLocalStorageItem,
// } from '../../shared/utils/local-storage.utils';

import {
  AbsoluteFillView,
  ContainerGlue,
  BackgroundColorView,
  FlexContent,
  ImageBackgroundStyle,
  LogoStyle,
  LogoImageStyle,
} from './styled';



export interface IProps {
  children?: any;
}



const AnimatedSplash: React.FC<IProps> = (props: any) => {
  const dispatch = useDispatch();


  const [animationDone, setAnimationDone] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const logoWidth = 120;
  const logoHeight = 120;
  const backgroundColor = Colors.primaryDark;
  const logoImage = Images.logoIconApp;
  // const imageBackgroundSource = Images.backgroundSplashBubble;


  // async function loadAllRents() {
  //   try {
  //     const response = await GetAllGenders();

  //     if (!response) {
  //       dispatch({ type: MODELES_HIGHLIGHTS, payload: arrayModelesHighlights });
  //       // FIXME Mudar para tela erro de servidor
  //       return;
  //     }

  //     dispatch({ type: MODELES_HIGHLIGHTS, payload: response });
  //   }
  //   catch (error: any) {
  //     console.error(error);

  //     dispatch({ type: MODELES_HIGHLIGHTS, payload: arrayModelesHighlights });
  //     // FIXME Mudar para tela erro de servidor
  //   }
  // };


  function renderChildren() {
    // if (props.preload || props.preload === null) {
    //   return props.children;
    // }
    // else {
    if (isLoaded) {
      return props.children;
    }
    // }

    return null;
  }


  // function setLocationFunc() {
  //   dispatch({
  //     type: USER_LOCATION, payload: {
  //       city: 'Guarulhos',
  //       state: 'São Paulo',
  //       country: 'Brasil',
  //     },
  //   });
  // }


  // function setGenderFunc() {
  //   const getLocalGender = getLocalStorageItem(USER_GENDER_CHOOSE_ACTION);

  //   if (getLocalGender) {
  //     dispatch({ type: USER_GENDER_CHOOSE_ACTION, payload: getLocalGender });
  //   }
  //   else {
  //     const firstGender = arrayModelesHighlights[0]?.gender;

  //     dispatch({ type: USER_GENDER_CHOOSE_ACTION, payload: firstGender });
  //     setLocalStorageItem(USER_GENDER_CHOOSE_ACTION, firstGender);
  //   }
  // }



  useEffect(() => {
    // console.log(getCurrentLanguage());

    if (!isLoaded) {
      // loadAllRents();
      // setLocationFunc();
      // setGenderFunc();
    }


    setTimeout(async () => {
      setIsLoaded(true);
    }, 2000);


    if (isLoaded) {
      setAnimationDone(true);
    }
  }, [isLoaded]);



  return (

    <Screen
      backgroundColor={Colors.transparent}>


      {!animationDone && (
        <AbsoluteFillView />
      )}


      <ContainerGlue>

        {!animationDone && (
          <BackgroundColorView
            backgroundColor={backgroundColor}
          // style={[
          //   logoOpacity,
          // ]}
          />
        )}


        <FlexContent
        // style={[
        //   !isLoaded && appScale,
        //   opacityClearToVisible,
        // ]}
        >
          {renderChildren()}
        </FlexContent>



        {/* {!animationDone && (
          <ImageBackgroundStyle
            alt={'imageBackground'}
            src={imageBackgroundSource}
            tintColor={tintBlackColor()}
          // style={[
          //   imageScale,
          //   logoOpacity,
          //   {
          //     width,
          //     height,
          //     tintColor: backgroundColor,
          //   },
          // ]}
          />
        )} */}


        {!animationDone && (
          <LogoStyle>
            <LogoImageStyle
              alt={'logo'}
              src={logoImage}
              width={logoWidth}
              height={logoHeight}
            // style={[
            //   logoScale,
            //   logoOpacity,
            // ]}
            />
          </LogoStyle>
        )}

      </ContainerGlue>

    </Screen>

  );
};



export default AnimatedSplash;
