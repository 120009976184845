const environment = {
  production: false,
  environment: 'DEV',
  title: 'Dev Environment Heading',
  apiAccountGlobalUrl: 'https://api-dev.noitada.app',
  apiUrl: 'https://api-dev.secretio.app',
};



export default environment;
