import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  EditRentPaymentTypeModele,
} from '../../../../../../apis/endpoints/modeles.endpoints';

import ButtonAction from '../../../../../../components/Buttons/ButtonAction';

import {
  Content,
} from '../../../../../../components/Composh/web';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../../../../components/Contents/ContentPadder';

import FooterApp from '../../../../../../components/Footers/FooterApp';
import LoadingScreen from '../../../../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../../../../components/Titles/SectionTitle';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
  Sizes,
  Values,
} from '../../../../../../shared/constants';

import EPaymentType from '../../../../../../shared/enums/Financial/payment-types.enum';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../../../shared/utils/errors.utils';

import {
  PaymentsContainer,
  PaymentsPriceContainer,
  PaymentsContent,
  WrapperItemContent,
  ImagePayment,
  PaymentsSubTitle,
  TogglePayTypeStyled,
  CardPricesForm,
  InputTextStyled,
  PaymentsInputContainer,
  InputBooleanStyled,
} from './styled';



export interface IProps {
  modele: any;
}



const TabPricesScreen: React.FC<IProps> = (props: IProps) => {
  const modeleDetails = props.modele || null;

  const dispatch = useDispatch();


  const formik = useRef<FormikProps<any>>(null);

  const [price15minValue, setPrice15minValue] = useState<string>('');
  const [price15minEnabled, setPrice15minEnabled] = useState<boolean>(false);

  const [price30minValue, setPrice30minValue] = useState<string>('');
  const [price30minEnabled, setPrice30minEnabled] = useState<boolean>(false);

  const [price1hourValue, setPrice1hourValue] = useState<string>('');
  const [price1hourEnabled, setPrice1hourEnabled] = useState<boolean>(false);

  const [price2hourValue, setPrice2hourValue] = useState<string>('');
  const [price2hourEnabled, setPrice2hourEnabled] = useState<boolean>(false);

  const [price4hourValue, setPrice4hourValue] = useState<string>('');
  const [price4hourEnabled, setPrice4hourEnabled] = useState<boolean>(false);

  const [priceOvernightValue, setPriceOvernightValue] = useState<string>('');
  const [priceOvernightEnabled, setPriceOvernightEnabled] = useState<boolean>(false);

  const [priceDailyValue, setPriceDailyValue] = useState<string>('');
  const [priceDailyEnabled, setPriceDailyEnabled] = useState<boolean>(false);

  const [priceDailyTravelValue, setPriceDailyTravelValue] = useState<string>('');
  const [priceDailyTravelEnabled, setPriceDailyTravelEnabled] = useState<boolean>(false);

  const [priceWeeklyValue, setPriceWeeklyValue] = useState<string>('');
  const [priceWeeklyEnabled, setPriceWeeklyEnabled] = useState<boolean>(false);

  const [priceWeeklyTravelValue, setPriceWeeklyTravelValue] = useState<string>('');
  const [priceWeeklyTravelEnabled, setPriceWeeklyTravelEnabled] = useState<boolean>(false);

  const [priceMonthlyValue, setPriceMonthlyValue] = useState<string>('');
  const [priceMonthlyEnabled, setPriceMonthlyEnabled] = useState<boolean>(false);

  const [priceMonthlyTravelValue, setPriceMonthlyTravelValue] = useState<string>('');
  const [priceMonthlyTravelEnabled, setPriceMonthlyTravelEnabled] = useState<boolean>(false);


  const [paymentTypes, setPaymentTypes] = useState<Array<string>>([]);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    if (formik.current) {
      const moduleName = modeleDetails?.appRentPrices;

      setPrice15minValue(moduleName?.price15min);
      setPrice15minEnabled(moduleName?.price15min);

      setPrice30minValue(moduleName?.price30min);
      setPrice30minEnabled(moduleName?.price30min);

      setPrice1hourValue(moduleName?.price1hour);
      setPrice1hourEnabled(moduleName?.price1hour);

      setPrice2hourValue(moduleName?.price2hour);
      setPrice2hourEnabled(moduleName?.price2hour);

      setPrice4hourValue(moduleName?.price4hour);
      setPrice4hourEnabled(moduleName?.price4hour);

      setPriceOvernightValue(moduleName?.priceOvernight);
      setPriceOvernightEnabled(moduleName?.priceOvernight);

      setPriceDailyValue(moduleName?.priceDaily);
      setPriceDailyEnabled(moduleName?.priceDaily);

      setPriceDailyTravelValue(moduleName?.priceDailyTravel);
      setPriceDailyTravelEnabled(moduleName?.priceDailyTravel);

      setPriceWeeklyValue(moduleName?.priceWeekly);
      setPriceWeeklyEnabled(moduleName?.priceWeekly);

      setPriceWeeklyTravelValue(moduleName?.priceWeeklyTravel);
      setPriceWeeklyTravelEnabled(moduleName?.priceWeeklyTravel);

      setPriceMonthlyValue(moduleName?.priceMonthly);
      setPriceMonthlyEnabled(moduleName?.priceMonthly);

      setPriceMonthlyTravelValue(moduleName?.priceMonthlyTravel);
      setPriceMonthlyTravelEnabled(moduleName?.priceMonthlyTravel);


      if (moduleName?.paymentTypes) {
        const splitItem = moduleName?.paymentTypes?.split(', ');
        setPaymentTypes(splitItem);
      }

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function mountPayload() {
    const values = formik?.current?.values;

    const payload = {
      price15min: price15minEnabled ? price15minValue : '',
      price30min: price30minEnabled ? price30minValue : '',
      price1hour: price1hourEnabled ? price1hourValue : '',
      price2hour: price2hourEnabled ? price2hourValue : '',
      price4hour: price4hourEnabled ? price4hourValue : '',
      priceOvernight: priceOvernightEnabled ? priceOvernightValue : '',
      priceDaily: priceDailyEnabled ? priceDailyValue : '',
      priceDailyTravel: priceDailyTravelEnabled ? priceDailyTravelValue : '',
      priceWeekly: priceWeeklyEnabled ? priceWeeklyValue : '',
      priceWeeklyTravel: priceWeeklyTravelEnabled ? priceWeeklyTravelValue : '',
      priceMonthly: priceMonthlyEnabled ? priceMonthlyValue : '',
      priceMonthlyTravel: priceMonthlyTravelEnabled ? priceMonthlyTravelValue : '',

      paymentTypes: values?.paymentTypes?.join(', '),
    };

    return payload;
  }


  async function saveItemGroup() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = mountPayload();

    try {
      const response = await EditRentPaymentTypeModele(modeleDetails?.useralias, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function setArrayMoreItems(name: string, item: string, valueTo: any, setValueTo: any) {
    const oldValues = [...valueTo];
    const index = oldValues.indexOf(item);
    if (index !== -1) {
      oldValues.splice(index, 1);
    }
    else {
      oldValues.push(item);
    }
    setValueTo(oldValues);
    formik.current?.setFieldValue(name, oldValues);
  }


  function renderInputPrices(
    key: string,
    label: string,
    state: any,
    setState: any,
    enabled: any,
    setEnabled: any,
  ) {
    const nameState = key;


    return (

      <PaymentsInputContainer>

        <InputTextStyled
          disabled={loading || !enabled}
          type={'MONEY'}
          options={{
            precision: 2,
            separator: ',',
            delimiter: '.',
            unit: 'R$',
            suffixUnit: '',
          }}
          labelText={label}
          placeholderText={'Valor'}
          value={state}
          countLimit={Values.priceCount}
          onChange={(rawText: string) => {
            setState(rawText);
          }}
          onBlur={formik?.current?.handleBlur(nameState)}
        />


        <InputBooleanStyled
          title={'Não realiza'}
          checked={!enabled}
          disabled={loading}
          onPress={() => {
            setEnabled(!enabled);
          }}
        />

      </PaymentsInputContainer>

    );
  }


  function renderToggle(image: any, value: string, displayValue: string) {
    const verifyItem = paymentTypes.some((valueItem) => valueItem === value);


    return (

      <TogglePayTypeStyled
        selected={verifyItem}
        disabled={loading}
        iconContent={
          <ImagePayment
            alt={'PaymentImage'}
            src={image}
          />
        }
        value={value}
        height={Sizes.TOOGLE_HEIGHT}
        displayValue={displayValue}
        backgroundColor={Colors.inputBackground}
        backgroundTintColor={Colors.secondaryDark}
        borderWidth={1.5}
        borderColor={Colors.toolbarLine}
        borderRadius={Sizes.cardRadius}
        borderTintColor={Colors.secondary}
        textColor={Colors.inputPlaceholder}
        textTintColor={Colors.white}
        onPress={() => {
          setArrayMoreItems('paymentTypes', value, paymentTypes, setPaymentTypes);
        }}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveItemGroup();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        // handleChange,
        // handleBlur,
        // values,
        // errors,
        // setFieldValue,
      }) => (

        <Content>

          {!mounted && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.white}
            />
          )}



          {mounted && (
            <ContentPadder>

              <CardPricesForm
                backgroundColor={Colors.formBackground}
                borderRadius={Sizes.cardRadius}>

                <WrapperItemContent>

                  <SectionTitle
                    title={'Valores'}
                  />


                  <PaymentsPriceContainer>

                    {renderInputPrices(
                      'price15min',
                      '15 minutos',
                      price15minValue,
                      setPrice15minValue,
                      price15minEnabled,
                      setPrice15minEnabled,
                    )}


                    {renderInputPrices(
                      'price30min',
                      '30 minutos',
                      price30minValue,
                      setPrice30minValue,
                      price30minEnabled,
                      setPrice30minEnabled,
                    )}


                    {renderInputPrices(
                      'price1hour',
                      '1 hora',
                      price1hourValue,
                      setPrice1hourValue,
                      price1hourEnabled,
                      setPrice1hourEnabled,
                    )}


                    {renderInputPrices(
                      'price2hour',
                      '2 horas',
                      price2hourValue,
                      setPrice2hourValue,
                      price2hourEnabled,
                      setPrice2hourEnabled,
                    )}


                    {renderInputPrices(
                      'price4hour',
                      '4 horas',
                      price4hourValue,
                      setPrice4hourValue,
                      price4hourEnabled,
                      setPrice4hourEnabled,
                    )}


                    {renderInputPrices(
                      'priceOvernight',
                      'Pernoite (12h)',
                      priceOvernightValue,
                      setPriceOvernightValue,
                      priceOvernightEnabled,
                      setPriceOvernightEnabled,
                    )}


                    {renderInputPrices(
                      'priceDaily',
                      'Diária',
                      priceDailyValue,
                      setPriceDailyValue,
                      priceDailyEnabled,
                      setPriceDailyEnabled,
                    )}


                    {renderInputPrices(
                      'priceDailyTravel',
                      'Diária de viagem',
                      priceDailyTravelValue,
                      setPriceDailyTravelValue,
                      priceDailyTravelEnabled,
                      setPriceDailyTravelEnabled,
                    )}


                    {renderInputPrices(
                      'priceWeekly',
                      'Semanal',
                      priceWeeklyValue,
                      setPriceWeeklyValue,
                      priceWeeklyEnabled,
                      setPriceWeeklyEnabled,
                    )}


                    {renderInputPrices(
                      'priceWeeklyTravel',
                      'Semanal de viagem',
                      priceWeeklyTravelValue,
                      setPriceWeeklyTravelValue,
                      priceWeeklyTravelEnabled,
                      setPriceWeeklyTravelEnabled,
                    )}


                    {renderInputPrices(
                      'priceMonthly',
                      'Mensal',
                      priceMonthlyValue,
                      setPriceMonthlyValue,
                      priceMonthlyEnabled,
                      setPriceMonthlyEnabled,
                    )}


                    {renderInputPrices(
                      'priceMonthlyTravel',
                      'Mensal de viagem',
                      priceMonthlyTravelValue,
                      setPriceMonthlyTravelValue,
                      priceMonthlyTravelEnabled,
                      setPriceMonthlyTravelEnabled,
                    )}

                  </PaymentsPriceContainer>

                </WrapperItemContent>



                <WrapperItemContent>

                  <SectionTitle
                    title={'Pagamentos aceitos'}
                  />


                  <PaymentsContainer>
                    <PaymentsSubTitle>
                      Formas:
                    </PaymentsSubTitle>


                    <PaymentsContent>

                      {renderToggle(
                        Images.flagTypeCash,
                        EPaymentType.CASH,
                        'Dinheiro',
                      )}


                      {renderToggle(
                        Images.flagTypeCheque,
                        EPaymentType.CHEQUE,
                        'Cheque',
                      )}


                      {renderToggle(
                        Images.flagTypePix,
                        EPaymentType.PIX,
                        'Pix',
                      )}

                    </PaymentsContent>
                  </PaymentsContainer>



                  <PaymentsContainer>
                    <PaymentsSubTitle>
                      Cartão de Débito:
                    </PaymentsSubTitle>


                    <PaymentsContent>

                      {renderToggle(
                        Images.flagCardElo,
                        EPaymentType.DEBIT_ELO,
                        'Elo',
                      )}


                      {renderToggle(
                        Images.flagCardMastercard,
                        EPaymentType.DEBIT_MASTERCARD,
                        'Mastercard',
                      )}


                      {renderToggle(
                        Images.flagCardVisa,
                        EPaymentType.DEBIT_VISA,
                        'Visa',
                      )}

                    </PaymentsContent>
                  </PaymentsContainer>



                  <PaymentsContainer>
                    <PaymentsSubTitle>
                      Cartão de Crédito:
                    </PaymentsSubTitle>


                    <PaymentsContent>

                      {renderToggle(
                        Images.flagCardAmerican,
                        EPaymentType.CREDIT_AMERICAN,
                        'American Express',
                      )}


                      {renderToggle(
                        Images.flagCardDiners,
                        EPaymentType.CREDIT_DINERS,
                        'Diners',
                      )}


                      {renderToggle(
                        Images.flagCardElo,
                        EPaymentType.CREDIT_ELO,
                        'Elo',
                      )}


                      {renderToggle(
                        Images.flagCardMastercard,
                        EPaymentType.CREDIT_MASTERCARD,
                        'Mastercard',
                      )}


                      {renderToggle(
                        Images.flagCardVisa,
                        EPaymentType.CREDIT_VISA,
                        'Visa',
                      )}

                    </PaymentsContent>
                  </PaymentsContainer>

                </WrapperItemContent>



                <ContentButtonForm>
                  <ButtonAction
                    width={Sizes.buttonMinWidth}
                    title={'Salvar'}
                    onPress={() => {
                      handleSubmit();
                    }}
                  />
                </ContentButtonForm>

              </CardPricesForm>

            </ContentPadder>
          )}



          <FooterApp />

        </Content>

      )}
    </Formik>

  );
};



export default TabPricesScreen;
