import Axios from 'axios';

import {
  ETokenAsync,
} from '../../shared/enums';

import AppEnvironments from '../../shared/environments/application';

import {
  getLocalStorageItem,
} from '../../shared/utils/local-storage.utils';

import ApiWS from '../index';

const baseAccountGlobalUrl = AppEnvironments().apiAccountGlobalUrl;



export const GetUserByUsernameNumberClient = async (username: string) => {
  const token = getLocalStorageItem(ETokenAsync.USER_TOKEN);
  (Axios.defaults.headers as any).authorization = token;


  const url = baseAccountGlobalUrl + `/contas-global-support/users/find/${username}`;

  const response = await Axios.get(url);

  return {
    data: response.data,
  };
};



export const LinkClientNumberUserGlobalAccount = async (useralias: string, payload: any) => {
  const url = `/support/modeles/global-account/${useralias}`;

  const response = await ApiWS.post(url, payload);
  return response.data;
};
