import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  IconCamera,
} from '../../Icons';

import {
  GCPictureViewImageContainer,
  PictureContentView,
  NoPictureView,
  CardImportInput,
  CardImportInputContent,
  CardImportIconView,
  NoPictureText,
  GCPictureImage,
  PictureImageChangeText,
} from './styled';



export interface IProps {
  disabled?: boolean;
  accept: string;
  idButton: string;
  fileImage?: any;
  image?: string | null;
  onPress?: any;
}



const VideoUpload: React.FC<IProps> = (props: IProps) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);



  useEffect(() => {
    if (props.fileImage) {
      setImageUrl(URL.createObjectURL(props.fileImage));
    }
  }, [props.fileImage]);


  useEffect(() => {
    setImageUrl(props.image || '');
  }, []);



  return (

    <GCPictureViewImageContainer>
      <PictureContentView htmlFor={props.idButton}>

        <CardImportInput
          disabled={props.disabled}
          type={'file'}
          id={props.idButton}
          onChange={props.onPress}
          accept={props.accept}
        />


        {(imageUrl === '' || imageUrl === null || imageUrl === undefined)
          ? (
            <NoPictureView>

              <CardImportInputContent>

                <CardImportIconView>
                  <IconCamera
                    size={Sizes.iconSize}
                    color={Colors.secondary}
                  />
                </CardImportIconView>


                <NoPictureText>
                  Clique para adicionar um vídeo
                </NoPictureText>

              </CardImportInputContent>

            </NoPictureView>
          )
          : (
            <NoPictureView>

              <GCPictureImage
                autoPlay
                controls={false}
                playsInline
                muted>
                <source src={imageUrl} />
                Seu navegador não suporta a reprodução de vídeos HTML5.
              </GCPictureImage>


              <PictureImageChangeText>
                {!props.fileImage
                  ? 'Clique para alterar o vídeo'
                  : `${props.fileImage.name}`
                }
              </PictureImageChangeText>

            </NoPictureView>
          )
        }

      </PictureContentView>
    </GCPictureViewImageContainer>

  );
};



export default VideoUpload;
