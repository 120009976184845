export enum EStreamMediaTypeRelation {
  SUBCATEGORY_IMAGE = 'subcategory_image',
  CHANNEL_IMAGE = 'channel_image',
  CHANNEL_COVER = 'channel_cover',
  VIDEO_IMAGE = 'video_image',
  VIDEO_DATA = 'video_data',
  VIDEO_ADS = 'video_ads',
}



export default EStreamMediaTypeRelation;
