import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const CardStarListContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
`;


export const CardStarListImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  background-color: ${Colors.gray};
  border-radius: ${Sizes.cardRadius}px;
`;


export const CardStarListInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
`;

export const CardStarListInfoName = styled.p`
  color: ${Colors.black};
  font-size: 16px;
  font-weight: bold;
`;


export const CardStarListSelectedText = styled.p`
  margin-top: 2px;
  color: ${Colors.secondary};
  font-size: 13px;
  font-weight: bold;
`;
