import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Formik,
} from 'formik';

import {
  AuthenticateUser,
  setAuthHeaders,
} from '../../apis/endpoints/auth.endpoints';

import LoginModel from '../../apis/models/Access/login.model';
import ButtonAction from '../../components/Buttons/ButtonAction';

import {
  Screen,
} from '../../components/Composh/web';

import LoginContainer from '../../components/Containers/LoginContainer';
import InputText from '../../components/Inputs/InputText';

import NameRoutes from '../../navigation/names';

import {
  RootState,
} from '../../redux';

import {
  APP_SNACKBAR_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  USER_LOGGED,
  USER_SET,
} from '../../redux/reducers/user.store';

import {
  Colors,
  Images,
  Values,
} from '../../shared/constants';

import {
  ETokenAsync,
} from '../../shared/enums';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../shared/utils/errors.utils';

import {
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../shared/utils/local-storage.utils';

import {
  UserHasAuthorization,
} from '../../shared/utils/user.utils';

import LoginValidationSchema from '../../shared/validations/Login';

import {
  AccessLoginContainer,
  LogoContent,
  LogoImageView,
  LogoImage,
  LogoName,
  LogoApplication,
  InputsView,
  ButtonsMarginView,
} from './styled';



const AccessScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);


  const [loading, setLoading] = useState(false);
  // const [loaded, setLoaded] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function appSetValues(idUser: string, newToken: string, userData: any) {
    try {
      setLocalStorageItem(ETokenAsync.USER_ID, idUser);
      setLocalStorageItem(ETokenAsync.USER_TOKEN, newToken);
      setLocalStorageItem(ETokenAsync.USER_DATA, userData);

      dispatch({ type: USER_LOGGED, payload: true });
      dispatch({ type: USER_SET, payload: userData });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function appClearValues() {
    try {
      dispatch({ type: USER_SET, payload: {} });
      removeLocalStorageItem(USER_SET);

      dispatch({ type: USER_LOGGED, payload: false });
      removeLocalStorageItem(USER_LOGGED);
    }
    catch (error: any) {
      console.error(error);
    }
  }


  async function sendLoginRequest(values: typeof LoginModel) {
    const loginForm: typeof LoginModel = {
      email: values.email.toLowerCase(),
      password: values.password,
    };

    try {
      setLoading(true);

      try {
        const data = await AuthenticateUser(loginForm);
        const newToken = data?.token;
        const userData = data?.user;
        const idUser = data?.user?.id;

        setAuthHeaders(newToken);

        if (UserHasAuthorization(userData)) {
          try {
            appSetValues(idUser, newToken, userData);

            // setLoaded(true);
          }
          catch (error: any) {
            console.error(error);
            appClearValues();

            const messageError = treatErrorAndReturnMessage(error);
            const colorError = treatErrorAndReturnColor(error);
            showSnackBarProps(colorError, messageError);
          }
        }
        else {
          appClearValues();
          // throw new Error('NOT_AUTHORIZED');
          showSnackBarProps(Colors.danger, 'Erro');
        }
      }
      catch (error: any) {
        console.error(error);
        appClearValues();

        if (error.message === 'NOT_AUTHORIZED') {
          showSnackBarProps(Colors.danger, 'Não existe permissão de acesso a Secretio para esse usuário.');
        }
        else {
          showSnackBarProps(Colors.danger, error?.message || 'Error');
        }
      }
    }
    catch (error: any) {
      console.error(error);
      appClearValues();

      if (error.message === 'NOT_AUTHORIZED') {
        showSnackBarProps(Colors.danger, 'Não existe permissão de acesso a Secretio para esse usuário.');
      }

      if (error.code === 'ECONNABORTED') {
        showSnackBarProps(Colors.danger, 'Tempo esgotado para entrar');
      }

      if (error.response) {
        if (error.response.status === 403) {
          showSnackBarProps(Colors.danger, 'Não existe permissão de acesso para esse usuário.');
        }
        else if (error.response.status === 401) {
          showSnackBarProps(Colors.danger, 'Usuário e/ou senha inválidos.');
        }
        else if (error.response.status === 400) {
          showSnackBarProps(Colors.danger, 'Usuário e/ou senha inválidos.');
        }
      }
      else {
        showSnackBarProps(Colors.danger, error?.message || 'Error');
      }
    }
    finally {
      setLoading(false);
    }
  }


  function onOpenHomeScreen() {
    history.replace(NameRoutes.HomeScreen);
  }


  async function redirectToHome() {
    if (loggedIn) {
      onOpenHomeScreen();
    }
  }



  useEffect(() => {
    redirectToHome();
  }, [loggedIn]);

  useEffect(() => {
    redirectToHome();
  }, []);



  return (

    <Formik
      validateOnMount
      initialValues={LoginModel}
      validationSchema={LoginValidationSchema}
      onSubmit={(values) => {
        // KeyboardModal.dismiss();
        sendLoginRequest(values);
      }}>
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <LoginContainer
            backgroundImage={Images.loginImageBackground}>

            <AccessLoginContainer>

              <LogoContent>
                <LogoImageView>
                  <LogoImage
                    src={Images.logoIconApp}
                  />
                </LogoImageView>

                <LogoName>
                  Secretio
                </LogoName>

                <LogoApplication>
                  Entre ou Registre-se para continuar
                </LogoApplication>
              </LogoContent>



              <InputsView>

                <ButtonsMarginView>
                  <InputText
                    disabled={loading}
                    autoCorrect={false}
                    type={'EMAIL'}
                    labelText={'Usuário'}
                    placeholderText={'E-mail'}
                    errorText={errors.email}
                    countLimit={Values.emailMaxCount}
                    value={values.email}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                  />


                  <InputText
                    password
                    disabled={loading}
                    type={'PASSWORD'}
                    labelText={'Senha'}
                    placeholderText={'Senha'}
                    errorText={errors.password}
                    countLimit={Values.passwordMaxCount}
                    value={values.password}
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                  />

                  <ButtonAction
                    disabled={loading}
                    title={'Acessar'}
                    // iconLeft={
                    // <IconServer
                    //   color={'white'}
                    //   size={20}
                    // />
                    // }
                    onPress={() => {
                      handleSubmit();
                    }}
                  />
                </ButtonsMarginView>

              </InputsView>

            </AccessLoginContainer>

          </LoginContainer>

        </Screen>

      )}
    </Formik>

  );
};



export default AccessScreen;
