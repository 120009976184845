export const APP_COLORS = {
  primaryAccent:                    '#ffed94',
  primary:                          '#ffe14d',
  primaryDark:                      '#998000',
  secondaryAccent:                  '#e15b5b',
  secondary:                        '#ff571f',
  secondaryDark:                    '#b82e00',
  tertiary:                         '#898b9f',
  tertiaryDark:                           '#FFE358',
  accent:                           '#00FFFF',
};


export const APP_DEFAULT = {
  black:                            '#000000',
  white:                            '#FFFFFF',
  grayLight:                        '#b5b5b5',
  gray:                             '#898989',
  grayDark:                         '#666666',
  muted:                            '#9FA5AA',
  shadow:                           '#292929',
  transparent:                      'transparent',
  neutral:                          'rgba(255, 255, 255, 0.65)',
};


export const APP_GENRES = {
  male:                             '#3556f8',
  female:                           '#df2020',
  transMale:                        '#9035f8',
  transFemale:                      '#df07b3',
};


export const APP_STATUS = {
  danger:                           '#ef4e4e',
  warning:                          '#ffc600',
  attentionLight:                   '#ffa729',
  attention:                        '#FF7D35',
  attentionDark:                    '#ff630f',
  accept:                           '#46c700',
  disabled:                         '#656565',
  sketch:                           '#d829ff',
};


export const APP_ITEMS = {
  appBackground:                    '#dedcd8',
  appScrollbar:                     '#414858',
};


export const ALERTS = {
  alertOverlay:                     'rgba(0, 0, 0, 0.65)',
  alertBackground:                  '#0c1022', // '#0d111c',
  alertTitleColor:                  '#FFFFFF',
};


export const BUTTONS = {
  buttonOkDark:                     '#947b00',
  buttonOk:                         '#e6bf00',
  buttonOkAccent:                   '#17d6cc',
  buttonOkLight:                    '#79bfb8',

  buttonAttentionDark:              '#502c11',
  buttonAttention:                  '#a35f1f',
  buttonAttentionAccent:            '#de7c21',

  buttonCancelDark:                 '#501111',
  buttonCancel:                     '#960A0A',
  buttonCancelAccent:               '#D61717',

  buttonActionDark:                 '#0b4a89',
  buttonAction:                     '#1266ba',
  buttonActionAccent:               '#208af3',

  buttonDisabledDark:               '#7d818c',
  buttonDisabled:                   '#a9adb7',

  buttonSolid:                      '#c6dbe7',
  buttonSolidDark:                  '#0C2238',
};


export const CARD = {
  cardBackground:                   'rgba(255, 255, 255, .65)',
  cardBackgroundHover:              '#ed9e9e',
  cardOverlay:                      'rgba(0, 0, 0, 0.55)',
  cardButton:                       '#79bfb8',
  cardFooterOverlay:                'rgba(0, 0, 0, 0.3)',
};


export const FORM = {
  formBackground:                   'rgba(255, 255, 255, .5)',
};


export const INPUTS = {
  inputBackground:                  '#ebbcbc',
  inputLabel:                       '#431e1e',
  inputPlaceholder:                 '#4a4a4a',
  inputCount:                       '#2e353d',
  inputCard:                        '#1b2e4b',
  inputMediaAdded:                  '#5D5D5D',
};


export const LEVEL = {
  fanClubLevel:                      '#6d51a7',
  fanClubBackgroundLevel:            '#1e1231',
};


export const MENU = {
  menuBackground:                   '#d0c9bb',
  menuOverlay:                      'rgba(0, 0, 0, 0.8)',
};


export const MODALS = {
  //
};


export const REFRESH = {
  refreshBackground:                '#e6e6ea',
};


export const TEXT = {
  textTitle:                        '#545460',
  textDescription:                  '#8a96a3',

  textSubtitle:                     '#666870',
  textSubDescription:               '#696E7D',

  textApp:                          '#939393',
  subtextApp:                       'rgba(255, 255, 255, 0.5)',
};


export const TOOLBAR = {
  toolbarBackground:                '#fbfbfb',
  toolbarLine:                      '#a6a6a6',

  toolbarFixedBackground:           '#161827',

  tabBackground:                    '#d6d6d6',
  
  // Profile Colors
  // profileToolbar:                   '#0A0A23',
  // profileColorTop:                  '#131a1f',
  // profileColorBottom:               '#404d55',

  // Navigation Bottom Colors
  navigationBottomBackground:       '#0A0A14',
  navigationBottomLine:             '#101827',
};



const Colors = {
  ...APP_COLORS,
  ...APP_DEFAULT,
  ...APP_GENRES,
  ...APP_STATUS,
  ...APP_ITEMS,
  ...ALERTS,
  ...BUTTONS,
  ...CARD,
  ...FORM,
  ...INPUTS,
  ...LEVEL,
  ...MENU,
  ...MODALS,
  ...REFRESH,
  ...TEXT,
  ...TOOLBAR,
};



export default Colors;
