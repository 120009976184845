export const ANIMATIONS = {
  animationTime:                        300,
};



export const CREATION_LOCK = {
  normalLock:                           10,
  lightLock:                            20,
  mediumLock:                           30,
  superLock:                            99,
  personalityLock:                      999,
  errorLock:                            2000,
};



export const HELPCOUNT_COMMON = {
  dateCount:                            8,

  codeCount:                            6,
};



export const HELPCOUNT_ADDRESS = {
  zipCount:                             8,
  
  streetMinCount:                       8,
  streetMaxCount:                       255,

  numberMinCount:                       1,
  numberMaxCount:                       30,

  complementMinCount:                   8,
  complementMaxCount:                   255,

  districtMinCount:                     3,
  districtMaxCount:                     255,

  cityMinCount:                         3,
  cityMaxCount:                         255,

  stateMinCount:                        3,
  stateMaxCount:                        100,

  countryMinCount:                      3,
  countryMaxCount:                      100,
};



export const HELPCOUNT_FINCANCIAL = {
  priceCount:                           6,

  agencyCount:                          8,
  accountCount:                         8,
  dvCount:                              1,
};



export const HELPCOUNT_PROFILE = {
  nameMinCount:                         2,
  nameMaxCount:                         200,

  cpfCount:                             11,

  cnpjCount:                            14,

  usernameMinCount:                     5,
  usernameMaxCount:                     50,

  emailMinCount:                        9,
  emailMaxCount:                        200,

  passwordMinCount:                     8,
  passwordMaxCount:                     20,

  ddiMinCount:                          1,
  ddiMaxCount:                          4,

  phoneMinCount:                        5,
  phoneMaxCount:                        25,
  
  filterCount:                          25,

  linkCount:                            255,

  aboutMeCount:                         255,
  textCount:                            3000,

  ageCount:                             2,

  hourMinCount:                         1,
  hourMaxCount:                         2,
};



export const HELPCOUNT_PAYMENTS = {
  creditCardMinCount:                   14,
  creditCardMaxCount:                   16,
};



export const TABLES = {
  firstPage:                            1,
  itemsPerPage:                         10,
  pagesPagination:                      5,
};



export const TIMES = {
  reRenderTime:                         50,
  mountTime:                            200,
  snackBarTime:                         1800,
};



const Values = {
  ...ANIMATIONS,
  ...CREATION_LOCK,
  ...HELPCOUNT_COMMON,
  ...HELPCOUNT_ADDRESS,
  ...HELPCOUNT_FINCANCIAL,
  ...HELPCOUNT_PROFILE,
  ...HELPCOUNT_PAYMENTS,
  ...TABLES,
  ...TIMES,
};



export default Values;
