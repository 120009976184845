import React from 'react';

import ButtonAction from '../../../../../../components/Buttons/ButtonAction';

import {
  Overlay,
} from '../../../../../../components/Composh/web';

import {
  Colors,
} from '../../../../../../shared/constants';

import {
  CategoryChooseContainer,
  TravelInfoTitle,
  TravelInfoContainer,
  TravelInfoTextView,
  TravelInfoText,
} from './styled';



export interface IProps {
  visible?: boolean;
  onCancelPress?: any;
}



const HelpTravelModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <CategoryChooseContainer>

        <TravelInfoContainer>
          <TravelInfoTitle>
            Recursos e regras de localização de viagens:
          </TravelInfoTitle>

          <TravelInfoTextView>
            {[
              'Se Anuncie em até 6 localizaçoes de cidade de viagem',
              'Anuncie-se com até 90 dias de antecedência em cada cidade',
              'Anuncie até 30 dias no máximo em cada cidade',
            ].map((item) => {
              return (
                <TravelInfoText>
                  {item}
                </TravelInfoText>
              );
            })}
          </TravelInfoTextView>
        </TravelInfoContainer>


        <ButtonAction
          title={'Fechar'}
          onPress={() => {
            if (props.onCancelPress) {
              props.onCancelPress();
            }
          }}
        />

      </CategoryChooseContainer>

    </Overlay>

  );
};



export default HelpTravelModal;
