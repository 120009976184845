import styled,
{
  css,
} from 'styled-components';

import ButtonEnable from '../../../../components/Buttons/ButtonEnable';

import {
  Card,
} from '../../../../components/Composh/web';

import InputText from '../../../../components/Inputs/InputText';

import {
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
  backgroundColor?: string;
}



export const ProjectParamsContentCenterFlex = styled(Card.View)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  width: 100%;
  padding: ${Sizes.paddingFlatList}px;

  @media ${PlatformDevices.maxLaptop} {
    margin-bottom: 35px;
  }
`;

export const ProjectParamsWrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

export const ProjectParamsActiveItemContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
`;

export const InputsMediaStyle = css`
  @media ${PlatformDevices.maxTablet} {
    width: 100%;
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(50% - 10px);
  }

  @media ${PlatformDevices.minLaptopL} {
    width: calc(25% - 10px);
  }
`;

export const ProjectParamsButtonPublishStyled = styled(ButtonEnable)`
  margin-top: 3px;
  margin-bottom: 10px;

  ${InputsMediaStyle};
`;


export const InputStyle = css`
  margin-left: 5px;
  margin-right: 5px;
`;

export const InputResponse = css`
  @media ${PlatformDevices.maxMobileM} {
    width: 100%;
  }

  @media ${PlatformDevices.minMobileM} {
    width: calc(50% - 10px);
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(calc(100% / 3) - 10px);
  }

  @media ${PlatformDevices.minTabletM} {
    width: calc(20% - 10px);
  }
`;


export const InputTextStyled = styled(InputText)`
  ${InputStyle};
  ${InputResponse};
`;
