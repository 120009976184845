import ApiWS from '../index';



export const GetAllGenderUser = async () => {
  const url = '/support/app/genders-highlights';

  const response = await ApiWS.get(url);
  return response.data;
};

export const FindUserHighlights = async (payload: any) => {
  const url = '/support/app/genders-highlights/find';

  const response = await ApiWS.post(url, payload);
  return response.data;
};



export const UpdateUserGenderHighlights = async (
  userId: string,
  gender: any,
  highlightType: any,
  picture: any,
) => {
  const requestConfig = {
    headers: {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  const form = new FormData();
  form.append('gender', gender);
  form.append('highlightType', highlightType);
  form.append('name', picture?.name || 'UserProfile');
  form.append('type', picture?.type || '');
  form.append('image', picture);

  // form.append('image',
  //   {
  //     uri: picture.uri,
  //     name: 'user-profile-' + FilenameFromPath(picture.uri),
  //     type: 'image/jpg',
  //   } as any);

  const url = `/support/app/genders-highlights/${userId}`;

  const pictureResponse = await ApiWS.post(url, form, requestConfig);
  return pictureResponse.data;
};
