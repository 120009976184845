import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

const marginItems = 8;



export interface IProps {
  disabled?: boolean;
  color?: string;
}



export const ImageBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  aspect-ratio: 1;

  @media ${PlatformDevices.maxMobileM} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minMobileM} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }

  @media ${PlatformDevices.minMobileL} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
  }
  
  @media ${PlatformDevices.minTabletM} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
  }

  @media ${PlatformDevices.minLaptopL} {
    width: calc(100% / 6);
    min-width: calc(100% / 6);
    max-width: calc(100% / 6);
  }
`;

export const ImagePublishAllContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const ImageBoxContent = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const LazyImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;

export const LazyVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;


export const RibbonPhotoTypeMedia = styled.p`
  position: absolute;
  top: ${marginItems}px;
  left: ${marginItems}px;
  padding: 4px 6px;
  background-color: ${Colors.black};
  color: ${Colors.white};
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
`;


export const RibbonPhotoLevel = styled.p`
  position: absolute;
  top: ${marginItems + 30}px;
  left: ${marginItems}px;
  padding: 4px 6px;
  background-color: #292828;
  color: ${Colors.white};
  font-size: 9.5px;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
`;



export const RibbonTopInfo = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: ${marginItems}px;
  left: ${marginItems}px;
  padding: 4px 6px;
  background-color: ${Colors.black};
  border-radius: 6px;
  gap: 6px;
`;

export const RibbonVideoDuration = styled.p`
  color: ${Colors.white};
  font-size: 13px;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0,0,0,.7);
`;

export const RibbonHdVideoInserted = styled.span`
  color: ${Colors.warning};
  font-size: 13px;
  font-weight: 500;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0,0,0,.7);
`;


export const VideoIcon = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  padding: 12px;
  background-color: rgba(47, 41, 41, 0.5);
  transform: translate(-50%,-50%);
  border-radius: 50%;
`;



export const CanBuyRibbon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: ${marginItems}px;
  right: ${marginItems}px;
  width: 30px;
  height: 30px;
  background-color: ${Colors.alertOverlay};
  border-radius: 50px;
`;



export const ImageActionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: ${marginItems}px;
  right: ${marginItems}px;
`;

export const ImageActionButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: 6px;
  background-color: ${Colors.alertOverlay};
  border-radius: 50px;
`;
