import styled from 'styled-components';

import Colors from '../../shared/constants/Colors';



export const AccessLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 22px;
  background-color: ${Colors.appBackground};
`;


export const LogoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-top: 20px;
`;

export const LogoImageView = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding: 9px;
  background-color: black;
  border-radius: 5px;
  
  shadow-color: #808080;
  shadow-offset: 0 4px;
  shadow-opacity: 0.8;
  shadow-radius: 10px;

  elevation: 8;
`;

export const LogoImage = styled.img`
  height: 50px;
  width: 50px;
`;

export const LogoName = styled.h1`
  margin-bottom: 10px;
  color: white;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.5px;
`;

export const LogoApplication = styled.h2`
  color: gray;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.5px;
`;



export const InputsView = styled.div`
  flex: 1;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const ButtonsMarginView = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
`;
