import Axios from 'axios';
import AppEnvironments from '../../shared/environments/application';
import ApiWS from '../index';

const baseAccountGlobalUrl = AppEnvironments().apiAccountGlobalUrl;



export const setAuthHeaders = (token: string) => {
  (ApiWS.defaults.headers as any).authorization = token;
};



export const AuthenticateUser = async (data: any) => {
  const url = baseAccountGlobalUrl + '/auth';

  const response = await Axios.post(url, data);

  if (response.data.token) {
    const newToken = response.data.token;

    const userData = response.data.user;

    return {
      token: newToken,
      user: userData,
    };
  }
  else {
    console.log('TOKEN NOT PRESENT');
    console.log(response.data);
  }
};



export const AuthenticateUserToken = async (data: any) => {
  const url = baseAccountGlobalUrl + '/auth/token';
  const response = await Axios.post(url, data);

  if (response.data) {
    const newToken = response.data.token;

    const userData = response.data.user;

    return {
      token: newToken,
      user: userData,
    };
  }
};



export const TryRevalidateSession = async (token: string | null) => {
  if (token) {
    const url = baseAccountGlobalUrl + '/auth/revalidate';

    const response = await Axios.post(url, { token });

    return response.data;
  }
  else {
    return null;
  }
};



export const GetUserByClientNumber = async (clientNumber: string) => {
  const url = `/auth/${clientNumber}`;

  const response = await ApiWS.get(url);
  return response.data;
};
