import styled from 'styled-components';

import {
  Card,
} from '../../../../components/Composh/web';

import InputBoolean from '../../../../components/Inputs/InputBoolean';
import InputText from '../../../../components/Inputs/InputText';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';



export const CarnivalCabinHeaderView = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.08);
`;

export const CarnivalCabinBackgroundView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 160px;
  margin-bottom: -50px;
  z-index: 10;
`;

export const CarnivalCabinBackgroundText = styled.p`
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: ${Colors.white};
  font-size: 13px;
  z-index: 5;
`;

export const CarnivalCabinImagesContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: 20px;
  z-index: 20;
`;



export const CardContactsForm = styled(Card.View)`
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;



export const InputTextStyled = styled(InputText)`
`;

export const InputBooleanStyled = styled(InputBoolean)`
`;
