import React from 'react';

import ListTablePaginator from '../../Composh/web/ListTable/Paginator';

import {
  TableControlContainer,
  TablePaginationFooterView,
} from './styled';



export interface IProps {
  topBorder?: boolean;
  color?: string;
  accentColor?: string;
  maxPagesToShow?: number;
  pageCurrent?: number | string;
  pagesTotal?: number | string;
  pageSelected?: (page: string) => void;
}



const TableFooter: React.FC<IProps> = (props: IProps) => {
  const tableNormalColor = props.color;
  const tableAccentColor = props.accentColor;

  const newPageCurrent = Number(props.pageCurrent);
  const newPagesTotal = Number(props.pagesTotal);


  return (

    <TableControlContainer
      topBorder={props.topBorder}>

      <TablePaginationFooterView>
        {newPageCurrent > 0 && newPagesTotal > 0 && (
          <ListTablePaginator
            {...props}
            color={tableNormalColor}
            accentColor={tableAccentColor}
            maxPagesToShow={props.maxPagesToShow}
            pageCurrent={props.pageCurrent}
            pagesTotal={props.pagesTotal}
            pageSelected={(sizeString) => {
              if (props.pageSelected) {
                props.pageSelected(sizeString);
              }
            }}
          />
        )}
      </TablePaginationFooterView>

    </TableControlContainer>

  );
};



export default TableFooter;
