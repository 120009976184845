import styled,
{
  css,
} from 'styled-components';

import {
  Card,
  Toggle,
} from '../../../../../../components/Composh/web';

import InputCheckbox from '../../../../../../components/Inputs/InputCheckbox';
import InputText from '../../../../../../components/Inputs/InputText';

import {
  Colors,
  Sizes,
} from '../../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../../shared/utils/platforms.utils';



export const CardPricesForm = styled(Card.View)`
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;

export const PaymentsPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;


export const InputResponse = css`
  flex: 1;

  @media ${PlatformDevices.maxTabletM} {
    min-width: 100%;
  }

  @media ${PlatformDevices.minTabletM} {
    min-width: calc(50% - 10px);
  }

  @media ${PlatformDevices.minLaptop} {
    min-width: calc(calc(100% / 3) - 10px);
  }
`;

export const PaymentsInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;

  ${InputResponse};
`;

export const InputTextStyled = styled(InputText)`
  flex: 1;
`;

export const InputBooleanStyled = styled(InputCheckbox)`
  margin-left: 8px;
  margin-right: 15px;
`;


export const PaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  margin-bottom: 5px;
`;

export const PaymentsText = styled.p`
  margin-top: 3px;
  color: ${Colors.textApp};
  font-size: ${14}px;
  line-height: 20.5px;
  white-space: pre-wrap;

  margin-top: 7px;
  margin-bottom: 3px;
`;

export const PaymentsContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;



export const WrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;



export const PaymentsImage = styled.img`
  width: 55px;
  height: 25px;
  object-fit: contain;
`;


export const ImagePayment = styled.img`
  width: 55px;
  height: 25px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: contain;
`;

export const PaymentsSubTitle = styled.p`
  margin-left: 4px;
  margin-bottom: 7px;
  color: ${Colors.black};
  font-size: 16px;
  font-weight: bold;
`;


export const PaymentsEmptyView = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;



export const TogglePayTypeStyled = styled(Toggle)`
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 8px;
`;
