import styled from 'styled-components';

import {
  Content,
} from '../../../../../../components/Composh/web';

import {
  Colors,
  Sizes,
} from '../../../../../../shared/constants';



export const CategoryChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 270px;
  max-height: 320px;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const CategoryContent = styled(Content)`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
`;
