import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Images,
} from '../../../shared/constants';

import ButtonAddImage from '../../Buttons/ButtonAddImage';

import {
  PictureOrderView,
  PictureContentView,
  CardImportInput,
  PictureOrderButton,
  PictureOrderImage,
} from './styled';



export interface IProps {
  disabled?: boolean;
  idButton: string;
  fileImage?: any;
  image?: string;
  onPress?: any;
}



const PictureOrderItem: React.FC<IProps> = (props: IProps) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);


  const eventImage = (props.image === '' || props.image === null || props.image === undefined)
    ? Images.bannerDetails
    : props.image;



  useEffect(() => {
    if (props.fileImage) {
      setImageUrl(URL.createObjectURL(props.fileImage));
    }
  }, [props.fileImage]);


  useEffect(() => {
    setImageUrl(imageUrl || '');
  }, []);



  return (

    <PictureOrderView>
      <PictureContentView htmlFor={props.idButton}>

        <CardImportInput
          disabled={props.disabled}
          type={'file'}
          id={props.idButton}
          onChange={(e) => {
            props.onPress(e);
          }}
          accept={'.jpg, .jpeg, .bmp, .png'}
        />


        <PictureOrderButton>

          <PictureOrderImage
            src={eventImage}
          />

        </PictureOrderButton>



        <ButtonAddImage
          text={'Inserir imagem'}
        />

      </PictureContentView>
    </PictureOrderView>

  );
};



export default PictureOrderItem;
