import styled from 'styled-components';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

export const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`;


// Breadcrumb

export const BreadcrumbLink = styled(Link)`
  text-decoration: none;
`;

export const BreadcrumbSeparator = styled(MdKeyboardArrowRight)`
  margin-left: -3px;
  margin-right: -3px;
`;

export const BreadcrumbText = styled.p`
  color: gray;
  font-size: 12px;
`;
