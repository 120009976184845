import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  CreateHelpFaqQuestions,
  EditHelpFaqQuestions,
} from '../../../../../apis/endpoints/help-faq-questions.endpoints';

import ButtonAction from '../../../../../components/Buttons/ButtonAction';

import {
  Content,
  Title,
} from '../../../../../components/Composh/web';

import {
  Screen,
} from '../../../../../components/Containers/ScreenContainer';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../../components/Controls';

import FooterApp from '../../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';
import RequiredText from '../../../../../components/Titles/TextRequired';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../../shared/constants';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../../shared/utils/errors.utils';

import {
  CardContactsForm,
  InputTextStyled,
  InputBooleanStyled,
} from './styled';



export interface IRouteLocation {
  edit: boolean;
  item?: any;
}



const HelpFaqQuestionsFormScreen: React.FC = () => {
  const dispatch = useDispatch();

  const routeLocation = useLocation();
  const params = routeLocation?.state as IRouteLocation;


  const formik = useRef<FormikProps<any>>(null);

  const editForm = params?.edit;
  const [itemToEdit, setItemToEdit] = useState<any | any>(params?.item);

  const [frequentItem, setFrequentItem] = useState<boolean>(false);
  const [visibleItem, setVisibleItem] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    if (editForm && formik.current) {
      formik.current?.setFieldValue('idFaqCategory', itemToEdit?.idFaqCategory);
      formik.current?.setFieldValue('appLink', itemToEdit?.appLink);
      formik.current?.setFieldValue('title', itemToEdit?.title);
      formik.current?.setFieldValue('description', itemToEdit?.description);

      setFrequentItem(itemToEdit?.frequent ? itemToEdit?.frequent : false);
      formik.current?.setFieldValue('frequent', itemToEdit?.frequent);

      setVisibleItem(itemToEdit?.visible ? itemToEdit?.visible : false);
      formik.current?.setFieldValue('visible', itemToEdit?.visible);

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function resetForm() {
    setMounted(false);

    setFrequentItem(false);
    setVisibleItem(false);

    formik.current?.resetForm();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  };


  function mountPayload() {
    const values = formik?.current?.values;

    const payload: any = {
      idFaqCategory: values.idFaqCategory,
      appLink: values.appLink,
      title: values.title ? String(values.title).trim() : '',
      description: values.description ? String(values.description).trim() : '',
      frequent: values.frequent,
      visible: values.visible,
    };

    return payload;
  }


  async function createEvent() {
    const payload = mountPayload();

    const response = await CreateHelpFaqQuestions(payload);

    if (response) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');

      resetForm();
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function editEvent() {
    const payload = mountPayload();

    const itemToSave = {
      id: itemToEdit?.id,
      ...payload,
    };

    setItemToEdit(itemToSave);

    const response = await EditHelpFaqQuestions(itemToEdit?.id, payload);

    if (response) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function saveEvent() {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      if (editForm) {
        await editEvent();
      }
      else {
        await createEvent();
      }
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveEvent();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <HeaderScreen
            leftIcon={
              <ButtonBack
                color={Colors.black}
                size={27}
              />
            }
            centerContent={
              <Title
                color={Colors.black}>
                {editForm
                  ? 'Editar questão [Ajuda]'
                  : 'Criar questão [Ajuda]'
                }
              </Title>
            }
          />



          <Content>

            {!mounted && (
              renderLoading()
            )}



            {mounted && (
              <ContentPadder>

                <CardContactsForm
                  backgroundColor={Colors.formBackground}
                  borderRadius={Sizes.cardRadius}>

                  <RequiredText>
                    * {'Campos obrigatórios'}
                  </RequiredText>



                  <InputTextStyled
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={`${'Id da categoria'} *`}
                    placeholderText={`${'Id'} *`}
                    errorText={errors.idFaqCategory}
                    countLimit={Values.nameMaxCount}
                    value={values.idFaqCategory}
                    onChange={handleChange('idFaqCategory')}
                    onBlur={handleBlur('idFaqCategory')}
                  />



                  <InputTextStyled
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={`${'Mostrar em'} *`}
                    placeholderText={`${'Aplicativo'} *`}
                    errorText={errors.appLink}
                    countLimit={Values.nameMaxCount}
                    value={values.appLink}
                    onChange={handleChange('appLink')}
                    onBlur={handleBlur('appLink')}
                  />



                  <InputTextStyled
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={`${'Título da questão'} *`}
                    placeholderText={`${'Título'} *`}
                    errorText={errors.title}
                    countLimit={Values.nameMaxCount}
                    value={values.title}
                    onChange={handleChange('title')}
                    onBlur={handleBlur('title')}
                  />



                  <InputTextStyled
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={`${'Descrição da questão'} *`}
                    placeholderText={'Descrição'}
                    errorText={errors.description}
                    countLimit={Values.textCount}
                    value={values.description}
                    onChange={handleChange('description')}
                    onBlur={handleBlur('description')}
                  />



                  <InputBooleanStyled
                    disabled={loading}
                    labelText={'Frequente'}
                    selected={frequentItem}
                    onPress={(state: boolean) => {
                      setFieldValue('frequent', state);
                      setFrequentItem(state);
                    }}
                  />



                  <InputBooleanStyled
                    disabled={loading}
                    labelText={'Visível'}
                    selected={visibleItem}
                    onPress={(state: boolean) => {
                      setFieldValue('visible', state);
                      setVisibleItem(state);
                    }}
                  />



                  <ContentButtonForm>
                    <ButtonAction
                      width={Sizes.buttonMinWidth}
                      title={'Salvar'}
                      onPress={() => {
                        handleSubmit();
                      }}
                    />
                  </ContentButtonForm>

                </CardContactsForm>

              </ContentPadder>
            )}



            <FooterApp />

          </Content>

        </Screen>

      )}
    </Formik>

  );
};



export default HelpFaqQuestionsFormScreen;
