import styled from 'styled-components';

import {
  Card,
} from '../../../../components/Composh/web';

import {
  Colors,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
  backgroundColor?: string;
}



export const SubCateoryItemEditButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SubCateoryItemEditText = styled.p`
  margin-right: 8px;
`;



export const ProjectParamsContentCenterFlex = styled(Card.View)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  width: 100%;
  margin-top: 5px;

  @media ${PlatformDevices.maxLaptop} {
    margin-bottom: 35px;
  }
`;



export const CabinImageView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  width: 50px;
  height: 50px;
  border-radius: 4px;

  background-color: ${Colors.sketch};
`;


export const CabinListImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
`;
