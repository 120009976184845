import styled from 'styled-components';

import {
  Card,
  ListFlat,
} from '../../../../components/Composh/web';



export const AllModelesRightContent = styled(Card.View)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  margin-top: 5px;
`;



export const AllModelesFlatList = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'hidden',
    padding: 5,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
