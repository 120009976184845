import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  Input,
} from '../../Composh/web';



export interface IProps {
  color?: string;
}



export const ContentChipTop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
`;


export const FilterLabel = styled(Input.Label)`
  margin-left: 0;
  margin-right: 0;
`;



export const FilterChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 5px;
`;



export const FilterChipSearch = styled.p`
  padding-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  font-size: 15px;
  color: ${(props: IProps) => props.color || Colors.grayDark};
  border-color: ${(props: IProps) => props.color || Colors.grayDark};
  border-style: solid;
  border-width: 1px;
  border-radius: ${Sizes.buttonRadius}px;
`;
