import React from 'react';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import {
  returnIconGenre,
} from '../../../config/genre-icon.config';

import {
  RenderRibbonImage,
} from '../../../config/plan.config';

import { countriesOption } from '../../../shared/arrays/App/countries.array';
import { typeGenders } from '../../../shared/arrays/Gender/gender.array';
import { cockSizesArray } from '../../../shared/arrays/User/user-cock-size.array';
import { positionsArray } from '../../../shared/arrays/User/user-positions.array';
import { userStatusArray } from '../../../shared/arrays/User/user-status.array';

import {
  Colors,
  Images,
  Sizes,
} from '../../../shared/constants';

import {
  EGenderUser,
} from '../../../shared/enums';

import EPlansUser from '../../../shared/enums/Financial/plan-user.enum';
import EResponseBool from '../../../shared/enums/Response/response-bool.enum';

import {
  MaskNotificationsBadge,
} from '../../../shared/utils/masks.utils';

import {
  stringRawDataText,
  validateString,
} from '../../../shared/utils/string.utils';

import {
  IconAds,
  IconCircleCheck,
  IconCountPulse,
  IconVerified,
  IconVerifiedPromoter,
  IconCircleRemove,
  IconCircleAttention,
  IconPenis,
  IconBed,
} from '../../Icons';

import {
  CardModeleContainer,
  CardModeleContent,
  CardModeleUserContent,
  CardModeleGold,
  Cardaphotoh,
  Cardphotobigtall,
  Cardaphotodimmer,
  CardOndimmer,
  CardModfeonline,
  CardModeffeGold,
  CardMffodeleGoldInfo,
  CardAllModeleInfoHeader,
  CardAllModeleHeaderName,
  HomeIdentiyViewUsername,
  HomeIdentiyUsername,
  HomeIdentiyIcon,
  CardModeleDetaisContent,
  CardModeleDetaisItem,
  CardAllModeleHeaderIcon,
  ModelesPlaceCountry,
  CafrdModeleGold,
  ModelAllAboutContent,
  CaffrdModeleGold,
  CardMffodeleGolfd,
  CardBadgesContainerFooter,
  CardbadgesContainerabsolute,
  CardbadgesIconse,
  CardModelfeGold,
  CardIconUserText,
  CardAllModeleFooterActions,
  CardAllModeleActionItem,
  CardAllModeleActionIcon,
  CardAllModeleActionText,
} from './styled';



export interface IProps {
  alias?: string;
  useralias?: string;
  image?: string;

  cock?: string;
  position?: string;

  rent?: boolean;
  fanClub?: boolean;
  live?: boolean;
  massage?: boolean;
  stream?: boolean;

  online?: boolean;
  verified?: boolean;
  promoter?: boolean;
  exclusive?: boolean;
  pornstar?: boolean;

  country?: string;
  place?: string;

  about?: string;
  gender?: EGenderUser;
  visible?: boolean;
  status?: any;
  plan?: EPlansUser;

  statusMediaAntiFake?: EResponseBool;
  statusProfile?: number;
  statusImages?: number;
  statusVideos?: number;
  statusReviews?: number;

  onPress?: any;
  onAntiFakePress?: any;
  onProfilePress?: any;
  onPicturePress?: any;
  onVideoPress?: any;
  onReviewsPress?: any;
}



const CardModelApprove: React.FC<IProps> = (props: IProps) => {
  const planReceived = props.plan as EPlansUser;

  const userImage = validateString(props.image) || Images.avatarUserImage;
  const iconSize = 26;

  const countryValue = props.country;
  const filterLangCountry = countriesOption.find((country) => stringRawDataText(country.code) === stringRawDataText(countryValue));



  function renderIconBool() {
    const statusBool = props.statusMediaAntiFake as EResponseBool;

    if (statusBool === EResponseBool.NO) {
      return (

        <IconCircleRemove
          color={Colors.danger}
          size={iconSize}
        />

      );
    }


    if (statusBool === EResponseBool.CAUTION) {
      return (

        <IconCircleAttention
          color={Colors.attention}
          size={iconSize}
        />

      );
    }


    return (

      <IconCircleCheck
        color={Colors.accept}
        size={iconSize}
      />

    );
  }



  function renderIconOkOrNot(statusBool: number) {
    if (statusBool > 0) {
      return (

        <IconCountPulse
          color={Colors.attention}
          text={MaskNotificationsBadge(statusBool)}
        />

      );
    }


    return (

      <IconCircleCheck
        color={Colors.accept}
        size={iconSize}
      />

    );
  }


  function renderModeleServices(): string {
    const itemMoreArray = [];

    if (props.fanClub) {
      itemMoreArray.push('FanClub');
    }

    if (props.rent) {
      itemMoreArray.push('Acompanhante');
    }

    if (props.live) {
      itemMoreArray.push('Live');
    }

    if (props.massage) {
      itemMoreArray.push('Massagem');
    }

    if (props.stream) {
      itemMoreArray.push('Stream');
    }

    if (itemMoreArray?.length > 0) {
      return itemMoreArray.join(', ');
    }

    return '-';
  }


  function renderModeleMoreItems(): string {
    const itemMoreArray = [];

    if (props.exclusive) {
      itemMoreArray.push('Exclusivo');
    }

    if (props.pornstar) {
      itemMoreArray.push('Pornstar');
    }

    if (itemMoreArray?.length > 0) {
      return itemMoreArray.join(', ');
    }

    return '-';
  }



  return (

    <CardModeleContainer>
      <CardModeleContent
        backgroundColor={Colors.white}
        borderRadius={Sizes.cardRadius}>

        <CardModeleUserContent>

          {RenderRibbonImage(planReceived) && (
            <CardModeleGold
              src={RenderRibbonImage(planReceived)}
            />
          )}



          <Cardaphotoh
            onClick={() => {
              if (props.onPress) {
                props.onPress();
              }
            }}>

            <Cardphotobigtall
              src={userImage}
            />



            <Cardaphotodimmer>

              {props.online && (
                <CardOndimmer>

                  <CardModfeonline />

                  <CardModeffeGold>
                    {'Online'.toUpperCase()}
                  </CardModeffeGold>

                </CardOndimmer>
              )}

            </Cardaphotodimmer>

          </Cardaphotoh>



          <CardMffodeleGoldInfo>

            <CardAllModeleInfoHeader>

              <CardAllModeleHeaderName
                onClick={() => {
                  if (props.onPress) {
                    props.onPress();
                  }
                }}>
                {props.alias || '-'}
              </CardAllModeleHeaderName>



              <HomeIdentiyViewUsername>

                <HomeIdentiyUsername>
                  @{props.useralias || '-'}
                </HomeIdentiyUsername>


                {props.verified && (
                  <HomeIdentiyIcon
                    onClick={() => {
                      // dispatch({ type: USER_VERIFIED_MODAL, payload: true });
                    }}>
                    <IconVerified
                      size={13}
                      color={Colors.accent}
                    />
                  </HomeIdentiyIcon>
                )}


                {props.promoter && (
                  <HomeIdentiyIcon
                    onClick={() => {
                      // dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: true });
                    }}>
                    <IconVerifiedPromoter
                      size={13}
                      color={Colors.black}
                    />
                  </HomeIdentiyIcon>
                )}

              </HomeIdentiyViewUsername>



              <HomeIdentiyViewUsername>

                {countryValue && (
                  <CardAllModeleHeaderIcon>
                    <ModelesPlaceCountry
                      src={filterLangCountry?.image}
                    />
                  </CardAllModeleHeaderIcon>
                )}


                <CafrdModeleGold
                  title={props.place}>
                  {props.place}
                </CafrdModeleGold>

              </HomeIdentiyViewUsername>



              {(props.gender !== EGenderUser.WOMAN && props.gender !== EGenderUser.TRANS_MAN) && (
                <CardModeleDetaisContent>

                  <CardModeleDetaisItem>

                    <CardAllModeleHeaderIcon>
                      <IconPenis
                        color={'#999'}
                        size={14}
                      />
                    </CardAllModeleHeaderIcon>


                    <CafrdModeleGold
                      title={props.place}>
                      {cockSizesArray.find((item) => item?.value === props.cock)?.label || '-'}
                    </CafrdModeleGold>

                  </CardModeleDetaisItem>



                  <CardModeleDetaisItem>

                    <CardAllModeleHeaderIcon>
                      <IconBed
                        color={'#999'}
                        size={14}
                      />
                    </CardAllModeleHeaderIcon>


                    <CafrdModeleGold
                      title={props.place}>
                      {positionsArray.find((item) => item?.value === props.position)?.label || '-'}
                    </CafrdModeleGold>

                  </CardModeleDetaisItem>

                </CardModeleDetaisContent>
              )}

            </CardAllModeleInfoHeader>



            <ModelAllAboutContent
              onClick={() => {
                if (props.onPress) {
                  props.onPress();
                }
              }}>
              <CaffrdModeleGold>
                {props.about || '-'}
              </CaffrdModeleGold>
            </ModelAllAboutContent>



            <CardMffodeleGolfd
              color={Colors.secondaryDark}
              onClick={() => {
                if (props.onPress) {
                  props.onPress();
                }
              }}>
              {renderModeleServices()}
            </CardMffodeleGolfd>



            <CardMffodeleGolfd
              color={Colors.primaryDark}
              onClick={() => {
                if (props.onPress) {
                  props.onPress();
                }
              }}>
              {renderModeleMoreItems()}
            </CardMffodeleGolfd>



            <CardBadgesContainerFooter>

              <CardbadgesContainerabsolute>

                <CardModelfeGold>
                  <CardbadgesIconse>
                    {returnIconGenre(
                      props.gender,
                      Colors.black,
                      19,
                    )}
                  </CardbadgesIconse>

                  <CardIconUserText>
                    {typeGenders?.find((item: IInputSelect) => item.value === props.gender)?.label || '-'}
                  </CardIconUserText>
                </CardModelfeGold>



                <CardModelfeGold>
                  <CardbadgesIconse>
                    <IconAds
                      color={props.visible ? Colors.accept : Colors.attention}
                      size={21}
                    />
                  </CardbadgesIconse>

                  <CardIconUserText
                    color={props.visible ? Colors.accept : Colors.attention}>
                    {props.visible ? 'Visivel' : 'Nao Visivel'}
                  </CardIconUserText>
                </CardModelfeGold>



                <CardModelfeGold>
                  <CardbadgesIconse>
                    <IconAds
                      color={Colors.black}
                      size={21}
                    />
                  </CardbadgesIconse>

                  <CardIconUserText>
                    {userStatusArray?.find((item: IInputSelect) => item.value === props.status)?.label || '-'}
                  </CardIconUserText>
                </CardModelfeGold>

              </CardbadgesContainerabsolute>

            </CardBadgesContainerFooter>

          </CardMffodeleGoldInfo>

        </CardModeleUserContent>



        <CardAllModeleFooterActions>

          <CardAllModeleActionItem
            onClick={() => {
              if (props.onAntiFakePress) {
                props.onAntiFakePress();
              }
            }}>

            <CardAllModeleActionIcon>
              {renderIconBool()}
            </CardAllModeleActionIcon>

            <CardAllModeleActionText>
              Midia anti-fake
            </CardAllModeleActionText>

          </CardAllModeleActionItem>



          <CardAllModeleActionItem
            onClick={() => {
              if (props.onProfilePress) {
                props.onProfilePress();
              }
            }}>

            <CardAllModeleActionIcon>
              {renderIconOkOrNot(props.statusProfile)}
            </CardAllModeleActionIcon>

            <CardAllModeleActionText>
              Fotos do perfil
            </CardAllModeleActionText>

          </CardAllModeleActionItem>



          <CardAllModeleActionItem
            onClick={() => {
              if (props.onPicturePress) {
                props.onPicturePress();
              }
            }}>

            <CardAllModeleActionIcon>
              {renderIconOkOrNot(props.statusImages)}
            </CardAllModeleActionIcon>

            <CardAllModeleActionText>
              Galeria de imagens
            </CardAllModeleActionText>

          </CardAllModeleActionItem>



          <CardAllModeleActionItem
            onClick={() => {
              if (props.onVideoPress) {
                props.onVideoPress();
              }
            }}>

            <CardAllModeleActionIcon>
              {renderIconOkOrNot(props.statusVideos)}
            </CardAllModeleActionIcon>


            <CardAllModeleActionText>
              Galeria de videos
            </CardAllModeleActionText>

          </CardAllModeleActionItem>



          <CardAllModeleActionItem
            onClick={() => {
              if (props.onReviewsPress) {
                props.onReviewsPress();
              }
            }}>

            <CardAllModeleActionIcon>
              {renderIconOkOrNot(props.statusReviews)}
            </CardAllModeleActionIcon>

            <CardAllModeleActionText>
              Avaliações
            </CardAllModeleActionText>

          </CardAllModeleActionItem>

        </CardAllModeleFooterActions>

      </CardModeleContent>
    </CardModeleContainer>

  );
};



export default CardModelApprove;
