import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  IconCheck,
  IconClose,
} from '../../../../../../components/Icons';

import {
  RootState,
} from '../../../../../../redux';

import {
  Colors,
} from '../../../../../../shared/constants';

import {
  VideoContentRightHome,
  WarningApproveText,
  WrapperItemContent,
  ApproveSplitView,
  ApproveSplitIconView,
  ApproveSplitText,
  ApproveItemInfoView,
  ApproveItemSplitText,
  ApproveSubItemSplitText,
} from './styled';



const RightVideoInfo: React.FC = () => {
  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  return (

    <VideoContentRightHome>

      <WarningApproveText>
        Siga estas regras para acelerar o processo de aprovação.
      </WarningApproveText>



      <WrapperItemContent>

        <ApproveSplitView>
          <ApproveSplitIconView>
            <IconCheck
              color={Colors.black}
              size={20}
            />
          </ApproveSplitIconView>


          <ApproveSplitText>
            Aceito
          </ApproveSplitText>
        </ApproveSplitView>



        <ApproveItemInfoView>
          <ApproveItemSplitText>
            Vídeos com mais de 30 segundos.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Vídeos com mais de 10 minutos podem ser classificados como vídeos de produção caso haja mais de uma pessoa e também são mostrados no Stream do Secretio.
          </ApproveItemSplitText>

          {projectParams?.showFanClub && (
            <ApproveSubItemSplitText>
              Vídeos de produção são com duas ou mais pessoas, passam dos 10 min, automáticamente são classificados apenas para o seu fã-clube visualizar e também são mostrados no Stream do Secretio.
            </ApproveSubItemSplitText>
          )}

          <ApproveItemSplitText>
            Tamanho do arquivo menor que 195 MB.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Formatos suportados: .AVI, .WMV, .MOV, .MP4, .MKV, .MPG, .MPEG.
          </ApproveItemSplitText>
        </ApproveItemInfoView>

      </WrapperItemContent>



      <WrapperItemContent>

        <ApproveSplitView>
          <ApproveSplitIconView>
            <IconClose
              color={Colors.black}
              size={20}
            />
          </ApproveSplitIconView>


          <ApproveSplitText>
            Não aceito
          </ApproveSplitText>
        </ApproveSplitView>



        <ApproveItemInfoView>
          <ApproveItemSplitText>
            Vídeos com menos de 30 segundos.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Tamanho do arquivo maior que 100 MB.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Vídeos protegidos por direitos autorais, a menos que um consentimento por escrito e assinado do proprietário dos direitos autorais nos seja enviado.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Vídeos que retratam álcool, drogas, armas, fezes, sangue, tortura, notas ou outra violência.
          </ApproveItemSplitText>
        </ApproveItemInfoView>

      </WrapperItemContent>

    </VideoContentRightHome>

  );
};



export default RightVideoInfo;
