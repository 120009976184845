import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useLocation,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  CreateProjectMessages,
  EditProjectMessages,
} from '../../../../apis/endpoints/project-messages.endpoints';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Content,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../../components/Titles/SectionTitle';
import RequiredText from '../../../../components/Titles/TextRequired';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import { statusMessagesArray } from '../../../../shared/arrays/Project/project-messages-status.array';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  DayJStoIso,
  FormatIsoDateWithPattern,
} from '../../../../shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  DetailsTopViewItems,
  InputTextStyled,
  InputButtonCheckboxStyled,
  InputViewStyled,
  CardContactsForm,
  ContactsAttendanceOptionsContent,
  GenderUserRadio,
} from './styled';



export interface IRouteLocation {
  edit: boolean;
  item?: any;
}



const CreateProjectMessageScreen: React.FC = () => {
  const dispatch = useDispatch();

  const routeLocation = useLocation();
  const params = routeLocation?.state as IRouteLocation;


  const formik = useRef<FormikProps<any>>(null);

  const editForm = params?.edit;
  const [itemToEdit, setItemToEdit] = useState<any | any>(params?.item);

  const [typeUser, setTypeUser] = useState<boolean>(false);
  const [typeModele, setTypeModele] = useState<boolean>(false);
  const [typeSupport, setTypeSupport] = useState<boolean>(false);

  const [statusMessage, setStatusMessage] = useState('');
  const [initialStatusMessage, setInitialStatusMessage] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function renderTypeMessages(): string {
    const itemMoreArray: Array<string> = [];

    if (typeUser) {
      itemMoreArray.push('USER');
    }

    if (typeModele) {
      itemMoreArray.push('MODELE');
    }

    if (typeSupport) {
      itemMoreArray.push('SUPPORT');
    }

    if (itemMoreArray?.length > 0) {
      return itemMoreArray.join(', ');
    }

    return '-';
  }


  function updateForm() {
    if (editForm && formik.current) {
      if (itemToEdit?.toProfile) {
        const splitItem: Array<string> = itemToEdit?.toProfile?.split(', ');
        console.log(splitItem);

        setTypeUser(splitItem.includes('USER') ? true : false);
        setTypeModele(splitItem.includes('MODELE') ? true : false);
        setTypeSupport(splitItem.includes('SUPPORT') ? true : false);
      }
      formik.current?.setFieldValue('toProfile', renderTypeMessages());


      if (itemToEdit?.status) {
        const indexStatus = statusMessagesArray.find((item: any) => item.value === itemToEdit?.status);

        if (indexStatus) {
          setStatusMessage(indexStatus.value);
          setInitialStatusMessage(indexStatus.id);
        }
        else {
          setStatusMessage(itemToEdit?.gender);
          setInitialStatusMessage(itemToEdit?.gender);
        }
      }
      formik.current?.setFieldValue('status', itemToEdit?.status);


      formik.current?.setFieldValue('title', itemToEdit?.title);
      formik.current?.setFieldValue('description', itemToEdit?.description);
      formik.current?.setFieldValue('text', itemToEdit?.text);

      formik.current?.setFieldValue('startDate', FormatIsoDateWithPattern(itemToEdit?.startDate, 'dd/MM/yyyy'));
      formik.current?.setFieldValue('endDate', FormatIsoDateWithPattern(itemToEdit?.endDate, 'dd/MM/yyyy'));

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function mountPayload() {
    const values = formik?.current?.values;

    const payload: any = {
      toProfile: renderTypeMessages(),
      status: statusMessage,
      title: values.title,
      description: values.description,
      text: values.text,
      startDate: DayJStoIso(values.startDate, 'DD/MM/YYYY'),
      endDate: DayJStoIso(values.endDate, 'DD/MM/YYYY'),
    };

    return payload;
  }


  async function createEvent() {
    const payload = mountPayload();

    const response = await CreateProjectMessages(payload);

    if (response) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function editEvent() {
    const payload = mountPayload();

    const itemToSave = {
      id: itemToEdit?.id,
      ...payload,
    };

    setItemToEdit(itemToSave);

    const response = await EditProjectMessages(itemToEdit?.id, payload);

    if (response) {
      showSnackBarProps(Colors.accept, 'Item salvo com sucesso');
    }
    else {
      console.error('Error', response);
      showSnackBarProps(Colors.danger, 'Erro ao salvar item');
    }
  }


  async function saveEvent() {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      if (editForm) {
        await editEvent();
      }
      else {
        await createEvent();
      }
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveEvent();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <HeaderScreen
            leftIcon={
              <ButtonBack
                color={Colors.black}
                size={27}
              />
            }
            centerContent={
              <Title
                color={Colors.black}>
                {editForm
                  ? 'Editar mensagem'
                  : 'Criar mensagem'
                }
              </Title>
            }
          />



          <Content>

            {!mounted && (
              renderLoading()
            )}



            {mounted && (
              <ContentPadder>

                <CardContactsForm
                  backgroundColor={Colors.formBackground}
                  borderRadius={Sizes.cardRadius}>

                  <RequiredText>
                    * {'Campos obrigatórios'}
                  </RequiredText>



                  <DetailsTopViewItems>

                    <SectionTitle
                      title={'Para os perfis'}
                    />


                    <ContactsAttendanceOptionsContent>

                      <InputButtonCheckboxStyled
                        title={'Usuário'}
                        checked={typeUser}
                        onPress={() => {
                          setTypeUser(!typeUser);
                        }}
                      />


                      <InputButtonCheckboxStyled
                        title={'Modelo'}
                        checked={typeModele}
                        onPress={() => {
                          setTypeModele(!typeModele);
                        }}
                      />


                      <InputButtonCheckboxStyled
                        title={'Suporte'}
                        checked={typeSupport}
                        onPress={() => {
                          setTypeSupport(!typeSupport);
                        }}
                      />

                    </ContactsAttendanceOptionsContent>

                  </DetailsTopViewItems>



                  <DetailsTopViewItems>

                    <SectionTitle
                      title={'Dados principais'}
                    />


                    <ContactsAttendanceOptionsContent>

                      <InputViewStyled
                        noShadow
                        labelText={`${'Status'} *`}
                        backgroundColor={Colors.transparent}
                        helpText={errors.gender}
                        helpColor={Colors.danger}
                        countLimit={'Marque apenas um'}>

                        <GenderUserRadio
                          data={statusMessagesArray}
                          initial={initialStatusMessage}
                          circleSize={16}
                          boxDirection={'row'}
                          direction={'column'}
                          activeColor={Colors.secondaryDark}
                          deactiveColor={Colors.inputPlaceholder}
                          textStyle={{
                            marginTop: 5,
                          }}
                          onPress={(item: any) => {
                            setFieldValue('status', item?.value);
                            setStatusMessage(item.value);
                          }}
                        />

                      </InputViewStyled>



                      <InputTextStyled
                        disabled={loading}
                        autoCorrect
                        type={'TEXT'}
                        labelText={`${'Título'} *`}
                        placeholderText={`${'Título'} *`}
                        errorText={errors.title}
                        countLimit={Values.nameMaxCount}
                        value={values.title}
                        onChange={handleChange('title')}
                        onBlur={handleBlur('title')}
                      />



                      <InputTextStyled
                        disabled={loading}
                        autoCorrect
                        type={'TEXT'}
                        labelText={`${'Descrição'} *`}
                        placeholderText={`${'Descrição'} *`}
                        errorText={errors.description}
                        countLimit={Values.aboutMeCount}
                        value={values.description}
                        onChange={handleChange('description')}
                        onBlur={handleBlur('description')}
                      />



                      <InputTextStyled
                        disabled={loading}
                        autoCorrect
                        type={'TEXT'}
                        labelText={'Texto de suporte'}
                        placeholderText={'Texto de suporte'}
                        errorText={errors.text}
                        countLimit={Values.usernameMaxCount}
                        value={values.text}
                        onChange={handleChange('text')}
                        onBlur={handleBlur('text')}
                      />



                      <InputTextStyled
                        disabled={loading}
                        autoCorrect
                        type={'DATETIME'}
                        options={{
                          format: 'DD/MM/YYYY',
                        }}
                        labelText={`${'Início da mensagem'} *`}
                        placeholderText={`${'Data'} *`}
                        errorText={errors.startDate}
                        countLimit={Values.usernameMaxCount}
                        value={values.startDate}
                        onChange={(_rawText: string, maskedText: string) => {
                          setFieldValue('startDate', maskedText);
                        }}
                        onBlur={handleBlur('startDate')}
                      />



                      <InputTextStyled
                        disabled={loading}
                        autoCorrect

                        type={'DATETIME'}
                        options={{
                          format: 'DD/MM/YYYY',
                        }}
                        labelText={`${'Final da mensagem'} *`}
                        placeholderText={`${'Data'} *`}
                        errorText={errors.endDate}
                        countLimit={Values.usernameMaxCount}
                        value={values.endDate}
                        onChange={(_rawText: string, maskedText: string) => {
                          setFieldValue('endDate', maskedText);
                        }}
                        onBlur={handleBlur('endDate')}
                      />

                    </ContactsAttendanceOptionsContent>

                  </DetailsTopViewItems>



                  <ContentButtonForm>
                    <ButtonAction
                      width={Sizes.buttonMinWidth}
                      title={'Salvar'}
                      onPress={() => {
                        handleSubmit();
                      }}
                    />
                  </ContentButtonForm>

                </CardContactsForm>

              </ContentPadder>
            )}



            <FooterApp />

          </Content>

        </Screen>

      )}
    </Formik>

  );
};



export default CreateProjectMessageScreen;
