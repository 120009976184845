import {
  EAppTranslations,
  EAppTutorialTranslations,
  EButtonsTranslations,
  ECompanyTypesTranslations,
  ECountriesTranslations,
  EDrinksTranslations,
  EEventsTranslations,
  EInterpolatesTranslations,
  EKitchensTranslations,
  ELabelsTranslations,
  ELanguagesTranslations,
  EMenuOrderTranslations,
  EMessagesEmptysTranslations,
  EMessagesErrosTranslations,
  EMessagesHelpsTranslations,
  EMessagesQuestionsTranslations,
  EMessagesSuccessTranslations,
  EMusicsTranslations,
  EPaymentsTranslations,
  EReservesTranslations,
  ESocialsTranslations,
  ETimeTranslations,
  ETitlesTranslations,
  ESubtitlesTranslations,
  EValidationsTranslations,
  ECarnivalTranslations,
} from './translates.keys';



export const APP: Record<EAppTranslations, string> = {
  NAME_APP: 'Secretio Support',
  SHORTNAME_APP: 'Support',
  SLOGAN_APP: '¡Aquí tienes las mejores razones para disfrutar la noche!',
  COMPANY_NAME: 'Secretio',
  COMPANY_APP: '¡Para disfrutar de la noche!',
};


export const APP_TUTORIAL: Record<EAppTutorialTranslations, string> = {
  TUTORIAL_TITLE_1: 'Diversión',
  TUTORIAL_TEXT_1: '¡La manera más fácil de ir a fiestas, discotecas, pubs o tus bares favoritos con tus amigos!',
  TUTORIAL_TITLE_2: 'Practicidad',
  TUTORIAL_TEXT_2: '¡La mejor herramienta para encontrar dónde será tu próxima Secretio!',
  TUTORIAL_TITLE_3: 'Precios',
  TUTORIAL_TEXT_3: '¡Descuentos y promociones en toda tu Secretio!',
  TUTORIAL_TITLE_4: '¿Listo para disfrutar de la noche?',
};


export const BUTTONS: Record<EButtonsTranslations, string> = {
  ACTION_ACCESS: 'Ingresar',
  ACTION_ACCESS_TO_CONTINUE: 'Ingresar para continuar',
  ACTION_REGISTER: 'Registrar',
  ACTION_REGISTER_USER: 'Registrar Usuario',
  ACTION_REGISTER_COMPANY: 'Registrar Empresa',
  ACTION_REGISTER_TO_CONTINUE: 'Registrar para continuar',
  ACTION_CANCEL: 'Cancelar',
  ACTION_CONFIRM: 'Confirmar',
  ACTION_FORWARD: 'Avanzar',
  ACTION_BACK: 'Retroceder',
  ACTION_CLOSE: 'Cerrar',
  ACTION_ASK_CHECKIN: 'Solicitar Check-in',
  ACTION_CANCEL_CHECKIN: 'Cancelar Check-in',
  ACTION_CHECKIN_MANUAL: 'Check-in Manual',
  ACTION_CLICK_OPEN: 'Clic para Abrir',
  ACTION_CLICK_CLOSE: 'Clic para Cerrar',
  ACTION_CLICK_CANCEL: 'Clic para Cancelar',
  ACTION_MORE_DETAILS: 'Clic para más detalles',
  ACTION_LISTS_SEE: 'Ver Listas',
  ACTION_SEE_MENUSHEET: 'Ver Menú',
  ACTION_PAY: 'Pagar',
  ACTION_PAY_APP: 'Pago en Línea',
  ACTION_PAY_PRESENTIAL: 'Pago Presencial',
  ACTION_PAY_ORDERSHEET: 'Pagar Comanda',
  ACTION_ADD_MORE_ITEMS: 'Agregar más ítems',
  ACTION_BAG_SEE: 'Ver Bolsa',
  ACTION_BAG_CLEAN: 'Vaciar Bolsa',
  ACTION_FINISH_ORDER: 'Finalizar Pedido',
  ACTION_CLOSE_ORDERSHEET: 'Cerrar Comanda',
  ACTION_SEE_WIFI: 'Ver Wi-Fi',
  ACTION_NO: 'No',
  ACTION_YES: 'Sí',
  ACTION_SEE_HISTORY: 'Ver Historial Completo',
  ACTION_HISTORY: 'Historial Completo',
  ACTION_SAVE: 'Guardar',
  ACTION_OPEN_LIST: 'Clic para Abrir Lista',
  ACTION_OPEN_GALLERY: 'Clic para Ver Galería',
  ACTION_TRANSFER_ADD: 'Agregar Cuenta para Transferencia',
  ACTION_COMPANY_ADD: 'Agregar Empresa',
  ACTION_USER_DISABLE: 'Desactivar Perfil',
  ACTION_RECOVER_ACCESS: 'Recuperar Acceso',
  ACTION_CARD_ADD: 'Agregar Tarjeta',
  ACTION_ADDRESS_REGISTER: 'Registrar Dirección',
  ACTION_CLICK_EDIT: 'Clic para Editar',
  ACTION_LOCATION_ENABLED: 'Activar Ubicación',
  ACTION_INVITE_LINK: 'Invitar por Enlace',
  ACTION_ADD_PERSON: 'Agregar Persona',
  ACTION_ADD_PEOPLE: 'Agregar Personas',
  ACTION_ADD_MANUAL: 'Agregar Manualmente',
  ACTION_LIST_PARTICIPATE: 'Participar en Lista',
  ACTION_LIST_LEAVE: 'Salir de Lista',
  ACTION_TICKET_SEE: 'Ver Boletos',
  ACTION_COPY_PASSWORD: 'Clic para Copiar Contraseña',
  ACTION_COPY_LINK: 'Copiar link',
  ACTION_SEE_DATES: 'Ver Más Fechas',
  ACTION_DO_LOGIN: 'Ingresar',
  ACTION_TO_RESERVE: 'Reservar',
  ACTION_CANCEL_RESERVE: 'Cancelar Reserva',
  ACTION_SEARCH: 'Buscar',
  ACTION_RESEARCH: 'Refazer Buscar',
  ACTION_ADDRESS_SEE_YOURS: 'Clic para Ver tus Direcciones',
  ACTION_ADDRESS_SEE: 'Ver Direcciones',
  ACTION_PAY_FORMS_SEE: 'Ver Métodos de Pago',
  ACTION_OPEN_WAZE: 'Abrir con Waze',
  ACTION_OPEN_UBER: 'Abrir con Uber',
  ACTION_HOUR_SEE: 'Clic para Ver Horarios',
  ACTION_CLICK_TO_MANAGE: 'Clic para Gestionar',
  ACTION_LOCATION_MY: 'Usar mi Ubicación Actual',
  ACTION_PROFILE_SEE: 'Ver Perfil',
  ACTION_CLICK_TO_ADD: 'Clic para Agregar',
  ACTION_CLICK_TO_EDIT: 'Clic para Editar',
  ACTION_TO_SEE_PROFILE: 'Clic para Ver tu Perfil',
  ACTION_SKIP: 'Omitir',
  ACTION_COME_ON: '¡Vamos allá!',
  ACTION_PROFILE_MANAGE: 'Haga clic para solicitar este perfil',
  ACTION_CHECK_ALL: 'Marca todos',
  ACTION_UNCHECK_ALL: 'Desmarca todos',
  ACTION_JOIN_VIA_LIST: 'Unirse a través de listas',
  ACTION_SEE_AVAILABLES_TICKETS: 'Ver entradas disponibles',
  ACTION_ENTRANCE_IN_EVENT: 'Asiste al evento',
};


export const COMPANY_TYPES: Record<ECompanyTypesTranslations, string> = {
  EVENT_PRODUCER: 'Productor de Eventos',
  EVENT_PRODUCERS: 'Productores de Eventos',
  BAR: 'Bar',
  BARS: 'Bares',
  PUB: 'Pub',
  PUBS: 'Pubs',
  NIGHT_CLUB: 'Club Nocturno',
  NIGHT_CLUBS: 'Clubs Nocturnos',
  RESTAURANT: 'Restaurante',
  RESTAURANTS: 'Restaurantes',
  PIZZERIA: 'Pizzería',
  PIZZERIAS: 'Pizzerías',
  STEAKHOUSE: 'Churrasquería',
  STEAKHOUSES: 'Churrasquerías',
  HAMBURGUER: 'Hamburguería',
  HAMBURGUERS: 'Hamburguerías',
  COFFEE_SHOP: 'Cafetería',
  COFFEE_SHOPS: 'Cafeterías',
  CARNIVAL_PRODUCER: 'Productor de Carnavales',
  CARNIVAL_PRODUCERS: 'Productores de Carnavales',
  PARTY_PRODUCER: 'Productor de Festivales',
  PARTY_PRODUCERS: 'Productores de Festivales',
  GOVERN: 'Gobierno',
  GOVERNS: 'Gobiernos',
};


export const CARNIVALS: Record<ECarnivalTranslations, string> = {
  CARNIVAL_VOTES: 'Votaciones de Carnaval',
  CARNIVAL_INTEREST_POINTS: 'Puntos de Interés',
  CARNIVAL_OFFICIAL: 'Carnaval Oficial',
  CARNIVAL_MICARETA: 'Micareta',
  CIRCUIT_OFFICIAL: 'Circuito Oficial',
  BLOC: 'Bloque',
  CIRCUIT: 'Circuito',
  CIRCUITS: 'Circuitos',
  CABIN: 'Camarote',
  CABINS: 'Camarotes',
  POLICE: 'Policía',
  BUS: 'Autobús',
  TAXI: 'Taxi',
  BATHROOM: 'Baño',
  FIREMAN: 'Bombero',
  SAVE_LIFE: 'Salvavidas',
  MEDICAL_POINT: 'Punto Médico',
  JUVENILE: 'Organización Juvenil',
  OTHER_ATTRACTION: 'Otras Atracciones',
  AGENCY_FLOW: 'Agentes de Flujo',
  AGENCY_PUBLIC: 'Órganos Públicos',
  CLEAN_HEALTH: 'Salud e Higiene',
  AREA_RESERVED: 'Áreas Reservadas',
};


export const COUNTRIES: Record<ECountriesTranslations, string> = {
  BRAZIL: 'Brasil',
  PORTUGAL: 'Portugal',
  UNITED_STATES: 'Estados Unidos',
  SPAIN: 'España',
  ITALY: 'Italia',
  FANCE: 'Francia',
};


export const DRINKS: Record<EDrinksTranslations, string> = {
  WATER: 'Agua',
  SODA: 'Refresco',
  VODKA: 'Vodka',
  BEER: 'Cerveza',
  WINE: 'Vino',
  TEA: 'Té',
  COFFEE: 'Café',
  JUICE: 'Jugo',
  WHISKY: 'Whisky',
  LIQUOR: 'Licor',
  COCKTAIL: 'Cóctel',
  CHAMPAGNE: 'Champán',
  GIN: 'Gin',
  RUM: 'Ron',
  ENERGY_DRINK: 'Bebida Energética',
  SMOOTHIE: 'Batido',
  YERBA_MATE: 'Mate',
  SAKE: 'Sake',
  TEQUILA: 'Tequila',
};


export const EVENTS: Record<EEventsTranslations, string> = {
  EVENT_TYPE: 'Tipo de evento',
  EVENT_IDEAL_LGBT: 'Ideal para el público LGBTQIA+',
  EVENT_ABOUT: 'Sobre el evento',
  EVENT_ATTRACTIONS: 'Atracciones',
  EVENT_START: 'Inicio del evento',
  EVENT_END: 'Final del evento',
  EVENT_OPENBAR: 'Bebidas en el OpenBar',
  EVENT_PARENTAL_RATING: 'Clasificación indicativa',
  EVENT_PARENTAL_NONE: 'Clasificación libre',
  EVENT_PARENTAL_14: 'Para mayores de 14 años',
  EVENT_PARENTAL_16: 'Para mayores de 16 años',
  EVENT_PARENTAL_18: 'Para mayores de 18 años',
  EVENT_LIST_AVAILABLES: 'Hay listas públicas disponibles',
  ID_TICKET: 'Ingreso ID',
  TICKET: 'Ingreso',
  TICKETS: 'Ingresos',
  TICKETS_MY: 'Mis ingresos',
  TICKETS_AVAILABLE: 'Entradas disponibles',
  EVENT_FREE: 'Evento Gratuito',
  EVENT_DATE: 'Fecha del evento',
  EVENT_DATE_INITIAL: 'Inicio del evento',
  EVENT_DATE_FINAL: 'Final del evento',
  TICKET_ENTRANCE_PRICE: 'Valores de ingresos y entradas',
  PARTY: 'Fiesta',
  NIGHT_PARTY: 'Noche de fiesta',
  NOT_CONFIRMED: 'No confirmado',
};


export const INTERPOLATES: Record<EInterpolatesTranslations, string> = {
  HELLO: 'Hola',
  PERSON_TO: 'Sirve',
  CODE_ABREV: 'Cód.:',
  FROM: 'Desde',
  TO: 'Hasta',
  PAY_PRESENTIAL: 'Pagar presencial',
  PAY_BY_APP: 'Pagar por Secretio',
  PAY_TO: 'Pagar a',
  TO_DO: 'Por hacer',
  HAVE_WIFI: 'Aquí hay Wi-Fi',
  INTERPOLATE_ORDERSHEET_CLOSING: 'Esperando a que el socio {company_name} cierre la comanda',
  INTERPOLATE_ORDERSHEET_CLOSE: 'Haz clic en "Pagar comanda" para pagar directamente por Secretio o para {company_name}',
  INTERPOLATE_ORDERSHEET_FINISH: 'Realiza el pago a {company_name} para finalizar la comanda',
  AT: 'a las',
  OR: 'o',
  AND: 'y',
  THERE_IS: 'Hay',
  LAST_ORDER: 'Último Pedido',
  LAST_ORDER_THERE_IS: 'Último Pedido hace',
  ACCESS_USER_REGISTER: 'Al registrarte en Secretio, aceptas los',
  ACCESS_USER_TERMS_USE: 'Términos de Uso',
  ACCESS_USER_TERMS_PRIVACY: 'Políticas de Privacidad',
  INTERPOLATE_LIST_LIMIT: '¡Se alcanzó el límite de {listAdditionalNumber} invitados!',
  INTERPOLATE_LIST_UNTIL_LIMIT: 'Hasta {listAdditionalNumber} persona(s) en la lista por invitado',
  PRICE_BETWEEN_MIN_MAX: 'Entre {minPrice} y {maxPrice}',
  PRICE_INSTALLMENT_VALUE: 'En hasta {installment} cuotas con tarjeta',
  RESERVE_PEOPLE_LIMIT: 'Hasta {limitReserve} persona(s) por reserva',
  RESERVE_TABLE_AVAILABLE: 'Mesas disponibles',
  RESERVE_PRICE_PAY: 'Importe de {price} para la reserva',
  PAGE_VIEWS: 'Vistas de esta página',
};


export const KITCHENS: Record<EKitchensTranslations, string> = {
  AFRICAN: 'Africana',
  GERMAN: 'Alemana',
  ARABIC: 'Árabe',
  ARGENTINA: 'Argentina',
  DRINKS: 'Bebidas',
  BRAZILIAN: 'Brasileña',
  COFFEE: 'Cafetería',
  MEATS: 'Carnes',
  CHINESE: 'China',
  COLOMBIAN: 'Colombiana',
  KOREAN: 'Coreana',
  SPANISH: 'Española',
  FRENCH: 'Francesa',
  SEAFOOD: 'Mariscos',
  INDIAN: 'India',
  ITALIAN: 'Italiana',
  JAPANESE: 'Japonesa',
  MEDITERRANEAN: 'Mediterránea',
  MEXICAN: 'Mexicana',
  FRIED_PASTRY: 'Empanadas',
  FISH: 'Pescados',
  PERUVIAN: 'Peruana',
  PIZZA: 'Pizza',
  PORTUGUESE: 'Portuguesa',
  HEALTHY: 'Saludable',
  JUICES: 'Jugos',
  THAI: 'Tailandesa',
  VEGETARIAN: 'Vegetariana',
};


export const LABELS: Record<ELabelsTranslations, string> = {
  ALL: 'Todo',
  COMPANY: 'Empresa',
  COMPANIES: 'Empresas',
  COMPANIES_MY: 'Mis empresas',
  PROFILE: 'Perfil',
  PROFILES: 'Perfiles',
  PROFILES_MY: 'Mis perfiles',
  PERSONAL_DATA: 'Datos personales',
  PERSONAL_INFORMATIONS: 'Informaciones personales',
  COUNTRY: 'País',
  FANTASY_NAME: 'Nombre fantasía (Nombre público)',
  SOCIAL_REASON: 'Razón social',
  LOADING: 'Cargando',
  USER: 'Usuario',
  NAME: 'Nombre',
  NAME_FULL: 'Nombre completo',
  DATE_BORN: 'Fecha de nacimiento',
  GENRE: 'Género',
  DDI_NUMBER_FIRST: 'DDI del teléfono principal',
  DDI_NUMBER_SECOND: 'DDI del teléfono secundario',
  DDI: 'DDI',
  PERSONS: 'Persona(s)',
  USERNAME: 'Nombre de usuario',
  USERNAME_COMPANY: 'Nombre de usuario de la empresa',
  EMAIL: 'Correo electrónico',
  PASSWORD: 'Contraseña',
  PASSWORD_CURRENT: 'Contraseña actual',
  PASSWORD_NEW: 'Nueva contraseña',
  PASSWORD_REPEAT: 'Repetir la nueva contraseña',
  PASSWORD_CONFIRM: 'Confirmar contraseña',
  CNPJ: 'CNPJ',
  CPF: 'CPF',
  FORMS: 'Formas',
  COMMENTS: 'Comentarios',
  OPTIONAL: 'Opcional',
  PUBLIC_ID: 'ID público',
  PUBLIC_PARTNER_ID: 'ID público del socio',
  NUMBER_PRIMARY: 'Número principal',
  NUMBER_SECONDARY: 'Número secundario',
  PARTNER_ABOUT: 'Sobre el socio',
  PARTNER_SERVICES: 'Servicios disponibles',
  PARTNER_PHONES: 'Teléfonos',
  PHONE_ONLY_ONE: 'Número con DDD',
  PHONE_FIRST: 'Teléfono principal con DDD',
  PHONE_SECOND: 'Teléfono secundario con DDD',
  PARTNER_RULES: 'Reglas',
  PARTNER_TYPES_KITCHEN: 'Tipos de cocina',
  PARTNER_OPEN_HOURS: 'Horario de funcionamiento',
  WIFI: 'Wi-Fi',
  KIDS_AREA: 'Área Kids',
  PARKING: 'Estacionamiento',
  SMOKING: 'Área de fumadores',
  AIR_CONDITIONER: 'Aire acondicionado',
  ACCESSIBILITY: 'Accesibilidad',
  MORE_POPULAR: 'Más populares',
  NUMBER_OR_NAME: 'Nombre o número',
  INPUT_MODAL_ADD_USER_PLACEHOLDER: 'Buscar nombre...',
  CONFIRM_CPF: 'Confirmar CPF del titular',
  CONFIRM_CNPJ: 'Confirmar CNPJ de la empresa',
  CONFIRM_INFO: 'Confirmar información',
  NICKNAME: 'Apodo',
  DESCRIPTION: 'Descripción',
  FAVORED: 'Beneficiario(a)',
  ACCOUNT_USE_MAIN: 'Usar esta cuenta como actual',
  ADDRESS_USE_MAIN: 'Usar esta dirección como actual',
  INPUT_MODAL_ADD_USER_CARGO: 'Correo electrónico o nombre de usuario',
  CODE: 'Código',
  DIGIT: 'Dígito',
  ACCOUNT: 'Cuenta',
  ID: 'ID',
  ID_PRIVATE: 'ID Privado',
  ID_PUBLIC: 'ID Público',
  COMPANY_STATUS_APP: 'Situación en Secretio',
  COMPANY_STATUS_ACTIVE: 'Empresa activa',
  COMPANY_STATUS_INACTIVE: 'Empresa deshabilitada',
  PLACE_NAME: 'Nombre del lugar',
  ZIP_CODE: 'CEP',
  STREET: 'Calle', // 'Avenida / Calle',
  STREETS: 'Calles',
  DISTRICT: 'Barrio',
  DISTRICTS: 'Barrios',
  ATTRACTIONS: 'Atracciones',
  COMPLEMENT: 'Complemento',
  CITY: 'Ciudad',
  CITIES: 'Ciudades',
  STATE: 'Estado',
  SOCIAL: 'Social',
  SLOGAN: 'Eslogan',
  CELLPHONE: 'Teléfono celular',
  ABOUT: 'Acerca de',
  ABOUT_APP: 'Acerca de Secretio',
  PROFILE_TYPE: 'Tipo de perfil',
  PARTNER_TYPE: 'Tipo de socio',
  INFORMATIONS: 'Informaciones',
  CARD_HOLDER_NAME: 'Nombre del titular de la tarjeta',
  CARD_NICKNAME: 'Apodo de la tarjeta',
  MALE: 'Masculino',
  FEMALE: 'Femenino',
  SOCIAL_NAME: 'Nombre social',
  ABOUT_ME: 'Acerca de mí',
  LEVEL: 'Nivel',
  BIRTHDAY: 'Cumpleaños',
  PICTURE: 'Foto',
  USER_PRE_REGISTER: 'Usuario pre registrado',
  DATAS: 'Datos',
  SHOPS: 'Compras',
  OPTIONS: 'Opciones',
  ME_AND_GUESTS: 'Yo y los invitados',
  ONLY_YOU: 'Solo tú',
  ONLY_FOR_ME: 'Solo para mí',
  PEOPLE: 'personas',
  RESERVE_DATE: 'Fecha de reserva',
  DATE_SELECTED: 'Fecha seleccionada',
  ENVIRONMENT: 'Ambiente',
  PEOPLE_NUMBER: 'Número de personas',
  MORE_INFORMATIONS: 'Más Informaciones',
  OBSERVATIONS: 'Observaciones',
  INITIAL_DATE: 'Fecha de inicio',
  INITIAL_HOUR: 'Hora de inicio',
  FINAL_DATE: 'Fecha final',
  FINAL_HOUR: 'Hora final',
  INITIAL_VALUE: 'Valor inicial',
  FINAL_VALUE: 'Valor final',
  PARAMETERS: 'Parámetros',
  NAME_OR_USERNAME: 'Nombre o nombre de usuario',
  MODALITIES: 'Modalidades',
  VERSION: 'Versión',
  MY_ACCOUNT: 'Mi cuenta',
  ACCOUNT_SEE: 'Ver cuenta',
  ACCOUNT_ACTIONS: 'Acciones de la cuenta',
  PAYMENTS_FORMS: 'Formas de pago',
  LOCATION: 'Ubicación',
  LOCATION_NO_ADDRESS: 'Sin información sobre la dirección',
  TICKETS_SALES_UNTIL: 'Ventas hasta',
  PARTICIPATION: 'Participación',
  WAIT_YOU: 'Te esperamos',
  ITEMS: 'Ítems',
  HELP: 'Ayuda',
  TYPE: 'Tipo',
  CANCELED: 'Cancelado',
  FINISHED: 'Finalizado',
  OTHER: 'Otro',
  ADD: 'Agregar',
  ACTUAL: 'Actual',
  COUNTRIES: 'Países',
  NO: 'No',
  YES: 'Sí',
  LIST_NAME: 'Nombre de la lista',
  GUIDES_INFORMATIONS: 'Guías e informaciones',
  PHONES_USEFUL: 'Teléfonos útiles',
  CASADINHA: 'Casadinha',
  OPEN_BAR: 'Open Bar',
  LIST: 'Lista',
  VIP: 'VIP',
};


export const LANGUAGES: Record<ELanguagesTranslations, string> = {
  PT_BR: 'Portugués (Brasil)',
  EN_US: 'Inglés (Estados Unidos)',
  ES_AR: 'Español (España)',
  IT_IT: 'Italiano (Italia)',
  FR_FR: 'Francés (Francia)',
};


export const MENU_ORDER: Record<EMenuOrderTranslations, string> = {
  COMBO: 'Combo',
  ITEM_DOUBLE: 'Doble artículo',
  AVAILABLES: 'Disponible',
  TABLE: 'Mesa',
  NUMBER: 'Número',
  NUMBER_TABLE_ENTRANCE: 'Número de mesa / entrada',
  TABLE_ENTRANCE: 'Mesa / entrada',
  MIN_ORDERSHEET: 'Pedido mínimo',
  WAITER_CALL: 'Llamar al camarero',
  WAITER_CALLING: 'Llamando al camarero',
  WAITER_GOING: 'Camarero en camino',
  ORDERSHEET_CLOSING_ORDERSHEET: 'Cerrando comanda',
  ORDERSHEET_CLOSED: 'Comanda cerrada',
  ORDERSHEET_OPENED: 'Comanda abierta',
  TOTAL_CONSUMPTION: 'Consumo total',
  ALCOHOLIC: 'Alcohólico',
  SPICY: 'Picante',
  VEGETARIAN: 'Vegetariano',
  VEGAN: 'Vegano',
  ORGANIC: 'Orgánico',
  NO_LACTOSE: 'Sin lactosa',
  NO_GLUTEN: 'Sin gluten',
  REQUESTS: 'Pedidos',
  REQUESTS_MINIMAL: 'Pedidos mínimos',
  REQUESTS_UNTIL: 'Pedidos hasta',
  DELIVERY: 'Delivery',
  REMOVAL_ITEM: 'Retirada',
  PRESENTIAL: 'Presencial',
  ORDER_PLACED: 'Pedido realizado',
  ORDER_PREPARING: 'Preparando',
  ORDER_TO_DELIVER: 'A entregar',
  ORDER_REFUSED: 'Pedido rechazado',
};


export const MESSAGES_EMPTYS: Record<EMessagesEmptysTranslations, string> = {
  EMPTY_GALLERY_PHOTOS: 'Lo siento, sin medios de este socio!',
  EMPTY_COMPANY_NOT_FOUND: 'Lo siento, perfil no encontrado!',
  EMPTY_COMPANY_EVENTS: 'No hay eventos aquí todavía!',
  EMPTY_EVENTS_NOT_FOUND: 'Lo siento, evento no disponible!',
  EMPTY_HIGHLIGHTS_ITEMS: 'Lo siento, no se encontraron destacados hoy!',
  EMPTY_HIGHLIGHTS_EVENTS: 'No hay eventos aquí todavía!',
  EMPTY_HIGHLIGHTS_PARTNERS: 'No hay socios en Secretio aquí!',
  EMPTY_CATEGORIES: 'Sin categoría',
  EMPTY_ITEMS: 'Lo siento, no hay elementos en el menú!',
  EMPTY_MENUSHEET_CATEGORIES: 'Lo siento, no hay elementos en esta categoría!',
  EMPTY_NOTIFICATIONS: 'No hay notificaciones aquí todavía!',
  EMPTY_ORDERSHEET_HISTORY: 'No hay elementos en el historial todavía!',
  EMPTY_BANKS: 'No hay bancos aquí, lo siento!',
  EMPTY_COMPANIES_REGISTERED: 'No hay empresas registradas!',
  EMPTY_HOME: 'No hay datos para mostrar!',
  EMPTY_FAVORITES: '¡Aquí no hay favoritos!',
  EMPTY_PAYMENT: 'No hay métodos de pago registrados!',
  EMPTY_ADDRESS: 'No hay direcciones registradas!',
  EMPTY_USER_DATA: 'Usuario sin datos',
  EMPTY_USER_NO_RECOG: 'Usuario no identificado',
  EMPTY_LISTS: 'No hay listas registradas',
  EMPTY_LISTS_PARTICIPATE: 'No hay listas para participar todavía!',
  EMPTY_LISTS_FINISHED: 'No hay listas en las que hayas participado todavía!',
  EMPTY_TICKET: 'No hay entradas todavía!',
  EMPTY_TICKET_IN_EVENT: 'No hay entradas para este evento',
  EMPTY_EVENT_EXPLORE: 'Explora otros eventos como este',
  EMPTY_CARD_REGISTERED: 'No hay tarjeta registrada',
  EMPTY_CARD_NO_ADDED: 'Todavía no has agregado una tarjeta a tu cuenta',
  EMPTY_RESERVE_REGISTERED: 'No hay reservas disponibles o registradas!',
  EMPTY_RESERVE_USER: 'No hay reservas todavía para disfrutar de la noche!',
  EMPTY_LINK_SEE: 'Sin enlace para ver',
  EMPTY_SEARCH: 'Lo siento, nada encontrado en la búsqueda!',
  EMPTY_ORDERS: 'No hay pedidos',
  EMPTY_EVENT_FINISHED: '¡El evento ya ha terminado!',
  EMPTY_PLACES: 'No hay lugares registrados todavía!',
  EMPTY_CARNIVAL_ATTRACTIONS: 'Lo siento, sin atracciones hoy!',
  EMPTY_CARNIVAL_NONE: '¡Lo sentimos, todavía no hay carnaval ni micaretas!',
  EMPTY_CARNIVAL_DISTRICTS: 'Lo siento, sin fiesta en los barrios hoy!',
  EMPTY_CARNIVAL_CABINS: 'Lo siento, las cabinas están cerradas hoy!',
  EMPTY_CARNIVAL_LOCATIONS: 'Lo siento, no hay ubicaciones aquí!',
  EMPTY_CARNIVAL_STREETS: 'Lo siento, sin fiesta en la calle hoy!',
  EMPTY_CARNIVAL_INFOS: 'Lo siento, no hay guías aquí!',
  EMPTY_PHONES: 'Lo siento, no hay teléfonos aquí!',
  EMPTY_CARNIVAL_VOTES: 'Lo sentimos, ¡aún no hay votos!',
  EMPTY_LANGUAGES: '¡Aún no hay idiomas!',
  EMPTY_LIST_AVAILABLE: 'No hay listas disponibles',
  EMPTY_TICKET_AVAILABLE: 'No hay entradas disponibles',
};


export const MESSAGES_ERRORS: Record<EMessagesErrosTranslations, string> = {
  ERROR: 'Error',
  ERROR_PAY: 'Error al pagar',
  ERROR_PAY_APP: 'Tuvimos un problema, por favor realiza el pago en el establecimiento',
  ERROR_CHECKIN_ASK: 'No se pudo solicitar el Check-in',
  ERROR_CHECKIN_CANCEL: 'No se pudo cancelar el Check-in',
  ERROR_HAD: 'Hubo un error',
  ERROR_SEARCH: 'Error al buscar datos',
  TRY_AGAIN: 'Error, por favor inténtalo de nuevo',
  ACCESS_DENIED: 'No hay permiso de acceso para este usuario.',
  PASSWORD_WRONG: 'Usuario y/o contraseña inválidos.',
  ERROR_PARAMS_INVALID: 'Parámetros inválidos',
  ERROR_COMPANY_CREATE: 'Error al crear la empresa',
  ERROR_USER_CREATE: 'Error al crear el perfil',
  ERROR_DATE: 'Error en la fecha',
  ERROR_NO_DATA_FOUND: 'Datos no encontrados, verifica la información e inténtalo de nuevo.',
  ERROR_EMPTY_FIELDS: 'Completa todos los campos correctamente antes de continuar',
  ERROR_ACCOUNT_TRANSFER: 'Error al crear cuenta de transferencia',
  ERROR_ACCOUNT_SEARCH: 'Error al buscar cuentas de transferencia',
  ERROR_ACCOUNT_MAIN_CHANGE: 'Hubo un error al cambiar la cuenta',
  ERROR_ACCOUNT_DELETE: 'Hubo un error al eliminar la cuenta',
  ERROR_SAVE: 'Error al guardar los datos!',
  ERROR_CREATE: 'Error al crear',
  ERROR_UPDATE: 'Error al actualizar',
  ERROR_USER_DISABLE: 'Error al deshabilitar el usuario.',
  ERROR_COMPANY_DISABLE: 'Error al deshabilitar la empresa.',
  ERROR_MAKE_REGISTER: 'Error al hacer el registro',
  ERROR_PAYMENT_MAIN_CHOOSED: 'Hubo un error al elegir el pago principal',
  ERROR_PAYMENT_DELETE: 'Hubo un error al eliminar el pago',
  ERROR_ADDRESS_DELETE: 'Hubo un error al eliminar la dirección',
  ERROR_LIST_USER_ADDED: 'Error al agregar usuarios',
  ERROR_LIST_ENTER: 'Error al intentar unirse a la lista',
  ERROR_LIST_GET_DATA: 'Error al obtener listas',
  ERROR_GET_DATA: 'Error al obtener datos',
  ERROR_TICKET_UNAVAILABLES: 'Entradas no disponibles',
  ERROR_LISTS_UNAVAILABLES: 'Listas no disponibles',
  ERROR_USER_DOESNOT_EXIST: '¡El usuario no existe!',
  ERROR_USER_IN_LIST: 'El usuario ya está en la lista',
  ERROR_FAVORITE_ADD: 'Error al agregar a favoritos',
  ERROR_FAVORITE_REMOVE: 'Error al remover de favoritos',
};


export const MESSAGES_HELP: Record<EMessagesHelpsTranslations, string> = {
  HELP_INPUT_COMPANY: 'Ingresa los datos de la empresa',
  HELP_INPUT_USER: 'Ingresa la información a continuación',
  HELP_INPUT_REQUIRED: 'Campos obligatorios',
  HELP_QR: 'Lee el código QR del socio para hacer check-in y evitar colas, agilizando tu entrada a lugares y eventos.',
  HELP_CHECKIN: 'Para hacer check-in, simplemente ingresa el ID Público del socio, el número de mesa, por ejemplo, y espera la confirmación de tu check-in.',
  HELP_CHECKIN_WAIT: 'Espera la confirmación del check-in. Si lo deseas, puedes cancelar la solicitud de check-in en cualquier momento.',
  HELP_EVENT_RATING: 'La clasificación e información de este evento son responsabilidad de sus creadores y productores.',
  HELP_EVENT_ALCOHOL: 'La venta de bebidas alcohólicas está prohibida para personas menores de 18 años.',
  HELP_PAY_UNAVAILABLE: 'Pago en línea no disponible. No hay tarjetas registradas.',
  HELP_SUCCESS_ORDER_DONE: 'Puedes seguir el estado de los elementos directamente en el historial de la comanda.',
  HELP_CHEKIN_HOW_TO: 'En la puerta del lugar o en la entrada del evento, muestra este código QR para validar boletos, listas, reservas y órdenes de entrada.',
  HELP_ORDERSHEET_CLOSING: 'Esperando cierre',
  HELP_ORDERSHEET_THANKS: 'Gracias por usar Secretio',
  HELP_ORDERSHEET_PARTNER_CLOSE: 'Tu comanda ha sido cerrada por el socio',
  HELP_ORDERSHEET_RECUSED: 'Orden rechazada',
  HELP_ORDERSHEET_NOT_FOUND: 'Check-in no aceptado o no encontrado',
  HELP_SOME: '¿Alguna pregunta?',
  HELP_TALK_TO_US: 'Habla con Secretio',
  HELP_ACCOUNT_TRANSFER: 'Para que se realice la transferencia, la cuenta bancaria a agregar debe pertenecer a la persona registrada o a la empresa.',
  HELP_ACCOUNT_BANK_RECEIVE: 'El banco receptor puede optar por la modalidad en la que acreditar la transferencia si sus cuentas de ahorro y corriente tienen el mismo número.',
  HELP_ACCOUNT_LIST_TRANSFER: 'Registra una cuenta bancaria y transfiere tu dinero a ella.',
  HELP_ACCOUNT_LIST_TRANSFER_TEXT: 'Para transferir dinero a tu cuenta bancaria, es necesario registrar una cuenta bancaria a tu nombre o al nombre de la empresa.',
  HELP_PROFILE_PICTURE: 'Una foto ayuda a que las personas te reconozcan y te permite saber qué cuenta está conectada.',
  HELP_LOGOTIPO_COMPANY_TEXT: 'Un logotipo ayuda a personalizar el perfil y permite encontrarlo más rápido.',
  HELP_LOGOTIPO_COMPANY_DIMENSIONS: 'Recomendamos una imagen cuadrada (1x1), de aproximadamente 400x400 de tamaño.',
  HELP_COMPANY_COMPLETE_PROFILE: 'Completa el perfil de tu empresa para utilizar los servicios de Secretio.',
  HELP_USER_DISABLE: 'Si deseas desactivar tu cuenta, nos encargaremos de ello por ti. Nos gusta informar a nuestros usuarios que, incluso si la cuenta está desactivada, por razones de seguridad, no podrás crear otra cuenta en nuestra plataforma, ya que solo puede haber una cuenta por CPF.',
  HELP_USER_DISABLE_BACK: 'Puedes volver en cualquier momento accediendo a tu cuenta con la contraseña ya registrada y, si la olvidas, puedes restablecerla.',
  HELP_COMPANY_DISABLE_BACK: 'Para activar nuevamente esta empresa, debes ponerte en contacto con soporte y solicitar la activación.',
  HELP_COMPANY_DISABLE_PLAN: '¡Si esta empresa tiene algún plan, también se cancelará!',
  HELP_WELCOME_TITLE: 'Bienvenido a tu cuenta',
  HELP_WELCOME_DESCRIPTION: 'Administra tu información, privacidad y seguridad para que Secretio satisfaga tus necesidades.',
  HELP_PASSWORD_NEW: 'Ingresa y confirma tu nueva contraseña; a partir de ella podrás acceder o Secretio.',
  HELP_PASSWORD_OLD: 'Ingresa tu contraseña actual para que podamos confirmar que eres tú; luego ingresa y confirma tu nueva contraseña.',
  HELP_PASSWORD_REMENBER: 'Si no recuerdas tu contraseña actual, necesitarás',
  HELP_PASSWORD_REDEFINE: 'redefinirla.',
  HELP_PASSWORD_FORGOT: 'Si olvidaste tu contraseña, ingresa el correo electrónico que utilizaste para registrarte en Secretio a continuación.',
  HELP_INVITE_PEOPLE: 'Puedes invitar a más personas ahora o más tarde.',
  HELP_INVITE_LINK: 'Comparte este enlace con tus invitados para que agreguen sus nombres a esta lista.',
  HELP_LIST_HOW_INVITE: 'Ingresa el nombre de usuario de tu invitado o, si la persona no está en Secretio, ingresa su CPF.',
  HELP_LIST_HOW_FINISH: 'Al agregar a una persona, su participación se confirma automáticamente al finalizar.',
  HELP_WIFI_NO_REGISTER: 'Sin Wi-Fi registrado; debes preguntar para saber si Wi-Fi está disponible.',
  HELP_RESERVE_MINUTES: 'Después de 30 minutos desde la hora reservada, si no hay nadie para validar la reserva, se finalizará y se liberará la mesa.',
  HELP_RESERVE_AGE: 'La edad mínima para pagar es de 4 años.',
  HELP_RESERVE_TABLE: 'La opción de unir mesas depende del socio.',
  HELP_RESERVE_TAXES: 'Las posibles tarifas, como estacionamiento o espacio infantil, no están incluidas en el precio de la reserva.',
  HELP_RESERVE_CANCEL: 'Si nadie puede asistir, cancela la reserva con anticipación. La cancelación se puede hacer hasta 3 horas antes de la hora reservada; después de ese tiempo, la cancelación no se puede realizar.',
  HELP_MINIMAL_AGE: 'Con al menos 4 años',
  HELP_RESERVE_OBS_EXAMPLE: 'Ejemplo: Cumpleaños, Celebración',
  HELP_RESERVE_QUANTITY_PARTNER: 'La cantidad de personas en la reserva será la cantidad aceptada; depende del socio identificar y contar la cantidad de personas para esa reserva y si aceptará más personas que las descritas.',
  HELP_SEARCH_BY_NAME: 'Buscar por nombre o nombre de usuario',
  HELP_SEARCH_PARAMETERS: 'Buscar por parámetros',
  HELP_LOGIN_TO_SEE: 'Inicia sesión para ver tu información',
  HELP_INSERT_USER: 'Inserta el usuario',
  HELP_USER_HAVENT_DATA: 'No tengo ninguna información',
  HELP_COMPANY_QUANTITY_ACCEPT: 'La aceptación de la cantidad de personas depende del socio. Es su responsabilidad identificar y contar el número de participantes y si aceptarán más personas de las descritas.',
  HELP_PROFILE_VERIFY_UNMANAGE: 'Perfil no administrado. Tenga cuidado y consulte siempre la información y los precios en el sitio, ya que pueden estar desactualizados.',
  HELP_PROFILE_UNMANAGE: 'El perfil para esta ubicación aún no ha sido solicitado. Los propietarios que solicitan un perfil pueden actualizar su información, realizar ventas, crear reservas, agregar fotos y más.',
  HELP_PROFILE_MANAGE: 'Una persona vinculada a esta ubicación administra este perfil.',
  HELP_ORDERSHEET_DETAILS_ENABLED: 'Puedes usar la consumación Secretio para comprar y pagar este evento.',
  HELP_ORDERSHEET_DETAILS_DISABLED: 'El consumo es desactivado por el productor, al llegar al local deberás preguntar cómo comprar y pagar tu pedido.',
};


export const MESSAGES_QUESTIONS: Record<EMessagesQuestionsTranslations, string> = {
  ANY_QUESTION: 'Cualquier duda',
  ANY_QUESTION_CONTACT: 'contáctenos',
  PAY_ATTENTION: 'Presta Atención',
  QUESTION_DELETE_CARD: '¿Eliminar tarjeta?',
  QUESTION_DELETE_CARD_TEXT: '¿Te gustaría eliminar esta tarjeta?',
  QUESTION_ORDERSHEET_CLOSE: '¿Cerrar hoja de pedidos?',
  QUESTION_ADD_CREDIT_CARD: 'No hay tarjeta de crédito en tu cuenta, ¿quieres agregar una?',
  QUESTION_ACCOUNT_MAIN_CHANGE: '¿Cambiar cuenta bancaria principal?',
  QUESTION_ACCOUNT_MAIN_CHANGE_TEXT: '¿Te gustaría establecer esta cuenta como la principal?',
  QUESTION_ACCOUNT_DELETE: '¿Eliminar cuenta bancaria?',
  QUESTION_ACCOUNT_DELETE_TEXT: '¿Te gustaría eliminar esta cuenta bancaria?',
  QUESTION_USER_DISABLE: '¿Estás seguro de que quieres desactivar tu cuenta?',
  QUESTION_COMPANY_DISABLE: '¿Estás seguro de que quieres desactivar esta empresa?',
  QUESTION_ADDRESS_REGISTER_TEXT: 'Para agregar un método de pago, es necesario registrar una dirección principal. ¿Hacerlo ahora?',
  QUESTION_ADDRESS_DELETE: '¿Eliminar dirección?',
  QUESTION_ADDRESS_DELETE_TEXT: '¿Te gustaría eliminar este elemento?',
  QUESTION_BAG_ITEM_REMOVE: '¿Quieres quitar {itemSaleName} de tu carrito?',
  QUESTION_BAG_CLEAR: '¿Vaciar la bolsa?',
  QUESTION_EXIT: '¿Quieres salir',
  QUESTION_FAVORITE_REMOVE: '¿Eliminar de favoritos?',
};


export const MESSAGES_SUCCESS: Record<EMessagesSuccessTranslations, string> = {
  SUCCESS: 'Éxito',
  SUCCESS_CHECKIN_ASK: 'Check-in confirmado. ¡Disfruta!',
  SUCCESS_CHECKIN_CANCEL: '¡Solicitud de check-in cancelada!',
  SUCCESS_ADD_ITEM_BAG: '¡Elemento agregado con éxito!',
  SUCCESS_ORDER_DONE: '¡Orden realizada!',
  SUCCESS_PAYED: '¡Pagado!',
  SUCEESS_COMPANY_CREATE: 'Empresa creada con éxito!',
  SUCEESS_COMPANY_CREATE_TEXT: 'Ahora debes esperar nuestra evaluación de datos.',
  SUCEESS_USER_CREATE: 'Perfil creado con éxito!',
  SUCEESS_USER_CREATE_TEXT: 'Hemos enviado un correo electrónico de confirmación a {user_email}. Abre el correo electrónico, confirma la creación del perfil y podrás iniciar sesión con tus credenciales registradas. Tu número de cliente también está en el correo electrónico.',
  SUCCESS_ACCOUNT_TRANSFER: 'Cuenta de transferencia creada con éxito',
  SUCCESS_ACCOUNT_MAIN_CHANGE: 'Nueva cuenta principal cambiada',
  SUCCESS_ACCOUNT_DELETE: 'Cuenta eliminada con éxito',
  SUCCESS_CREATE: 'Creado con éxito',
  SUCCESS_UPDATE: 'Actualización exitosa',
  SUCCESS_USER_DISABLE: 'Usuario desactivado con éxito',
  SUCCESS_COMPANY_DISABLE: 'Empresa desactivada con éxito',
  SUCCESS_PASSWORD_RECOVER: '¡Solicitud de recuperación de contraseña realizada con éxito!',
  SUCCESS_PASSWORD_RECOVER_TEXT: 'Hemos enviado un correo electrónico de confirmación a {user_email}. Abre el correo electrónico, haz clic en el enlace y podrás cambiar tu contraseña.',
  SUCCESS_CARD_REGISTER: 'Tarjeta registrada con éxito',
  SUCCESS_PAYMENT_MAIN_CHOOSED: 'Pago principal elegido',
  SUCCESS_PAYMENT_DELETE: 'Pago eliminado con éxito',
  SUCCESS_ADDRESS_DELETE: 'Dirección eliminada con éxito',
  SUCCESS_EMAIL_CHANGE: 'Cambio de correo electrónico enviado con éxito',
  SUCCESS_LIST_USER_ADDED: 'Usuarios añadidos con éxito',
  SUCCESS_PASSWORD_COPY: 'Contraseña copiada',
  SUCCESS_USER_ADDED: 'Usuario añadido con éxito',
  SUCCESS_FAVORITE_ADD: 'Favorito añadido con éxito',
  SUCCESS_FAVORITE_REMOVE: 'Favorito eliminado con éxito',
  SUCCESS_COPIED_ADDRESS: 'Dirección copiada',
  SUCCESS_COPIED_LINK: 'Enlace copiado',
};


export const MUSICS: Record<EMusicsTranslations, string> = {
  EVENT_MUSICAL_GENRES: 'Géneros Musicales',
  MUSIC: 'Música',
  ELECTRONIC: 'Electrónica',
  FUNK: 'Funk',
  COUNTRY_MUSIC: 'Country Music',
  POP: 'Pop',
  RAP: 'Rap',
  ROCK: 'Rock',
  MPB: 'MPB',
  AXE: 'Axé',
  SAMBA: 'Samba',
  REGGAE: 'Reggae',
  FORRO: 'Forró',
  GOSPEL: 'Gospel',
  HIP_HOP: 'Hip-Hop',
  JAZZ: 'Jazz',
  CLASSIC_MUSIC: 'Música Clásica',
  PAGODE: 'Pagode',
  BLUES: 'Blues',
};


export const PAYMENTS: Record<EPaymentsTranslations, string> = {
  BANK: 'Banco',
  AGENCY: 'Sucursal',
  CARD_SELECTED: 'Tarjeta seleccionada',
  DEBIT_CARD: 'Tarjeta de débito',
  CREDIT_CARD: 'Tarjeta de crédito',
  CREDIT: 'Crédito',
  DEBIT: 'Débito',
  TICKETS_CARD: 'Tickets',
  CASH: 'Efectivo',
  BANK_CHECK: 'Cheque bancario',
  PIX: 'Pix',
  ELO: 'Elo',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
  AMERICAN: 'American',
  DINERS: 'Diners',
  HIPERCARD: 'Hipercard',
  ALELO: 'Alelo',
  BEN: 'Ben',
  SODEXO: 'Sodexo',
  CARD_TICKET: 'Ticket',
  VR: 'Vr',
  CHANGE: 'Cambio',
  DISCOUNT: 'Descuento',
  TIP: 'Propina',
  TOTAL_PAY: 'Total pagado',
  TOTAL: 'Total',
  SUBTOTAL: 'Subtotal',
  TAXES: 'Impuestos y cargos',
  TAX_DELIVERY: 'Tarifa de entrega',
  PARTNER_PAYMENTS_ON: 'Pagos aceptados en el lugar',
  WAITING_PAYMENT: 'Esperando pago',
  ALL_BANKS: 'Todos los bancos',
  ACCOUNT_CURRENT: 'Cuenta corriente',
  ACCOUNT_SAVING: 'Cuenta de ahorros',
  ACCOUNT_NICKNAME: 'Apodo de la cuenta',
  ACCOUNT_BELONGING: 'Cuenta perteneciente a',
  ACCOUNT_CURRENT_SAVING_SAME_NUMBER: 'Cuentas corriente y de ahorro con el mismo número',
  CARD_ACCEPTED: 'Tarjetas aceptadas en Secretio',
  PAYMENT_FORM: 'Forma de pago',
  CARD_NUMBER: 'Número de tarjeta',
  DATE_VALID_UNTIL: 'Válido hasta',
  CARD: 'Tarjeta',
  CARDS: 'Tarjetas',
  CARD_CVV: 'CVV',
  CARD_VALIDITY: 'Validez',
  CARD_CURRENCY: 'Tarjeta actual',
  CARD_DEFINE_CURRENCY: 'Definir tarjeta actual',
  CARD_DELETE: 'Eliminar tarjeta',
  CARD_CHOOSE_MAIN: 'Usar esta tarjeta como la actual',
  CARD_CHOOSE_DEBIT_TOO: 'Si falla el cargo en crédito, intentar cargo en débito (si está disponible)',
  CARD_CHOOSE_CREDIT_TOO: 'Si falla el cargo en débito, intentar cargo en crédito (si está disponible)',
};


export const RESERVES: Record<EReservesTranslations, string> = {
  RESERVE_TO: 'Reserva para',
  RESERVE_PRICE: 'Valor de la reserva',
  RESERVE_FREE: 'Reserva gratuita',
  RESERVED_TO: 'Reservado para',
  RESERVED_AT: 'Reservado a las',
  RESERVE_MADE_TO: 'Reserva hecha por',
  RESERVE_HOUR: 'Hora de la reserva',
  RESERVE_PEOPLE: 'Personas en la reserva',
};


export const SOCIALS: Record<ESocialsTranslations, string> = {
  WHATSAPP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'Twitter',
  YOUTUBE: 'Youtube',
  LINKEDIN: 'LinkedIn',
  CONTACT_US: 'Contáctenos',
  SEND_MESSAGE: 'Enviar un Mensaje',
};


export const TIME: Record<ETimeTranslations, string> = {
  DATE: 'Fecha',
  HOUR: 'Hora',
  DATE_HOUR: 'Fecha y Hora',
  DATES_AVAILABLE: 'Fechas Disponibles',
  OPENED_UNTIL: 'Abierto hasta',
  OPENEDS: 'Abierto',
  CLOSE_IN: 'Cierra en',
  CLOSED: 'Cerrado',
  CLOSEDS: 'Cerrado',
  TODAY: 'Hoy',
  SUNDAY: 'Domingo',
  MONDAY: 'Lunes',
  TUESDAY: 'Martes',
  WEDNESDAY: 'Miércoles',
  THURSDAY: 'Jueves',
  FRIDAY: 'Viernes',
  SATURDAY: 'Sábado',
  NEXT: 'Próximos',
  PAST: 'Pasados',
  UPDATED_AT: 'Actualizado',
};


export const TITLES: Record<ETitlesTranslations, string> = {
  HOME: 'Inicio',
  FAVORITES: 'Favoritos',
  EXIT: 'Salir',
  FORGOT_PASSWORD_QUESTION: '¿Olvidaste tu contraseña?',
  RECOVER_PASSWORD: 'Recuperar contraseña',
  REGISTER: 'Registro',
  ACCESS: 'Acceso',
  CHECKIN: 'Check-in',
  CHECKOUT: 'Check-Out',
  CHECKIN_WAIT: 'Esperando Check-in',
  QR_READER: '¿No quieres perder tiempo?',
  QR_MY_CODE: 'Mi código QR',
  GALLERY: 'Galería',
  COMPANY_DETAILS: 'Detalles del socio',
  ALL_EVENTS: 'Todos los eventos',
  LISTS: 'Listas',
  LISTS_EVENT: 'Listas de eventos',
  LISTS_ALL: 'Todas las listas',
  LISTS_AVAILABLES: 'Listas disponibles',
  LISTS_SPECIALS: 'Listas especiales',
  MENUSHEET: 'Menú',
  HIGHLIGHTS: 'Destacados',
  PARTNER: 'Socio',
  PARTNERS: 'Socios',
  PARTNERS_HIGHLIGHTS: 'Perfiles destacados hoy',
  EVENTS: 'Eventos',
  EVENTS_HIGHTLIGHTS: 'Eventos destacados hoy',
  EVENTS_MORE: 'Eventos para ti',
  EVENTS_STREET: 'Eventos callejeros',
  MAP: 'Mapa',
  MAPS: 'Mapas',
  NOTIFICATIONS: 'Notificaciones',
  NOTIFICATIONS_CONFIG: 'Configurar notificaciones',
  ORDERSHEET: 'Hoja de pedidos',
  ORDERSHEET_PAY: 'Pagar hoja de pedidos',
  PAYMENT_OPTIONS: 'Opciones de pago',
  ORDERBAG: 'Bolsa',
  HISTORIC: 'Historial',
  COMPANY_NEW: 'Nueva empresa',
  COMPANY_REGISTER: 'Registrar empresa',
  BANKS: 'Bancos',
  MODAL_ADD_USER: 'Agregar usuario a la empresa',
  ACCOUNT_TRANSFER: 'Transferir cuentas',
  ACCOUNT_TRANSFER_ADD: 'Agregar cuenta de transferencia',
  COMPANY_ADDRESS_EDIT: 'Editar dirección de la empresa',
  CONTACT: 'Contacto',
  COMPANY_EDIT: 'Editar empresa',
  COMPANY_DATAS: 'Datos de la empresa',
  PROFILE_PICTURE: 'Foto de perfil',
  LOGOTIPO: 'Logotipo',
  LOGOTIPO_COMPANY: 'Logotipo del perfil',
  ADDRESS: 'Dirección',
  ADDRESS_CREATE: 'Crear dirección',
  ADDRESS_EDIT: 'Editar dirección',
  ADDRESSES: 'Direcciones',
  USER_DISABLE: 'Desactivar perfil',
  COMPANY_DISABLE: 'Desactivar empresa',
  REDEFINE_PASSWORD: 'Redefinir contraseña',
  CHANGE_PASSWORD: 'Cambiar contraseña',
  PREFERENCES: 'Preferencias',
  SETTINGS: 'Configuración',
  LANGUAGE: 'Idioma',
  CARD_ADD: 'Agregar tarjeta',
  ADDRESS_REGISTER: 'Registrar dirección',
  PAYMENTS_SUBSCRIPTIONS: 'Pagos y suscripciones',
  PROFILE_MY_ABOUT: 'Acerca de mi perfil',
  PROFILE_DETAILS: 'Detalles del perfil',
  PROFILE_INFORMATIOS: 'Información del perfil',
  PROFILE_PUBLIC: 'Perfil público',
  PROFILE_MAIN: 'Cuenta principal',
  PERMISSIONS_GROUP: 'Grupos y permisos',
  INVITE_TO_LIST: 'Invitar a la lista',
  INVITE_PEOPLE: 'Invitar personas',
  PARTICIPATE: 'Participar',
  LIST_PARTICIPATE: 'Listas participantes',
  DETAILS_ITEM: 'Detalles del artículo',
  MY_ORDERS: 'Mis pedidos',
  RESERVES: 'Reservas',
  RESERVE_DETAILS: 'Detalles de la reserva',
  SEARCH: 'Buscar',
  OTHER_INFORMATIONS: 'Otra información',
  TERMS_POLICY: 'Términos y políticas',
  LICENCES: 'Licencias',
  KNOW_APP: 'Conoce Secretio',
  PROFILE_UNMANAGE: 'Perfil no administrado',
  PROFILE_MANAGE: 'Perfil administrado',
  SHARE: 'Compartir',
};


export const SUBTITLES: Record<ESubtitlesTranslations, string> = {
  SUBTITLES_LOGOTIPO_COMPANY: 'Un logotipo ayuda a personalizar tu empresa',
  SUBTITLES_PROFILE_PICTURE: 'Una foto ayuda a personalizar tu cuenta',
  SUBTITLES_PROFILE_ALL: 'Mira cómo aparecen tus diferentes perfiles en los servicios de Secretio',
  SUBTITLES_INFORMATIONS_COMPANY: 'Nombre, sobre y más',
  SUBTITLES_DATAS_COMPANY: 'Registro y nombre legal, por ejemplo',
  SUBTITLES_COMPANY_CONTACT: 'Haz clic para editar tus contactos',
  SUBTITLES_COMPANY_TRANSFER_YES: 'Haz clic para gestionar las cuentas de transferencia de esta empresa',
  SUBTITLES_COMPANY_TRANSFER_NO: 'Cuentas de transferencia aún no disponibles',
  SUBTITLES_COMPANY_ADDRESS: 'Haz clic para editar la dirección de la empresa',
  SUBTITLES_ACCOUNT_TRANSFER_ADD: 'Secretio aún necesita revisar tu registro, espera un poco más para intentarlo de nuevo',
  SUBTITLES_SETTINGS: 'Configuraciones de tu cuenta global',
  SUBTITLES_CARDS: 'Tarjetas de crédito', // 'Tarjetas de crédito y débito',
  SUBTITLES_PROFILE_PUBLIC: 'Estos son los datos que aparecen en este perfil público',
  SUBTITLES_PERMISSIONS_GROUP: 'Agrega personas y gestiona permisos',
  SUBTITLES_HIGHLIGHT_MAP: 'Permite el uso de la ubicación para ver lugares cercanos',
  SUBTITLES_HIGHLIGHT_MAP_EXPERIENCE: 'De esta manera podemos mejorar tu experiencia en Secretio',
  SUBTITLES_LIST_IN: 'Estás en la lista',
  SUBTITLES_LIST_INSIDE: 'Ya estás en la lista: {listName}',
  SUBTITLES_LIST_OUTSIDE: 'Haz clic en el botón participar para unirte a la lista: {listName}',
  SUBTITLES_SEE_ITEMS_MY_LOCATION: 'Ver eventos y socios a mi alrededor',
  SUBTITLES_KNOW_APP: 'Menú, reservas, comandas, enlace bio, entradas y mucho más.',
};


export const VALIDATIONS: Record<EValidationsTranslations, string> = {
  ONLY_NUMBERS: 'Solo números',
  VALIDATE_MIN_DIGITS: 'Debe contener al menos {count} dígitos',
  VALIDATE_MAX_DIGITS: 'Debe contener hasta {count} dígitos',
  VALIDATE_EXACT_DIGITS: 'Debe contener {count} dígitos',
  VALIDATE_MIN_CHAR: 'Debe contener al menos {count} caracteres',
  VALIDATE_MAX_CHAR: 'Debe contener hasta {count} caracteres',
  VALIDATE_EXACT_CHAR: 'Debe contener {count} caracteres',
  VALIDATE_ALL_FIELDS: 'Complete todos los campos correctamente antes de continuar',
  VALIDATE_ALL_SELECTED: 'Asegúrese de que todos los campos estén seleccionados',
  VALIDATE_HAS_USER: 'Ya hay un perfil con este nombre de usuario/correo electrónico',
  VALIDATE_ONLY_ONE: 'Marcar solo uno',
  VALIDATE_ONE_MORE: 'Marcar uno o más',
  VALIDATE_EMAIL_REQUIRED: 'El correo electrónico es obligatorio',
  VALIDATE_EMAIL_VALID: 'Ingrese un correo electrónico válido',
  VALIDATE_EMAIL_DOMAIN: 'Dominio de correo electrónico incompleto',
  VALIDATE_PASSWORD_REQUIRED: 'La contraseña es obligatoria',
  VALIDATE_ID_SHORT_REQUIRED: 'Se requiere un ID público',
  VALIDATE_CPF_VALID: 'Ingrese un CPF válido',
  VALIDATE_USER_CPF_USERNAME: 'Ingrese un CPF válido o un nombre de usuario solo con letras, números, "_", y "."',
  VALIDATE_USERNAME_CHAR_SPECIAL: 'El nombre de usuario solo puede contener letras, números, "_", y "."',
  VALIDATE_USER_REQUIRED: 'Se requiere un nombre de usuario',
};



const Translates = {
  ...APP,
  ...APP_TUTORIAL,
  ...BUTTONS,
  ...COMPANY_TYPES,
  ...CARNIVALS,
  ...COUNTRIES,
  ...DRINKS,
  ...EVENTS,
  ...INTERPOLATES,
  ...KITCHENS,
  ...LABELS,
  ...LANGUAGES,
  ...MENU_ORDER,
  ...MESSAGES_EMPTYS,
  ...MESSAGES_HELP,
  ...MESSAGES_ERRORS,
  ...MESSAGES_QUESTIONS,
  ...MESSAGES_SUCCESS,
  ...MUSICS,
  ...PAYMENTS,
  ...RESERVES,
  ...SOCIALS,
  ...TIME,
  ...TITLES,
  ...SUBTITLES,
  ...VALIDATIONS,
};



export default Translates;
