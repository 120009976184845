import styled,
{
  css,
} from 'styled-components';

import {
  Card,
  Input,
  Radio,
} from '../../../components/Composh/web';

import InputCheckbox from '../../../components/Inputs/InputCheckbox';
import InputText from '../../../components/Inputs/InputText';

import {
  Sizes,
} from '../../../shared/constants';



export const CardContactsForm = styled(Card.View)`
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;



export const DetailsTopViewItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
`;

export const DetailsBottomViewItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
`;



export const InputStyle = css`
  margin-left: 5px;
  margin-right: 5px;
`;

export const InputTextStyled = styled(InputText)`
  ${InputStyle};
`;


export const InputButtonCheckboxStyled = styled(InputCheckbox)`
  ${InputStyle};
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const InputViewStyled = styled(Input.View)`
  ${InputStyle};
  margin-top: 10px;
`;



export const ContactsAttendanceOptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

export const GenderUserRadio = styled(Radio)`
`;
