import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  Input,
} from '../../Composh/web';



export interface IProps {
  password?: any;
  disabled?: any;
  noShadow?: any;
  autoCapitalize?: any;
  autoCorrect?: any;
  type?: any;
  backgroundColor?: any;
  iconLeft?: any;
  iconRight?: any;
  labelText?: any;
  placeholderColor?: any;
  inputTextColor?: any;
  placeholderText?: any;
  errorText?: any;
  helpText?: any;
  helpColor?: any;
  countLimit?: any;
  borderColor?: any;
  value?: any;
  onChange?: any;
  onBlur?: any;
  options?: any;
}



const InputText: React.FC<IProps> = (props: IProps) => {
  return (

    <Input.Text
      {...props}
      disabled={props.disabled}
      password={props.password}
      noShadow={props.noShadow}
      options={props.options}
      autoCorrect={props.autoCorrect}
      autoCapitalize={props.autoCapitalize}
      type={props.type}
      backgroundColor={props.backgroundColor || Colors.inputBackground}
      iconLeft={props.iconLeft}
      iconRight={props.iconRight}
      labelText={props.labelText}
      labelColor={Colors.inputLabel}
      placeholderText={props.placeholderText}
      placeholderTextColor={props.placeholderColor || Colors.inputPlaceholder}
      inputTextColor={props.inputTextColor || Colors.black}
      helpText={props.errorText ? props.errorText : props.helpText}
      helpColor={props.errorText ? Colors.danger : props.helpColor || Colors.danger}
      countLimit={props.countLimit}
      countColor={Colors.inputCount}
      borderColor={props.errorText
        ? Colors.danger
        : props.borderColor ?
          props.borderColor
          : null
      }
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
    />

  );
};



export default InputText;
