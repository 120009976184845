import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  IListSection,
} from '../../../../../apis/interfaces/App/list-section.interface';

import {
  IPhoneDDI,
} from '../../../../../apis/interfaces/Phones/phone-ddi.interface';

import CardDdi from '../../../../../components/Cards/CardDdi';

import {
  Epigraph,
  Overlay,
} from '../../../../../components/Composh/web';

import EmptyContent from '../../../../../components/Empty';
import HeaderModal from '../../../../../components/Headers/HeaderModal';

import {
  IconClose,
} from '../../../../../components/Icons';

import InputText from '../../../../../components/Inputs/InputText';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import { ddiArray } from '../../../../../shared/arrays/App/ddi.array';

import {
  Colors,
  Images,
  Values,
} from '../../../../../shared/constants';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import {
  DdiModalView,
  FilteredInput,
  DdiClearButton,
  FilteredDdiContent,
  FilteredListSection,
  StyledListSection,
} from './styled';



export interface IProps {
  visible?: boolean;
  setData?: any;
  onClose?: any;
}



const DdiModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;

  const { t: translate } = useTranslation();


  const [filterDdi, setFilterDdi] = useState('');

  const [ddiData, setDdiData] = useState<Array<IListSection<IPhoneDDI>>>([]);

  const [allDdi, setAllDdi] = useState<Array<IPhoneDDI>>([]);
  const [filterArrayDdi, setFilterArrayDdi] = useState<Array<IPhoneDDI>>([]);

  const [loading, setLoading] = useState(false);



  function getDdiList() {
    try {
      setLoading(true);

      const response = ddiArray;

      if (response) {
        const frequentCountries: Array<IPhoneDDI> = response.filter((item: IPhoneDDI) => item?.frequent);
        const nonFrequentCountries: Array<IPhoneDDI> = response.filter((item: IPhoneDDI) => !item?.frequent);

        const countrySectionObject = [
          {
            title: 'Frequentes',
            data: frequentCountries,
          },
          {
            title: 'Outros DDI',
            data: nonFrequentCountries,
          },
        ];

        setAllDdi(response);
        setDdiData(countrySectionObject);
      }
    }
    catch (error: any) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  }


  function renderHeader(item: any, index: number) {
    return (

      <Epigraph.Section
        key={index}
        text={item?.title}
        textColor={Colors.secondary}
        borderBottomColor={Colors.secondary}
      />

    );
  }


  function renderItem(item: IPhoneDDI, index: number) {
    return (

      <CardDdi
        key={index}
        value={item?.value}
        image={item?.image}
        label={item?.label}
        onPress={() => {
          props.setData(item);
          props.onClose();
        }}
      />

    );
  }


  function returnListFiltered() {
    const getAllTofilterBankArray = allDdi.filter((bankFilter: IPhoneDDI) =>
      bankFilter.label.toLowerCase().includes(filterDdi.toLowerCase()) ||
      bankFilter.value.toString().includes(filterDdi),
    );
    setFilterArrayDdi(getAllTofilterBankArray);
  }


  function renderLoading() {
    if (!loading) {
      return null;
    }

    if (allDdi?.length !== 0) {
      return null;
    }

    return (

      <LoadingScreen
        type={'FOOTER'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    if (loading) {
      return null;
    }

    return (

      <EmptyContent
        image={Images.noImage}
        title={'Sem DDI ainda!'}
      />

    );
  }



  useEffect(() => {
    getDdiList();
  }, []);



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={() => {
        if (props.onClose) {
          props.onClose();
        }
      }}>

      <DdiModalView>

        <HeaderModal
          title={'DDI'}
          onPress={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        />



        <FilteredInput>
          <InputText
            disabled={loading}
            type={'TEXT'}
            iconRight={filterDdi === ''
              ? null
              : (
                <DdiClearButton
                  onClick={() => {
                    setFilterDdi('');
                  }}>
                  <IconClose
                    color={Colors.black}
                    size={28}
                  />
                </DdiClearButton>
              )
            }
            placeholderText={translate(TranslateConfig.NUMBER_OR_NAME)}
            value={filterDdi}
            // helpText={errorMessage}
            countLimit={Values.filterCount}
            onChange={(rawText: string) => {
              setFilterDdi(rawText);
              returnListFiltered();
            }}
          // onBlur={handleBlur('cpf')}
          />
        </FilteredInput>


        <FilteredDdiContent>

          {renderLoading()}



          {!loading && (!allDdi || allDdi?.length === 0 || (filterDdi !== '' && filterArrayDdi.length === 0)) && (
            listEmptyComponent()
          )}



          {allDdi?.length !== 0 && filterArrayDdi.length !== 0 && filterDdi !== '' && (
            <FilteredListSection>
              {filterArrayDdi.map((itemFiltered: IPhoneDDI, index: number) => renderItem(itemFiltered, index))}
            </FilteredListSection>
          )}



          {allDdi?.length !== 0 && filterDdi === '' && (
            <StyledListSection
              sections={ddiData}
              style={{
                justifyContent: loading ? 'center' : 'flex-start',
              }}
              keyExtractor={(keyNumber: number) => String(keyNumber)}
              renderItem={(item: any, index: number) => renderItem(item, index)}
              renderSectionHeader={(item: any, index: number) => renderHeader(item, index)}
            />
          )}

        </FilteredDdiContent>

      </DdiModalView>

    </Overlay>

  );
};



export default DdiModal;
