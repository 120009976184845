import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const ContentInputFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
`;

export const ContentHeaderViewFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  margin-bottom: 10px;
`;

export const ContentHeaderTitle = styled.p`
  padding-bottom: 4px;
  color: ${Colors.black};
  font-size: 20px;
  font-weight: bold;
`;

export const ContentHelpButton = styled.div`
  display: flex;
  flex-direction: column;

  @media ${PlatformDevices.minLaptopL} {
    display: none;
  }
`;
