const User = {
  id: '',
  name: '',
  publicName: '',
  email: '',
  username: '',
  cpf: '',
  birthday: null,
  gender: null,
  phone: null,
  userInfo: {
    level: '',
    verified: false,
  },
  address: null,
  aboutMe: '',

  companys: [],
  info: null,
  login: '',
  oldPassword: null,
  password: '',
  picture: null,
  report: null,
  verified: false,
};



export default User;
