import React,
{
  useState,
} from 'react';

import {
  Colors,
  Values,
} from '../../shared/constants';

import TableFooter from './Sections/TableFooter';
import TableHeader from './Sections/TableHeader';
import TableTextEmpty from './Sections/TableTextEmpty';
import TableTotal from './Sections/TableTotal';

import {
  TableNextStyled,
  ScrollTableHorizontalStyled,
  ListTableStyled,
} from './styled';



export interface IProps {
  headerTopBorder?: boolean;
  headerBottomBorder?: boolean;

  data: any;
  columns: any;

  noQuantity?: boolean;

  color?: string;
  accentColor?: string;

  sizePerPageList?: Array<number>;
  // baseProps?: any;
  paginationProviders?: (providers: {
    size: number;
    page: number;
  }) => void;
  paginationProps?: any;
  // paginationTableProps?: any;

  headerTable?: any;
}



const TableRow: React.FC<IProps> = (props: IProps) => {
  const colorNormalTable = props.color || Colors.white;
  const colorAccentTable = props.accentColor || Colors.black;
  const totalColor = Colors.black;


  const sizePerPage = props.sizePerPageList || [10, 20, 30];

  const [selected, setSelected] = useState(sizePerPage[0]);

  const dataTable = props.data && Array.isArray(props.data) && props.data?.length > 0 ? props.data : [];



  function customTotal(from: any, to: any, size: any) {
    return (

      <TableTotal
        color={totalColor}
        from={from}
        to={to}
        size={size}
      />

    );
  }


  const handleSizePerPage = (newSizePerPage: number, pageNumber: number) => {
    const sendProviders = {
      size: newSizePerPage,
      page: pageNumber,
    };

    if (props.paginationProviders) {
      props.paginationProviders(sendProviders);
    }
  };


  const options = {
    ...props.paginationProps,
    paginationTotalRenderer: customTotal,
  };



  return (

    <TableNextStyled>

      {props.headerTable && props.headerTable}



      {!props.noQuantity && (
        <TableHeader
          {...props.paginationProps}
          color={colorNormalTable}
          accentColor={colorAccentTable}
          bottomBorder={props.headerBottomBorder}
          options={options}
          maxPagesToShow={Values.pagesPagination}
          sizePerPage={sizePerPage}
          sizeSelected={selected}
          onPress={(quantity: number) => {
            if (quantity !== selected) {
              setSelected(quantity);
              handleSizePerPage(quantity, 1);
            }
          }}
          pageSelected={(sizeString) => {
            const sizeNumeric = Number(sizeString);
            handleSizePerPage(selected, sizeNumeric);
          }}
        />
      )}



      <ScrollTableHorizontalStyled>
        <ListTableStyled
          // hover
          // bordered={false}
          color={Colors.secondaryAccent}
          data={dataTable}
          colunasVisiveis={props.columns}
          textEmpty={
            <TableTextEmpty
              color={totalColor}
            />
          }
        // {...props.baseProps}
        // {...props.paginationTableProps}
        />
      </ScrollTableHorizontalStyled>



      <TableFooter
        {...props.paginationProps}
        topBorder={props.headerTopBorder}
        color={colorNormalTable}
        accentColor={colorAccentTable}
        maxPagesToShow={Values.pagesPagination}
        pageSelected={(sizeString) => {
          const sizeNumeric = Number(sizeString);
          handleSizePerPage(selected, sizeNumeric);
        }}
      />

    </TableNextStyled>

  );
};



export default TableRow;
