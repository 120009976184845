import styled from 'styled-components';

import {
  ListFlat,
} from '../../../../../components/Composh/web';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';



export const StarSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 310px;
  height: 450px;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const FilteredInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;
`;

export const BankClearButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;



export const AllModelesFlatList = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    paddingTop: 6,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 6,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
