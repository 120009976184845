import {
  Keys,
} from '../shared/constants';



export function openSite(siteToOpen: string) {
  window.open(siteToOpen, '_blank')?.focus();
};



export function openWebApp(urlForBrowser: string) {
  window.open(urlForBrowser, '_blank')?.focus();
}



export function openPhone(phone: string) {
  window.open(`tel:${phone}`);

  // if (Platform.OS === 'android') {
  //   Communications.phonecall(phone, true);
  // }
  // else if (Platform.OS === 'ios') {
  //   Linking.openURL(`tel:${phone}`);
  // }
};



export function openProfileWhatsApp(fullNumber: string, message: string) {
  const encodedMessage = message || 'Olá, vi esse anúncio no Secretio';
  const linkWhatsAppCompany = `https://api.whatsapp.com/send?phone=${fullNumber}&text=${encodeURIComponent(encodedMessage)}`;

  openSite(linkWhatsAppCompany);
};


export function openBeModeleWhatsApp() {
  const encodedMessage = Keys.whatsAppBeModeleMessage;
  const linkWhatsAppCompany = `https://api.whatsapp.com/send?phone=${Keys.whatsAppNumber}&text=${encodeURIComponent(encodedMessage)}`;

  openSite(linkWhatsAppCompany);
};


export function openEmail(subject: string, message: string) {
  const emailSubject = subject;
  const emailBody = encodeURIComponent(message);
  const mailtoLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
  window.location.href = mailtoLink;
};
