import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  clickable?: boolean;
}



export const PersonalStatisticsContainer = styled.a`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  cursor: ${(props: IProps) => !props.clickable ? 'default' : 'pointer'};

  @media ${PlatformDevices.maxMobileM} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minMobileM} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }
  
  @media ${PlatformDevices.minTablet} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
  }
`;


export const PersonalStatisticsView = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PersonalStatisticsIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: ${Colors.cardBackground};
  border-radius: ${2}px;
`;

export const PersonalStatisticsInfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PersonalItemTitle = styled.h3`
  margin-bottom: 3px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
`;

export const PersonalItemText = styled.p`
  color: ${Colors.black};
  font-size: 16px;
  line-height: 18px;
`;

export const PersonalItemDescriptionText = styled.p`
  margin-top: 10px;
  color: ${Colors.gray};
  font-size: 13px;
  font-style: italic;
`;
