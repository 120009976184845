import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const UserReviewMakeInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 7px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 7px;
`;

export const UserHowPublishButton = styled.a`
  display: flex;
  flex-direction: column;
`;

export const UserReviewMakeInfoView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const UserReviewMakeAliasText = styled.p`
  color: ${Colors.black};
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;

export const MenuVerifiedContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MenuUserNameText = styled.p`
  color: ${Colors.black};
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const IconVerifiedView = styled.a`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;
