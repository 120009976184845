const environment = {
  production: true,
  environment: 'PROD',
  title: 'Production Environment Heading',
  apiAccountGlobalUrl: 'https://api.noitada.app',
  apiUrl: 'https://api.secretio.app',
};



export default environment;
