import styled from 'styled-components';

import {
  Card,
} from '../../../../../components/Composh/web';

import {
  PlatformDevices,
} from '../../../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
  backgroundColor?: string;
}



export const ProjectParamsContentCenterFlex = styled(Card.View)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  width: 100%;
  margin-top: 5px;

  @media ${PlatformDevices.maxLaptop} {
    margin-bottom: 35px;
  }
`;
