import ApiWS from '../index';



export const GetAllImagesModeles = async (payload: string) => {
  const url = '/support/project/medias-modele';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const ArchiveModeleImage = async (idMedia: string) => {
  const url = `/support/project/medias-modele/archive/${idMedia}`;

  const response = await ApiWS.put(url);
  return response.data;
};



export const ChangeRevisedModeleImage = async (idMedia: string) => {
  const url = `/support/project/medias-modele/revised/${idMedia}`;

  const response = await ApiWS.put(url);
  return response.data;
};



export const DeleteModeleImage = async (idMedia: string) => {
  const url = `/support/project/medias-modele/${idMedia}`;

  const response = await ApiWS.delete(url);
  return response.data;
};
