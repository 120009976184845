import styled from 'styled-components';

import {
  Sizes,
} from '../../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../../shared/utils/platforms.utils';



export const ImageContentRightHome = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${Sizes.contentMargin}px;
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: 5px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer}px;

  @media ${PlatformDevices.maxLaptopL} {
    margin-left: 0;
  }

  @media ${PlatformDevices.minLaptop} {
    width: ${Sizes.contentRightHomeWidth}px;
  }
`;


export const WarningApproveText = styled.p`
  margin-bottom: 20px;
  color: #fff9;
  font-size: 18px;
`;
