import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  Card,
} from '../../Composh/web';

const marginItems = 8;



export interface IProps {
  disabled?: boolean;
  color?: string;
}



export const VideoBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 6px;
  padding-bottom: 6px;

  @media ${PlatformDevices.maxMobileM} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minMobileM} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media ${PlatformDevices.minMobileL} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
    padding-left: 5px;
    padding-right: 5px;
  }
  
  @media ${PlatformDevices.minTabletM} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media ${PlatformDevices.minLaptopL} {
    width: calc(100% / 6);
    min-width: calc(100% / 6);
    max-width: calc(100% / 6);
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const ImagePublishAllContainer = styled(Card.View)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 400px;
  border-color: ${Colors.cardBackground};
  border-style: solid;
  border-width: 2px;
  transition: all 0.2s ease;

  :hover{
    border-color: ${Colors.secondary};
  }
`;


export const VideoBoxContent = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${Colors.black};
`;

export const LazyVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;


export const RibbonPhotoTypeMedia = styled.span`
  position: absolute;
  top: ${marginItems}px;
  left: ${marginItems}px;
  padding: 4px 6px;
  background-color: ${Colors.black};
  color: ${Colors.white};
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
`;


export const RibbonPending = styled.p`
  position: absolute;
  bottom: ${40}px;
  left: ${marginItems}px;
  max-width: 85px;
  padding: 4px 6px;
  color: ${Colors.white};
  background-color: ${Colors.danger};
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
  border-radius: 6px;
`;

export const RibbonTopInfo = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: ${marginItems}px;
  left: ${marginItems}px;
  padding: 4px 6px;
  background-color: ${Colors.black};
  border-radius: 6px;
  gap: 6px;
`;

export const RibbonVideoDuration = styled.p`
  color: ${Colors.white};
  font-size: 13px;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0,0,0,.7);
`;

export const RibbonHdVideoInserted = styled.span`
  color: ${Colors.warning};
  font-size: 13px;
  font-weight: 500;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0,0,0,.7);
`;


export const VideoIcon = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  padding: 12px;
  background-color: rgba(47, 41, 41, 0.5);
  transform: translate(-50%,-50%);
  border-radius: 50%;
`;



export const CanBuyRibbon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: ${marginItems}px;
  right: ${marginItems}px;
  width: 30px;
  height: 30px;
  background-color: ${Colors.alertOverlay};
  border-radius: 50px;
`;



export const ImageActionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: ${marginItems}px;
  right: ${marginItems}px;
`;

export const ImageActionButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: 6px;
  background-color: ${Colors.alertOverlay};
  border-radius: 50px;
`;



export const ReviewActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  min-height: 40px;
  background-color: ${Colors.formBackground};
`;

export const ReviewActionsButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
  cursor: ${(props: IProps) => props.disabled ? 'not-allowed' : 'pointer'};
`;

export const ReviewActionsIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;

export const ReviewActionsText = styled.p`
  color: ${(props: IProps) => props.color || Colors.secondaryDark};
  font-size: 13.5px;
  line-height: 17px;
`;

