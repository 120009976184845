import ApiWS from '../index';



export const GetHelpFaqCategories = async (payload: any) => {
  const url = '/support/project/help/faq-categories';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const CreateHelpFaqCategories = async (payload: any) => {
  const url = '/support/project/help/faq-categories';

  const response = await ApiWS.post(url, payload);
  return response.data;
};



export const EditHelpFaqCategories = async (id: string, payload: any) => {
  const url = `/support/project/help/faq-categories/${id}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const DeleteHelpFaqCategories = async (id: any) => {
  const url = `/support/project/help/faq-categories/${id}`;

  const response = await ApiWS.put(url);
  return response.data;
};
