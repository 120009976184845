import {
  EGenderUser,
} from '../../enums';



export const typeGenders = [
  {
    id: 1,
    value: EGenderUser.WOMAN,
    label: 'Mulher',
  },
  {
    id: 2,
    value: EGenderUser.MAN,
    label: 'Homem',
  },
  {
    id: 3,
    value: EGenderUser.TRANS_WOMAN,
    label: 'Trans mulher',
  },
  {
    id: 4,
    value: EGenderUser.TRANS_MAN,
    label: 'Trans homem',
  },
];
