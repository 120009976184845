import {
  css,
} from 'styled-components';



export const TableTopStyled = css`
  border-top: 1px solid #999;
  box-shadow: 0 1px 0 #fff;
`;

export const TableBottomStyled = css`
  border-bottom: 1px solid #999;
  box-shadow: 0 1px 0 #fff;
`;
