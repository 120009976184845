import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  FindUserHighlights,
  UpdateUserGenderHighlights,
} from '../../../../apis/endpoints/app-genders-highlights.endpoints';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Content,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import ImageUpload from '../../../../components/Picture/ImageUpload';
import RequiredText from '../../../../components/Titles/TextRequired';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import { EUploadType } from '../../../../shared/enums';
import EPictureHighlightRelation from '../../../../shared/enums/Picture/picture-highlight-relation.enum';
import EResponseStatus from '../../../../shared/enums/Response/response-status.enum';

import {
  TranslateConfig,
} from '../../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  CardContactsForm,
  InputTextStyled,
} from './styled';



const GenderHighlightsFormScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const formik = useRef<FormikProps<any>>(null);


  const [modeleFound, setModeleFound] = useState<any>(null);

  const [pictureVertical, setPictureVertical] = useState(null);
  const [linkPictureVertical, setLinkPictureVertical] = useState(null);
  const [boolPictureVertical, setBoolPictureVertical] = useState(false);

  const [pictureHorizontal, setPictureHorizontal] = useState(null);
  const [linkPictureHorizontal, setLinkPictureHorizontal] = useState(null);
  const [boolPictureHorizontal, setBoolPictureHorizontal] = useState(false);

  const [pictureUserUpgrade, setPictureUserUpgrade] = useState(null);
  const [linkPictureUserUpgrade, setLinkPictureUserUpgrade] = useState(null);
  const [boolPictureUserUpgrade, setBoolPictureUserUpgrade] = useState(false);

  const [pictureModeleUpgrade, setPictureModeleUpgrade] = useState(null);
  const [linkPictureModeleUpgrade, setLinkPictureModeleUpgrade] = useState(null);
  const [boolPictureModeleUpgrade, setBoolPictureModeleUpgrade] = useState(false);

  const [picturePremium, setPicturePremium] = useState(null);
  const [linkPicturePremium, setLinkPicturePremium] = useState(null);
  const [boolPicturePremium, setBoolPicturePremium] = useState(false);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function mountPayload() {
    const values = formik?.current?.values;

    const payload: any = {
      useralias: values.useralias,
    };

    return payload;
  }


  function treatHighlightsImages(highlightsReceived: Array<any>) {
    const highlights = Array.isArray(highlightsReceived) && highlightsReceived?.length > 0 ? highlightsReceived : [];

    highlights.forEach((highlight) => {
      const { highlightType, link } = highlight;
      console.log('highlightType', highlightType);
      console.log('link', link);

      if (highlightType === EPictureHighlightRelation.HIGHLIGHT_VERTICAL) {
        setLinkPictureVertical(link);
        return;
      }

      if (highlightType === EPictureHighlightRelation.HIGHLIGHT_HORIZONTAL) {
        setLinkPictureHorizontal(link);
        return;
      }

      if (highlightType === EPictureHighlightRelation.HIGHLIGHT_USER_UPGRADE) {
        setLinkPictureUserUpgrade(link);
        return;
      }

      if (highlightType === EPictureHighlightRelation.HIGHLIGHT_MODELE_UPGRADE) {
        setLinkPictureModeleUpgrade(link);
        return;
      }

      if (highlightType === EPictureHighlightRelation.HIGHLIGHT_PREMIUN) {
        setLinkPicturePremium(link);
      }
    });
  }


  async function findUserToHighlights() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = mountPayload();

    try {
      const response = await FindUserHighlights(payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao salvar item');
        return;
      }

      setModeleFound(response?.data);
      treatHighlightsImages(response?.data?.highlights);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function saveGenderImage(type: EPictureHighlightRelation, picture: any) {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = mountPayload();

    try {
      const response = await UpdateUserGenderHighlights(payload?.useralias, modeleFound?.gender, type, picture);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_UPDATE));
        return;
      }

      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_UPDATE));
      // picCloseModal(true);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function handlePictureChange(event: any, setPicture: any, setItemPicture: any) {
    const eventImageFile = event.target.files[0];

    if (event) {
      setPicture(eventImageFile);
      setItemPicture(true);
    }
    else {
      setPicture(null);
      setItemPicture(false);
    }
  }


  function renderSectionGender(
    title: string,
    idButton: EUploadType,
    relation: EPictureHighlightRelation,
    fileImage: any,
    setFileImage: any,
    linkImage: any,
    itemBoolean: any,
    setItemBoolean: any,
  ) {
    return (

      <div>

        <p>
          {title || '-'}
        </p>



        <ImageUpload
          disabled={loading}
          accept={'image/jpeg, image/png'}
          idButton={idButton}
          fileImage={fileImage}
          image={linkImage}
          onPress={(event: any) => {
            handlePictureChange(event, setFileImage, setItemBoolean);
          }}
        />



        <ButtonAction
          disabled={!itemBoolean}
          width={Sizes.buttonMinWidth}
          title={'Salvar'}
          onPress={() => {
            if (!itemBoolean) {
              return;
            }

            saveGenderImage(relation, fileImage);
          }}
        />

      </div>

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
      }}>
      {({
        // dirty,
        // isValid,
        // handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        // setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <HeaderScreen
            leftIcon={
              <ButtonBack
                color={Colors.black}
                size={27}
              />
            }
            centerContent={
              <Title
                color={Colors.black}>
                {'Criar/editar gênero [Destaque]'}
              </Title>
            }
          />



          <Content>

            {!mounted && (
              renderLoading()
            )}



            {mounted && (
              <ContentPadder>

                <CardContactsForm
                  backgroundColor={Colors.formBackground}
                  borderRadius={Sizes.cardRadius}>

                  <RequiredText>
                    * {'Campos obrigatórios'}
                  </RequiredText>



                  <InputTextStyled
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={`${'Nome de usuário'} *`}
                    placeholderText={`${'Nome de usuário'} *`}
                    errorText={errors.useralias}
                    countLimit={Values.nameMaxCount}
                    value={values.useralias}
                    onChange={handleChange('useralias')}
                    onBlur={handleBlur('useralias')}
                  />


                  <button
                    onClick={() => {
                      findUserToHighlights();
                    }}>
                    Buscar
                  </button>



                  {modeleFound && (
                    <div>

                      <div>
                        <p>
                          Detalhes do modelo
                        </p>


                        <p>
                          Nome: {modeleFound?.alias || '-'}
                        </p>


                        <p>
                          Nome de usuário: {modeleFound?.useralias || '-'}
                        </p>


                        <p>
                          Gênero: {modeleFound?.gender || '-'}
                        </p>
                      </div>



                      <div>

                        {renderSectionGender(
                          'Adicionar imagem vertical',
                          EUploadType.HIGHLIGHT_VERTICAL,
                          EPictureHighlightRelation.HIGHLIGHT_VERTICAL,
                          pictureVertical,
                          setPictureVertical,
                          linkPictureVertical,
                          boolPictureVertical,
                          setBoolPictureVertical,
                        )}



                        {renderSectionGender(
                          'Adicionar imagem horizontal',
                          EUploadType.HIGHLIGHT_HORIZONTAL,
                          EPictureHighlightRelation.HIGHLIGHT_HORIZONTAL,
                          pictureHorizontal,
                          setPictureHorizontal,
                          linkPictureHorizontal,
                          boolPictureHorizontal,
                          setBoolPictureHorizontal,
                        )}



                        {renderSectionGender(
                          'Adicionar imagem User Upgrade',
                          EUploadType.HIGHLIGHT_USER_UPGRADE,
                          EPictureHighlightRelation.HIGHLIGHT_USER_UPGRADE,
                          pictureUserUpgrade,
                          setPictureUserUpgrade,
                          linkPictureUserUpgrade,
                          boolPictureUserUpgrade,
                          setBoolPictureUserUpgrade,
                        )}



                        {renderSectionGender(
                          'Adicionar imagem Modelo upgrade',
                          EUploadType.HIGHLIGHT_MODELE_UPGRADE,
                          EPictureHighlightRelation.HIGHLIGHT_MODELE_UPGRADE,
                          pictureModeleUpgrade,
                          setPictureModeleUpgrade,
                          linkPictureModeleUpgrade,
                          boolPictureModeleUpgrade,
                          setBoolPictureModeleUpgrade,
                        )}



                        {renderSectionGender(
                          'Adicionar imagem Premium',
                          EUploadType.HIGHLIGHT_PREMIUN,
                          EPictureHighlightRelation.HIGHLIGHT_PREMIUN,
                          picturePremium,
                          setPicturePremium,
                          linkPicturePremium,
                          boolPicturePremium,
                          setBoolPicturePremium,
                        )}

                      </div>

                    </div>
                  )}


                </CardContactsForm>

              </ContentPadder>
            )}



            <FooterApp />

          </Content>

        </Screen>

      )}
    </Formik>

  );
};



export default GenderHighlightsFormScreen;
