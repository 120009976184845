import styled from 'styled-components';

import {
  Card,
} from '../../../../components/Composh/web';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export const CardLotMediasForm = styled(Card.View)`
  flex: 1;
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;



export const CardLotMediasHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;

  @media ${PlatformDevices.maxMobileM} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;


export const CardLotMediasAddHeader = styled.label`
  @media ${PlatformDevices.maxMobileM} {
    margin-top: 5px;
  }
`;



export const CardLotMediasDragView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 30px;
  padding: 5px;
  border-color: ${Colors.secondaryDark};
  border-style: dashed;
  border-width: 2px;
  border-radius: ${Sizes.cardRadius}px;
`;



export const CardLotMediasInput = styled.input`
  display: none;
  width: 100%;
  height: 100%;
`;


export const CardLotMediasAddBody = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;


export const CardLotMediasContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
