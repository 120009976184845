import React,
{
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import {
  RootState,
} from '../../../redux';

import { picureMediaTypeRelationArray } from '../../../shared/arrays/Picture/type-relation.array';
import { picureVisibilityGenders } from '../../../shared/arrays/Picture/visibility.array';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  validateString,
} from '../../../shared/utils/string.utils';

import {
  IconDelete,
  IconEdit,
  IconMoney,
  IconPlay,
} from '../../Icons';

import {
  ImageBoxContainer,
  ImagePublishAllContainer,
  ImageBoxContent,
  LazyImage,
  LazyVideo,
  RibbonPhotoTypeMedia,
  RibbonPhotoLevel,
  RibbonTopInfo,
  RibbonVideoDuration,
  RibbonHdVideoInserted,
  VideoIcon,
  CanBuyRibbon,
  ImageActionContent,
  ImageActionButton,
} from './styled';



export interface IProps {
  type: 'image' | 'video';

  media?: string;
  typeRelation?: string;
  visibility?: string;
  canBuy?: boolean;

  duration?: string;
  isHD?: boolean;

  onImagePress?: any;
  onVisibleOrRevisedPress?: any;
  onEditPress?: any;
  onDeletePress?: any;
}



const CardPublishMediaLot: React.FC<IProps> = (props: IProps) => {
  const userImage = validateString(props.media) || Images.noImage;

  const [isPlaying, setIsPlaying] = useState(false);

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };



  return (

    <ImageBoxContainer>
      <ImagePublishAllContainer>

        <ImageBoxContent>

          {String(props.type).includes('image') && (
            <LazyImage
              src={userImage}
            />
          )}


          {String(props.type).includes('video') && (
            <LazyVideo
              controls={isPlaying}
              autoPlay={isPlaying}
              playsInline
              muted>
              <source src={props.media} />
              Seu navegador não suporta a reprodução de vídeos HTML5.
            </LazyVideo>
          )}



          <RibbonPhotoTypeMedia>
            {picureMediaTypeRelationArray?.find((item: IInputSelect) => item.value === props.typeRelation)?.label || '-'}
          </RibbonPhotoTypeMedia>



          <RibbonPhotoLevel>
            {picureVisibilityGenders?.find((item: IInputSelect) => item.value === props.visibility)?.label || '-'}
          </RibbonPhotoLevel>



          {props.type === 'video' && (
            <RibbonTopInfo>
              {props.isHD && (
                <RibbonHdVideoInserted>
                  HD
                </RibbonHdVideoInserted>
              )}

              <RibbonVideoDuration>
                {props.duration || '00:00'}
              </RibbonVideoDuration>
            </RibbonTopInfo>
          )}



          {props.type === 'video' && !isPlaying && (
            <VideoIcon
              onClick={() => {
                togglePlay();
              }}>
              <IconPlay
                color={Colors.white}
                size={32}
              />
            </VideoIcon>
          )}



          {props.canBuy && (
            <CanBuyRibbon>
              <IconMoney
                color={Colors.white}
                size={18}
              />
            </CanBuyRibbon>
          )}



          <ImageActionContent>

            {projectParams?.showFanClub && (
              <ImageActionButton
                onClick={() => {
                  if (props.onEditPress) {
                    props.onEditPress();
                  }
                }}>
                <IconEdit
                  color={Colors.attention}
                  size={18}
                />
              </ImageActionButton>
            )}



            <ImageActionButton
              onClick={() => {
                if (props.onDeletePress) {
                  props.onDeletePress();
                }
              }}>
              <IconDelete
                color={Colors.danger}
                size={18}
              />
            </ImageActionButton>

          </ImageActionContent>

        </ImageBoxContent>

      </ImagePublishAllContainer>
    </ImageBoxContainer>

  );
};



export default CardPublishMediaLot;
