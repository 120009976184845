import React from 'react';

import {
  Images,
} from '../../../../../shared/constants';

import {
  RankingTitle,
  FflirtyAwardsSingle,
  FaContestTop,
  FaContestTopImage,
  FaContestInfo,
  FaContestInfoTitle,
  FaContestInfoDescription,
  FaContestFinalResultText,
  FaContestTopThreeView,
  FaRank1,
  FaRank1ModelImageView,
  FaRank1ModelImage,
  FaRank1FaPrizeAmount,
  FaRank1FaPrizeAmountText,
  FaRank1Shield,
  FaRank1ShieldImage,
  FaRank1ModelInformation,
  FaRank1ModelInfoView,
  FaRank1ModelOnline,
  FaRank1ModelName,
  FaRank1ModelVote,
  FaRank1ModelVoteButton,
  FaRank1ModelVoteText,
  FaRank2,
  FaRank2ModelImageView,
  FaRank2FaPrizeAmountText,
  FaRank2ShieldImage,
  FaRank3,
  FaRank3ModelImageView,
  FaRank1FaPrizeAmountView,
} from './styled';



const TabMonthlyCrush: React.FC = () => {
  return (

    <div>

      <RankingTitle>
        The Flirty Awards 2021
      </RankingTitle>



      <FflirtyAwardsSingle>

        <FaContestTop>
          <FaContestTopImage
            src='https://cdn4.vscdns.com/images/flirty-awards/backgrounds/flirt-eyes.png'
          />

          <FaContestInfo>
            <FaContestInfoTitle>
              Best Flirt Eyes
            </FaContestInfoTitle>

            <FaContestInfoDescription>
              Soulful browns, piercing blues, hazel with a glint of mischief – these are the guys who can level you with a glance.
            </FaContestInfoDescription>
          </FaContestInfo>


          <FaContestFinalResultText>
            Final Results
          </FaContestFinalResultText>

          <FaContestTopThreeView>

            <FaRank1>
              <FaRank1ModelImageView href="/models/bios/robert-smiley/about.php" target="_blank">

                <FaRank1ModelImage
                  src={'https://cdn4.vscdns.com/images/models/samples-640x480/3874462.jpg'}
                />

                <FaRank1FaPrizeAmount>
                  <FaRank1FaPrizeAmountView>
                    <FaRank1FaPrizeAmountText>
                      $500
                    </FaRank1FaPrizeAmountText>
                  </FaRank1FaPrizeAmountView>
                </FaRank1FaPrizeAmount>
                <FaRank1Shield>
                  <FaRank1ShieldImage
                    src={Images.shield1}
                  />
                </FaRank1Shield>
              </FaRank1ModelImageView>


              <FaRank1ModelInformation>
                <FaRank1ModelInfoView>
                  <FaRank1ModelOnline />

                  <FaRank1ModelName>
                    Robert smiley
                  </FaRank1ModelName>
                </FaRank1ModelInfoView>

                <FaRank1ModelVote>
                  14,270 votes
                </FaRank1ModelVote>

                <FaRank1ModelVoteButton>
                  <FaRank1ModelVoteText>
                    Vote
                  </FaRank1ModelVoteText>
                </FaRank1ModelVoteButton>
              </FaRank1ModelInformation>
            </FaRank1>


            <FaRank2>
              <FaRank2ModelImageView href="/models/bios/joao-batista/about.php" target="_blank">
                <FaRank1ModelImage
                  src={'https://cdn4.vscdns.com/images/models/samples-640x480/3874462.jpg'}
                />

                <FaRank1FaPrizeAmount>
                  <FaRank1FaPrizeAmountView>
                    <FaRank2FaPrizeAmountText>
                      $250
                    </FaRank2FaPrizeAmountText>
                  </FaRank1FaPrizeAmountView>
                </FaRank1FaPrizeAmount>

                <FaRank1Shield>
                  <FaRank2ShieldImage
                    src={Images.shield2}
                  />
                </FaRank1Shield>
              </FaRank2ModelImageView>

              <FaRank1ModelInformation>
                <FaRank1ModelInfoView>
                  <FaRank1ModelOnline />

                  <FaRank1ModelName>
                    Joao batista
                  </FaRank1ModelName>
                </FaRank1ModelInfoView>

                <FaRank1ModelVote>
                  9,116 votes
                </FaRank1ModelVote>

                <FaRank1ModelVoteButton>
                  <FaRank1ModelVoteText>
                    Vote
                  </FaRank1ModelVoteText>
                </FaRank1ModelVoteButton>
              </FaRank1ModelInformation>
            </FaRank2>


            <FaRank3>
              <FaRank3ModelImageView href="/models/bios/reynoldo/about.php" target="_blank">
                <FaRank1ModelImage
                  src={'https://cdn4.vscdns.com/images/models/samples-640x480/3874462.jpg'}
                />

                <FaRank1FaPrizeAmount>
                  <FaRank1FaPrizeAmountView>
                    <FaRank2FaPrizeAmountText>
                      $100
                    </FaRank2FaPrizeAmountText>
                  </FaRank1FaPrizeAmountView>
                </FaRank1FaPrizeAmount>

                <FaRank1Shield>
                  <FaRank2ShieldImage
                    src={Images.shield3}
                  />
                </FaRank1Shield>
              </FaRank3ModelImageView>


              <FaRank1ModelInformation>
                <FaRank1ModelInfoView>
                  <FaRank1ModelOnline />

                  <FaRank1ModelName>
                    Reynoldo
                  </FaRank1ModelName>
                </FaRank1ModelInfoView>

                <FaRank1ModelVote>
                  7,716 votes
                </FaRank1ModelVote>

                <FaRank1ModelVoteButton>
                  <FaRank1ModelVoteText>
                    Vote
                  </FaRank1ModelVoteText>
                </FaRank1ModelVoteButton>
              </FaRank1ModelInformation>
            </FaRank3>
          </FaContestTopThreeView>
        </FaContestTop>


        {/* Fazer uma lista com Posição, Nome, Dinheiro, Botão de ação ver */}

      </FflirtyAwardsSingle>


    </div>

  );
};



export default TabMonthlyCrush;
