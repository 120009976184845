import styled from 'styled-components';

import {
  Card,
} from '../../../../components/Composh/web';

import {
  ContentRowToColumnPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  Colors,
  Images,
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
  enabled?: boolean;
  required?: boolean;
}



export const CompanyApprovePadder = styled(ContentRowToColumnPadder)`
  @media ${PlatformDevices.maxLaptop} {
    flex-direction: column-reverse;
  }
`;



export const ApproveSectionInfoCompanyView = styled(Card.View)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  flex: 1;
  width: 100%;

  @media ${PlatformDevices.maxTabletM} {
    align-items: center;
  }

  @media ${PlatformDevices.maxLaptop} {
    margin-top: 35px;
  }
`;



export const DetailsApproveViewBackground = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 230px;
`;

export const HeaderUserBackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${Images.backgroundDot}) repeat scroll 0 0 rgba(0, 0, 0, 0);
  z-index: 1;
`;

export const DetailsApproveBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const DetailsApproveViewImage = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 400px;
  aspect-ratio: 9 / 16;
  margin-top: -50px;
  border-radius: ${Sizes.cardRadius}px;
  z-index: 10;

  @media ${PlatformDevices.minTabletM} {
    margin-right: 20px;
  }
`;

export const DetailsApproveImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;



export const DetailsApproveContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${Sizes.paddingFlatList}px;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 10px;
  }

  @media ${PlatformDevices.minTabletM} {
    margin-top: -350px;
  }
`;


export const DetailsSectionView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const CompanyInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const CompanyUserActiveText = styled.p`
  margin-top: 2px;
  margin-bottom: 2px;
  color: ${Colors.black};
  font-size: 15px;
  word-wrap: break-word;
`;

export const CompanyUserActiveBoldText = styled.span`
  font-weight: bold;
`;



export const ApproveSectionStatusCompanyView = styled.div`
  display: flex;
  flex-direction: column;
  width: 370px;
  margin-left: ${40 - Sizes.paddingFlatList}px;

  @media ${PlatformDevices.maxLaptop} {
    width: 100%;
    min-width: 100%;
    margin-left: 0;
  }
`;

export const ApproveAdsImageButton = styled(Card.View)`
  display: flex;
  flex-direction: column;
  padding: ${Sizes.paddingFlatList}px;
`;

export const CompanyToActiveContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const CompanyItemToActiveContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const CompanyItemToActiveView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CompanyItemToActiveText = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${Colors.black};
`;

export const CompanyItemToActiveDescription = styled.p`
  color: ${(props: IProps) => props.required ? Colors.danger : Colors.grayDark};
  font-size: 14px;
`;

export const CompanyItemDescStatusText = styled.p`
  color: ${Colors.grayDark};
  font-size: 13px;
  font-style: italic;
  text-align: left;
`;

export const CompanyItemStatusText = styled.p`
  margin-left: 10px;
  color: ${(props: IProps) => props.enabled ? Colors.accept : Colors.danger};
  font-size: 22px;
  font-weight: bold;
  text-align: right;
`;

export const CompanyItemWarningText = styled.p`
  margin-left: 10px;
  color: ${(props: IProps) => props.enabled ? Colors.accept : Colors.attention};
  font-size: 22px;
  font-weight: bold;
  text-align: right;
`;
