import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  GetRentAllServicesModele,
  EditRentServicesModele,
} from '../../../../../../apis/endpoints/modeles.endpoints';

import ButtonAction from '../../../../../../components/Buttons/ButtonAction';

import {
  Content,
} from '../../../../../../components/Composh/web';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../../../../components/Contents/ContentPadder';

import RentServices from '../../../../../../components/Details/RentServices';
import FooterApp from '../../../../../../components/Footers/FooterApp';
import LoadingScreen from '../../../../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../../../../components/Titles/SectionTitle';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../../../redux/reducers/app-modals.store';

import {
  USER_SET,
} from '../../../../../../redux/reducers/user.store';

import {
  Colors,
  Sizes,
} from '../../../../../../shared/constants';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../../../shared/utils/errors.utils';

import {
  CardServicesForm,
  WrapperItemContent,
} from './styled';



export interface IProps {
  modele: any;
}



const ServicesScreen: React.FC<IProps> = (props: IProps) => {
  const modeleDetails = props.modele || null;
  const availableUser = modeleDetails?.id;

  const dispatch = useDispatch();


  const [rentAllService, setRentAllService] = useState<Array<any>>([]);
  const [userService, setUserService] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getFavoritesProfilesFunction = async () => {
    try {
      setLoading(true);

      const response = await GetRentAllServicesModele(availableUser);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao obter');
        return;
      }

      setRentAllService(response?.data);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  async function updateForm() {
    await getFavoritesProfilesFunction();
    setMounted(true);
  }


  async function saveItemGroup() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = userService;

    try {
      const response = await EditRentServicesModele(availableUser, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
      dispatch({ type: USER_SET, payload: response?.data });
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  const handleCheckboxChange = async (idService: number, doItem?: boolean, speciallity?: boolean) => {
    console.log('idService', idService, doItem, speciallity);

    const updatedUserService = rentAllService.map((item) => {
      if (item?.id === idService) {
        return { idService, doItem: !!doItem, speciallity: !!speciallity };
      }

      return { idService: item?.id, doItem: item?.doItem, speciallity: item?.speciallity };
    });

    console.log('updatedUserService', updatedUserService);
    setUserService(updatedUserService);
  };



  useEffect(() => {
    updateForm();
  }, []);



  return (

    <Content>

      {!mounted && (
        <LoadingScreen
          type={'SCREEN'}
          color={Colors.white}
        />
      )}



      {mounted && (
        <ContentPadder>

          <CardServicesForm
            backgroundColor={Colors.formBackground}
            borderRadius={Sizes.cardRadius}>

            <WrapperItemContent>

              <SectionTitle
                title={'Serviços'}
              />


              <RentServices
                edit
                disabled={loading}
                color={Colors.secondaryDark}
                data={rentAllService}
                onCheckboxChange={(idService: number, doItem?: boolean, speciallity?: boolean) => {
                  handleCheckboxChange(idService, doItem, speciallity);
                }}
              />

            </WrapperItemContent>



            <ContentButtonForm>
              <ButtonAction
                width={Sizes.buttonMinWidth}
                title={'Salvar'}
                onPress={() => {
                  saveItemGroup();
                }}
              />
            </ContentButtonForm>

          </CardServicesForm>

        </ContentPadder>
      )}



      <FooterApp />

    </Content>

  );
};



export default ServicesScreen;
