import styled,
{
  css,
} from 'styled-components';

import {
  Card,
} from '../../../../components/Composh/web';

import InputSelect from '../../../../components/Inputs/InputSelect';

import {
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export const InputmarginStyle = css`
  margin-left: 5px;
  margin-right: 5px;
`;

export const InputSelectStyled = styled(InputSelect)`
  ${InputmarginStyle};
  width: calc(25% - 10px);
`;

export const TravelButtonsContainer = styled.div`
  ${InputmarginStyle};
  display: flex;
  flex-direction: column;
  width: calc(25% - 10px);
`;



export const TravelColumnPlaceView = styled(Card.View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;
