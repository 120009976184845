import styled,
{
  css,
} from 'styled-components';

import Color from 'color';

import {
  Divider,
} from '../../components/Composh/web';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export interface IProps {
  disabled?: boolean;
  selected?: boolean;
  color?: string;
  backgroundColor?: string;
}


export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;



export const MenuHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;

  background: ${Colors.tabBackground};
  background: linear-gradient(0deg, ${Colors.tabBackground} 20%, ${Colors.toolbarBackground} 90%);
`;

export const MenuImageContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;


export const MenuImageHeaderView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin-left: 12px;
`;


export const MenuThemeContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  margin-top: 5px;
`;

export const MenuHeaderStatusText = styled.p`
  color: ${Colors.black};
  font-size: 13.5px;
`;


export const MenuVerifiedContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MenuUserNameText = styled.h2`
  margin-bottom: 3px;
  color: ${Colors.grayDark};
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconVerifiedView = styled.a`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;



export const MenuThemeButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23px;
  height: 23px;
  margin-bottom: 3px;
`;



export const MenuHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
`;

export const MenuHeaderName = styled.p`
  margin-top: 2px;
  color: ${Colors.black};
  font-size: 14px;
`;

export const MenuSeparator = styled(Divider)`
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const MenuInfoNameText = styled.p`
  margin-top: 3px;
  color: ${Colors.grayDark};
  font-size: 13px;
  font-style: italic;
`;



export const MenuListContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-color: ${Colors.menuBackground};
`;


export const ActiveIndicatorContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const MenuContentPlace = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const MenuItemList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;



export const ItemMenuInfoItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextActive = css`
  font-size: 17px;
  font-weight: 600;
`;

export const ColorStyle = css`
  color: ${(props: IProps) => props.color || Colors.secondary};
`;

export const ItemMenuText = styled.span`
  font-size: 16px;
  transition: all 0.25s ease;
  z-index: 5;

  ${(props: IProps) => props.selected && TextActive};
  ${(props: IProps) => props.color && ColorStyle};
`;

export const ItemMenuSubText = styled.span`
  margin-top: 2px;
  font-size: 13px;
  transition: color 0.25s ease;
  z-index: 5;

  ${(props: IProps) => props.color && ColorStyle};
`;

export const ItemMenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${Sizes.menuContent}px !important;
  width: ${Sizes.menuContent}px !important;
  min-width: ${Sizes.menuContent}px !important;
  max-width: ${Sizes.menuContent}px !important;
  margin-right: 6px;
  z-index: 5;
`;

export const ItemMenuContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  height: 55px;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  background-color: ${(props: IProps) => props.disabled
    ? Colors.black
    : props.selected
      ? Color(Colors.secondaryAccent).lighten(0.25).toString()
      : Colors.transparent
  };
  z-index: 0;
`;
