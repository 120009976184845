import ApiWS from '../index';



export const GetAppDashboardData = async () => {
  const url = '/support/dash-app';

  const response = await ApiWS.get(url);
  return response.data;
};
