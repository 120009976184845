import React from 'react';

// import { Container } from './styles';

const AppDownScreen: React.FC = () => {
  return (

    <h2>
      O suporte do secretio está indisponível
    </h2>

  );
};

export default AppDownScreen;
