import styled from 'styled-components';

import {
  Card,
} from '../../../../components/Composh/web';

import InputText from '../../../../components/Inputs/InputText';

import {
  Sizes,
} from '../../../../shared/constants';



export const CardContactsForm = styled(Card.View)`
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;



export const InputTextStyled = styled(InputText)`
`;
