import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  Card,
} from '../../Composh/web';



export const CardStarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 6px;
  padding-bottom: 6px;

  @media ${PlatformDevices.maxMobileM} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minMobileM} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media ${PlatformDevices.minMobileL} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
    padding-left: 5px;
    padding-right: 5px;
  }
  
  @media ${PlatformDevices.minTabletM} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media ${PlatformDevices.minLaptopL} {
    width: calc(100% / 6);
    min-width: calc(100% / 6);
    max-width: calc(100% / 6);
    padding-left: 5px;
    padding-right: 5px;
  }
`;



export const CardModelContainer = styled(Card.View)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 380px;
  border-color: ${Colors.cardBackground};
  border-style: solid;
  border-width: 2px;
  transition: all 0.2s ease;

  :hover{
    border-color: ${Colors.secondary};
  }
`;


export const FooterVideoInfoButton = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 290px;
  background-color: ${Colors.black};
`;

export const FooterVideoInfoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;


export const FooterVideoInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  min-height: 90px;
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
`;


export const VideoTextStarView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const FooterVideoInfoText = styled.p`
  color: ${Colors.black};
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  text-overflow: ellipsis;
`;

export const VideoRateView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
`;

export const VideoRateText = styled.div`
  margin-left: 4px;
  color: ${Colors.black};
  font-size: 14px;
`;


export const FooterVideoActionView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`;

export const VideoActionView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 15px;
`;

export const VideoItemActionTypeView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const VideoActionTypeView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModelInfoActionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const VideoActionTypeText = styled.p`
  color: ${Colors.gray};
  font-size: 13px;
  font-weight: 700;
`;
