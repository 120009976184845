import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useParams,
} from 'react-router-dom';

import {
  ChangeVisibleReview,
  GetModeleReviews,
} from '../../../../apis/endpoints/reviews-modele.endpoints';

import AlertMessage from '../../../../components/Alerts';
import CardComment from '../../../../components/Cards/CardComment';

import {
  Body,
  Container,
  Content,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
} from '../../../../shared/constants';

import EStatusVisible from '../../../../shared/enums/Status/status-visibility.enum';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  TitleModeleReviewContainer,
  TitleModeleReviewLengthView,
  TitleModeleReviewLengthText,
  TitleModeleReviewLengthHelpText,
  TitleModeleReviewAverageText,
  ButtonAutomaticPublishStyled,
  ModeleReviewScrollFlatList,
} from './styled';



export interface IRouteParams {
  useralias: string;
}



const ReviewModeleScreen: React.FC = () => {
  const dispatch = useDispatch();

  const route = useParams<IRouteParams>();
  const paramsRoute = route as IRouteParams;


  const { useralias } = paramsRoute;
  const userIdSearch = useralias;


  const [reviewsModele, setReviewsModele] = useState<Array<any>>([]);

  const [autoVisibleModule, setautoVisibleModule] = useState<boolean>(false);
  const [invisibleCommentModal, setInvisibleCommentModal] = useState<number | null>(null);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getModeleComments() {
    setLoading(true);

    try {
      const response = await GetModeleReviews(userIdSearch);

      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        setReviewsModele([]);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      setautoVisibleModule(responseModele?.autoVisibleModule);
      setReviewsModele(responseModele?.reviews);
    }
    catch (error: any) {
      console.error(error);
      setReviewsModele([]);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  async function changeVisibleComment(idComment: number) {
    setInvisibleCommentModal(null);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await ChangeVisibleReview(idComment);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
      getModeleComments();
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderItem(item: any, index: number) {
    return (

      <CardComment
        key={index}
        edit
        type={'MODELE'}
        visible={item?.visible}
        alias={item?.userMake?.alias}
        plan={item?.userMake?.plan?.planCurrent}
        image={item?.userMake?.picture}
        createdAt={item?.createdAt}
        rating={item?.rating}
        dateMeet={item?.date}
        comment={item?.comment}
        meet={item?.meet}
        ambience={item?.ambience}
        medias={item?.medias}
        recommend={item?.recommend}

        makeAlias={item?.userMake?.alias}
        makePlan={item?.userMake?.plan?.planCurrent}
        makeImage={item?.userMake?.picture}

        onVisibleOrRevisdPress={() => {
          if (item?.visible === EStatusVisible.VISIBLE) {
            setInvisibleCommentModal(item?.id);
          }
          else {
            changeVisibleComment(item?.id);
          }
        }}
      />

    );
  }


  function renderCommentNumberTitle(): string {
    if (loading) {
      return '';
    }

    if (reviewsModele?.length <= 0) {
      return 'Sem avaliações';
    }

    if (reviewsModele?.length === 1) {
      return '1 avaliação';
    }

    if (reviewsModele?.length > 1) {
      return `${reviewsModele?.length} avaliações`;
    }

    return '';
  }


  function listHeaderComponent() {
    return (

      <TitleModeleReviewContainer>

        <TitleModeleReviewLengthView>

          <ButtonAutomaticPublishStyled>
            {loading
              ? 'Carregando'
              : autoVisibleModule
                ? 'Publicação automática ativada'
                : 'Publicar automáticamente'
            }
          </ButtonAutomaticPublishStyled>


          {renderCommentNumberTitle() && (
            <TitleModeleReviewLengthText>
              {renderCommentNumberTitle()}
            </TitleModeleReviewLengthText>
          )}

        </TitleModeleReviewLengthView>


        {!loading && (
          <TitleModeleReviewLengthHelpText>
            Ao ativar essa opção, todos os comentários revisados serão automaticamente mostrados na página do modelo.
          </TitleModeleReviewLengthHelpText>
        )}


        {!loading && (
          <TitleModeleReviewAverageText>
            Apenas comentários visíveis são contabilizados na sua nota
          </TitleModeleReviewAverageText>
        )}

      </TitleModeleReviewContainer>

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.black}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={''}
        title={'Nenhuma avaliação aqui ainda.'}
      />

    );
  }



  useEffect(() => {
    getModeleComments();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          leftIcon={
            <ButtonBack
              color={Colors.black}
              size={27}
            />
          }
          centerContent={
            <Body>
              <Title
                color={Colors.black}>
                Avaliações
              </Title>

              <SubTitle
                color={Colors.gray}>
                @{userIdSearch}
              </SubTitle>
            </Body>
          }
        />



        <Content>

          <ContentPadder>

            {listHeaderComponent()}


            <ModeleReviewScrollFlatList
              noScroll
              loading={loading}
              data={reviewsModele}
              renderItem={renderItem}
              listLoadingComponent={renderLoading()}
              listEmptyComponent={listEmptyComponent()}
            />

          </ContentPadder>



          <FooterApp />

        </Content>

      </Container>



      <AlertMessage
        visible={Boolean(invisibleCommentModal)}
        title={'Deseja esconder o comentário do seu perfil?'}
        cancelText={'Não'}
        cancelPress={() => {
          setInvisibleCommentModal(null);
        }}
        okText={'Sim'}
        okPress={() => {
          changeVisibleComment(invisibleCommentModal);
        }}
      />

    </Screen>

  );
};



export default ReviewModeleScreen;
