import React from 'react';

import {
  // SeparatorVerticalStyled,
  ViewSeparator,
} from './styled';



export interface IProps {
  option?: string;
  style?: any;
}



const Separators: React.FC<IProps> = (props: any) => {
  switch (props.option) {
    case 'VERTICAL':
      return null;
      // (

      //   <SeparatorVerticalStyled
      //     alt={'vertical-separator'}
      //     src={Images.separatorVertical}
      //     style={props.style}
      //   />

      // );

    default:
      return (

        <ViewSeparator />

      );
  }
};



export default Separators;
