import styled from 'styled-components';



// export const SeparatorVerticalStyled = styled.img`
//   display: flex;
//   height: 25px;
//   width: 90%;
//   object-fit: stretch;
// `;

export const ViewSeparator = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 8px;
  margin-right: 8px;
`;
