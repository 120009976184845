import React from 'react';

import {
  DrawerViewTitle,
  MenuEpigraphSection,
} from './styled';



export interface IProps {
  title: string;
  backgroundColor?: string;
}



const DrawerTitle: React.FC<IProps> = (props) => {
  return (

    <DrawerViewTitle
      backgroundColor={props.backgroundColor}>

      <MenuEpigraphSection>
        {props.title.toUpperCase()}
      </MenuEpigraphSection>

    </DrawerViewTitle>

  );
};



export default DrawerTitle;
