import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  ArchiveModeleReview,
  ChangeRevisedModeleReview,
  DeleteModeleReview,
  GetAllReviewsModeles,
} from '../../../../apis/endpoints/publish-review.endpoints';

import {
  IPagination,
} from '../../../../apis/interfaces/App/pagination.interface';

import CardComment from '../../../../components/Cards/CardComment';

import {
  Body,
  Content,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import SubHeader from '../../../../components/Headers/SubHeader';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import TableSectionFilter from '../../../../components/Table/Filter';
import TableChildren from '../../../../components/Table/TableChildren';

import NameRoutes from '../../../../navigation/names';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import { EDrawerMenu } from '../../../../shared/enums';
import EResponseStatus from '../../../../shared/enums/Response/response-status.enum';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  TableListButtonAction,
  TableListHeaderContainer,
} from '../../../../styles/form.styled';

import {
  AllModelesRightContent,
  AllModelesFlatList,
} from './styled';



const AvaliationsToReviseScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const perPageArray = [10, 20, 30];

  const [pageSelected, setPageSelected] = useState<number>(Values.firstPage);
  const [tableProviders, setTableProviders] = useState<any>(null);
  const [modelesArray, setModelesArray] = useState<Array<any>>([]);
  const [modelesPagination, setModelesPagination] = useState<IPagination | null>(null);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function mountPayload() {
    const payload = {
      pageNumber: tableProviders?.pageNumber || Values.firstPage,
      itemsPerPage: tableProviders?.itemsPerPage || perPageArray[0],
      revised: false,
      // sortOrder: '',
      // sortColumn: '',
    } as any;

    return payload;
  }


  const getAllModelesComments = async () => {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = mountPayload();

    try {
      const response = await GetAllReviewsModeles(payload);

      if (!Array.isArray(response?.data) || !response?.data) {
        setModelesArray([]);
        return;
      }

      setModelesArray(response?.data);
      setPageSelected(response?.pagination?.pageCurrent);
      setModelesPagination(response?.pagination);
    }
    catch (error: any) {
      console.error(error);
      setModelesArray([]);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  async function archiveComment(idComment: number) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await ArchiveModeleReview(idComment);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
      getAllModelesComments();
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function changeRevisedComment(idComment: number, useralias: string) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await ChangeRevisedModeleReview(idComment, useralias);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
      getAllModelesComments();
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function deleteReview(idComment: number) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await DeleteModeleReview(idComment);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao deletar comentário');
        return;
      }

      showSnackBarProps(Colors.accept, 'Comentário deletado com sucesso');
      getAllModelesComments();
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function listHeaderComponent() {
    return (

      <SubHeader
        title={'Um título'}
        color={Colors.secondaryDark}>

        <TableListHeaderContainer>

          <TableListButtonAction
            title={'Filtros'}
            onPress={() => {
              if (!loading) {
                // TODO
              }
            }}
          />

        </TableListHeaderContainer>

      </SubHeader>

    );
  }


  function renderItem(item: any, index: number) {
    return (

      <CardComment
        key={index}
        edit
        type={'SUPPORT'}
        visible={item?.visible}
        alias={item?.userReceived?.alias}
        plan={item?.userReceived?.plan?.planCurrent}
        image={item?.userReceived?.picture}
        createdAt={item?.createdAt}
        rating={item?.rating}
        dateMeet={item?.date}
        comment={item?.comment}
        meet={item?.meet}
        ambience={item?.ambience}
        medias={item?.medias}
        recommend={item?.recommend}

        makeAlias={item?.userMake?.alias}
        makePlan={item?.userMake?.plan?.planCurrent}
        makeImage={item?.userMake?.picture}

        onImagePress={() => {
          history.push(NameRoutes.PanelModeleScreen.replace(
            ':useralias',
            item?.userReceived?.useralias,
          ));
        }}
        onArchivePress={() => {
          archiveComment(item?.id);
        }}
        onVisibleOrRevisdPress={() => {
          changeRevisedComment(item?.id, item?.userReceived?.useralias);
        }}
        onDeletePress={() => {
          deleteReview(item?.id);
        }}
      />

    );
  };


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.secondary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={'Images.logoIconAppWhite'}
        title={'Não há modelos ainda!'}
      />

    );
  }


  useEffect(() => {
    getAllModelesComments();
  }, [tableProviders]);


  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.REVIEW_TO_PUBLISH });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.black}>
              Avaliações a publicar
            </Title>

            <SubTitle
              color={Colors.gray}>
              App
            </SubTitle>
          </Body>
        }
      />



      <Content>

        <ContentPadder>

          {listHeaderComponent()}



          <AllModelesRightContent
            backgroundColor={Colors.formBackground}
            borderRadius={Sizes.cardRadius}>

            <TableSectionFilter.View>

              <TableSectionFilter.Chip>
                Revisado: {mountPayload()?.revised ? 'Sim' : 'Não'}
              </TableSectionFilter.Chip>

            </TableSectionFilter.View>



            <TableChildren
              headerTopBorder
              headerBottomBorder
              color={Colors.white}
              accentColor={Colors.secondaryDark}
              sizePerPageList={perPageArray}
              paginationProviders={(providers) => {
                // setPageSelected(providers?.page);
                setTableProviders({
                  pageNumber: providers?.page,
                  itemsPerPage: providers?.size,
                });
              }}
              paginationProps={{
                pageCurrent: pageSelected,
                pagesTotal: modelesPagination?.pagesTotal,
                from: modelesPagination?.fromCount,
                to: modelesPagination?.toCount,
                dataSize: modelesPagination?.itemsTotal,
              }}>

              <AllModelesFlatList
                data={modelesArray}
                renderItem={renderItem}
                listLoadingComponent={renderLoading()}
                listEmptyComponent={listEmptyComponent()}
              />

            </TableChildren>

          </AllModelesRightContent>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};


export default AvaliationsToReviseScreen;
