import styled from 'styled-components';

import {
  Card,
} from '../../../../components/Composh/web';

import InputCheckbox from '../../../../components/Inputs/InputCheckbox';
import InputText from '../../../../components/Inputs/InputText';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';



export const CardContactsForm = styled(Card.View)`
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;



export const InputTextStyled = styled(InputText)`
`;


export const InputButtonCheckboxStyled = styled(InputCheckbox)`
`;



export const ServiceWarningFormView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 25px;
`;

export const ServiceWarningFormLabel = styled.p`
  margin-bottom: 2px;
  color: ${Colors.black};
  font-size: 13px;
`;

export const ServiceWarningFormText = styled.p`
  color: ${Colors.secondary};
  font-size: 15px;
  font-weight: bold;
`;
