import styled from 'styled-components';

import {
  Colors,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';

import {
  ListTable,
  Scrolls,
} from '../Composh/web';



export const TableNextStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;



export const ScrollTableHorizontalStyled = styled(Scrolls.Horizontal)`
  background-color: ${Colors.white};
`;


export const ListTableStyled = styled(ListTable)`
  font-size: 13px;

  @media ${PlatformDevices.maxMobileL} {
    min-width: 150vh;
  }

  

  /* border-radius: 0.25rem; */
  /* border: 1px solid rgba(0,0,0,.125) !important; */
  
  /*
  .table,
  table {
    overflow: hidden;
    margin: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-left: hidden;
    border-right: hidden;

    .no-style {
      padding: 0 !important;
      margin: 0 !important;
    }


    thead {
      
      tr {
        background-color: ${Colors.secondary};
        
        th {
          padding: 6px 4px !important;
          color: white;
          font-size: ${'13px'};
          font-weight: 600;
          text-align: center;
          letter-spacing: -.04rem;
          word-wrap: break-word;
          vertical-align: middle;
        }
      }
    }



    tbody {
      border-top-color: #FFFFFF !important;
      border-top-style: solid !important;
      border-top-width: 2px !important;


      .no-horizontal-border {
        border-left-width: 0;
        border-right-width: 0;
      }


      tr {
        border-color: #dee2e6;
        border-style: solid;
        border-width: 1px;


        .td-button {
          color: ${Colors.tertiaryDark};
          cursor: pointer;
        }


        .actions {
          width: 20px;
          align-items: center;
          justify-content: center;
        }


        td {
          padding: 6px 4px !important;
          font-size: ${'13px'};
          font-weight: normal;
          text-align: center;
          vertical-align: middle;
          
          word-wrap: break-word;
        }

        :nth-of-type(odd)>* {
          --bs-table-accent-bg: ${Colors.secondaryAccent};
        }

        :hover:nth-of-type(odd) > *,
        :hover {
          background-color: ${Colors.secondary};
          --bs-table-accent-bg: ${Colors.secondary};
        }
      }
    }

  }


  .order, .react-bootstrap-table-sort-order,
  .order-4, .caret-4-asc, .caret-4-desc {
    display: block;
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 0.375rem dashed;
    border-top: 0.375rem solid ${Colors.white};
    border-right: 0.375rem solid transparent;
    border-left: 0.375rem solid transparent;
  }
  
  .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 0.375rem dashed;
    border-bottom: 0.375rem solid ${Colors.white};
    content: "";
  }


  .pagination {
    margin: 0; 

    .page-item > a {
      color: ${Colors.primary};
    }
    
    .active > a, 
    .active > a:focus, 
    .active > a:active, 
    
    .active > span,
    .active > span:hover, 
    .active > span:focus {
      color: ${Colors.white} !important;
      background-color: ${Colors.secondary};
      border-color: ${Colors.secondary};
      box-shadow: none;
    }

    .page-item > a:hover,
    .active > a:hover {
      color: ${Colors.white} !important;
      background-color: ${Colors.secondaryDark};
      border-color: ${Colors.secondaryDark};
    }
  }
  */
`;
