import React from 'react';



const StarsDetailsScreen: React.FC = () => {
  return (

    <div>

      <h2>Detalhes Estrelas</h2>


      <div>
        <label htmlFor="name">Nome</label>
      </div>


      <button
        type="button"
        onClick={() => {
          // handleSubmit();
        }}>
        Mais
      </button>

    </div>

  );
};



export default StarsDetailsScreen;
