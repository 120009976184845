import ApiWS from '../index';



export const GetStreamChannels = async (payload: any) => {
  const url = '/support/stream/channels';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const CreateStreamChannels = async (payload: any) => {
  const url = '/support/stream/channels';

  const response = await ApiWS.post(url, payload);
  return response.data;
};



export const EditStreamChannels = async (id: string, payload: any) => {
  const url = `/support/stream/channels/${id}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const DeleteStreamChannels = async (id: any) => {
  const url = `/support/stream/channels/${id}`;

  const response = await ApiWS.put(url);
  return response.data;
};
