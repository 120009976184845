import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  GetModeleProfissionalPanel,
  EditParamsModele,
  EnableModeleUseraliasEdit,
} from '../../../../apis/endpoints/modeles.endpoints';

import {
  IInputSelect,
} from '../../../../apis/interfaces/App/input-select.interface';

import AlertMessage from '../../../../components/Alerts';

import {
  Body,
  Container,
  Content,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentRowToColumnPadder,
} from '../../../../components/Contents/ContentPadder';

import UserAvatar from '../../../../components/Contents/UserAvatar';

import {
  ButtonBack,
} from '../../../../components/Controls';

import ButtonPanelLocation from '../../../../components/Details/ButtonPanelLocation';
import ButtonPanelTravels from '../../../../components/Details/ButtonPanelTravels';
import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconAntiFake,
  IconCharacteristics,
  IconCheck,
  IconCircleAttention,
  IconCircleCheck,
  IconCircleRemove,
  IconClose,
  IconFaClub,
  IconGlobalAccount,
  IconLive,
  IconMassageOn,
  IconMediaComplete,
  IconMediaLot,
  IconPhone,
  IconProfile,
  IconRentOn,
  IconSocialComLocal,
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialOnlyFans,
  IconSocialPrivacy,
  IconSocialTelegram,
  IconSocialTiktok,
  IconSocialTwitter,
  IconSocialWebsite,
  IconStar,
  IconUpgrade,
  IconUser,
  IconVerified,
  IconVerifiedPromoter,
  IconVisibility,
} from '../../../../components/Icons';

import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../../components/Titles/SectionTitle';

import {
  SetSuitsGenderImage,
} from '../../../../config/genre.config';

import {
  openSite,
} from '../../../../config/linking.config';

import NameRoutes from '../../../../navigation/names';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
  USER_BRAND_PROMOTER_MODAL,
  USER_VERIFIED_MODAL,
} from '../../../../redux/reducers/app-modals.store';

import { countriesOption } from '../../../../shared/arrays/App/countries.array';
import { plansFinancialArray } from '../../../../shared/arrays/Financial/plans.array';
import { typeGenders } from '../../../../shared/arrays/Gender/gender.array';
import { userStatusArray } from '../../../../shared/arrays/User/user-status.array';

import {
  Colors,
  Images,
  Sizes,
} from '../../../../shared/constants';

import EResponseStatus from '../../../../shared/enums/Response/response-status.enum';
import EUserType from '../../../../shared/enums/Users/user-type.enum';

import {
  TranslateConfig,
} from '../../../../shared/translations';

import {
  returnCityArray,
  returnCountryArray,
  returnStateArray,
} from '../../../../shared/utils/address.utils';

import {
  FormatExtenseDate,
} from '../../../../shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  stringRawDataText,
  validateNumber,
  validateString,
} from '../../../../shared/utils/string.utils';

import {
  PanelContentCenterFlex,
  DetailsApproveViewBackground,
  PanelPadderContent,
  DetailsApproveBackground,
  HeaderUserBackgroundOverlay,
  DetailsApproveViewImage,
  PanelAvatarItemInfo,
  PanelAvatarName,
  PanelUsernameValuesView,
  PanelAvatarUsername,
  HomeIdentiyIcon,
  PanelWrapperItemContent,
  PanelWrapperLastItemContent,
  PanelActiveItemContent,
  ButtonPublishStyled,
  PanelResumeContent,
  PanelResumeTitle,
  PanelLocationsFlag,
  PanelResumeText,
  PanelResumeLocationContainer,
  PanelResumeLevelContainer,
  PanelResumeLevelView,
  PanelResumeLevelImage,
  InputButtonCheckboxStyled,
  PanelValuesContainer,
  PanelValuesContent,
  PanelSocialContent,
  PanelSocialButton,
  PanelValuesLabel,
  PanelValuesText,
  PanelValuesPublishView,
  PanelValuesPublishIcon,
  PanelValuesPublishText,
  PanelOptionsContainer,
  PanelUpgradeContainer,
  PanelItemsButton,
  PanelItemsIcon,
  PanelItemsText,
  PanelInputHelpText,
  CommentsCountContainer,
  CommentsCountIcon,
  CommentsCountText,
  FinancialRightSuggestion,
  FinancialAdsImageButton,
} from './styled';



export interface IRouteParams {
  useralias: string;
}



const PanelModeleScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const route = useParams<IRouteParams>();
  const paramsRoute = route as IRouteParams;

  const { useralias } = paramsRoute;
  const userIdSearch = useralias;


  const charIconsSize = 20;

  const [modeleDetails, setModeleDetails] = useState<any>(null);

  const [configModulesParams, setConfigModulesParams] = useState<any>(null);

  const [rentModule, setRentModule] = useState<boolean>(false);
  const [fanClubModule, setFanClubModule] = useState<boolean>(false);
  const [liveModule, setLiveModule] = useState<boolean>(false);
  const [massageModule, setMassageModule] = useState<boolean>(false);
  const [streamModule, setStreamModule] = useState<boolean>(false);

  const [showFaceStatus, setShowFaceStatus] = useState<boolean>(false);

  const [showUseraliasModal, setShowUseraliasModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function setStatesParams(itemReceived: any) {
    setRentModule(itemReceived?.params?.rent);
    setFanClubModule(itemReceived?.params?.fanClub);
    setLiveModule(itemReceived?.params?.live);
    setMassageModule(itemReceived?.params?.massage);
    setStreamModule(itemReceived?.params?.stream);
    setShowFaceStatus(itemReceived?.showFace);
  }


  function appProjectParamsValues(dataParams: any) {
    setConfigModulesParams(dataParams);
  }


  async function getActualModele() {
    try {
      setLoading(true);

      const response = await GetModeleProfissionalPanel(userIdSearch);

      if (!response?.data) {
        console.error('Error', response);
        setModeleDetails(null);
        showSnackBarProps(Colors.danger, 'Erro ao trazer dados');
        return;
      }

      appProjectParamsValues(response?.data?.configs?.modules);

      setStatesParams(response?.data?.modele);

      setModeleDetails(response?.data?.modele);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);

      return null;
    }
    finally {
      setLoading(false);
    }
  };


  async function saveItemGroup(payload: any) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await EditParamsModele(userIdSearch, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      setStatesParams(response?.data);
      setModeleDetails(response?.data);

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function setUseraliasTrueUser() {
    setShowUseraliasModal(false);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await EnableModeleUseraliasEdit(userIdSearch);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
      await getActualModele();
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderPanelButton(title: string, icon: any, onPress: any, accent = false, disabled = false, information = '', color = Colors.danger) {
    return (

      <PanelItemsButton
        accent={accent}
        disabled={disabled}
        onPress={onPress}>

        <PanelItemsIcon>
          {icon}
        </PanelItemsIcon>


        <PanelItemsText>
          {title}
        </PanelItemsText>


        {information && (
          <PanelInputHelpText
            color={color}>
            {information}
          </PanelInputHelpText>
        )}

      </PanelItemsButton>

    );
  }


  function renderLocation() {
    const hasLocation = modeleDetails?.location
      && modeleDetails?.location?.country
      && modeleDetails?.location?.state
      && modeleDetails?.location?.city;

    const countryUser = modeleDetails?.location?.country;

    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((item: IInputSelect) => item.value === modeleDetails?.location?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(countryUser, findState?.value);
    const findCity = getCityArray?.find((item: IInputSelect) => item.value === modeleDetails?.location?.city);
    const cityUser = findCity?.label;


    return (

      <ButtonPanelLocation
        title={'Localização'}
        country={hasLocation ? countryUser : ''}
        place={hasLocation
          ? `${cityUser}, ${stateUser}`
          : 'Sem localização definida'
        }
        onPress={() => {
          history.push(
            NameRoutes.ModeleLocationFormSection.replace(
              ':useralias',
              userIdSearch,
            ),
          );
        }}
      />

    );
  }


  function renderAtualLocation() {
    // Obtenha a data atual
    const currentDate = new Date();

    // Verifique se o usuário está em uma viagem atualmente
    const currentTravel = modeleDetails?.appTravels?.find((travel) => {
      const startDate = new Date(travel.startAt);
      const endDate = new Date(travel.endAt);
      return currentDate >= startDate && currentDate <= endDate;
    });

    // Se o usuário está em uma viagem atualmente, retorne a localização da viagem atual
    if (currentTravel) {
      const cityUser = currentTravel.city;
      const stateUser = currentTravel.state;
      const getUserCountry = currentTravel.country;

      const filterLangCountry = countriesOption.find((country) => stringRawDataText(country.code) === stringRawDataText(getUserCountry));

      return (

        <PanelResumeLocationContainer>
          <PanelLocationsFlag
            src={filterLangCountry?.image}
          />

          <PanelResumeText>
            {`${cityUser}, ${stateUser} - ${getUserCountry}`}
          </PanelResumeText>
        </PanelResumeLocationContainer>

      );
    }

    // Caso contrário, verifique se o usuário tem uma localização definida
    const hasLocation = modeleDetails?.location &&
      modeleDetails?.location?.country &&
      modeleDetails?.location?.state &&
      modeleDetails?.location?.city;

    const countryUser = modeleDetails?.location?.country;
    const findCountry = returnCountryArray()?.find((item: IInputSelect) => item.value === countryUser);
    const getUserCountry = findCountry?.label;

    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((item: IInputSelect) => item.value === modeleDetails?.location?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(countryUser, findState?.value);
    const findCity = getCityArray?.find((item: IInputSelect) => item.value === modeleDetails?.location?.city);
    const cityUser = findCity?.label;

    // Retorne a localização definida pelo usuário, se houver
    if (hasLocation) {
      const filterLangCountry = countriesOption.find((country) => stringRawDataText(country.code) === stringRawDataText(countryUser));

      return (

        <PanelResumeLocationContainer>
          <PanelLocationsFlag
            src={filterLangCountry?.image}
          />

          <PanelResumeText>
            {`${cityUser}, ${stateUser} - ${getUserCountry}`}
          </PanelResumeText>
        </PanelResumeLocationContainer>

      );
    }


    // Se o usuário não tiver localização definida
    return (

      <PanelResumeLocationContainer>
        <PanelResumeText>
          Sem localização definida
        </PanelResumeText>
      </PanelResumeLocationContainer>

    );
  }


  function renderHeaderRent(value: number) {
    const loopValue = value;
    const rows = [];

    for (let index = 0; index < loopValue; index++) {
      rows.push(
        <PanelResumeLevelView
          key={index}>
          <PanelResumeLevelImage
            src={SetSuitsGenderImage(modeleDetails?.gender)}
          />
        </PanelResumeLevelView>,
      );
    }



    return (

      <PanelResumeContent>

        <PanelResumeTitle>
          Nível de anúncio de acompanhante:
        </PanelResumeTitle>


        <PanelResumeLevelContainer>
          {rows}
        </PanelResumeLevelContainer>

      </PanelResumeContent>

    );
  }


  function renderTravels() {
    return (

      <ButtonPanelTravels
        title={'Viagens'}
        travelCount={validateNumber(modeleDetails?.appTravels?.length) || 0}
        onPress={() => {
          history.push(
            NameRoutes.ModeleTravelSeeScreen.replace(
              ':useralias',
              userIdSearch,
            ),
          );
        }}
      />

    );
  }


  function renderPendeciesTitle() {
    if (validateNumber(modeleDetails?.pendencies) && modeleDetails?.pendencies > 0) {
      if (modeleDetails?.pendencies === 1) {
        return '1 atualização pendente';
      }

      if (modeleDetails?.pendencies > 1) {
        return `${modeleDetails?.pendencies} atualizações pendentes`;
      }
    }

    return 'Sem atualizações pendentes';
  }



  useEffect(() => {
    getActualModele();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet>
        <title>
          {`Detalhes do modelo | ${translate(TranslateConfig.NAME_APP)}`}
        </title>
      </Helmet>



      <Container>

        <HeaderScreen
          leftIcon={
            <ButtonBack
              color={Colors.black}
            />
          }
          centerContent={
            <Body>
              <Title
                color={Colors.black}>
                Detalhes do modelo
              </Title>

              <SubTitle
                color={Colors.gray}>
                @{userIdSearch || '-'}
              </SubTitle>
            </Body>
          }
        />



        <Content>

          {loading && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.secondary}
            />
          )}



          {!loading && (
            <ContentRowToColumnPadder>

              <PanelContentCenterFlex
                backgroundColor={Colors.formBackground}
                borderRadius={Sizes.cardRadius}>

                <DetailsApproveViewBackground>
                  <HeaderUserBackgroundOverlay />

                  <DetailsApproveBackground
                    src={validateString(modeleDetails?.pictureCover) || Images.bannerDetails}
                  />
                </DetailsApproveViewBackground>



                <DetailsApproveViewImage>
                  <UserAvatar
                    plan={modeleDetails?.plan?.planCurrent}
                    image={modeleDetails?.picture}
                    width={190}
                    height={250}
                  />
                </DetailsApproveViewImage>



                <PanelPadderContent>

                  <PanelAvatarItemInfo>

                    <PanelAvatarName>
                      {modeleDetails?.alias || '-'}
                    </PanelAvatarName>



                    <PanelUsernameValuesView>

                      <PanelAvatarUsername>
                        @{modeleDetails?.useralias || '-'}
                      </PanelAvatarUsername>


                      {modeleDetails?.verified && (
                        <HomeIdentiyIcon
                          onClick={() => {
                            dispatch({ type: USER_VERIFIED_MODAL, payload: true });
                          }}>
                          <IconVerified
                            size={16}
                          />
                        </HomeIdentiyIcon>
                      )}


                      {modeleDetails?.brandPromoter && (
                        <HomeIdentiyIcon
                          onClick={() => {
                            dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: true });
                          }}>
                          <IconVerifiedPromoter
                            size={16}
                          />
                        </HomeIdentiyIcon>
                      )}

                    </PanelUsernameValuesView>



                    <PanelValuesLabel>
                      ID: {modeleDetails?.id || '-'}
                    </PanelValuesLabel>



                    {(
                      modeleDetails?.contacts?.instagram ||
                      modeleDetails?.contacts?.twitter ||
                      modeleDetails?.contacts?.facebook ||
                      modeleDetails?.contacts?.tiktok ||
                      modeleDetails?.contacts?.telegram ||
                      modeleDetails?.contacts?.onlyfans ||
                      modeleDetails?.contacts?.privacy ||
                      modeleDetails?.contacts?.website
                    ) &&
                      (
                        <PanelSocialContent>

                          {modeleDetails?.contacts?.instagram && (
                            <PanelSocialButton
                              onClick={() => {
                                openSite(`http://instagram.com/_u/${modeleDetails?.contacts?.instagram}`);
                              }}>
                              <IconSocialInstagram
                                color={Colors.secondary}
                                size={charIconsSize}
                              />
                            </PanelSocialButton>
                          )}



                          {modeleDetails?.contacts?.twitter && (
                            <PanelSocialButton
                              onClick={() => {
                                openSite(`http://twitter.com/${modeleDetails?.contacts?.twitter}`);
                              }}>
                              <IconSocialTwitter
                                color={Colors.secondary}
                                size={charIconsSize}
                              />
                            </PanelSocialButton>
                          )}



                          {modeleDetails?.contacts?.facebook && (
                            <PanelSocialButton
                              onClick={() => {
                                openSite(`http://facebook.com/${modeleDetails?.contacts?.facebook}`);
                              }}>
                              <IconSocialFacebook
                                color={Colors.secondary}
                                size={charIconsSize}
                              />
                            </PanelSocialButton>
                          )}



                          {modeleDetails?.contacts?.tiktok && (
                            <PanelSocialButton
                              onClick={() => {
                                openSite(`http://tiktok.com/${modeleDetails?.contacts?.tiktok}`);
                              }}>
                              <IconSocialTiktok
                                color={Colors.secondary}
                                size={charIconsSize}
                              />
                            </PanelSocialButton>
                          )}



                          {modeleDetails?.contacts?.telegram && (
                            <PanelSocialButton
                              onClick={() => {
                                openSite(`http://t.me/${modeleDetails?.contacts?.telegram}`);
                              }}>
                              <IconSocialTelegram
                                color={Colors.secondary}
                                size={charIconsSize}
                              />
                            </PanelSocialButton>
                          )}



                          {modeleDetails?.contacts?.onlyfans && (
                            <PanelSocialButton
                              onClick={() => {
                                openSite(`https://onlyfans.com/${modeleDetails?.contacts?.onlyfans}`);
                              }}>
                              <IconSocialOnlyFans
                                color={Colors.secondary}
                                size={charIconsSize}
                              />
                            </PanelSocialButton>
                          )}



                          {modeleDetails?.contacts?.privacy && (
                            <PanelSocialButton
                              onClick={() => {
                                openSite(`https://privacy.com.br/Profile/${modeleDetails?.contacts?.privacy}`);
                              }}>
                              <IconSocialPrivacy
                                color={Colors.secondary}
                                size={charIconsSize}
                              />
                            </PanelSocialButton>
                          )}



                          {modeleDetails?.contacts?.website && (
                            <PanelSocialButton
                              onClick={() => {
                                openSite(modeleDetails?.contacts?.website);
                              }}>
                              <IconSocialWebsite
                                color={Colors.secondary}
                                size={charIconsSize}
                              />
                            </PanelSocialButton>
                          )}

                        </PanelSocialContent>
                      )
                    }



                    {(
                      modeleDetails?.contacts?.linkComLocal
                    ) &&
                      (
                        <PanelSocialContent>

                          {modeleDetails?.contacts?.linkComLocal && (
                            <PanelSocialButton
                              onClick={() => {
                                openSite(modeleDetails?.contacts?.linkComLocal);
                              }}>
                              <IconSocialComLocal
                                color={Colors.secondaryDark}
                                size={charIconsSize}
                              />
                            </PanelSocialButton>
                          )}

                        </PanelSocialContent>
                      )
                    }

                  </PanelAvatarItemInfo>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={'Resumo'}
                    />


                    <PanelResumeContent>
                      <PanelResumeTitle>
                        Localização atual:
                      </PanelResumeTitle>


                      {renderAtualLocation()}
                    </PanelResumeContent>



                    {modeleDetails?.appRentLevel && modeleDetails?.appRentLevel[0] && renderHeaderRent(modeleDetails?.appRentLevel[0]?.level)}

                  </PanelWrapperItemContent>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={'Informações gerais'}
                    />


                    <PanelValuesContainer>

                      <PanelValuesContent>

                        <PanelValuesLabel>
                          ID do membro:
                          {' '}
                          <PanelValuesText>
                            {modeleDetails?.clientNumber || '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          Email cadastrado:
                          {' '}
                          <PanelValuesText>
                            {modeleDetails?.email || '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          Gênero:
                          {' '}
                          <PanelValuesText>
                            {typeGenders?.find((item: IInputSelect) => item.value === modeleDetails?.gender)?.label || '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          Tipo de perfil:
                          {' '}
                          <PanelValuesText>
                            {modeleDetails?.type === EUserType.MODELE
                              ? 'Modelo'
                              : 'Cliente'
                            }
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          Exclusivo:
                          {' '}
                          <PanelValuesText
                            color={modeleDetails?.exclusive ? Colors.secondary : Colors.black}>
                            {modeleDetails?.exclusive ? 'Sim' : 'Não'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          Pornstar:
                          {' '}
                          <PanelValuesText
                            color={modeleDetails?.pornstar ? Colors.secondary : Colors.black}>
                            {modeleDetails?.pornstar ? 'Sim' : 'Não'}
                          </PanelValuesText>
                        </PanelValuesLabel>

                      </PanelValuesContent>



                      <PanelValuesContent>

                        <PanelValuesLabel>
                          Membro desde:
                          {' '}
                          <PanelValuesText>
                            {modeleDetails?.reports?.createdAt ? FormatExtenseDate(modeleDetails?.reports?.createdAt) : '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          Status:
                          {' '}
                          <PanelValuesText>
                            {userStatusArray?.find((item: IInputSelect) => item.value === modeleDetails?.status)?.label || '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          Plano:
                          {' '}
                          <PanelValuesText>
                            {plansFinancialArray?.find((item: IInputSelect) => item.value === modeleDetails?.plan?.planCurrent)?.label || '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        {modeleDetails?.canChangeUseralias && (
                          <PanelValuesLabel>
                            <PanelValuesPublishText
                              color={Colors.attention}>
                              {'Edição de nome de usuário ativo'}
                            </PanelValuesPublishText>
                          </PanelValuesLabel>
                        )}


                        <PanelValuesLabel>
                          <PanelValuesPublishText
                            color={modeleDetails?.plan?.isToPay
                              ? Colors.accept
                              : Colors.attention
                            }>
                            {modeleDetails?.plan?.isToPay
                              ? 'Sem isenção de pagamento'
                              : 'Com isensão de pagamento'
                            }
                          </PanelValuesPublishText>
                        </PanelValuesLabel>



                        <PanelValuesPublishView>
                          <PanelValuesPublishIcon>
                            {modeleDetails?.visible
                              ? (
                                <IconCircleCheck
                                  color={Colors.accept}
                                  size={18}
                                />
                              )
                              : (
                                <IconCircleRemove
                                  color={Colors.attention}
                                  size={18}
                                />
                              )
                            }
                          </PanelValuesPublishIcon>

                          <PanelValuesPublishText
                            color={modeleDetails?.visible ? Colors.accept : Colors.attention}>
                            {modeleDetails?.visible
                              ? 'Perfil visível'
                              : 'Perfil não visível'
                            }
                          </PanelValuesPublishText>
                        </PanelValuesPublishView>



                        <PanelValuesPublishView>
                          <PanelValuesPublishIcon>
                            {validateNumber(modeleDetails?.pendencies) && modeleDetails?.pendencies > 0
                              ? (
                                <IconCircleAttention
                                  color={Colors.attention}
                                  size={18}
                                />
                              )
                              : (
                                <IconCircleCheck
                                  color={Colors.accept}
                                  size={18}
                                />
                              )
                            }
                          </PanelValuesPublishIcon>

                          <PanelValuesPublishText
                            color={validateNumber(modeleDetails?.pendencies) && modeleDetails?.pendencies > 0 ? Colors.attention : Colors.accept}>
                            {renderPendeciesTitle()}
                          </PanelValuesPublishText>
                        </PanelValuesPublishView>
                      </PanelValuesContent>

                    </PanelValuesContainer>

                  </PanelWrapperItemContent>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={'Parâmetros'}
                    />


                    <PanelActiveItemContent>

                      <InputButtonCheckboxStyled
                        title={'Rosto visível'}
                        checked={showFaceStatus}
                        onPress={() => {
                          if (!loading) {
                            saveItemGroup({ showFace: !showFaceStatus });
                          }
                        }}
                      />

                    </PanelActiveItemContent>

                  </PanelWrapperItemContent>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={'Ativar serviços'}
                    />


                    <PanelActiveItemContent>

                      <ButtonPublishStyled
                        disabled={loading || !configModulesParams?.showRent}
                        selected={rentModule}
                        textOn={'Acompanhante ativado'}
                        textOff={'Ativar acompanhante'}
                        helpText={!configModulesParams?.showRent ? 'Módulo está desativado global' : ''}
                        onPress={() => {
                          if (!loading) {
                            saveItemGroup({ rent: !rentModule });
                          }
                        }}
                      />


                      <ButtonPublishStyled
                        disabled={loading || !configModulesParams?.showFanClub}
                        selected={fanClubModule}
                        textOn={'Fan Club ativado'}
                        textOff={'Ativar FanClub'}
                        helpText={!configModulesParams?.showFanClub ? 'Módulo está desativado global' : ''}
                        onPress={() => {
                          if (!loading) {
                            saveItemGroup({ fanClub: !fanClubModule });
                          }
                        }}
                      />


                      <ButtonPublishStyled
                        disabled={loading || !configModulesParams?.showLive}
                        selected={liveModule}
                        textOn={'Live ativado'}
                        textOff={'Ativar Live'}
                        helpText={!configModulesParams?.showLive ? 'Módulo está desativado global' : ''}
                        onPress={() => {
                          if (!loading) {
                            saveItemGroup({ live: !liveModule });
                          }
                        }}
                      />


                      <ButtonPublishStyled
                        disabled={loading || !configModulesParams?.showMassage}
                        selected={massageModule}
                        textOn={'Massagem ativado'}
                        textOff={'Ativar massagem'}
                        helpText={!configModulesParams?.showMassage ? 'Módulo está desativado global' : ''}
                        onPress={() => {
                          if (!loading) {
                            saveItemGroup({ massage: !massageModule });
                          }
                        }}
                      />


                      <ButtonPublishStyled
                        disabled={loading || !configModulesParams?.showStream}
                        selected={streamModule}
                        textOn={'Stream ativado'}
                        textOff={'Ativar stream'}
                        helpText={!configModulesParams?.showStream ? 'Módulo está desativado global' : ''}
                        onPress={() => {
                          if (!loading) {
                            saveItemGroup({ stream: !streamModule });
                          }
                        }}
                      />

                    </PanelActiveItemContent>

                  </PanelWrapperItemContent>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={'Conta e dados'}
                    />


                    <PanelOptionsContainer>

                      {renderPanelButton(
                        !modeleDetails?.clientNumber ? 'Vincular perfil à Conta Global' : 'Visualizar vínculo da Conta Global',
                        <IconGlobalAccount
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          history.push({
                            pathname: NameRoutes.ModeleLinkAccountScreen.replace(
                              ':useralias',
                              userIdSearch,
                            ),
                            state: {
                              edit: true,
                              item: modeleDetails,
                            },
                          });
                        },
                        true,
                        false,
                        modeleDetails?.clientNumber && 'Número de cliente vinculado',
                        Colors.accept,
                      )}


                      {renderPanelButton(
                        modeleDetails?.visible ? 'Desativar perfil' : 'Ativar perfil',
                        modeleDetails?.visible
                          ? (
                            <IconClose
                              color={Colors.black}
                              size={26}
                            />
                          )
                          : (
                            <IconCheck
                              color={Colors.black}
                              size={26}
                            />
                          ),
                        () => {
                          history.push(
                            NameRoutes.ModeleVisibleProfileScreen.replace(
                              ':useralias',
                              userIdSearch,
                            ),
                          );
                        },
                        true,
                      )}


                      {renderPanelButton(
                        'Habilitar edição de nome de usuário',
                        <IconCheck
                          color={Colors.black}
                          size={26}
                        />
                        ,
                        () => {
                          setShowUseraliasModal(true);
                        },
                        true,
                        !modeleDetails?.clientNumber || modeleDetails?.canChangeUseralias,
                        (!modeleDetails?.clientNumber && 'Necessário número de cliente vinculado') || (modeleDetails?.canChangeUseralias && 'Edição ativado'),
                        modeleDetails?.canChangeUseralias ? Colors.accept : Colors.danger,
                      )}

                    </PanelOptionsContainer>

                  </PanelWrapperItemContent>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={'Perfil'}
                    />


                    <PanelOptionsContainer>

                      {renderPanelButton(
                        'Editar dados principal',
                        <IconProfile
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          history.push({
                            pathname: NameRoutes.CreateModeleFormScreen,
                            state: {
                              edit: true,
                              item: modeleDetails,
                            },
                          });
                        },
                        true,
                      )}


                      {renderPanelButton(
                        'Editar características',
                        <IconCharacteristics
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          history.push({
                            pathname: NameRoutes.EditParamsModeleFormScreen.replace(
                              ':useralias',
                              userIdSearch,
                            ),
                            state: {
                              item: modeleDetails,
                            },
                          });
                        },
                        true,
                      )}


                      {/* TODO: Colocar uma porcentagem ate atingir 100% dos serviços ativos e ai poder publicar */}
                      {/* Para ser visível em buscas e listagens, você precisa completar todos os campos do seu perfil. */}


                    </PanelOptionsContainer>

                  </PanelWrapperItemContent>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={'Serviços'}
                    />


                    <PanelOptionsContainer>

                      <PanelUpgradeContainer>
                        {renderPanelButton(
                          'Upgrade de perfil',
                          <IconUpgrade
                            color={Colors.black}
                            size={26}
                          />,
                          () => {
                            // history.push(NameRoutes.UpgradeModeleShowScreen);
                          },
                          true,
                        )}
                      </PanelUpgradeContainer>


                      {renderPanelButton(
                        'Perfil acompanhante',
                        <IconRentOn
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          history.push({
                            pathname: NameRoutes.RentProfileModeleScreen.replace(
                              ':useralias',
                              userIdSearch,
                            ),
                            state: {
                              item: modeleDetails,
                            },
                          });
                        },
                        true,
                        !rentModule,
                        !rentModule && 'Módulo está desativado global',
                      )}


                      {renderPanelButton(
                        'Fã-clube',
                        <IconFaClub
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          // history.push(NameRoutes.PeopleClubListScreen);
                        },
                        true,
                        !fanClubModule,
                        !fanClubModule && 'Módulo está desativado global',
                      )}


                      {renderPanelButton(
                        'Live',
                        <IconLive
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          // history.push(NameRoutes.ModeleLiveOptionsScreen);
                        },
                        true,
                        !liveModule,
                        !liveModule && 'Módulo está desativado global',
                      )}


                      {renderPanelButton(
                        'Massagem',
                        <IconMassageOn
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          // history.push(NameRoutes.ProfileRentModeleScreen);
                        },
                        true,
                        !massageModule,
                        !massageModule && 'Módulo está desativado global',
                      )}

                    </PanelOptionsContainer>

                  </PanelWrapperItemContent>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={'Mídias'}
                    />


                    <PanelOptionsContainer>

                      {renderPanelButton(
                        'Mídias',
                        <IconMediaComplete
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          history.push(
                            NameRoutes.ModeleMediaAddScreen.replace(
                              ':useralias',
                              userIdSearch,
                            ),
                          );
                        },
                      )}



                      {renderPanelButton(
                        'Adicionar mídias em lote na galeria',
                        <IconMediaLot
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          history.push(
                            NameRoutes.ModeleMediasLotScreen.replace(
                              ':useralias',
                              userIdSearch,
                            ),
                          );
                        },
                      )}


                      {renderPanelButton(
                        'Verificaçao anti-fake',
                        <IconAntiFake
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          // history.push(NameRoutes.AntiFakeVerifiedScreen);
                        },
                      )}

                    </PanelOptionsContainer>

                  </PanelWrapperItemContent>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={'Informações'}
                    />


                    <PanelOptionsContainer>

                      {renderLocation()}


                      {renderTravels()}


                      {renderPanelButton(
                        'Contatos / Disponibilidade',
                        <IconPhone
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          history.push({
                            pathname: NameRoutes.ContactsModeleScreen.replace(
                              ':useralias',
                              userIdSearch,
                            ),
                            state: {
                              item: modeleDetails,
                            },
                          });
                        },
                      )}


                      {renderPanelButton(
                        'Avaliações',
                        <CommentsCountContainer>
                          <CommentsCountIcon>
                            <IconStar
                              color={Colors.secondaryDark}
                              size={26}
                            />
                          </CommentsCountIcon>


                          <CommentsCountText>
                            {modeleDetails?.appReviewsAverage}
                          </CommentsCountText>
                        </CommentsCountContainer>,
                        () => {
                          history.push(
                            NameRoutes.ReviewModeleScreen.replace(
                              ':useralias',
                              userIdSearch,
                            ),
                          );
                        },
                      )}

                    </PanelOptionsContainer>

                  </PanelWrapperItemContent>



                  <PanelWrapperLastItemContent>

                    <SectionTitle
                      title={'Informações'}
                    />


                    <PanelOptionsContainer>


                      {renderPanelButton(
                        'Visibiliidade e privacidade',
                        <IconVisibility
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          history.push(
                            NameRoutes.VisibilityModeleScreen.replace(
                              ':useralias',
                              userIdSearch,
                            ),
                          );
                        },
                      )}


                      {renderPanelButton(
                        'Dados do perfil profissional',
                        <IconUser
                          color={Colors.black}
                          size={26}
                        />,
                        () => {
                          history.push(
                            NameRoutes.ModeleDataProfileScreen.replace(
                              ':useralias',
                              userIdSearch,
                            ),
                          );
                        },
                      )}

                    </PanelOptionsContainer>

                  </PanelWrapperLastItemContent>

                </PanelPadderContent>

              </PanelContentCenterFlex>



              <FinancialRightSuggestion>

                <FinancialAdsImageButton
                  backgroundColor={Colors.formBackground}
                  borderRadius={Sizes.cardRadius}>

                  <p>
                    Dados de financeiro, galeria privada...
                  </p>

                </FinancialAdsImageButton>

              </FinancialRightSuggestion>

            </ContentRowToColumnPadder>
          )}



          <FooterApp />

        </Content>

      </Container>



      <AlertMessage
        visible={showUseraliasModal}
        title={'Deseja ativar a edição do nome de usuário?'}
        cancelText={'Não'}
        cancelPress={() => {
          setShowUseraliasModal(false);
        }}
        okText={'Sim'}
        okPress={() => {
          setUseraliasTrueUser();
        }}
      />

    </Screen>

  );
};



export default PanelModeleScreen;
