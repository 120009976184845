import IAppRoute from '../../apis/interfaces/App/app-route.interface';
import HelpFaqCategoriesShowScreen from '../../screens/Help/FAQ/Categories/ShowAll';
import HelpFaqQuestionsShowScreen from '../../screens/Help/FAQ/Questions/ShowAll';
import HelpRequestsShowScreen from '../../screens/Help/Requests';

import HomeScreen from '../../screens/Home';
import SocialScreen from '../../screens/Marketing/Social';
import WhatsAppAdsScreen from '../../screens/Marketing/WhatsAppAds';
import ModelesAllListScreen from '../../screens/Modeles/AllModeles';
import ModelesToPublishScreen from '../../screens/Modeles/Publish';
import ProjectMessagesFormScreen from '../../screens/Project/Messages/List';
import ProjectParamsFormScreen from '../../screens/Project/Params';

import PlansPricesShowAllScreen from '../../screens/Project/Plans/ShowAll';
import AppDashboardScreen from '../../screens/Secretio/Dashboard';
import GendersHighlightsScreen from '../../screens/Secretio/GendersHighlights';
import AntiFakeAllScreen from '../../screens/Secretio/Publish/AntiFake';
import DeletedMediasScreen from '../../screens/Secretio/Publish/DeletedMedias';
import PicturesAproveAllScreen from '../../screens/Secretio/Publish/Images';
import ProfileMediasApproveScreen from '../../screens/Secretio/Publish/ProfileMedias';
import AvaliationsToReviseScreen from '../../screens/Secretio/Publish/Reviews';
import VideosAproveAllScreen from '../../screens/Secretio/Publish/Videos';
import RankingsScreen from '../../screens/Secretio/Rankings';

import RentServicesScreen from '../../screens/Secretio/RentService/ShowAll';
import ShopDashboardScreen from '../../screens/Shop/Dashboard';
import ShopProductsScreen from '../../screens/Shop/Products';

import CategoriesShowAllScreen from '../../screens/Stream/Categories/ShowAll';
import SubCategoriesShowAllScreen from '../../screens/Stream/CategoriesSub/ShowAll';
import ChannelsShowAllScreen from '../../screens/Stream/Channels/ShowAll';
import StarsShowAllScreen from '../../screens/Stream/Stars/ShowAll';
import VideosShowAllScreen from '../../screens/Stream/Videos/ShowAll';
import StreamVideosAdsScreen from '../../screens/Stream/VideosAds';


import NameRoutes from '../names';



const DrawerRoutes: IAppRoute[] = [

  // Highlights

  {
    path: NameRoutes.HomeScreen,
    page: HomeScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.AppDashboardScreen,
    page: AppDashboardScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.RankingsScreen,
    page: RankingsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ShopDashboardScreen,
    page: ShopDashboardScreen,
    isPublic: false,
    exact: true,
  },



  // Project

  {
    path: NameRoutes.ProjectParamsFormScreen,
    page: ProjectParamsFormScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ProjectMessagesFormScreen,
    page: ProjectMessagesFormScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.PlansPricesShowAllScreen,
    page: PlansPricesShowAllScreen,
    isPublic: false,
    exact: true,
  },



  // Profiles

  {
    path: NameRoutes.ModelesAllListScreen,
    page: ModelesAllListScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ModelesToPublishScreen,
    page: ModelesToPublishScreen,
    isPublic: false,
    exact: true,
  },



  // App

  {
    path: NameRoutes.GendersHighlightsScreen,
    page: GendersHighlightsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.RentServicesScreen,
    page: RentServicesScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.AvaliationsToReviseScreen,
    page: AvaliationsToReviseScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ProfileMediasApproveScreen,
    page: ProfileMediasApproveScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.PicturesAproveAllScreen,
    page: PicturesAproveAllScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.VideosAproveAllScreen,
    page: VideosAproveAllScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.AntiFakeAllScreen,
    page: AntiFakeAllScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.DeletedMediasScreen,
    page: DeletedMediasScreen,
    isPublic: false,
    exact: true,
  },



  // Streams

  {
    path: NameRoutes.CategoriesShowAllScreen,
    page: CategoriesShowAllScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.SubCategoriesShowAllScreen,
    page: SubCategoriesShowAllScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ChannelsShowAllScreen,
    page: ChannelsShowAllScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.StarsShowAllScreen,
    page: StarsShowAllScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.VideosShowAllScreen,
    page: VideosShowAllScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.StreamVideosAdsScreen,
    page: StreamVideosAdsScreen,
    isPublic: false,
    exact: true,
  },



  // Shop

  {
    path: NameRoutes.ShopProductsScreen,
    page: ShopProductsScreen,
    isPublic: false,
    exact: true,
  },



  // Marketing

  {
    path: NameRoutes.SocialScreen,
    page: SocialScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.WhatsAppAdsScreen,
    page: WhatsAppAdsScreen,
    isPublic: false,
    exact: true,
  },



  // Help

  {
    path: NameRoutes.HelpFaqCategoriesShowScreen,
    page: HelpFaqCategoriesShowScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.HelpFaqQuestionsShowScreen,
    page: HelpFaqQuestionsShowScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.HelpRequestsShowScreen,
    page: HelpRequestsShowScreen,
    isPublic: false,
    exact: true,
  },

];



export default DrawerRoutes;
