import styled,
{
  css,
} from 'styled-components';

import ToggleGrid from '../../../../../../components/Buttons/ToggleGrid';

import {
  Card,
} from '../../../../../../components/Composh/web';

import InputBoolean from '../../../../../../components/Inputs/InputBoolean';

import {
  Sizes,
} from '../../../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../../../shared/utils/platforms.utils';



export const CardRentForm = styled(Card.View)`
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingFlatList}px;
  padding-right: ${Sizes.paddingFlatList}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;

export const WrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

export const ToggleModeleEdit = styled(ToggleGrid)`
  display: flex;
  flex-direction: column;
  height: 95px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 10px;

  @media ${PlatformDevices.maxMobileS} {
    width: calc(100% - 10px);
  }

  @media ${PlatformDevices.minMobileS} {
    width: calc(50% - 10px);
  }

  @media ${PlatformDevices.minMobileL} {
    width: calc(calc(100% / 3) - 10px);
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(25% - 10px);
  }

  @media ${PlatformDevices.minTabletM} {
    width: calc(calc(100% / 6) - 10px);
  }
`;


export const ModeleEditInputContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
`;



export const InputResponse = css`
  @media ${PlatformDevices.maxMobileM} {
    width: 100%;
  }

  @media ${PlatformDevices.minMobileM} {
    width: calc(50% - 10px);
  }

  @media ${PlatformDevices.minTablet} {
    width: calc(calc(100% / 3) - 10px);
  }

  @media ${PlatformDevices.minTabletM} {
    width: calc(25% - 10px);
  }
`;

export const InputBooleanStyle = css`
  width: calc(15% - 10px); 
  margin-left: 5px;
  margin-right: 5px;
`;

export const InputBooleanStyled = styled(InputBoolean)`
  ${InputBooleanStyle};
  ${InputResponse};
`;
