import React from 'react';

import ListTablePaginator from '../../Composh/web/ListTable/Paginator';
import PaginationTotal from '../../Composh/web/ListTable/TotalStandalone';

import {
  TableControlContainer,
  TableControlsView,
  TableButtonControl,
  TableSpanControl,
  TableFooterControlContainer,
  TablePaginationHeaderView,
} from './styled';



export interface IProps {
  color?: string;
  accentColor?: string;
  bottomBorder?: boolean;
  sizePerPage?: Array<number>;
  sizeSelected?: any;
  options?: any;
  onPress?: any;

  maxPagesToShow?: number;
  pageCurrent?: number | string;
  pagesTotal?: number | string;
  pageSelected?: (page: string) => void;
}



const TableHeader: React.FC<IProps> = (props: IProps) => {
  const tableNormalColor = props.color;
  const tableAccentColor = props.accentColor;

  const newPageCurrent = Number(props.pageCurrent);
  const newPagesTotal = Number(props.pagesTotal);



  return (

    <TableControlContainer
      bottomBorder={props.bottomBorder}>

      <TableControlsView>
        {props.sizePerPage && props.sizePerPage?.length > 0 && props.sizePerPage.map((quantity: any) => {
          const isSelect = quantity === props.sizeSelected;

          return (
            <TableButtonControl
              key={quantity}
              type={'button'}
              selected={isSelect}
              color={tableNormalColor}
              accentColor={tableAccentColor}
              onClick={() => {
                if (props.onPress) {
                  props.onPress(quantity);
                }
              }}>

              <TableSpanControl
                selected={isSelect}
                color={tableNormalColor}
                accentColor={tableAccentColor}>
                {quantity}
              </TableSpanControl>

            </TableButtonControl>
          );
        })}



        <TableFooterControlContainer>
          <PaginationTotal
            {...props.options}
          />
        </TableFooterControlContainer>

      </TableControlsView>



      <TablePaginationHeaderView>
        {newPageCurrent > 0 && newPagesTotal > 0 && (
          <ListTablePaginator
            {...props}
            color={tableNormalColor}
            accentColor={tableAccentColor}
            maxPagesToShow={props.maxPagesToShow}
            pageCurrent={props.pageCurrent}
            pagesTotal={props.pagesTotal}
            pageSelected={(sizeString) => {
              if (props.pageSelected) {
                props.pageSelected(sizeString);
              }
            }}
          />
        )}
      </TablePaginationHeaderView>

    </TableControlContainer>

  );
};



export default TableHeader;
