import React from 'react';

import {
  useSelector,
} from 'react-redux';

import Color from 'color';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import {
  RootState,
} from '../../../redux';

import { picureVisibilityGenders } from '../../../shared/arrays/Picture/visibility.array';

import {
  Colors,
  Images,
  Sizes,
} from '../../../shared/constants';

import {
  EPlansUser,
} from '../../../shared/enums';

import EStatusVisible from '../../../shared/enums/Status/status-visibility.enum';

import {
  validateString,
} from '../../../shared/utils/string.utils';

import UserHeaderInfo from '../../Details/UserHeaderInfo';

import {
  IconArchiveOff,
  IconArchiveOn,
  IconCheck,
  IconClose,
  IconDelete,
  IconEdit,
  IconInformation,
  IconMoney,
} from '../../Icons';

import {
  ImageBoxContainer,
  ImagePublishAllContainer,
  ImageBoxContent,
  LazyImage,
  RibbonPhotoTypeMedia,
  RibbonPending,
  CanBuyRibbon,
  ImageActionContent,
  ImageActionButton,
  ReviewActionsContainer,
  ReviewActionsButton,
  ReviewActionsIcon,
  ReviewActionsText,
} from './styled';



export interface IProps {
  type: 'PUBLISH' | 'MODELE' | 'DELETED';

  image?: string;
  typeRelation?: string;
  visible?: EStatusVisible;
  visibility?: string;
  revised?: boolean;
  canBuy?: boolean;

  modeleName?: string;
  modeleUsername?: string;
  modelePlan?: EPlansUser;
  modeleImage?: string;
  verified?: boolean;
  brandPromoter?: boolean;

  onUserPress?: any;
  onArchivePress?: any;
  onVisibleOrRevisedPress?: any;
  onEditPress?: any;
  onDeletePress?: any;
}



const CardPublishImage: React.FC<IProps> = (props: IProps) => {
  const colorRevisedIcon = props.revised ? Color(Colors.accept).darken(0.5).toString() : Colors.secondaryDark;
  const sizeIcon = 18;

  const userImage = validateString(props.image) || Images.noImage;


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  function renderEditComments(enabled: boolean, color: string, title: string, icon: any, onPress: any) {
    return (

      <ReviewActionsButton
        disabled={!enabled}
        onClick={() => {
          if (enabled && onPress) {
            onPress();
          }
        }}>

        <ReviewActionsIcon>
          {icon}
        </ReviewActionsIcon>


        <ReviewActionsText
          color={color}>
          {title}
        </ReviewActionsText>

      </ReviewActionsButton>

    );
  }


  function renderStatusArchive() {
    if (props.visible === EStatusVisible.VISIBLE) {
      return {
        icon: (
          <IconArchiveOn
            color={Colors.attentionDark}
            size={sizeIcon}
          />
        ),
        color: Colors.attentionDark,
        text: 'Arquivar',
      };
    }

    if (props.visible === EStatusVisible.INVISIBLE || props.visible === EStatusVisible.ARCHIVED) {
      return {
        icon: (
          <IconArchiveOff
            color={Colors.secondaryDark}
            size={sizeIcon}
          />
        ),
        color: Colors.secondaryDark,
        text: 'Desarquivar',
      };
    }

    return {
      icon: (
        <IconInformation
          color={Colors.gray}
          size={sizeIcon}
        />
      ),
      color: Colors.gray,
      text: 'Ação',
    };
  }



  return (

    <ImageBoxContainer>
      <ImagePublishAllContainer
        backgroundColor={Colors.cardBackground}
        borderRadius={Sizes.cardRadius}>

        <ImageBoxContent>

          <LazyImage
            src={userImage}
          />



          <RibbonPhotoTypeMedia>
            {picureVisibilityGenders?.find((item: IInputSelect) => item.value === props.visibility)?.label || '-'}
          </RibbonPhotoTypeMedia>



          {props.type === 'MODELE' && !props.revised && (
            <RibbonPending>
              Pendente de aprovação
            </RibbonPending>
          )}



          {props.canBuy && (
            <CanBuyRibbon>
              <IconMoney
                color={Colors.white}
                size={18}
              />
            </CanBuyRibbon>
          )}



          {props.visible !== EStatusVisible.DELETED && (
            <ImageActionContent>

              {projectParams?.showFanClub && props.type === 'MODELE' && (
                <ImageActionButton
                  onClick={() => {
                    if (props.onEditPress) {
                      props.onEditPress();
                    }
                  }}>
                  <IconEdit
                    color={Colors.attention}
                    size={18}
                  />
                </ImageActionButton>
              )}



              <ImageActionButton
                onClick={() => {
                  if (props.onDeletePress) {
                    props.onDeletePress();
                  }
                }}>
                <IconDelete
                  color={Colors.danger}
                  size={18}
                />
              </ImageActionButton>

            </ImageActionContent>
          )}

        </ImageBoxContent>



        {props.modeleName && props.modeleImage && props.modeleUsername && (
          <UserHeaderInfo
            modeleName={props.modeleName}
            modeleUsername={props.modeleUsername}
            modelePlan={props.modelePlan}
            modeleImage={props.modeleImage}
            verified={props.verified}
            brandPromoter={props.brandPromoter}
            onUserPress={() => {
              if (props.onUserPress) {
                props.onUserPress();
              }
            }}
          />
        )}



        <ReviewActionsContainer>

          {props.visible !== EStatusVisible.HIDE_BY_USER && props.visible !== EStatusVisible.DELETED && renderEditComments(
            true,
            renderStatusArchive()?.color,
            renderStatusArchive()?.text,
            renderStatusArchive()?.icon,
            () => {
              if (props.visible !== EStatusVisible.HIDE_BY_USER && props.onArchivePress) {
                props.onArchivePress();
              }
            },
          )}


          {props.visible !== EStatusVisible.DELETED && renderEditComments(
            true,
            colorRevisedIcon,
            props.revised
              ? 'Desrevisar'
              : 'Revisar item',
            props.revised
              ? (
                <IconClose
                  color={colorRevisedIcon}
                  size={sizeIcon}
                />
              )
              : (
                <IconCheck
                  color={colorRevisedIcon}
                  size={sizeIcon}
                />
              ),
            () => {
              if (props.onVisibleOrRevisedPress) {
                props.onVisibleOrRevisedPress();
              }
            },
          )}


          {props.type !== 'DELETED' && props.visible === EStatusVisible.DELETED && renderEditComments(
            false,
            Colors.black,
            'Mídia deletada',
            <IconDelete
              color={Colors.black}
              size={sizeIcon}
            />,
            null,
          )}


          {props.type === 'DELETED' && renderEditComments(
            true,
            Colors.danger,
            'Deletar completamente',
            <IconDelete
              color={Colors.danger}
              size={sizeIcon}
            />,
            () => {
              if (props.onDeletePress) {
                props.onDeletePress();
              }
            },
          )}

        </ReviewActionsContainer>

      </ImagePublishAllContainer>
    </ImageBoxContainer>

  );
};



export default CardPublishImage;
