import React,
{
  useEffect,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  BrowserRouter,
} from 'react-router-dom';

import {
  Loader,
  SnackBar,
} from '../components/Composh/web';

import LoadingProgress from '../components/Loadings/LoadingProgress';

import {
  RootState,
} from '../redux';

import {
  APP_RESET_APP,
  APP_SNACKBAR_RESET,
} from '../redux/reducers/app-modals.store';

import SplashScreen from '../screens/Splash';

import {
  Colors,
} from '../shared/constants';

import AppRoutes from './routes/app.routes';

import {
  LoadingProgressStyled,
} from './styled';



const Routes: React.FC = () => {
  const dispatch = useDispatch();


  const modalLoadingState = useSelector((state: RootState) => state.appModals.modalLoading);
  const snackbarState = useSelector((state: RootState) => state.appModals.snackBar);



  useEffect(() => {
    dispatch({ type: APP_RESET_APP });
  }, []);


  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: APP_SNACKBAR_RESET });
    }, 1800);
  }, [snackbarState.visible]);



  return (

    <BrowserRouter>

      <SplashScreen>

        <AppRoutes />



        <SnackBar
          visible={snackbarState.visible}
          backgroundColor={snackbarState.color}
          textMessage={snackbarState.text}
        />



        <Loader
          visible={modalLoadingState}
          overlayBackground={Colors.alertOverlay}>

          <LoadingProgressStyled>
            <LoadingProgress
              backgroundColor={Colors.black}
              color={Colors.white}
            />
          </LoadingProgressStyled>

        </Loader>

      </SplashScreen>

    </BrowserRouter>

  );
};



export default Routes;
