import * as Yup from 'yup';

import {
  Values,
} from '../../constants';



const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('O Usuário é obrigatório')
    .email('Digite um E-mail Váildo')
    // .usernameValidation('Digite um Nome de Usuário Válido')
    .min(Values.emailMinCount, `Precisa conter pelo menos ${Values.emailMinCount} caracteres`),

  password: Yup.string()
    .required('A Senha é obrigatório')
    .min(Values.passwordMinCount, `Precisa conter pelo menos ${Values.passwordMinCount} caracteres`),
});



export default LoginValidationSchema;
