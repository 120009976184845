import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const PictureOrderView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 160px;
`;


export const PictureContentView = styled.label`
  width: 100%;
  height: 100%;
`;


export const CardImportInput = styled.input`
  display: none;
  width: 100%;
  height: 100%;
`;



export const PictureOrderButton = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  height: 100%;
  width: 100%;
  margin-top: 3px;

  background-color: ${Colors.sketch};
`;


export const PictureOrderImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
`;


export const CarnivalCabinOverflow = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  min-height: 110%;
  margin-bottom: -10px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;
