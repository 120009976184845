import styled from 'styled-components';

import {
  ListFlat,
} from '../../../../components/Composh/web';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export const TitleModeleReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 45px;
  margin-bottom: 15px;
  background-color: ${Colors.cardBackground};
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  border-radius: ${Sizes.cardRadius}px;
`;

export const TitleModeleReviewLengthView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const TitleModeleReviewLengthText = styled.p`
  color: ${Colors.black};
  font-size: 20px;

  @media ${PlatformDevices.maxTablet} {
    margin-bottom: 10px;
  }
`;

export const TitleModeleReviewLengthHelpText = styled.p`
  margin-top: 4px;
  color: ${Colors.gray};
  font-size: 15px;
`;

export const TitleModeleReviewAverageText = styled.p`
  margin-top: 7px;
  color: ${Colors.gray};
  font-size: 13px;
`;



export const ButtonAutomaticPublishStyled = styled.p`
  color: ${Colors.secondaryDark};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
`;


export const ModeleReviewScrollFlatList = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
