import {
  EAppTranslations,
  EAppTutorialTranslations,
  EButtonsTranslations,
  ECompanyTypesTranslations,
  ECountriesTranslations,
  EDrinksTranslations,
  EEventsTranslations,
  EInterpolatesTranslations,
  EKitchensTranslations,
  ELabelsTranslations,
  ELanguagesTranslations,
  EMenuOrderTranslations,
  EMessagesEmptysTranslations,
  EMessagesErrosTranslations,
  EMessagesHelpsTranslations,
  EMessagesQuestionsTranslations,
  EMessagesSuccessTranslations,
  EMusicsTranslations,
  EPaymentsTranslations,
  EReservesTranslations,
  ESocialsTranslations,
  ETimeTranslations,
  ETitlesTranslations,
  ESubtitlesTranslations,
  EValidationsTranslations,
  ECarnivalTranslations,
} from './translates.keys';



export const APP: Record<EAppTranslations, string> = {
  NAME_APP: 'Secretio Support',
  SHORTNAME_APP: 'Support',
  SLOGAN_APP: 'Here are the best reasons to enjoy the night!',
  COMPANY_NAME: 'Secretio',
  COMPANY_APP: 'To enjoy the night!',
};


export const APP_TUTORIAL: Record<EAppTutorialTranslations, string> = {
  TUTORIAL_TITLE_1: 'Fun',
  TUTORIAL_TEXT_1: 'The easiest way to go to parties, nightclubs, pubs, or your favorite bars with your friends!',
  TUTORIAL_TITLE_2: 'Convenience',
  TUTORIAL_TEXT_2: 'The best tool to find where your next Secretio will be!',
  TUTORIAL_TITLE_3: 'Prices',
  TUTORIAL_TEXT_3: 'Discounts and promotions throughout your Secretio!',
  TUTORIAL_TITLE_4: 'Ready to enjoy the night?',
};


export const BUTTONS: Record<EButtonsTranslations, string> = {
  ACTION_ACCESS: 'Log In',
  ACTION_ACCESS_TO_CONTINUE: 'Log In to Continue',
  ACTION_REGISTER: 'Register',
  ACTION_REGISTER_USER: 'Register User',
  ACTION_REGISTER_COMPANY: 'Register Company',
  ACTION_REGISTER_TO_CONTINUE: 'Register to Continue',
  ACTION_CANCEL: 'Cancel',
  ACTION_CONFIRM: 'Confirm',
  ACTION_FORWARD: 'Forward',
  ACTION_BACK: 'Back',
  ACTION_CLOSE: 'Close',
  ACTION_ASK_CHECKIN: 'Request Check-in',
  ACTION_CANCEL_CHECKIN: 'Cancel Check-in',
  ACTION_CHECKIN_MANUAL: 'Manual Check-in',
  ACTION_CLICK_OPEN: 'Click to Open',
  ACTION_CLICK_CLOSE: 'Click to Close',
  ACTION_CLICK_CANCEL: 'Click to Cancel',
  ACTION_MORE_DETAILS: 'Click for More Details',
  ACTION_LISTS_SEE: 'See Lists',
  ACTION_SEE_MENUSHEET: 'See Menu',
  ACTION_PAY: 'Pay',
  ACTION_PAY_APP: 'Online Payment',
  ACTION_PAY_PRESENTIAL: 'In-person Payment',
  ACTION_PAY_ORDERSHEET: 'Pay Order',
  ACTION_ADD_MORE_ITEMS: 'Add More Items',
  ACTION_BAG_SEE: 'See Bag',
  ACTION_BAG_CLEAN: 'Empty Bag',
  ACTION_FINISH_ORDER: 'Finish Order',
  ACTION_CLOSE_ORDERSHEET: 'Close Order',
  ACTION_SEE_WIFI: 'See Wi-Fi',
  ACTION_NO: 'No',
  ACTION_YES: 'Yes',
  ACTION_SEE_HISTORY: 'See Full History',
  ACTION_HISTORY: 'Full History',
  ACTION_SAVE: 'Save',
  ACTION_OPEN_LIST: 'Click to Open List',
  ACTION_OPEN_GALLERY: 'Click to View Gallery',
  ACTION_TRANSFER_ADD: 'Add Account for Transfer',
  ACTION_COMPANY_ADD: 'Add Company',
  ACTION_USER_DISABLE: 'Disable Profile',
  ACTION_RECOVER_ACCESS: 'Recover Access',
  ACTION_CARD_ADD: 'Add Card',
  ACTION_ADDRESS_REGISTER: 'Register Address',
  ACTION_CLICK_EDIT: 'Click to Edit',
  ACTION_LOCATION_ENABLED: 'Enable Location',
  ACTION_INVITE_LINK: 'Invite by Link',
  ACTION_ADD_PERSON: 'Add Person',
  ACTION_ADD_PEOPLE: 'Add People',
  ACTION_ADD_MANUAL: 'Add Manually',
  ACTION_LIST_PARTICIPATE: 'Participate in List',
  ACTION_LIST_LEAVE: 'Leave List',
  ACTION_TICKET_SEE: 'See Tickets',
  ACTION_COPY_PASSWORD: 'Click to Copy Password',
  ACTION_COPY_LINK: 'Copy link',
  ACTION_SEE_DATES: 'See More Dates',
  ACTION_DO_LOGIN: 'Log In',
  ACTION_TO_RESERVE: 'Reserve',
  ACTION_CANCEL_RESERVE: 'Cancel Reservation',
  ACTION_SEARCH: 'Search',
  ACTION_RESEARCH: 'Redo Search',
  ACTION_ADDRESS_SEE_YOURS: 'Click to View Your Addresses',
  ACTION_ADDRESS_SEE: 'See Addresses',
  ACTION_PAY_FORMS_SEE: 'See Payment Methods',
  ACTION_OPEN_WAZE: 'Open with Waze',
  ACTION_OPEN_UBER: 'Open with Uber',
  ACTION_HOUR_SEE: 'Click to View Hours',
  ACTION_CLICK_TO_MANAGE: 'Click to Manage',
  ACTION_LOCATION_MY: 'Use My Current Location',
  ACTION_PROFILE_SEE: 'View Profile',
  ACTION_CLICK_TO_ADD: 'Click to Add',
  ACTION_CLICK_TO_EDIT: 'Click to Edit',
  ACTION_TO_SEE_PROFILE: 'Click to View Your Profile',
  ACTION_SKIP: 'Skip',
  ACTION_COME_ON: 'Let\'s Go!',
  ACTION_PROFILE_MANAGE: 'Click to request this profile',
  ACTION_CHECK_ALL: 'Mark all',
  ACTION_UNCHECK_ALL: 'Unmark all',
  ACTION_JOIN_VIA_LIST: 'Join via Lists',
  ACTION_SEE_AVAILABLES_TICKETS: 'See available tickets',
  ACTION_ENTRANCE_IN_EVENT: 'Attend the event',
};


export const COMPANY_TYPES: Record<ECompanyTypesTranslations, string> = {
  EVENT_PRODUCER: 'Event Producer',
  EVENT_PRODUCERS: 'Event Producers',
  BAR: 'Bar',
  BARS: 'Bars',
  PUB: 'Pub',
  PUBS: 'Pubs',
  NIGHT_CLUB: 'Night Club',
  NIGHT_CLUBS: 'Night Clubs',
  RESTAURANT: 'Restaurant',
  RESTAURANTS: 'Restaurants',
  PIZZERIA: 'Pizzeria',
  PIZZERIAS: 'Pizzerias',
  STEAKHOUSE: 'Steakhouse',
  STEAKHOUSES: 'Steakhouses',
  HAMBURGUER: 'Hamburger Joint',
  HAMBURGUERS: 'Hamburger Joints',
  COFFEE_SHOP: 'Coffee Shop',
  COFFEE_SHOPS: 'Coffee Shops',
  CARNIVAL_PRODUCER: 'Carnival Producer',
  CARNIVAL_PRODUCERS: 'Carnival Producers',
  PARTY_PRODUCER: 'Festival Producer',
  PARTY_PRODUCERS: 'Festival Producers',
  GOVERN: 'Government',
  GOVERNS: 'Governments',
};


export const CARNIVALS: Record<ECarnivalTranslations, string> = {
  CARNIVAL_VOTES: 'Carnival Votes',
  CARNIVAL_INTEREST_POINTS: 'Points of Interest',
  CARNIVAL_OFFICIAL: 'Official Carnival',
  CARNIVAL_MICARETA: 'Micareta',
  CIRCUIT_OFFICIAL: 'Official Circuit',
  BLOC: 'Block',
  CIRCUIT: 'Circuit',
  CIRCUITS: 'Circuits',
  CABIN: 'Cabin',
  CABINS: 'Cabins',
  POLICE: 'Police',
  BUS: 'Bus',
  TAXI: 'Taxi',
  BATHROOM: 'Bathroom',
  FIREMAN: 'Fireman',
  SAVE_LIFE: 'Lifeguard',
  MEDICAL_POINT: 'Medical Point',
  JUVENILE: 'Juvenile Organization',
  OTHER_ATTRACTION: 'Other Attractions',
  AGENCY_FLOW: 'Flow Agents',
  AGENCY_PUBLIC: 'Public Agencies',
  CLEAN_HEALTH: 'Health and Cleanliness',
  AREA_RESERVED: 'Reserved Areas',
};


export const COUNTRIES: Record<ECountriesTranslations, string> = {
  BRAZIL: 'Brazil',
  PORTUGAL: 'Portugal',
  UNITED_STATES: 'United states',
  SPAIN: 'Spain',
  ITALY: 'Italy',
  FANCE: 'France',
};


export const DRINKS: Record<EDrinksTranslations, string> = {
  WATER: 'Water',
  SODA: 'Soda',
  VODKA: 'Vodka',
  BEER: 'Beer',
  WINE: 'Wine',
  TEA: 'Tea',
  COFFEE: 'Coffee',
  JUICE: 'Juice',
  WHISKY: 'Whisky',
  LIQUOR: 'Liquor',
  COCKTAIL: 'Cocktail',
  CHAMPAGNE: 'Champagne',
  GIN: 'Gin',
  RUM: 'Rum',
  ENERGY_DRINK: 'Energy Drink',
  SMOOTHIE: 'Smoothie',
  YERBA_MATE: 'Yerba Mate',
  SAKE: 'Sake',
  TEQUILA: 'Tequila',
};


export const EVENTS: Record<EEventsTranslations, string> = {
  EVENT_TYPE: 'Event type',
  EVENT_IDEAL_LGBT: 'Ideal for LGBTQIA+ audience',
  EVENT_ABOUT: 'About the event',
  EVENT_ATTRACTIONS: 'Attractions',
  EVENT_START: 'Event start',
  EVENT_END: 'Event end',
  EVENT_OPENBAR: 'Drinks at OpenBar',
  EVENT_PARENTAL_RATING: 'Parental rating',
  EVENT_PARENTAL_NONE: 'No rating',
  EVENT_PARENTAL_14: 'For ages 14 and up',
  EVENT_PARENTAL_16: 'For ages 16 and up',
  EVENT_PARENTAL_18: 'For ages 18 and up',
  EVENT_LIST_AVAILABLES: 'There are public lists available',
  ID_TICKET: 'Ticket ID',
  TICKET: 'Ticket',
  TICKETS: 'Tickets',
  TICKETS_MY: 'My tickets',
  TICKETS_AVAILABLE: 'Tickets available',
  EVENT_FREE: 'Free event',
  EVENT_DATE: 'Event date',
  EVENT_DATE_INITIAL: 'Event start',
  EVENT_DATE_FINAL: 'Event end',
  TICKET_ENTRANCE_PRICE: 'Ticket and entrance values',
  PARTY: 'Party',
  NIGHT_PARTY: 'Night party',
  NOT_CONFIRMED: 'Not confirmed',
};


export const INTERPOLATES: Record<EInterpolatesTranslations, string> = {
  HELLO: 'Hello',
  PERSON_TO: 'Serves',
  CODE_ABREV: 'Code:',
  FROM: 'From',
  TO: 'To',
  PAY_PRESENTIAL: 'Pay in person',
  PAY_BY_APP: 'Pay through Secretio',
  PAY_TO: 'Pay to',
  TO_DO: 'To do',
  HAVE_WIFI: 'Wi-Fi available here',
  INTERPOLATE_ORDERSHEET_CLOSING: 'Waiting for the partner {company_name} to close the tab',
  INTERPOLATE_ORDERSHEET_CLOSE: 'Click on "Pay tab" to pay directly through Secretio or to {company_name}',
  INTERPOLATE_ORDERSHEET_FINISH: 'Make the payment to {company_name} to finish the tab',
  AT: 'at',
  OR: 'or',
  AND: 'and',
  THERE_IS: 'There is',
  LAST_ORDER: 'Last Order',
  LAST_ORDER_THERE_IS: 'Last Order {time} ago',
  ACCESS_USER_REGISTER: 'By registering on Secretio, you accept the',
  ACCESS_USER_TERMS_USE: 'Terms of Use',
  ACCESS_USER_TERMS_PRIVACY: 'Privacy Policy',
  INTERPOLATE_LIST_LIMIT: 'Limit of {listAdditionalNumber} guests reached!',
  INTERPOLATE_LIST_UNTIL_LIMIT: 'Up to {listAdditionalNumber} person(s) on the list per guest',
  PRICE_BETWEEN_MIN_MAX: 'Between {minPrice} and {maxPrice}',
  PRICE_INSTALLMENT_VALUE: 'In up to {installment} installments on the card',
  RESERVE_PEOPLE_LIMIT: 'Up to {limitReserve} person(s) per reservation',
  RESERVE_TABLE_AVAILABLE: 'Available tables',
  RESERVE_PRICE_PAY: 'Amount of {price} for the reservation',
  PAGE_VIEWS: 'Views of this page',
};


export const KITCHENS: Record<EKitchensTranslations, string> = {
  AFRICAN: 'African',
  GERMAN: 'German',
  ARABIC: 'Arabic',
  ARGENTINA: 'Argentinian',
  DRINKS: 'Drinks',
  BRAZILIAN: 'Brazilian',
  COFFEE: 'Coffee Shop',
  MEATS: 'Meats',
  CHINESE: 'Chinese',
  COLOMBIAN: 'Colombian',
  KOREAN: 'Korean',
  SPANISH: 'Spanish',
  FRENCH: 'French',
  SEAFOOD: 'Seafood',
  INDIAN: 'Indian',
  ITALIAN: 'Italian',
  JAPANESE: 'Japanese',
  MEDITERRANEAN: 'Mediterranean',
  MEXICAN: 'Mexican',
  FRIED_PASTRY: 'Fried Pastry',
  FISH: 'Fish',
  PERUVIAN: 'Peruvian',
  PIZZA: 'Pizza',
  PORTUGUESE: 'Portuguese',
  HEALTHY: 'Healthy',
  JUICES: 'Juices',
  THAI: 'Thai',
  VEGETARIAN: 'Vegetarian',
};


export const LABELS: Record<ELabelsTranslations, string> = {
  ALL: 'All',
  COMPANY: 'Company',
  COMPANIES: 'Companies',
  COMPANIES_MY: 'My companies',
  PROFILE: 'Profile',
  PROFILES: 'Profiles',
  PROFILES_MY: 'My profiles',
  PERSONAL_DATA: 'Personal data',
  PERSONAL_INFORMATIONS: 'Personal information',
  COUNTRY: 'Country',
  FANTASY_NAME: 'Fantasy name (Public name)',
  SOCIAL_REASON: 'Social reason',
  LOADING: 'Loading',
  USER: 'User',
  NAME: 'Name',
  NAME_FULL: 'Full name',
  DATE_BORN: 'Date of birth',
  GENRE: 'Gender',
  DDI_NUMBER_FIRST: 'Primary phone DDI',
  DDI_NUMBER_SECOND: 'Secondary phone DDI',
  DDI: 'DDI',
  PERSONS: 'Person(s)',
  USERNAME: 'Username',
  USERNAME_COMPANY: 'Company username',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  PASSWORD_CURRENT: 'Current password',
  PASSWORD_NEW: 'New password',
  PASSWORD_REPEAT: 'Repeat the new password',
  PASSWORD_CONFIRM: 'Confirm password',
  CNPJ: 'CNPJ',
  CPF: 'CPF',
  FORMS: 'Forms',
  COMMENTS: 'Comments',
  OPTIONAL: 'Optional',
  PUBLIC_ID: 'Public ID',
  PUBLIC_PARTNER_ID: 'Public partner ID',
  NUMBER_PRIMARY: 'Primary number',
  NUMBER_SECONDARY: 'Secondary number',
  PARTNER_ABOUT: 'About the partner',
  PARTNER_SERVICES: 'Available services',
  PARTNER_PHONES: 'Phones',
  PHONE_ONLY_ONE: 'Number with area code',
  PHONE_FIRST: 'Primary phone with area code',
  PHONE_SECOND: 'Secondary phone with area code',
  PARTNER_RULES: 'Rules',
  PARTNER_TYPES_KITCHEN: 'Kitchen types',
  PARTNER_OPEN_HOURS: 'Opening hours',
  WIFI: 'Wi-Fi',
  KIDS_AREA: 'Kids area',
  PARKING: 'Parking',
  SMOKING: 'Smoking area',
  AIR_CONDITIONER: 'Air conditioner',
  ACCESSIBILITY: 'Accessibility',
  MORE_POPULAR: 'More popular',
  NUMBER_OR_NAME: 'Name or number',
  INPUT_MODAL_ADD_USER_PLACEHOLDER: 'Search name...',
  CONFIRM_CPF: 'Confirm CPF of the holder',
  CONFIRM_CNPJ: 'Confirm CNPJ of the company',
  CONFIRM_INFO: 'Confirm information',
  NICKNAME: 'Nickname',
  DESCRIPTION: 'Description',
  FAVORED: 'Favored',
  ACCOUNT_USE_MAIN: 'Use this account as current',
  ADDRESS_USE_MAIN: 'Use this address as current',
  INPUT_MODAL_ADD_USER_CARGO: 'Email or username',
  CODE: 'Code',
  DIGIT: 'Digit',
  ACCOUNT: 'Account',
  ID: 'ID',
  ID_PRIVATE: 'Private ID',
  ID_PUBLIC: 'Public ID',
  COMPANY_STATUS_APP: 'Status in Secretio',
  COMPANY_STATUS_ACTIVE: 'Active company',
  COMPANY_STATUS_INACTIVE: 'Inactive company',
  PLACE_NAME: 'Place name',
  ZIP_CODE: 'ZIP code',
  STREET: 'Street', // 'Avenue / Street',
  STREETS: 'Streets',
  DISTRICT: 'District',
  DISTRICTS: 'Districts',
  ATTRACTIONS: 'Attractions',
  COMPLEMENT: 'Complement',
  CITY: 'City',
  CITIES: 'Cities',
  STATE: 'State',
  SOCIAL: 'Social',
  SLOGAN: 'Slogan',
  CELLPHONE: 'Cellphone',
  ABOUT: 'About',
  ABOUT_APP: 'About Secretio',
  PROFILE_TYPE: 'Profile type',
  PARTNER_TYPE: 'Partner type',
  INFORMATIONS: 'Information',
  CARD_HOLDER_NAME: 'Cardholder name',
  CARD_NICKNAME: 'Card nickname',
  MALE: 'Male',
  FEMALE: 'Female',
  SOCIAL_NAME: 'Social name',
  ABOUT_ME: 'About me',
  LEVEL: 'Level',
  BIRTHDAY: 'Birthday',
  PICTURE: 'Picture',
  USER_PRE_REGISTER: 'Pre-registered user',
  DATAS: 'Data',
  SHOPS: 'Shops',
  OPTIONS: 'Options',
  ME_AND_GUESTS: 'Me and guests',
  ONLY_YOU: 'Only you',
  ONLY_FOR_ME: 'Only for me',
  PEOPLE: 'People',
  RESERVE_DATE: 'Reservation date',
  DATE_SELECTED: 'Selected date',
  ENVIRONMENT: 'Environment',
  PEOPLE_NUMBER: 'Number of people',
  MORE_INFORMATIONS: 'More information',
  OBSERVATIONS: 'Observations',
  INITIAL_DATE: 'Initial date',
  INITIAL_HOUR: 'Initial hour',
  FINAL_DATE: 'Final date',
  FINAL_HOUR: 'Final hour',
  INITIAL_VALUE: 'Initial value',
  FINAL_VALUE: 'Final value',
  PARAMETERS: 'Parameters',
  NAME_OR_USERNAME: 'Name or username',
  MODALITIES: 'Modalities',
  VERSION: 'Version',
  MY_ACCOUNT: 'My account',
  ACCOUNT_SEE: 'See account',
  ACCOUNT_ACTIONS: 'Account actions',
  PAYMENTS_FORMS: 'Payment forms',
  LOCATION: 'Location',
  LOCATION_NO_ADDRESS: 'No information about the address',
  TICKETS_SALES_UNTIL: 'Sales until',
  PARTICIPATION: 'Participation',
  WAIT_YOU: 'Waiting for you',
  ITEMS: 'Items',
  HELP: 'Help',
  TYPE: 'Type',
  CANCELED: 'Canceled',
  FINISHED: 'Finished',
  OTHER: 'Other',
  ADD: 'Add',
  ACTUAL: 'Actual',
  COUNTRIES: 'Countries',
  NO: 'No',
  YES: 'Yes',
  LIST_NAME: 'List name',
  GUIDES_INFORMATIONS: 'Guides and information',
  PHONES_USEFUL: 'Useful phones',
  CASADINHA: 'Casadinha',
  OPEN_BAR: 'Open Bar',
  LIST: 'List',
  VIP: 'VIP',
};


export const LANGUAGES: Record<ELanguagesTranslations, string> = {
  PT_BR: 'Portuguese (Brazil)',
  EN_US: 'English (USA)',
  ES_AR: 'Spanish (Spain)',
  IT_IT: 'Italian (Italy)',
  FR_FR: 'French (France)',
};


export const MENU_ORDER: Record<EMenuOrderTranslations, string> = {
  COMBO: 'Combo',
  ITEM_DOUBLE: 'Double item',
  AVAILABLES: 'Available',
  TABLE: 'Table',
  NUMBER: 'Number',
  NUMBER_TABLE_ENTRANCE: 'Table number / entrance',
  TABLE_ENTRANCE: 'Table / entrance',
  MIN_ORDERSHEET: 'Minimum order',
  WAITER_CALL: 'Call the waiter',
  WAITER_CALLING: 'Calling waiter',
  WAITER_GOING: 'Waiter on the way',
  ORDERSHEET_CLOSING_ORDERSHEET: 'Closing ordersheet',
  ORDERSHEET_CLOSED: 'Closed ordersheet',
  ORDERSHEET_OPENED: 'Opened ordersheet',
  TOTAL_CONSUMPTION: 'Total consumption',
  ALCOHOLIC: 'Alcoholic',
  SPICY: 'Spicy',
  VEGETARIAN: 'Vegetarian',
  VEGAN: 'Vegan',
  ORGANIC: 'Organic',
  NO_LACTOSE: 'No lactose',
  NO_GLUTEN: 'No gluten',
  REQUESTS: 'Requests',
  REQUESTS_MINIMAL: 'Minimal requests',
  REQUESTS_UNTIL: 'Requests until',
  DELIVERY: 'Delivery',
  REMOVAL_ITEM: 'Removal',
  PRESENTIAL: 'On-site',
  ORDER_PLACED: 'Order placed',
  ORDER_PREPARING: 'Preparing',
  ORDER_TO_DELIVER: 'To deliver',
  ORDER_REFUSED: 'Order refused',
};


export const MESSAGES_EMPTYS: Record<EMessagesEmptysTranslations, string> = {
  EMPTY_GALLERY_PHOTOS: 'Sorry, no media from this partner!',
  EMPTY_COMPANY_NOT_FOUND: 'Sorry, profile not found!',
  EMPTY_COMPANY_EVENTS: 'No events here yet!',
  EMPTY_EVENTS_NOT_FOUND: 'Sorry, event not available!',
  EMPTY_HIGHLIGHTS_ITEMS: 'Sorry, no highlights found today!',
  EMPTY_HIGHLIGHTS_EVENTS: 'No events here yet!',
  EMPTY_HIGHLIGHTS_PARTNERS: 'No partners on Secretio here!',
  EMPTY_CATEGORIES: 'No category',
  EMPTY_ITEMS: 'Sorry, no items in the Menu!',
  EMPTY_MENUSHEET_CATEGORIES: 'Sorry, no items in this category!',
  EMPTY_NOTIFICATIONS: 'No notifications here yet!',
  EMPTY_ORDERSHEET_HISTORY: 'No items in the history yet!',
  EMPTY_BANKS: 'No banks here, sorry!',
  EMPTY_COMPANIES_REGISTERED: 'No companies registered!',
  EMPTY_HOME: 'No data to show!',
  EMPTY_FAVORITES: 'There are no favorites here!',
  EMPTY_PAYMENT: 'No payment methods registered!',
  EMPTY_ADDRESS: 'No addresses registered!',
  EMPTY_USER_DATA: 'User without data',
  EMPTY_USER_NO_RECOG: 'Unidentified user',
  EMPTY_LISTS: 'No lists registered',
  EMPTY_LISTS_PARTICIPATE: 'No lists to participate in yet!',
  EMPTY_LISTS_FINISHED: 'No lists you participated in yet!',
  EMPTY_TICKET: 'No tickets yet!',
  EMPTY_TICKET_IN_EVENT: 'No tickets for this event',
  EMPTY_EVENT_EXPLORE: 'Explore other events like this',
  EMPTY_CARD_REGISTERED: 'No card registered',
  EMPTY_CARD_NO_ADDED: 'You have not added a card to your account yet',
  EMPTY_RESERVE_REGISTERED: 'No available or registered reservations!',
  EMPTY_RESERVE_USER: 'No reservations yet to enjoy the night!',
  EMPTY_LINK_SEE: 'No link to view',
  EMPTY_SEARCH: 'Sorry, nothing found in the search!',
  EMPTY_ORDERS: 'No Orders',
  EMPTY_EVENT_FINISHED: 'The event has already ended!',
  EMPTY_PLACES: 'No places registered yet!',
  EMPTY_CARNIVAL_ATTRACTIONS: 'Sorry, no attractions today!',
  EMPTY_CARNIVAL_NONE: 'Sorry, there\'s no carnival or micaretas yet!',
  EMPTY_CARNIVAL_DISTRICTS: 'Sorry, no party in the neighborhoods today!',
  EMPTY_CARNIVAL_CABINS: 'Sorry, the cabins are closed today!',
  EMPTY_CARNIVAL_LOCATIONS: 'Sorry, no locations here!',
  EMPTY_CARNIVAL_STREETS: 'Sorry, no street party today!',
  EMPTY_CARNIVAL_INFOS: 'Sorry, no guides here!',
  EMPTY_PHONES: 'Sorry, no phones here!',
  EMPTY_CARNIVAL_VOTES: 'Sorry, no votes yet!',
  EMPTY_LANGUAGES: 'No languages yet!',
  EMPTY_LIST_AVAILABLE: 'No lists available',
  EMPTY_TICKET_AVAILABLE: 'No tickets available',
};


export const MESSAGES_ERRORS: Record<EMessagesErrosTranslations, string> = {
  ERROR: 'Error',
  ERROR_PAY: 'Error while paying',
  ERROR_PAY_APP: 'We had a problem, please make the payment at the establishment',
  ERROR_CHECKIN_ASK: 'Could not request Check-in',
  ERROR_CHECKIN_CANCEL: 'Could not cancel Check-in',
  ERROR_HAD: 'There was an error',
  ERROR_SEARCH: 'Error while fetching data',
  TRY_AGAIN: 'Error, please try again',
  ACCESS_DENIED: 'There is no access permission for this user.',
  PASSWORD_WRONG: 'Invalid username and/or password.',
  ERROR_PARAMS_INVALID: 'Invalid parameters',
  ERROR_COMPANY_CREATE: 'Error creating company',
  ERROR_USER_CREATE: 'Error creating profile',
  ERROR_DATE: 'Error in date',
  ERROR_NO_DATA_FOUND: 'Data not found, check the information and try again.',
  ERROR_EMPTY_FIELDS: 'Fill in all fields correctly before continuing',
  ERROR_ACCOUNT_TRANSFER: 'Error creating transfer account',
  ERROR_ACCOUNT_SEARCH: 'Error searching transfer accounts',
  ERROR_ACCOUNT_MAIN_CHANGE: 'There was an error changing the account',
  ERROR_ACCOUNT_DELETE: 'There was an error deleting the account',
  ERROR_SAVE: 'Error saving data!',
  ERROR_CREATE: 'Error creating',
  ERROR_UPDATE: 'Error updating',
  ERROR_USER_DISABLE: 'Error disabling user.',
  ERROR_COMPANY_DISABLE: 'Error disabling company.',
  ERROR_MAKE_REGISTER: 'Error making registration',
  ERROR_PAYMENT_MAIN_CHOOSED: 'There was an error choosing the main payment',
  ERROR_PAYMENT_DELETE: 'There was an error deleting the payment',
  ERROR_ADDRESS_DELETE: 'There was an error deleting the address',
  ERROR_LIST_USER_ADDED: 'Error adding users',
  ERROR_LIST_ENTER: 'Error trying to join the list',
  ERROR_LIST_GET_DATA: 'Error getting lists',
  ERROR_GET_DATA: 'Error getting data',
  ERROR_TICKET_UNAVAILABLES: 'Tickets unavailable',
  ERROR_LISTS_UNAVAILABLES: 'Lists unavailable',
  ERROR_USER_DOESNOT_EXIST: 'User does not exist!',
  ERROR_USER_IN_LIST: 'User is already on the list',
  ERROR_FAVORITE_ADD: 'Error while adding to favorites',
  ERROR_FAVORITE_REMOVE: 'Error while removing from favorites',
};


export const MESSAGES_HELP: Record<EMessagesHelpsTranslations, string> = {
  HELP_INPUT_COMPANY: 'Enter the company details',
  HELP_INPUT_USER: 'Enter the information below',
  HELP_INPUT_REQUIRED: 'Required fields',
  HELP_QR: 'Read the partner\'s QR Code to check-in and avoid queues, speeding up your entry to places and events!',
  HELP_CHECKIN: 'To check-in, simply enter the Partner\'s Public ID, the Table Number for example, and wait for your Check-in confirmation.',
  HELP_CHECKIN_WAIT: 'Wait for the Check-in confirmation. If you want, you can cancel the Check-in request at any time.',
  HELP_EVENT_RATING: 'The rating and information for this event are the responsibility of its creators and producers.',
  HELP_EVENT_ALCOHOL: 'The sale of alcoholic beverages is prohibited for individuals under 18 years of age.',
  HELP_PAY_UNAVAILABLE: 'Online payment unavailable. No cards registered.',
  HELP_SUCCESS_ORDER_DONE: 'You can track the status of items directly in the order history.',
  HELP_CHEKIN_HOW_TO: 'At the venue or event entrance, show this QR Code to validate tickets, lists, reservations, and entrance orders.',
  HELP_ORDERSHEET_CLOSING: 'Awaiting closure',
  HELP_ORDERSHEET_THANKS: 'Thank you for using Secretio',
  HELP_ORDERSHEET_PARTNER_CLOSE: 'Your order has been closed by the partner',
  HELP_ORDERSHEET_RECUSED: 'Order refused',
  HELP_ORDERSHEET_NOT_FOUND: 'Check-in not accepted or not found',
  HELP_SOME: 'Any questions?',
  HELP_TALK_TO_US: 'Talk to Secretio',
  HELP_ACCOUNT_TRANSFER: 'For the transfer to be made, the bank account to be added must belong to the registered person or company.',
  HELP_ACCOUNT_BANK_RECEIVE: 'The receiving bank can choose in which modality to credit the transfer if its savings and current accounts have the same number.',
  HELP_ACCOUNT_LIST_TRANSFER: 'Register a bank account and transfer your money to it.',
  HELP_ACCOUNT_LIST_TRANSFER_TEXT: 'To transfer money to your bank account, it is necessary to register a bank account in your name or the company\'s name.',
  HELP_PROFILE_PICTURE: 'A photo helps people recognize you and lets you know which account is connected.',
  HELP_LOGOTIPO_COMPANY_TEXT: 'A logo helps personalize the profile and allows it to be found more quickly.',
  HELP_LOGOTIPO_COMPANY_DIMENSIONS: 'We recommend a square image (1x1), approximately 400x400 in size.',
  HELP_COMPANY_COMPLETE_PROFILE: 'Complete your company profile to use Secretio services.',
  HELP_USER_DISABLE: 'If you want to deactivate your account, we will take care of it for you. We like to inform our users that even if the account is deactivated, for security reasons, you will not be able to create another account on our platform, as there can only be one account per CPF.',
  HELP_USER_DISABLE_BACK: 'You can return at any time by accessing your account with the password already registered, and if you forget it, you can reset it.',
  HELP_COMPANY_DISABLE_BACK: 'To activate this company again, you must contact support and request activation.',
  HELP_COMPANY_DISABLE_PLAN: 'If this company has any plan, it will also be canceled!',
  HELP_WELCOME_TITLE: 'Welcome to your Account',
  HELP_WELCOME_DESCRIPTION: 'Manage your information, privacy, and security so that Secretio meets your needs.',
  HELP_PASSWORD_NEW: 'Enter and confirm your new password; it is from it that you will be able to access Secretio.',
  HELP_PASSWORD_OLD: 'Enter your current password so we can confirm it is you; then enter and confirm your new password.',
  HELP_PASSWORD_REMENBER: 'If you don\'t remember your current password, you need to',
  HELP_PASSWORD_REDEFINE: 'redefine it.',
  HELP_PASSWORD_FORGOT: 'If you have forgotten your password, enter the email you used to register with Secretio below.',
  HELP_INVITE_PEOPLE: 'You can invite more people now or later.',
  HELP_INVITE_LINK: 'Share this link with your guests for them to add their names to this list.',
  HELP_LIST_HOW_INVITE: 'Enter your guest\'s Username or, if the person is not on Secretio, enter their CPF.',
  HELP_LIST_HOW_FINISH: 'By adding a person, their participation is automatically confirmed when finishing!',
  HELP_WIFI_NO_REGISTER: 'No registered Wi-Fi; you must ask to know if Wi-Fi is available.',
  HELP_RESERVE_MINUTES: 'After 30 minutes from the reserved time, if there is no one to validate the reservation, it will be finalized, and the table released.',
  HELP_RESERVE_AGE: 'Minimum paying age is 4 years.',
  HELP_RESERVE_TABLE: 'The option to join tables is up to the Partner.',
  HELP_RESERVE_TAXES: 'Possible fees, such as parking or children\'s space, are not included in the reservation price.',
  HELP_RESERVE_CANCEL: 'If no one can attend, cancel the reservation in advance. Cancellation can be done up to 3 hours before the reserved time; after that time, cancellation cannot be done.',
  HELP_MINIMAL_AGE: 'At least 4 years old',
  HELP_RESERVE_OBS_EXAMPLE: 'Example: Birthday, Celebration',
  HELP_RESERVE_QUANTITY_PARTNER: 'The number of people in the reservation will be the accepted quantity; it is up to the partner to identify and count the number of people for that reservation and whether more than described will be accepted.',
  HELP_SEARCH_BY_NAME: 'Search by name or username',
  HELP_SEARCH_PARAMETERS: 'Search by parameters',
  HELP_LOGIN_TO_SEE: 'Log in to view your information',
  HELP_INSERT_USER: 'Insert the user',
  HELP_USER_HAVENT_DATA: 'I have no information',
  HELP_COMPANY_QUANTITY_ACCEPT: 'The acceptance of the number of people is up to the partner. It is their responsibility to identify and count the number of participants and whether they will accept more people than described.',
  HELP_PROFILE_VERIFY_UNMANAGE: 'Unmanaged profile. Be careful and always check the information and prices on site as they may be out of date.',
  HELP_PROFILE_UNMANAGE: 'The profile for this location has not yet been requested. Owners who request a profile can update their information, make sales, create reservations, add photos, and more.',
  HELP_PROFILE_MANAGE: 'A person linked to this location manages this profile.',
  HELP_ORDERSHEET_DETAILS_ENABLED: 'You can use the Secretio consummation to purchase and pay for this event.',
  HELP_ORDERSHEET_DETAILS_DISABLED: 'Consumption is deactivated by the producer, when you arrive at the location you must ask how to buy and pay your order.',
};


export const MESSAGES_QUESTIONS: Record<EMessagesQuestionsTranslations, string> = {
  ANY_QUESTION: 'Any questions',
  ANY_QUESTION_CONTACT: 'contact us',
  PAY_ATTENTION: 'Pay Attention',
  QUESTION_DELETE_CARD: 'Delete card?',
  QUESTION_DELETE_CARD_TEXT: 'Would you like to delete this card?',
  QUESTION_ORDERSHEET_CLOSE: 'Close ordersheet?',
  QUESTION_ADD_CREDIT_CARD: 'No credit card in your account, would you like to add one?',
  QUESTION_ACCOUNT_MAIN_CHANGE: 'Change main bank account?',
  QUESTION_ACCOUNT_MAIN_CHANGE_TEXT: 'Would you like to set this account as the main one?',
  QUESTION_ACCOUNT_DELETE: 'Delete bank account?',
  QUESTION_ACCOUNT_DELETE_TEXT: 'Would you like to delete this bank account?',
  QUESTION_USER_DISABLE: 'Are you sure you want to deactivate your account?',
  QUESTION_COMPANY_DISABLE: 'Are you sure you want to deactivate this company?',
  QUESTION_ADDRESS_REGISTER_TEXT: 'To add a payment method, it is necessary to register a main address. Do it now?',
  QUESTION_ADDRESS_DELETE: 'Delete address?',
  QUESTION_ADDRESS_DELETE_TEXT: 'Would you like to delete this item?',
  QUESTION_BAG_ITEM_REMOVE: 'Do you want to remove {itemSaleName} from your cart?',
  QUESTION_BAG_CLEAR: 'Empty the bag?',
  QUESTION_EXIT: 'Do you want to exit',
  QUESTION_FAVORITE_REMOVE: 'Remove from favorites?',
};


export const MESSAGES_SUCCESS: Record<EMessagesSuccessTranslations, string> = {
  SUCCESS: 'Success',
  SUCCESS_CHECKIN_ASK: 'Check-in confirmed. Enjoy!',
  SUCCESS_CHECKIN_CANCEL: 'Check-in request canceled!',
  SUCCESS_ADD_ITEM_BAG: 'Item added successfully!',
  SUCCESS_ORDER_DONE: 'Order placed!',
  SUCCESS_PAYED: 'Paid!',
  SUCEESS_COMPANY_CREATE: 'Company created successfully!',
  SUCEESS_COMPANY_CREATE_TEXT: 'Now you should wait for our data evaluation.',
  SUCEESS_USER_CREATE: 'Profile created successfully!',
  SUCEESS_USER_CREATE_TEXT: 'We have sent a confirmation email to {user_email}. Open the email, confirm the profile creation, and you will be able to log in using your registered credentials. Your customer number is also in the email.',
  SUCCESS_ACCOUNT_TRANSFER: 'Transfer account created successfully',
  SUCCESS_ACCOUNT_MAIN_CHANGE: 'New main account changed',
  SUCCESS_ACCOUNT_DELETE: 'Account deleted successfully',
  SUCCESS_CREATE: 'Created successfully',
  SUCCESS_UPDATE: 'Update successful',
  SUCCESS_USER_DISABLE: 'User disabled successfully',
  SUCCESS_COMPANY_DISABLE: 'Company disabled successfully',
  SUCCESS_PASSWORD_RECOVER: 'Password recovery request made successfully!',
  SUCCESS_PASSWORD_RECOVER_TEXT: 'We have sent a confirmation email to {user_email}. Open the email, click on the link, and you will be able to change your password.',
  SUCCESS_CARD_REGISTER: 'Card registered successfully',
  SUCCESS_PAYMENT_MAIN_CHOOSED: 'Main payment chosen',
  SUCCESS_PAYMENT_DELETE: 'Payment deleted successfully',
  SUCCESS_ADDRESS_DELETE: 'Address deleted successfully',
  SUCCESS_EMAIL_CHANGE: 'Change email sent successfully',
  SUCCESS_LIST_USER_ADDED: 'Users added successfully',
  SUCCESS_PASSWORD_COPY: 'Password copied',
  SUCCESS_USER_ADDED: 'User added successfully',
  SUCCESS_FAVORITE_ADD: 'Favorite added successfully',
  SUCCESS_FAVORITE_REMOVE: 'Favorite removed successfully',
  SUCCESS_COPIED_ADDRESS: 'Address copied',
  SUCCESS_COPIED_LINK: 'Link copied',
};


export const MUSICS: Record<EMusicsTranslations, string> = {
  EVENT_MUSICAL_GENRES: 'Musical Genres',
  MUSIC: 'Music',
  ELECTRONIC: 'Electronic',
  FUNK: 'Funk',
  COUNTRY_MUSIC: 'Country Music',
  POP: 'Pop',
  RAP: 'Rap',
  ROCK: 'Rock',
  MPB: 'MPB',
  AXE: 'Axé',
  SAMBA: 'Samba',
  REGGAE: 'Reggae',
  FORRO: 'Forró',
  GOSPEL: 'Gospel',
  HIP_HOP: 'Hip-Hop',
  JAZZ: 'Jazz',
  CLASSIC_MUSIC: 'Classical Music',
  PAGODE: 'Pagode',
  BLUES: 'Blues',
};


export const PAYMENTS: Record<EPaymentsTranslations, string> = {
  BANK: 'Bank',
  AGENCY: 'Agency',
  CARD_SELECTED: 'Selected Card',
  DEBIT_CARD: 'Debit Card',
  CREDIT_CARD: 'Credit Card',
  CREDIT: 'Credit',
  DEBIT: 'Debit',
  TICKETS_CARD: 'Tickets',
  CASH: 'Cash',
  BANK_CHECK: 'Bank Check',
  PIX: 'Pix',
  ELO: 'Elo',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
  AMERICAN: 'American',
  DINERS: 'Diners',
  HIPERCARD: 'Hipercard',
  ALELO: 'Alelo',
  BEN: 'Ben',
  SODEXO: 'Sodexo',
  CARD_TICKET: 'Ticket',
  VR: 'Vr',
  CHANGE: 'Change',
  DISCOUNT: 'Discount',
  TIP: 'Tip',
  TOTAL_PAY: 'Total Paid',
  TOTAL: 'Total',
  SUBTOTAL: 'Subtotal',
  TAXES: 'Taxes and Charges',
  TAX_DELIVERY: 'Delivery Fee',
  PARTNER_PAYMENTS_ON: 'Payments accepted on-site',
  WAITING_PAYMENT: 'Waiting for Payment',
  ALL_BANKS: 'All Banks',
  ACCOUNT_CURRENT: 'Current Account',
  ACCOUNT_SAVING: 'Savings Account',
  ACCOUNT_NICKNAME: 'Account Nickname',
  ACCOUNT_BELONGING: 'Account Belonging to',
  ACCOUNT_CURRENT_SAVING_SAME_NUMBER: 'Current and savings accounts with the same number',
  CARD_ACCEPTED: 'Cards accepted at Secretio',
  PAYMENT_FORM: 'Payment Form',
  CARD_NUMBER: 'Card Number',
  DATE_VALID_UNTIL: 'Valid Until',
  CARD: 'Card',
  CARDS: 'Cards',
  CARD_CVV: 'CVV',
  CARD_VALIDITY: 'Validity',
  CARD_CURRENCY: 'Current Card',
  CARD_DEFINE_CURRENCY: 'Set Current Card',
  CARD_DELETE: 'Delete Card',
  CARD_CHOOSE_MAIN: 'Use this card as the current one',
  CARD_CHOOSE_DEBIT_TOO: 'If credit charge fails, attempt debit charge (if available)',
  CARD_CHOOSE_CREDIT_TOO: 'If debit charge fails, attempt credit charge (if available)',
};


export const RESERVES: Record<EReservesTranslations, string> = {
  RESERVE_TO: 'Reserve For',
  RESERVE_PRICE: 'Reservation Fee',
  RESERVE_FREE: 'Free Reservation',
  RESERVED_TO: 'Reserved for',
  RESERVED_AT: 'Reserved at',
  RESERVE_MADE_TO: 'Reservation made by',
  RESERVE_HOUR: 'Reservation time',
  RESERVE_PEOPLE: 'People in the reservation',
};


export const SOCIALS: Record<ESocialsTranslations, string> = {
  WHATSAPP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'Twitter',
  YOUTUBE: 'Youtube',
  LINKEDIN: 'LinkedIn',
  CONTACT_US: 'Contact Us',
  SEND_MESSAGE: 'Send a Message',
};


export const TIME: Record<ETimeTranslations, string> = {
  DATE: 'Date',
  HOUR: 'Hour',
  DATE_HOUR: 'Date and Hour',
  DATES_AVAILABLE: 'Available Dates',
  OPENED_UNTIL: 'Open until',
  OPENEDS: 'Open',
  CLOSE_IN: 'Closes in',
  CLOSED: 'Closed',
  CLOSEDS: 'Closed',
  TODAY: 'Today',
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  NEXT: 'Next',
  PAST: 'Past',
  UPDATED_AT: 'Updated at',
};


export const TITLES: Record<ETitlesTranslations, string> = {
  HOME: 'Home',
  FAVORITES: 'Favorites',
  EXIT: 'Exit',
  FORGOT_PASSWORD_QUESTION: 'Forgot Password?',
  RECOVER_PASSWORD: 'Recover Password',
  REGISTER: 'Register',
  ACCESS: 'Access',
  CHECKIN: 'Check-in',
  CHECKOUT: 'Check-Out',
  CHECKIN_WAIT: 'Waiting for Check-in',
  QR_READER: 'Don\'t want to waste time?',
  QR_MY_CODE: 'My QR Code',
  GALLERY: 'Gallery',
  COMPANY_DETAILS: 'Partner Details',
  ALL_EVENTS: 'All Events',
  LISTS: 'Lists',
  LISTS_EVENT: 'Event Lists',
  LISTS_ALL: 'All Lists',
  LISTS_AVAILABLES: 'Available Lists',
  LISTS_SPECIALS: 'Special Lists',
  MENUSHEET: 'Menu',
  HIGHLIGHTS: 'Highlights',
  PARTNER: 'Partner',
  PARTNERS: 'Partners',
  PARTNERS_HIGHLIGHTS: 'Profiles in highlights today',
  EVENTS: 'Events',
  EVENTS_HIGHTLIGHTS: 'Highlighted events today',
  EVENTS_MORE: 'Events for you',
  EVENTS_STREET: 'Street events',
  MAP: 'Map',
  MAPS: 'Maps',
  NOTIFICATIONS: 'Notifications',
  NOTIFICATIONS_CONFIG: 'Configure Notifications',
  ORDERSHEET: 'Ordersheet',
  ORDERSHEET_PAY: 'Pay Ordersheet',
  PAYMENT_OPTIONS: 'Payment Options',
  ORDERBAG: 'Bag',
  HISTORIC: 'History',
  COMPANY_NEW: 'New Company',
  COMPANY_REGISTER: 'Register Company',
  BANKS: 'Banks',
  MODAL_ADD_USER: 'Add user to company',
  ACCOUNT_TRANSFER: 'Transfer Accounts',
  ACCOUNT_TRANSFER_ADD: 'Add transfer account',
  COMPANY_ADDRESS_EDIT: 'Edit company address',
  CONTACT: 'Contact',
  COMPANY_EDIT: 'Edit Company',
  COMPANY_DATAS: 'Company Data',
  PROFILE_PICTURE: 'Profile Picture',
  LOGOTIPO: 'Logo',
  LOGOTIPO_COMPANY: 'Profile Logo',
  ADDRESS: 'Address',
  ADDRESS_CREATE: 'Create Address',
  ADDRESS_EDIT: 'Edit Address',
  ADDRESSES: 'Addresses',
  USER_DISABLE: 'Disable Profile',
  COMPANY_DISABLE: 'Disable Company',
  REDEFINE_PASSWORD: 'Redefine Password',
  CHANGE_PASSWORD: 'Change Password',
  PREFERENCES: 'Preferences',
  SETTINGS: 'Settings',
  LANGUAGE: 'Language',
  CARD_ADD: 'Add Card',
  ADDRESS_REGISTER: 'Register Address',
  PAYMENTS_SUBSCRIPTIONS: 'Payments and Subscriptions',
  PROFILE_MY_ABOUT: 'About My Profile',
  PROFILE_DETAILS: 'Profile Details',
  PROFILE_INFORMATIOS: 'Profile Information',
  PROFILE_PUBLIC: 'Public Profile',
  PROFILE_MAIN: 'Main Account',
  PERMISSIONS_GROUP: 'Groups and Permissions',
  INVITE_TO_LIST: 'Invite to List',
  INVITE_PEOPLE: 'Invite People',
  PARTICIPATE: 'Participate',
  LIST_PARTICIPATE: 'Participating Lists',
  DETAILS_ITEM: 'Item Details',
  MY_ORDERS: 'My Orders',
  RESERVES: 'Reserves',
  RESERVE_DETAILS: 'Reserve Details',
  SEARCH: 'Search',
  OTHER_INFORMATIONS: 'Other Information',
  TERMS_POLICY: 'Terms and Policies',
  LICENCES: 'Licenses',
  KNOW_APP: 'Get to Know Secretio',
  PROFILE_UNMANAGE: 'Unmanaged profile',
  PROFILE_MANAGE: 'Managed profile',
  SHARE: 'Share',
};


export const SUBTITLES: Record<ESubtitlesTranslations, string> = {
  SUBTITLES_LOGOTIPO_COMPANY: 'A logo helps personalize your company',
  SUBTITLES_PROFILE_PICTURE: 'A photo helps personalize your account',
  SUBTITLES_PROFILE_ALL: 'See how your different profiles appear on Secretio Services',
  SUBTITLES_INFORMATIONS_COMPANY: 'Name, about, and more',
  SUBTITLES_DATAS_COMPANY: 'Registration and legal name, for example',
  SUBTITLES_COMPANY_CONTACT: 'Click to edit your contacts',
  SUBTITLES_COMPANY_TRANSFER_YES: 'Click to manage the transfer accounts of this company',
  SUBTITLES_COMPANY_TRANSFER_NO: 'Transfer accounts not yet available',
  SUBTITLES_COMPANY_ADDRESS: 'Click to edit the company address',
  SUBTITLES_ACCOUNT_TRANSFER_ADD: 'Secretio still needs to review your registration, wait a little longer to try again!',
  SUBTITLES_SETTINGS: 'Settings for your global account',
  SUBTITLES_CARDS: 'Credit Cards', // 'Credit and debit cards',
  SUBTITLES_PROFILE_PUBLIC: 'These are the information that appears on this public profile',
  SUBTITLES_PERMISSIONS_GROUP: 'Add people and manage permissions',
  SUBTITLES_HIGHLIGHT_MAP: 'Allow the use of location to see nearby places',
  SUBTITLES_HIGHLIGHT_MAP_EXPERIENCE: 'This way we can improve your experience on Secretio',
  SUBTITLES_LIST_IN: 'You are on the list',
  SUBTITLES_LIST_INSIDE: 'You are already on the list: {listName}',
  SUBTITLES_LIST_OUTSIDE: 'Click the participate button to join the list: {listName}',
  SUBTITLES_SEE_ITEMS_MY_LOCATION: 'See events and partners around me',
  SUBTITLES_KNOW_APP: 'Menu, reservations, orders, link bio, tickets, and much more.',
};


export const VALIDATIONS: Record<EValidationsTranslations, string> = {
  ONLY_NUMBERS: 'Numbers only',
  VALIDATE_MIN_DIGITS: 'Must contain at least {count} digits',
  VALIDATE_MAX_DIGITS: 'Must contain up to {count} digits',
  VALIDATE_EXACT_DIGITS: 'Must contain {count} digits',
  VALIDATE_MIN_CHAR: 'Must contain at least {count} characters',
  VALIDATE_MAX_CHAR: 'Must contain up to {count} characters',
  VALIDATE_EXACT_CHAR: 'Must contain {count} characters',
  VALIDATE_ALL_FIELDS: 'Fill in all fields correctly before continuing',
  VALIDATE_ALL_SELECTED: 'Make sure all fields are selected',
  VALIDATE_HAS_USER: 'There is already a profile with this username/email',
  VALIDATE_ONLY_ONE: 'Check only one',
  VALIDATE_ONE_MORE: 'Check one or more',
  VALIDATE_EMAIL_REQUIRED: 'Email is required',
  VALIDATE_EMAIL_VALID: 'Enter a valid email',
  VALIDATE_EMAIL_DOMAIN: 'Incomplete email domain',
  VALIDATE_PASSWORD_REQUIRED: 'Password is required',
  VALIDATE_ID_SHORT_REQUIRED: 'Public ID is required',
  VALIDATE_CPF_VALID: 'Enter a valid CPF',
  VALIDATE_USER_CPF_USERNAME: 'Enter a valid CPF or a username with only letters, numbers, "_", and "."',
  VALIDATE_USERNAME_CHAR_SPECIAL: 'Username can only contain letters, numbers, "_", and "."',
  VALIDATE_USER_REQUIRED: 'Username is required',
};



const Translates = {
  ...APP,
  ...APP_TUTORIAL,
  ...BUTTONS,
  ...COMPANY_TYPES,
  ...CARNIVALS,
  ...COUNTRIES,
  ...DRINKS,
  ...EVENTS,
  ...INTERPOLATES,
  ...KITCHENS,
  ...LABELS,
  ...LANGUAGES,
  ...MENU_ORDER,
  ...MESSAGES_EMPTYS,
  ...MESSAGES_HELP,
  ...MESSAGES_ERRORS,
  ...MESSAGES_QUESTIONS,
  ...MESSAGES_SUCCESS,
  ...MUSICS,
  ...PAYMENTS,
  ...RESERVES,
  ...SOCIALS,
  ...TIME,
  ...TITLES,
  ...SUBTITLES,
  ...VALIDATIONS,
};



export default Translates;
