export const CONTACT = {
  whatsAppNumber:                   '5571992742125',
  whatsAppMessage:                  'Olá! Eu gostaria de mais informações sobre o Secretio. Poderia me ajudar?',
  whatsAppProfileMessage:           'Olá! Vi seu anúncio no Secretio. Gostaria de mais informações.',
  whatsAppBeModeleMessage:          'Olá! gostaria de ser modelo no Secretio. O que preciso enviar?',
};


export const SOCIAL = {
  facebookUsername:                 'secretio',
  facebookIDUsername:               '636260263204734',
  instagramUsername:                'secretio',
  twitterUsername:                  'secretioBR',
  twitterIDUsername:                '837020906467766272',
  youtubeChannel:                   'UCymPVkRbb1sj2UPQs_YVqGw',
};


// App Stores Values
export const STORES = {
  secretioAppPackage:               'com.secretio',
  secretioAppStoreID:               '1455081329',
};



const Keys = {
  ...CONTACT,
  ...SOCIAL,
  ...STORES,
};



export default Keys;
