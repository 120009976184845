import React,
{
  useEffect,
  useState,
} from 'react';

import ButtonAddImage from '../../Buttons/ButtonAddImage';

import {
  PictureOrderView,
  PictureContentView,
  CardImportInput,
  PictureOrderButton,
  PictureOrderImage,
} from './styled';



export interface IProps {
  disabled?: boolean;
  idButton: string;
  fileImage?: any;
  image?: string;
  onPress?: any;
}



const StreamVideoItem: React.FC<IProps> = (props: IProps) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);



  useEffect(() => {
    if (props.fileImage) {
      setImageUrl(URL.createObjectURL(props.fileImage));
    }
  }, [props.fileImage]);


  useEffect(() => {
    setImageUrl(props.image || '');
  }, [props.image]);


  useEffect(() => {
    setImageUrl(props.image || '');
  }, []);



  return (

    <PictureOrderView>
      <PictureContentView htmlFor={props.idButton}>

        <CardImportInput
          disabled={props.disabled}
          type={'file'}
          id={props.idButton}
          onChange={(event) => {
            props.onPress(event);
          }}
          accept={'video/*'}
        />



        <PictureOrderButton>
          {(imageUrl === '' || imageUrl === null || imageUrl === undefined)
            ? (
              <p>
                Clique para adicinar um video
              </p>
            )
            : (


              <PictureOrderImage
                autoPlay
                controls={false}
                playsInline
                muted>
                <source src={imageUrl} />
                Seu navegador não suporta a reprodução de vídeos HTML5.
              </PictureOrderImage>

            )
          }
        </PictureOrderButton>



        <ButtonAddImage
          text={'Inserir imagem'}
        />

      </PictureContentView>
    </PictureOrderView>

  );
};



export default StreamVideoItem;
