import styled,
{
  css,
} from 'styled-components';

import Color from 'color';

import {
  GetPlatform,
} from '../../../config/plataform.config';

import {
  Colors,
} from '../../../shared/constants';

import {
  Card,
} from '../../Composh/web';



export interface IProps {
  accent?: boolean;
  disabled?: boolean;
  color?: string;
  backgroundColor?: string;
}



export const PanelHoverButton = css`
  :hover {
    background-color: ${(props: IProps) => Color(props.color).lighten(0.55).toString() || Colors.cardBackgroundHover};
    border-color: ${Colors.secondaryAccent};
  }
`;

export const PanelAccentStyle = css`
  border-color: ${Colors.grayLight};
  border-style: solid;
  border-width: 2px;
`;

export const PanelItemsInfoView = styled(Card.View)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 105px;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  transition: all .2s ease;
  opacity: ${(props: IProps) => props.disabled ? 0.5 : 1};
  cursor: ${(props: IProps) => props.disabled ? 'default' : 'pointer'};

  ${(props: IProps) => props.accent && PanelAccentStyle};

  ${(props: IProps) => GetPlatform() === 'WEB' && !props.disabled && PanelHoverButton};
`;
