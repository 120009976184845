import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const PictureOrderView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const PictureContentView = styled.label`
  width: 100%;
  height: 100%;
`;


export const CardImportInput = styled.input`
  display: none;
  width: 100%;
  height: 100%;
`;



export const PictureOrderButton = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: ${Sizes.imageLogoSize}px;
  width: ${Sizes.imageLogoSize}px;
  margin-top: 3px;

  background-color: ${Colors.gray};
  border-radius: ${Sizes.cardRadius}px;
  border-color: ${Colors.attention};
  border-style: solid;
  border-width: 1px;

  elevation: 4;

  shadow-offset: 5px 5px;
  shadow-color: gray;
  shadow-opacity: 0.7;
  shadow-radius: 4px;
`;



export const PictureOrderImage = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
