import ApiWS from '../index';



export const GetModeleReportsData = async (useralias: any) => {
  const url = `/support/modeles/reports/${useralias}`;

  const response = await ApiWS.get(url);
  return response.data;
};
