import {
  EAppMediaRelation,
} from '../../shared/enums';

import EStatusVisible from '../../shared/enums/Status/status-visibility.enum';

import ApiWS from '../index';



export const UpdateUserPicture = async (
  idUser: string,
  picture: any,
  payload: any,
) => {
  const requestConfig = {
    headers: {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  const form = new FormData();
  form.append('name', picture?.name || 'userImage');
  form.append('type', picture?.type || '');
  form.append('image', picture);

  payload?.typeRelation && form.append('typeRelation', payload?.typeRelation);
  payload?.revised && form.append('revised', payload?.revised || false);
  payload?.visible && form.append('visible', payload?.visible || EStatusVisible.INVISIBLE);
  payload?.visibility && form.append('visibility', payload?.visibility);
  payload?.canBuy && form.append('canBuy', payload?.canBuy);

  const url = `/support/medias/${EAppMediaRelation.USER}/image/${idUser}`;

  const pictureResponse = await ApiWS.post(url, form, requestConfig);
  return pictureResponse.data;
};



export const UpdateUserVideo = async (
  idUser: string,
  picture: any,
  payload: any,
) => {
  const requestConfig = {
    headers: {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  const form = new FormData();
  form.append('name', picture?.name || 'userVideo');
  form.append('type', picture?.type || '');
  form.append('image', picture);

  payload?.typeRelation && form.append('typeRelation', payload?.typeRelation);
  payload?.visibility && form.append('visibility', payload?.visibility);
  payload?.canBuy && form.append('canBuy', payload?.canBuy);

  const url = `/support/medias/${EAppMediaRelation.USER}/video/${idUser}`;

  const pictureResponse = await ApiWS.post(url, form, requestConfig);
  return pictureResponse.data;
};



export const AddLotUserMedias = async (
  idUser: string,
  mediaFiles: Array<any>,
) => {
  const requestConfig = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  const formData = new FormData();

  mediaFiles.forEach((itemMedia, index) => {
    formData.append(`media[${index}].name`, itemMedia?.name || 'userImage');
    formData.append(`media[${index}].type`, itemMedia?.type || '');
    formData.append(`media[${index}].image`, itemMedia?.image);

    itemMedia?.typeRelation && formData.append(`media[${index}].typeRelation`, itemMedia?.typeRelation);
    itemMedia?.visibility && formData.append(`media[${index}].visibility`, itemMedia?.visibility);
    itemMedia?.canBuy && formData.append(`media[${index}].canBuy`, itemMedia?.canBuy.toString());
  });

  const url = `/support/medias/${EAppMediaRelation.USER}/lot/${idUser}`;

  const response = await ApiWS.post(url, formData, requestConfig);
  return response.data;
};
