import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetProjectMessages,
} from '../../../../apis/endpoints/project-messages.endpoints';

import {
  IPagination,
} from '../../../../apis/interfaces/App/pagination.interface';

import {
  Body,
  Content,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import SubHeader from '../../../../components/Headers/SubHeader';
import {
  IconEdit,
} from '../../../../components/Icons';

import TableRow from '../../../../components/Table/TableRow';

import NameRoutes from '../../../../navigation/names';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import EDrawerMenu from '../../../../shared/enums/App/drawer-menu.enum';

import {
  FormatIsoDateWithPattern,
} from '../../../../shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  TableListHeaderContainer,
  TableListButtonAction,
  TableListRowItemButton,
} from '../../../../styles/form.styled';

import {
  ProjectParamsContentCenterFlex,
} from './styled';



export interface IRouteParams {
  useralias: string;
}



const ProjectMessagesFormScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const perPageArray = [10, 20, 30];

  const [pageSelected, setPageSelected] = useState<number>(Values.firstPage);
  const [tableProviders, setTableProviders] = useState<any>(null);
  const [periodArray, setModelesArray] = useState<Array<any>>([]);
  const [modelesPagination, setModelesPagination] = useState<IPagination | null>(null);

  const [loading, setLoading] = useState<boolean>(false);


  function renderTypeMessages(itemToReceived): string {
    const splitItem: Array<string> = itemToReceived?.split(', ');
    const itemMoreArray: Array<string> = [];

    if (splitItem.includes('USER')) {
      itemMoreArray.push('Usuário');
    }

    if (splitItem.includes('MODELE')) {
      itemMoreArray.push('Modelo');
    }

    if (splitItem.includes('SUPPORT')) {
      itemMoreArray.push('Suporte');
    }

    if (itemMoreArray?.length > 0) {
      return itemMoreArray.join(', ');
    }

    return '-';
  }


  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getActualModele() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      const payload = {
        pageNumber: tableProviders?.pageNumber || Values.firstPage,
        itemsPerPage: tableProviders?.itemsPerPage || perPageArray[0],
        // sortOrder: '',
        // sortColumn: '',
      } as any;

      // if (genderChoose) {
      //   payload.gender = genderChoose;
      // };

      const response = await GetProjectMessages(payload);

      if (!Array.isArray(response?.data) || !response?.data) {
        setModelesArray([]);
        return;
      }

      setModelesArray(response?.data);
      setPageSelected(response?.pagination?.pageCurrent);
      setModelesPagination(response?.pagination);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);

      return null;
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  function listHeaderComponent() {
    const today = new Date();
    const formattedDate = FormatIsoDateWithPattern(today.toISOString(), 'dd/MM/yyyy');


    return (

      <SubHeader
        title={`Hoje: ${formattedDate}`}
        color={Colors.secondaryDark}>

        <TableListHeaderContainer>

          <TableListButtonAction
            title={'Criar mensagem'}
            onPress={() => {
              history.push(NameRoutes.CreateProjectMessageScreen);
            }}
          />


          <TableListButtonAction
            title={'Filtros'}
            onPress={() => {
              // TODO
            }}
          />

        </TableListHeaderContainer>

      </SubHeader>

    );
  }


  const columns = [
    {
      dataField: 'id',
      label: 'ID',
      sort: true,
    },
    {
      dataField: 'toProfile',
      label: 'Mostrar para',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return renderTypeMessages(row?.toProfile) || '-';
      },
    },
    {
      dataField: 'status',
      label: 'Status',
      sort: true,
    },
    {
      dataField: 'title',
      label: 'Título',
      sort: true,
    },
    {
      dataField: 'description',
      label: 'Descrição',
      sort: true,
    },
    {
      dataField: 'text',
      label: 'Texto de suporte',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.text || '-';
      },
    },
    {
      dataField: 'startDate',
      label: 'Início em',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return FormatIsoDateWithPattern(row?.startDate, 'dd/MM/yyyy') || '-';
      },
    },
    {
      dataField: 'endDate',
      label: 'Final em',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return FormatIsoDateWithPattern(row?.endDate, 'dd/MM/yyyy') || '-';
      },
    },
    {
      dataField: 'active',
      label: 'Ativo',
      sort: false,
    },
    {
      dataField: 'EDIT',
      label: 'Editar',
      sort: false,
      formatter: (_cell: any, row: any) => {
        return (
          <TableListRowItemButton
            onClick={() => {
              if (!loading) {
                history.push({
                  pathname: NameRoutes.CreateProjectMessageScreen.replace(
                    ':idShort',
                    row?.id,
                  ),
                  state: {
                    edit: true,
                    item: row,
                  },
                });
              }
            }}>
            <IconEdit
              color={Colors.black}
              size={20}
            />
          </TableListRowItemButton>
        );
      },
    },
  ];



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.PROJECT_MESSAGES });
  }, []);


  useEffect(() => {
    getActualModele();
  }, [tableProviders]);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.black}>
              Avisos
            </Title>

            <SubTitle
              color={Colors.gray}>
              Todo o projeto
            </SubTitle>
          </Body>
        }
      />



      <Content>

        <ContentPadder>

          {listHeaderComponent()}



          <ProjectParamsContentCenterFlex
            backgroundColor={Colors.formBackground}
            borderRadius={Sizes.cardRadius}>

            <TableRow
              data={periodArray}
              columns={columns}
              headerTopBorder
              headerBottomBorder
              color={Colors.white}
              accentColor={Colors.secondaryDark}
              sizePerPageList={perPageArray}
              paginationProviders={(providers) => {
                // setPageSelected(providers?.page);
                setTableProviders({
                  pageNumber: providers?.page,
                  itemsPerPage: providers?.size,
                });
              }}
              paginationProps={{
                pageCurrent: pageSelected,
                pagesTotal: modelesPagination?.pagesTotal,
                from: modelesPagination?.fromCount,
                to: modelesPagination?.toCount,
                dataSize: modelesPagination?.itemsTotal,
              }}
            />

          </ProjectParamsContentCenterFlex>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default ProjectMessagesFormScreen;
