import ApiWS from '../index';



export const GetLocationModele = async (idModele: string) => {
  const url = `/support/modeles/location/${idModele}`;

  const response = await ApiWS.get(url);
  return response.data;
};



export const UpdateLocationModele = async (idModele: string, payload: any) => {
  const url = `/support/modeles/location/${idModele}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};
