import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  EditProjectParams,
  GetProjectParams,
} from '../../../apis/endpoints/project-params.endpoints';

import {
  Body,
  Content,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  Screen,
} from '../../../components/Containers/ScreenContainer';

import {
  ContentRowToColumnPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../components/Titles/SectionTitle';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  ProjectParamsContentCenterFlex,
  ProjectParamsWrapperItemContent,
  ProjectParamsActiveItemContent,
  ProjectParamsButtonPublishStyled,
  ProjectParamsRightSuggestion,
  ProjectParamsCardRight,
} from './styled';



export interface IRouteParams {
  useralias: string;
}



const ProjectParamsFormScreen: React.FC = () => {
  const dispatch = useDispatch();


  const [canAccessModule, setCanAccessModule] = useState<boolean>(false);
  const [canBeOnlineModule, setCanBeOnlineModule] = useState<boolean>(false);

  const [rentModule, setRentModule] = useState<boolean>(false);
  const [fanClubeModule, setFanClubeModule] = useState<boolean>(false);
  const [liveModule, setLiveModule] = useState<boolean>(false);
  const [massageModule, setMassageModule] = useState<boolean>(false);
  const [rankingModule, setRankingModule] = useState<boolean>(false);
  const [adsModule, setAdsModule] = useState<boolean>(false);

  const [showNotificationsModule, setShowNotificationsModule] = useState<boolean>(false);
  const [showChatMessagesModule, setShowChatMessagesModule] = useState<boolean>(false);
  const [showCalendarModule, setShowCalendarModule] = useState<boolean>(false);
  const [showFinancialModule, setShowFinancialModule] = useState<boolean>(false);
  const [showSignaturesModule, setShowSignaturesModule] = useState<boolean>(false);
  const [showLinkbioModule, setShowLinkbioModule] = useState<boolean>(false);

  const [streamModule, setStreamModule] = useState<boolean>(false);
  const [shopModule, setShopModule] = useState<boolean>(false);
  const [showBlogModule, setShowBlogModule] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function setStatesParams(itemReceived: any) {
    setCanAccessModule(itemReceived?.canAccess);
    setCanBeOnlineModule(itemReceived?.canBeOnline);

    setRentModule(itemReceived?.showRent);
    setFanClubeModule(itemReceived?.showFanClub);
    setLiveModule(itemReceived?.showLive);
    setMassageModule(itemReceived?.showMassage);
    setRankingModule(itemReceived?.showRanking);
    setAdsModule(itemReceived?.showAds);

    setShowFinancialModule(itemReceived?.showFinancial);
    setShowSignaturesModule(itemReceived?.showSignatures);

    setShowNotificationsModule(itemReceived?.showNotifications);
    setShowChatMessagesModule(itemReceived?.showChatMessages);
    setShowCalendarModule(itemReceived?.showCalendar);
    setShowLinkbioModule(itemReceived?.showLinkbio);

    setStreamModule(itemReceived?.showStream);
    setShopModule(itemReceived?.showShop);
    setShowBlogModule(itemReceived?.showBlog);
  }


  async function getEndpointParams() {
    try {
      setLoading(true);

      const response = await GetProjectParams();

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.attention, 'Nenhum parâmetro encontrado');
        return;
      }

      setStatesParams(response?.data);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);

      return null;
    }
    finally {
      setLoading(false);
    }
  };


  async function saveItemGroup(payload: any) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await EditProjectParams(payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      setStatesParams(response?.data);

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.PROJECT_PARAMS });
    getEndpointParams();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.black}>
              Parâmetros
            </Title>

            <SubTitle
              color={Colors.gray}>
              Todo o projeto
            </SubTitle>
          </Body>
        }
      />



      <Content>

        {loading && (
          <LoadingScreen
            type={'SCREEN'}
            color={Colors.secondary}
          />
        )}



        {!loading && (
          <ContentRowToColumnPadder>

            <ProjectParamsContentCenterFlex
              backgroundColor={Colors.formBackground}
              borderRadius={Sizes.cardRadius}>

              <ProjectParamsWrapperItemContent>

                <SectionTitle
                  title={'Ações'}
                />


                <ProjectParamsActiveItemContent>

                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={canAccessModule}
                    textOn={'Acesso/registro ativado'}
                    textOff={'Ativar acesso/registro'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ canAccess: !canAccessModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={canBeOnlineModule}
                    textOn={'Mostrar online ativado'}
                    textOff={'Ativar mostrar online'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ canBeOnline: !canBeOnlineModule });
                      }
                    }}
                  />

                </ProjectParamsActiveItemContent>

              </ProjectParamsWrapperItemContent>



              <ProjectParamsWrapperItemContent>

                <SectionTitle
                  title={'Serviços disponíveis'}
                />


                <ProjectParamsActiveItemContent>

                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={rentModule}
                    textOn={'Acompanhante ativado'}
                    textOff={'Ativar acompanhante'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showRent: !rentModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={fanClubeModule}
                    textOn={'Fan Club ativado'}
                    textOff={'Ativar fanClub'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showFanClub: !fanClubeModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={liveModule}
                    textOn={'Live ativado'}
                    textOff={'Ativar live'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showLive: !liveModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={massageModule}
                    textOn={'Massagem ativado'}
                    textOff={'Ativar massagem'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showMassage: !massageModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={rankingModule}
                    textOn={'Ranking ativado'}
                    textOff={'Ativar ranking'}
                    color={Colors.secondary}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showRanking: !rankingModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={adsModule}
                    textOn={'Anúncio ativado'}
                    textOff={'Ativar anúncio'}
                    color={Colors.secondary}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showAds: !adsModule });
                      }
                    }}
                  />

                </ProjectParamsActiveItemContent>

              </ProjectParamsWrapperItemContent>



              <ProjectParamsWrapperItemContent>

                <SectionTitle
                  title={'Financeiro'}
                />


                <ProjectParamsActiveItemContent>

                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={showFinancialModule}
                    textOn={'Gerir valores ativado'}
                    textOff={'Ativar gerir valores'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showFinancial: !showFinancialModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={showSignaturesModule}
                    textOn={'Assinaturas ativado'}
                    textOff={'Ativar assinaturas'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showSignatures: !showSignaturesModule });
                      }
                    }}
                  />

                </ProjectParamsActiveItemContent>

              </ProjectParamsWrapperItemContent>



              <ProjectParamsWrapperItemContent>

                <SectionTitle
                  title={'Ferramentas'}
                />


                <ProjectParamsActiveItemContent>

                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={showNotificationsModule}
                    textOn={'Notificações ativado'}
                    textOff={'Ativar notificações'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showNotifications: !showNotificationsModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={showChatMessagesModule}
                    textOn={'Chat/block ativado'}
                    textOff={'Ativar chat/block'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showChatMessages: !showChatMessagesModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={showCalendarModule}
                    textOn={'Calendário ativado'}
                    textOff={'Ativar calendário'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showCalendar: !showCalendarModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={showLinkbioModule}
                    textOn={'LinkBio ativado'}
                    textOff={'Ativar LinkBio'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showLinkbio: !showLinkbioModule });
                      }
                    }}
                  />

                </ProjectParamsActiveItemContent>

              </ProjectParamsWrapperItemContent>



              <ProjectParamsWrapperItemContent>

                <SectionTitle
                  title={'Plataformas'}
                />


                <ProjectParamsActiveItemContent>

                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={streamModule}
                    textOn={'Stream ativado'}
                    textOff={'Ativar stream'}
                    color={Colors.secondary}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showStream: !streamModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={shopModule}
                    textOn={'Shop ativado'}
                    textOff={'Ativar shop'}
                    color={Colors.secondary}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showShop: !shopModule });
                      }
                    }}
                  />


                  <ProjectParamsButtonPublishStyled
                    disabled={loading}
                    selected={showBlogModule}
                    textOn={'blog ativado'}
                    textOff={'Ativar blog'}
                    onPress={() => {
                      if (!loading) {
                        saveItemGroup({ showBlog: !showBlogModule });
                      }
                    }}
                  />

                </ProjectParamsActiveItemContent>

              </ProjectParamsWrapperItemContent>

            </ProjectParamsContentCenterFlex>



            <ProjectParamsRightSuggestion>

              <ProjectParamsCardRight
                backgroundColor={Colors.formBackground}
                borderRadius={Sizes.cardRadius}>

                <p>
                  Outras informações
                </p>

              </ProjectParamsCardRight>

            </ProjectParamsRightSuggestion>

          </ContentRowToColumnPadder>
        )}



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default ProjectParamsFormScreen;
