import {
  EChannelType,
} from '../../enums';



export const typeGenre = [
  {
    id: 1,
    value: EChannelType.ALL,
    label: 'Qualquer',
  },
  {
    id: 2,
    value: EChannelType.GAY,
    label: 'Gay',
  },
  {
    id: 3,
    value: EChannelType.LESBIAN,
    label: 'Lesbico',
  },
  {
    id: 4,
    value: EChannelType.STRAIGHT,
    label: 'Hétero',
  },
];
