import React,
{
  useState,
} from 'react';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  SwipeableDrawer,
} from '@material-ui/core';

import Color from 'color';

import UserAvatar from '../../components/Contents/UserAvatar';

import {
  IconAntiFake,
  IconAvaliations,
  IconCategoryMain,
  IconCategorySub,
  IconDashboard,
  IconAccessExit,
  IconHelp,
  IconHome,
  IconMediaPhoto,
  IconMediaVideo,
  IconMessages,
  IconNotification,
  IconRanking,
  IconService,
  IconSettings,
  IconDashboardShop,
  IconShopProducts,
  IconThemeDark,
  IconThemeLight,
  IconTv,
  IconTvVideo,
  IconTvVideoAds,
  IconUpgrade,
  IconUser,
  IconUserAdd,
  IconUserStar,
  IconVerified,
  IconHelpRequests,
  IconHelpQuestions,
  IconHelpCategories,
  IconDeleteTemp,
  IconMediaProfile,
  IconDashboardApp,
  IconSocialWhatsAppMessages,
} from '../../components/Icons';

import LoadingProgress from '../../components/Loadings/LoadingProgress';
import DrawerTitle from '../../components/Titles/DrawerTitle';

import {
  RootState,
} from '../../redux';

import {
  APP_DRAWER_ACTION,
  APP_MENU_SELECTED_ACTION,
  USER_VERIFIED_MODAL,
} from '../../redux/reducers/app-modals.store';

import {
  USER_LOGOUT_STATE,
} from '../../redux/reducers/user.store';

import {
  Colors,
  Sizes,
} from '../../shared/constants';

import {
  EPlansUser,
  EDrawerMenu,
} from '../../shared/enums';

import NameRoutes from '../names';

import {
  MenuContainer,
  MenuHeaderContainer,
  MenuImageHeaderView,
  MenuThemeContent,
  MenuThemeButton,
  MenuVerifiedContent,
  MenuUserNameText,
  IconVerifiedView,
  MenuHeaderName,
  MenuHeaderStatusText,
  MenuListContent,
  ActiveIndicatorContent,
  MenuContentPlace,
  MenuItemList,
  ItemMenuInfoItem,
  ItemMenuText,
  ItemMenuSubText,
  ItemMenuIcon,
  ItemMenuContainer,
  MenuImageContent,
  MenuHeaderInfo,
  MenuSeparator,
  MenuInfoNameText,
} from './styled';



export interface IProps {
  open?: boolean;

  onOpen?: any;
  onClose?: any;
}



const DrawerMenu: React.FC<IProps> = (props: any) => {
  const dispatch = useDispatch();
  const navigation = useHistory();


  const selectedIndex = useSelector((state: RootState) => state.appModals.menuSelected);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = 'true'; // user?.id;


  const genderColorChoose = Colors.secondary;
  const colorSelected = Color(Colors.secondary).darken(0.1).toString();
  const colorUnselected = Colors.textSubtitle;

  const [fixMenu] = useState(false);



  const routesHighlights = [
    {
      index: EDrawerMenu.HOME,
      icon: (
        <IconHome
          size={24}
          color={selectedIndex === EDrawerMenu.HOME ? colorSelected : colorUnselected}
        />
      ),
      name: 'Início',
      router: NameRoutes.HomeScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.RANKING,
      icon: (
        <IconRanking
          size={22}
          color={selectedIndex === EDrawerMenu.RANKING ? colorSelected : colorUnselected}
        />
      ),
      name: 'Rankings',
      router: NameRoutes.RankingsScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
  ];


  const routesProject = [
    {
      index: EDrawerMenu.PROJECT_PARAMS,
      icon: (
        <IconDashboard
          size={24}
          color={selectedIndex === EDrawerMenu.PROJECT_PARAMS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Parâmetros',
      router: NameRoutes.ProjectParamsFormScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.PROJECT_MESSAGES,
      icon: (
        <IconMessages
          size={24}
          color={selectedIndex === EDrawerMenu.PROJECT_MESSAGES ? colorSelected : colorUnselected}
        />
      ),
      name: 'Avisos',
      router: NameRoutes.ProjectMessagesFormScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.PROJECT_PLANS,
      icon: (
        <IconUpgrade
          size={22}
          color={selectedIndex === EDrawerMenu.PROJECT_PLANS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Planos e valores',
      router: NameRoutes.PlansPricesShowAllScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
  ];


  const routesProfiles = [
    {
      index: EDrawerMenu.ALL_MODELES,
      icon: (
        <IconUser
          size={24}
          color={selectedIndex === EDrawerMenu.ALL_MODELES ? colorSelected : colorUnselected}
        />
      ),
      name: 'Todos os modelos',
      router: NameRoutes.ModelesAllListScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.MODELE_TO_PUBLISH,
      icon: (
        <IconUserAdd
          size={24}
          color={selectedIndex === EDrawerMenu.MODELE_TO_PUBLISH ? colorSelected : colorUnselected}
        />
      ),
      name: 'Modelos a publicar',
      router: NameRoutes.ModelesToPublishScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
  ];


  const routesApp = [
    {
      index: EDrawerMenu.APP_DASHBOARD,
      icon: (
        <IconDashboardApp
          size={22}
          color={selectedIndex === EDrawerMenu.APP_DASHBOARD ? colorSelected : colorUnselected}
        />
      ),
      name: 'Dashboard',
      router: NameRoutes.AppDashboardScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.GENRES_ADS,
      icon: (
        <IconNotification
          size={24}
          color={selectedIndex === EDrawerMenu.GENRES_ADS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Gêneros [Destaques]',
      router: NameRoutes.GendersHighlightsScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.RENT_SERVICES,
      icon: (
        <IconService
          size={24}
          color={selectedIndex === EDrawerMenu.RENT_SERVICES ? colorSelected : colorUnselected}
        />
      ),
      name: 'Serviços de acompanhante',
      router: NameRoutes.RentServicesScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.REVIEW_TO_PUBLISH,
      icon: (
        <IconAvaliations
          size={24}
          color={selectedIndex === EDrawerMenu.REVIEW_TO_PUBLISH ? colorSelected : colorUnselected}
        />
      ),
      name: 'Avaliações a publicar',
      router: NameRoutes.AvaliationsToReviseScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.PROFILE_MEDIAS_TO_PUBLISH,
      icon: (
        <IconMediaProfile
          size={24}
          color={selectedIndex === EDrawerMenu.PROFILE_MEDIAS_TO_PUBLISH ? colorSelected : colorUnselected}
        />
      ),
      name: 'Mídias do perfil a publicar',
      router: NameRoutes.ProfileMediasApproveScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.IMAGES_TO_PUBLISH,
      icon: (
        <IconMediaPhoto
          size={24}
          color={selectedIndex === EDrawerMenu.IMAGES_TO_PUBLISH ? colorSelected : colorUnselected}
        />
      ),
      name: 'Imagens a publicar',
      router: NameRoutes.PicturesAproveAllScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.VIDEOS_TO_PUBLISH,
      icon: (
        <IconMediaVideo
          size={24}
          color={selectedIndex === EDrawerMenu.VIDEOS_TO_PUBLISH ? colorSelected : colorUnselected}
        />
      ),
      name: 'Vídeos a publicar',
      router: NameRoutes.VideosAproveAllScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.ANTIFAKE_TO_PUBLISH,
      icon: (
        <IconAntiFake
          size={24}
          color={selectedIndex === EDrawerMenu.ANTIFAKE_TO_PUBLISH ? colorSelected : colorUnselected}
        />
      ),
      name: 'Mídia anti-fake a publicar',
      router: NameRoutes.AntiFakeAllScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.DELETED_MEDIAS,
      icon: (
        <IconDeleteTemp
          size={24}
          color={selectedIndex === EDrawerMenu.DELETED_MEDIAS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Mídias deletadas',
      router: NameRoutes.DeletedMediasScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
  ];


  const routesStream = [
    {
      index: EDrawerMenu.STREAM_CATEGORIES,
      icon: (
        <IconCategoryMain
          size={24}
          color={selectedIndex === EDrawerMenu.STREAM_CATEGORIES ? colorSelected : colorUnselected}
        />
      ),
      name: 'Categorias',
      router: NameRoutes.CategoriesShowAllScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.STREAM_SUBCATEGORIES,
      icon: (
        <IconCategorySub
          size={22}
          color={selectedIndex === EDrawerMenu.STREAM_SUBCATEGORIES ? colorSelected : colorUnselected}
        />
      ),
      name: 'Subcategorias',
      router: NameRoutes.SubCategoriesShowAllScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.STREAM_CHANNELS,
      icon: (
        <IconTv
          size={22}
          color={selectedIndex === EDrawerMenu.STREAM_CHANNELS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Canais',
      router: NameRoutes.ChannelsShowAllScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.STREAM_STARS,
      icon: (
        <IconUserStar
          size={22}
          color={selectedIndex === EDrawerMenu.STREAM_STARS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Estrelas',
      router: NameRoutes.StarsShowAllScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.STREAM_VIDEOS,
      icon: (
        <IconTvVideo
          size={22}
          color={selectedIndex === EDrawerMenu.STREAM_VIDEOS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Videos',
      router: NameRoutes.VideosShowAllScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.STREAM_VIDEOS_ADS,
      icon: (
        <IconTvVideoAds
          size={22}
          color={selectedIndex === EDrawerMenu.STREAM_VIDEOS_ADS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Anúncio de videos',
      router: NameRoutes.StreamVideosAdsScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
  ];


  const routesShop = [
    {
      index: EDrawerMenu.SHOP_DASHBOARD,
      icon: (
        <IconDashboardShop
          size={24}
          color={selectedIndex === EDrawerMenu.SHOP_DASHBOARD ? colorSelected : colorUnselected}
        />
      ),
      name: 'Dashboard',
      router: NameRoutes.ShopDashboardScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.SHOP_PRODUCTS,
      icon: (
        <IconShopProducts
          size={24}
          color={selectedIndex === EDrawerMenu.SHOP_PRODUCTS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Produtos',
      router: NameRoutes.ShopProductsScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
  ];


  const routesMarketing = [
    {
      index: EDrawerMenu.MARKETING_SOCIAL,
      icon: (
        <IconHome
          size={24}
          color={selectedIndex === EDrawerMenu.MARKETING_SOCIAL ? colorSelected : colorUnselected}
        />
      ),
      name: 'Redes sociais',
      router: NameRoutes.SocialScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.MARKETING_MESSAGE_WHATSAPP,
      icon: (
        <IconSocialWhatsAppMessages
          size={24}
          color={selectedIndex === EDrawerMenu.MARKETING_MESSAGE_WHATSAPP ? colorSelected : colorUnselected}
        />
      ),
      name: 'Anúncios WhatsApp',
      router: NameRoutes.WhatsAppAdsScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
  ];


  const routesHelpModules = [
    {
      index: EDrawerMenu.HELP_FAQ_CATEGORIES,
      icon: (
        <IconHelpCategories
          size={24}
          color={selectedIndex === EDrawerMenu.HELP_FAQ_CATEGORIES ? colorSelected : colorUnselected}
        />
      ),
      name: 'Categorias [Ajuda]',
      router: NameRoutes.HelpFaqCategoriesShowScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.HELP_FAQ_QUESTIONS,
      icon: (
        <IconHelpQuestions
          size={24}
          color={selectedIndex === EDrawerMenu.HELP_FAQ_QUESTIONS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Questões [Ajuda]',
      router: NameRoutes.HelpFaqQuestionsShowScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.HELP_REQUESTS,
      icon: (
        <IconHelpRequests
          size={24}
          color={selectedIndex === EDrawerMenu.HELP_REQUESTS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Solicitações',
      router: NameRoutes.HelpRequestsShowScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
  ];


  const routesOptions = [
    {
      index: EDrawerMenu.SETTINGS,
      icon: (
        <IconSettings
          size={24}
          color={selectedIndex === EDrawerMenu.SETTINGS ? colorSelected : colorUnselected}
        />
      ),
      name: 'Configurações',
      router: NameRoutes.SettingsAppScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.HELP,
      icon: (
        <IconHelp
          size={24}
          color={selectedIndex === EDrawerMenu.HELP ? colorSelected : colorUnselected}
        />
      ),
      name: 'Ajuda',
      router: NameRoutes.HelpFaqScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
    {
      index: EDrawerMenu.EXIT,
      icon: (
        <IconAccessExit
          size={24}
          color={selectedIndex === EDrawerMenu.EXIT ? colorSelected : colorUnselected}
        />
      ),
      name: 'Sair',
      router: NameRoutes.AccessScreen,
      visible: true,
      enable: true,
      enableMessage: null,
    },
  ];


  const handleListItemClick = (index: EDrawerMenu) => {
    dispatch({ type: APP_DRAWER_ACTION, payload: false });
    if (index !== EDrawerMenu.EXIT) {
      dispatch({ type: APP_MENU_SELECTED_ACTION, payload: index });
    }
  };


  function pushButtonCLick(route: any) {
    if (route === NameRoutes.AccessScreen) {
      dispatch({ type: USER_LOGOUT_STATE, payload: true });
    }
    else {
      navigation.push(route);
    }
  }


  function renderItem(item: any) {
    const focused = selectedIndex === item.index;
    const colorFocused = focused ? colorSelected : colorUnselected;



    if (item.visible) {
      return (

        <ItemMenuContainer
          key={item.index}
          id={item.index}
          disabled={!item.enable}
          selected={focused}
          onClick={() => {
            if (item.enable) {
              pushButtonCLick(item.router);
              handleListItemClick(item.index);
            }
          }}>

          <ItemMenuIcon>
            {item.icon}
          </ItemMenuIcon>


          <ItemMenuInfoItem>
            <ItemMenuText
              selected={focused}
              color={colorFocused}>
              {item.name}
            </ItemMenuText>


            <ItemMenuSubText
              color={colorFocused}>
              {item.enableMessage}
            </ItemMenuSubText>
          </ItemMenuInfoItem>

        </ItemMenuContainer>

      );
    }
  };



  return (

    <SwipeableDrawer
      anchor={'left'}
      disableSwipeToOpen={true}
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}>

      <MenuContainer
        style={{
          height: '100%',
          width: !fixMenu ? 300 : 60,
        }}>


        <MenuHeaderContainer>

          <MenuImageContent
            style={{
              flexDirection: !fixMenu ? 'row' : 'column',
            }}>

            <UserAvatar
              plan={EPlansUser.NONE}
              image={user?.picture}
              width={!fixMenu ? Sizes.imageDrawer : 45}
            />


            <MenuImageHeaderView>

              <MenuThemeContent>

                <MenuHeaderStatusText>
                  Support
                </MenuHeaderStatusText>


                <MenuThemeButton
                  onClick={() => {
                    // TODO
                  }}>
                  {false
                    ? (
                      <IconThemeLight
                        color={Colors.white}
                        size={18}
                      />
                    )
                    : (
                      <IconThemeDark
                        color={Colors.black}
                        size={18}
                      />
                    )
                  }
                </MenuThemeButton>

              </MenuThemeContent>



              <MenuVerifiedContent>

                <MenuUserNameText>
                  @{user?.username || '-'}
                </MenuUserNameText>


                {user?.verified && (
                  <IconVerifiedView
                    onClick={() => {
                      dispatch({ type: USER_VERIFIED_MODAL, payload: true });
                    }}>
                    <IconVerified
                      size={13}
                    />
                  </IconVerifiedView>
                )}

              </MenuVerifiedContent>

            </MenuImageHeaderView>

          </MenuImageContent>



          {!fixMenu && (
            <MenuHeaderInfo>
              <MenuHeaderName>
                {user?.publicName || '-'}
              </MenuHeaderName>


              <MenuSeparator
                height={1.5}
                width={110}
                color={Colors.gray}
              />


              <MenuInfoNameText>
                {'Suporte'}
              </MenuInfoNameText>
            </MenuHeaderInfo>
          )}

        </MenuHeaderContainer>



        <MenuListContent>

          {!availableUser && (
            <ActiveIndicatorContent>
              <LoadingProgress
                color={Colors.secondary}
                size={20} // 'large'
              />
            </ActiveIndicatorContent>
          )}



          {availableUser && (
            <MenuContentPlace>

              {!routesHighlights.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu ? 'Destaques' : ' '}
                    backgroundColor={genderColorChoose}
                  />

                  {routesHighlights.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesProject.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu ? 'Todo o projeto' : ' '}
                    backgroundColor={genderColorChoose}
                  />

                  {routesProject.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesProfiles.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu ? 'Perfis' : ' '}
                    backgroundColor={genderColorChoose}
                  />

                  {routesProfiles.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesApp.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu ? 'Secretio App' : ' '}
                    backgroundColor={genderColorChoose}
                  />

                  {routesApp.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesStream.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu ? 'Stream' : ' '}
                    backgroundColor={genderColorChoose}
                  />

                  {routesStream.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesShop.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu ? 'Shop' : ' '}
                    backgroundColor={genderColorChoose}
                  />

                  {routesShop.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesMarketing.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu ? 'Marketing' : ' '}
                    backgroundColor={genderColorChoose}
                  />

                  {routesMarketing.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesHelpModules.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu ? 'Apoios' : ' '}
                    backgroundColor={genderColorChoose}
                  />

                  {routesHelpModules.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesOptions.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  <DrawerTitle
                    title={!fixMenu ? 'Opções' : ' '}
                    backgroundColor={genderColorChoose}
                  />

                  {routesOptions.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}


            </MenuContentPlace>
          )}
        </MenuListContent>

      </MenuContainer>
    </SwipeableDrawer>
  );
};



export default DrawerMenu;
