import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetStreamSubCategories,
} from '../../../../apis/endpoints/stream-subcategories.endpoints';

import {
  IPagination,
} from '../../../../apis/interfaces/App/pagination.interface';

import {
  Body,
  Content,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import SubHeader from '../../../../components/Headers/SubHeader';
import {
  IconEdit,
} from '../../../../components/Icons';

import TableRow from '../../../../components/Table/TableRow';

import NameRoutes from '../../../../navigation/names';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import EDrawerMenu from '../../../../shared/enums/App/drawer-menu.enum';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  TableListHeaderContainer,
  TableListButtonAction,
  TableListRowItemButton,
} from '../../../../styles/form.styled';

import {
  SubCateoryItemEditButton,
  SubCateoryItemEditText,
  ProjectParamsContentCenterFlex,
  CabinImageView,
  CabinListImage,
} from './styled';



const SubCategoriesShowAllScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const perPageArray = [10, 20, 30];

  const [pageSelected, setPageSelected] = useState<number>(Values.firstPage);
  const [tableProviders, setTableProviders] = useState<any>(null);
  const [periodArray, setModelesArray] = useState<Array<any>>([]);
  const [modelesPagination, setModelesPagination] = useState<IPagination | null>(null);

  const [loading, setLoading] = useState<boolean>(false);


  const columns = [
    {
      dataField: 'picture',
      label: 'Imagem',
      sort: true,
      headerStyle: () => {
        return { width: '40px' };
      },
      formatter: (_cell: any, row: any) => {
        return (
          <CabinImageView>
            <CabinListImage
              src={row?.picture}
            />
          </CabinImageView>
        );
      },
    },
    {
      dataField: 'id',
      label: 'ID',
      sort: true,
    },
    {
      dataField: 'name',
      label: 'Nome',
      sort: true,
    },
    {
      dataField: 'description',
      label: 'Descrição',
      sort: true,
    },
    {
      dataField: 'genre',
      label: 'Gênero',
      sort: true,
    },
    {
      dataField: 'category',
      label: 'Categoria',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return (
          <SubCateoryItemEditButton
            onClick={() => {
              if (!loading) {
                history.push({
                  pathname: NameRoutes.CategoriesCreateScreen,
                  state: {
                    edit: true,
                    item: row?.category,
                  },
                });
              }
            }}>
            <SubCateoryItemEditText>
              {row?.category?.name}
            </SubCateoryItemEditText>

            <IconEdit
              color={Colors.black}
              size={20}
            />
          </SubCateoryItemEditButton>
        );
      },
    },
    {
      dataField: 'visible',
      label: 'Visível',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.visible ? 'Visível' : 'Não visível';
      },
    },
    {
      dataField: 'edit',
      label: 'Editar',
      sort: false,
      formatter: (_cell: any, row: any) => {
        return (
          <TableListRowItemButton
            onClick={() => {
              if (!loading) {
                history.push({
                  pathname: NameRoutes.SubCategoriesCreateScreen,
                  state: {
                    edit: true,
                    item: row,
                  },
                });
              }
            }}>
            <IconEdit
              color={Colors.black}
              size={20}
            />
          </TableListRowItemButton>
        );
      },
    },
  ];



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getActualModele() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      const payload = {
        pageNumber: tableProviders?.pageNumber || Values.firstPage,
        itemsPerPage: tableProviders?.itemsPerPage || perPageArray[0],
        // sortOrder: '',
        // sortColumn: '',
      } as any;

      // if (genderChoose) {
      //   payload.gender = genderChoose;
      // };

      const response = await GetStreamSubCategories(payload);

      if (!Array.isArray(response?.data) || !response?.data) {
        setModelesArray([]);
        return;
      }

      setModelesArray(response?.data);
      setPageSelected(response?.pagination?.pageCurrent);
      setModelesPagination(response?.pagination);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);

      return null;
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  function listHeaderComponent() {
    return (

      <SubHeader
        title={'Um título'}
        color={Colors.secondaryDark}>

        <TableListHeaderContainer>

          <TableListButtonAction
            title={'Criar sub-categoria'}
            onPress={() => {
              history.push(NameRoutes.SubCategoriesCreateScreen);
            }}
          />


          <TableListButtonAction
            title={'Filtros'}
            onPress={() => {
              // TODO
            }}
          />

        </TableListHeaderContainer>

      </SubHeader>

    );
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.STREAM_SUBCATEGORIES });
  }, []);


  useEffect(() => {
    getActualModele();
  }, [tableProviders]);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.black}>
              Sub-categorias
            </Title>

            <SubTitle
              color={Colors.gray}>
              Stream
            </SubTitle>
          </Body>
        }
      />



      <Content>

        <ContentPadder>

          {listHeaderComponent()}



          <ProjectParamsContentCenterFlex
            backgroundColor={Colors.formBackground}
            borderRadius={Sizes.cardRadius}>

            <TableRow
              data={periodArray}
              columns={columns}
              headerTopBorder
              headerBottomBorder
              color={Colors.white}
              accentColor={Colors.secondaryDark}
              sizePerPageList={perPageArray}
              paginationProviders={(providers) => {
                // setPageSelected(providers?.page);
                setTableProviders({
                  pageNumber: providers?.page,
                  itemsPerPage: providers?.size,
                });
              }}
              paginationProps={{
                pageCurrent: pageSelected,
                pagesTotal: modelesPagination?.pagesTotal,
                from: modelesPagination?.fromCount,
                to: modelesPagination?.toCount,
                dataSize: modelesPagination?.itemsTotal,
              }}
            />

          </ProjectParamsContentCenterFlex>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default SubCategoriesShowAllScreen;
