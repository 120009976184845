import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  EditRentItemModele,
} from '../../../../../../apis/endpoints/modeles.endpoints';

import ButtonAction from '../../../../../../components/Buttons/ButtonAction';
import ChipApp from '../../../../../../components/Chip';

import {
  Content,
} from '../../../../../../components/Composh/web';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../../../../components/Contents/ContentPadder';

import FooterApp from '../../../../../../components/Footers/FooterApp';
import InputChip from '../../../../../../components/Inputs/InputChip';
import LoadingScreen from '../../../../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../../../../components/Titles/SectionTitle';

import {
  returnIconGenre,
} from '../../../../../../config/genre-icon.config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../../../redux/reducers/app-modals.store';

import { attendanceModele } from '../../../../../../shared/arrays/Rent/modele-attendance.array';
import { availableModele } from '../../../../../../shared/arrays/Rent/modele-available.array';
import { safeSexArray } from '../../../../../../shared/arrays/User/user-safe-sex-options.array';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../../../shared/constants';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../../../shared/utils/errors.utils';

import {
  CardRentForm,
  WrapperItemContent,
  ToggleModeleEdit,
  ModeleEditInputContent,
  InputBooleanStyled,
} from './styled';



export interface IProps {
  modele: any;
}



const ModeleRentsEditScreen: React.FC<IProps> = (props: IProps) => {
  const modeleDetails = props.modele || null;

  const dispatch = useDispatch();


  const formik = useRef<FormikProps<any>>(null);

  const [genderMeet, setGenderMeet] = useState<Array<any>>([]);
  const [availableTo, setAvailableTo] = useState<Array<any>>([]);
  const [sexCondom, setSexCondom] = useState<string | null>(null);

  const [myLocation, setMyLocation] = useState<boolean>(false);
  const [yourLocation, setYourLocation] = useState<boolean>(false);
  const [hotel, setHotel] = useState<boolean>(false);
  const [motel, setMotel] = useState<boolean>(false);
  const [travel, setTravel] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    if (formik.current) {
      if (modeleDetails?.appRent?.genderMeet) {
        const splitItem = modeleDetails?.appRent?.genderMeet?.split(', ');
        setGenderMeet(splitItem);
      }

      if (modeleDetails?.appRent?.availableTo) {
        const splitItem = modeleDetails?.appRent?.availableTo?.split(', ');
        setAvailableTo(splitItem);
      }

      const findsexCondom = safeSexArray?.find((item) => item?.value === modeleDetails?.appRent?.sexCondom);
      formik.current?.setFieldValue('sexCondom', findsexCondom?.value);
      setSexCondom(findsexCondom?.value);

      setMyLocation(modeleDetails?.appRentPlaces?.myLocation);
      formik.current?.setFieldValue('myLocation', modeleDetails?.appRentPlaces?.myLocation);

      setYourLocation(modeleDetails?.appRentPlaces?.yourLocation);
      formik.current?.setFieldValue('yourLocation', modeleDetails?.appRentPlaces?.yourLocation);

      setHotel(modeleDetails?.appRentPlaces?.hotel);
      formik.current?.setFieldValue('hotel', modeleDetails?.appRentPlaces?.hotel);

      setMotel(modeleDetails?.appRentPlaces?.motel);
      formik.current?.setFieldValue('motel', modeleDetails?.appRentPlaces?.motel);

      setTravel(modeleDetails?.appRentPlaces?.travel);
      formik.current?.setFieldValue('travel', modeleDetails?.appRentPlaces?.travel);

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function mountPayload() {
    const values = formik?.current?.values;

    const payload = {
      genderMeet: values?.genderMeet?.join(', '),
      availableTo: values?.availableTo?.join(', '),
      sexCondom: values?.sexCondom,

      myLocation: values?.myLocation,
      yourLocation: values?.yourLocation,
      hotel: values?.hotel,
      motel: values?.motel,
      travel: values?.travel,
    };

    return payload;
  }


  async function saveItemGroup() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = mountPayload();

    try {
      const response = await EditRentItemModele(modeleDetails?.useralias, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function setArrayMoreItems(name: string, item: string, valueTo: any, setValueTo: any) {
    const oldValues = [...valueTo];
    const index = oldValues.indexOf(item);
    if (index !== -1) {
      oldValues.splice(index, 1);
    }
    else {
      oldValues.push(item);
    }
    setValueTo(oldValues);
    formik.current?.setFieldValue(name, oldValues);
  }


  function renderAttendanceGenre(item: any, index: number) {
    const verifyItem = genderMeet.some((valueItem) => valueItem === item?.value);


    return (
      <ToggleModeleEdit
        hideBorder
        key={index}
        backgroundColor={verifyItem
          ? Colors.secondaryDark
          : Colors.inputBackground
        }
        icon={returnIconGenre(
          item?.value,
          verifyItem
            ? Colors.white
            : Colors.inputPlaceholder,
          35,
        )}
        label={item?.label}
        color={
          verifyItem
            ? Colors.white
            : Colors.inputPlaceholder
        }
        onPress={() => {
          setArrayMoreItems('genderMeet', item?.value, genderMeet, setGenderMeet);
        }}
      />

    );
  }


  function renderChipAvailableTo(item: any, index: number) {
    const verifyItem = availableTo.some((valueItem) => valueItem === item?.value);


    return (

      <ChipApp
        key={index}
        disabled={loading}
        selected={verifyItem}
        title={item?.label}
        onPress={() => {
          setArrayMoreItems('availableTo', item?.value, availableTo, setAvailableTo);
        }}
      />

    );
  }


  function renderModalities(item: any, index: number, onPress: any) {
    return (

      <ChipApp
        key={index}
        disabled={loading}
        selected={sexCondom === item?.value}
        title={item?.label}
        onPress={onPress}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveItemGroup();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        // handleChange,
        // handleBlur,
        // values,
        // errors,
        setFieldValue,
      }) => (

        <Content>

          {!mounted && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.white}
            />
          )}



          {mounted && (
            <ContentPadder>

              <CardRentForm
                backgroundColor={Colors.formBackground}
                borderRadius={Sizes.cardRadius}>

                <WrapperItemContent>
                  <SectionTitle
                    title={'Atendimento'}
                  />


                  <InputChip
                    countLimit={'Marque um ou vários'}>
                    {attendanceModele.map((item: any, index: number) => (
                      renderAttendanceGenre(item, index)
                    ))}
                  </InputChip>
                </WrapperItemContent>



                <WrapperItemContent>
                  <SectionTitle
                    title={'Disponível para:'}
                  />


                  <InputChip
                    countLimit={'Marque um ou vários'}>
                    {availableModele.map((item: any, index: number) => (
                      renderChipAvailableTo(item, index)
                    ))}
                  </InputChip>
                </WrapperItemContent>



                <WrapperItemContent>
                  <SectionTitle
                    title={'Sexo e Camisinha'}
                  />


                  <InputChip
                    countLimit={'Marque apenas um'}>
                    {safeSexArray.map((item: any, index: number) => (
                      renderModalities(
                        item,
                        index,
                        () => {
                          setFieldValue('sexCondom', item?.value);
                          setSexCondom(item?.value);
                        },
                      )
                    ))}
                  </InputChip>
                </WrapperItemContent>



                <WrapperItemContent>
                  <SectionTitle
                    title={'Locais de atendimento'}
                  />


                  <ModeleEditInputContent>
                    <InputBooleanStyled
                      disabled={loading}
                      labelText={'Meu local'}
                      selected={myLocation}
                      onPress={(state: boolean) => {
                        setFieldValue('myLocation', state);
                        setMyLocation(state);
                      }}
                    />


                    <InputBooleanStyled
                      disabled={loading}
                      labelText={'Seu local'}
                      selected={yourLocation}
                      onPress={(state: boolean) => {
                        setFieldValue('yourLocation', state);
                        setYourLocation(state);
                      }}
                    />


                    <InputBooleanStyled
                      disabled={loading}
                      labelText={'Hotel'}
                      selected={hotel}
                      onPress={(state: boolean) => {
                        setFieldValue('hotel', state);
                        setHotel(state);
                      }}
                    />


                    <InputBooleanStyled
                      disabled={loading}
                      labelText={'Motel'}
                      selected={motel}
                      onPress={(state: boolean) => {
                        setFieldValue('motel', state);
                        setMotel(state);
                      }}
                    />


                    <InputBooleanStyled
                      disabled={loading}
                      labelText={'Viagem'}
                      selected={travel}
                      onPress={(state: boolean) => {
                        setFieldValue('travel', state);
                        setTravel(state);
                      }}
                    />
                  </ModeleEditInputContent>
                </WrapperItemContent>



                <ContentButtonForm>
                  <ButtonAction
                    width={Sizes.buttonMinWidth}
                    title={'Salvar'}
                    onPress={() => {
                      handleSubmit();
                    }}
                  />
                </ContentButtonForm>

              </CardRentForm>

            </ContentPadder>
          )}



          <FooterApp />

        </Content>

      )}
    </Formik>

  );
};



export default ModeleRentsEditScreen;
