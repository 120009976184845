import React,
{
  useEffect,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Content,
  Title,
} from '../../components/Composh/web';

import {
  Screen,
} from '../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../components/Controls';

import FooterApp from '../../components/Footers/FooterApp';
import HeaderScreen from '../../components/Headers/HeaderScreen';

import NameRoutes from '../../navigation/names';

import {
  APP_MENU_SELECTED_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  Colors,
} from '../../shared/constants';

import EDrawerMenu from '../../shared/enums/App/drawer-menu.enum';

import {
  HomeContainer,
} from './styled';



const HomeScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.HOME });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
          />
        }
        centerContent={
          <Title
            color={Colors.black}>
            Início
          </Title>
        }
      />

      <Content>

        <ContentPadder>

          <div>
            <h2>
              HOME
            </h2>

          </div>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default HomeScreen;
