export enum EAppMediaTypeRelation {
  USER_IMAGE = 'user_image',
  USER_COVER = 'user_cover',
  GALLERY_IMAGES = 'gallery_images',
  GALLERY_VIDEOS = 'gallery_videos',
}



export default EAppMediaTypeRelation;
