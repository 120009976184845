import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

// import { Container } from './styles';



export interface IProps {
  color?: string;
}



const TableTextEmpty: React.FC<IProps> = (props: IProps) => {
  return (

    <p style={{ fontSize: 16, color: props.color || Colors.black }}>
      Sem dados a mostrar
    </p>

  );
};



export default TableTextEmpty;
