export enum EPictureInputType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}



export enum EPictureType {
  JPG = 'JPG',
  JPEG = 'JPEG',
  PNG = 'PNG',
  GIF = 'GIF',
}
