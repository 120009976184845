import React from 'react';

import CardTravel from '../../../../../components/Cards/CardTravel';
import EmptyContent from '../../../../../components/Empty';

import {
  Images,
} from '../../../../../shared/constants';

import {
  ProfileScrollCardsContent,
} from './styled';



export interface IProps {
  loading?: boolean;
  travels?: any;
  onDeleteTabPress?: any;
}



const TabTravelUpcoming: React.FC<IProps> = (props: IProps) => {
  const travelsArray = props.travels;



  function renderItem(item: any, index: any) {
    return (

      <CardTravel
        canDelete={true}
        number={index + 1}
        dateStart={item?.startAt}
        dateEnd={item?.endAt}
        city={item?.city}
        state={item?.state}
        country={item?.country}
        observation={item?.observation}
        showAdMyPlace={item?.showAdMyPlace}
        onDeletePress={() => {
          if (props.onDeleteTabPress) {
            props.onDeleteTabPress(item?.id);
          }
        }}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noImage}
        title={'Sem viagens cadastradas!'}
      />

    );
  }



  return (

    <ProfileScrollCardsContent
      data={travelsArray}
      renderItem={renderItem}
      listEmptyComponent={listEmptyComponent()}
    />

  );
};



export default TabTravelUpcoming;
