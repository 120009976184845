import {
  Colors,
  Images,
} from '../shared/constants';

import {
  EGenderUser,
} from '../shared/enums';



export function SetLightGenreText(toLightTextLevel: string) {
  switch (toLightTextLevel) {
    case EGenderUser.MAN:
      return 'Garotos';

    case EGenderUser.WOMAN:
      return 'Garotas';

    case EGenderUser.TRANS_MAN:
      return 'Garotos Trans';

    case EGenderUser.TRANS_WOMAN:
      return 'Garotas Trans';

    default:
      return '';
  }
}


export function SetGenreColor(toColorLevel: string) {
  switch (toColorLevel) {
    case EGenderUser.MAN:
      return Colors.male;

    case EGenderUser.WOMAN:
      return Colors.female;

    case EGenderUser.TRANS_MAN:
      return Colors.transMale;

    case EGenderUser.TRANS_WOMAN:
      return Colors.transFemale;

    default:
      return Colors.primary;
  }
}



export function SetSuitsGenderImage(toGenderTextImage: string) {
  switch (toGenderTextImage) {
    case EGenderUser.MAN:
      return Images.suitSpades;

    case EGenderUser.WOMAN:
      return Images.suitHearts;

    case EGenderUser.TRANS_MAN:
      return Images.suitClubs;

    case EGenderUser.TRANS_WOMAN:
      return Images.suitDiamonds;

    default:
      return Images.logoIconApp;
  }
}



// export function SetEmptyGenderImage(toGenderTextImage: string) {
//   switch (toGenderTextImage) {
//     case EGenderUser.MAN:
//       return Images.noModeleMan;

//     case EGenderUser.WOMAN:
//       return Images.noModeleWoman;

//     case EGenderUser.TRANS_MAN:
//       return Images.noModeleTransMan;

//     case EGenderUser.TRANS_WOMAN:
//       return Images.noModeleTransWoman;

//     default:
//       return Images.logoIconApp;
//   }
// }
