import styled from 'styled-components';

import ButtonAction from '../components/Buttons/ButtonAction';

import {
  PlatformDevices,
} from '../shared/utils/platforms.utils';



export const TableListHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`;


export const TableListButtonAction = styled(ButtonAction)`
  min-width: 140px;

  @media ${PlatformDevices.maxTabletM} {
    flex: 1;
  }
`;


export const TableListRowItemButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
