export enum EDrawerMenu {
  HOME = 'HOME',
  APP_DASHBOARD = 'APP_DASHBOARD',
  SHOP_DASHBOARD = 'SHOP_DASHBOARD',
  RANKING = 'RANKING',

  PROJECT_PARAMS = 'PROJECT_PARAMS',
  PROJECT_MESSAGES = 'PROJECT_MESSAGES',
  PROJECT_PLANS = 'PROJECT_PLANS',

  ALL_MODELES = 'ALL_MODELES',
  MODELE_TO_PUBLISH = 'MODELE_TO_PUBLISH',

  GENRES_ADS = 'GENRES_ADS',
  RENT_SERVICES = 'RENT_SERVICES',
  REVIEW_TO_PUBLISH = 'REVIEW_TO_PUBLISH',
  PROFILE_MEDIAS_TO_PUBLISH = 'PROFILE_MEDIAS_TO_PUBLISH',
  IMAGES_TO_PUBLISH = 'IMAGES_TO_PUBLISH',
  VIDEOS_TO_PUBLISH = 'VIDEOS_TO_PUBLISH',
  ANTIFAKE_TO_PUBLISH = 'ANTIFAKE_TO_PUBLISH',
  DELETED_MEDIAS = 'DELETED_MEDIAS',

  STREAM_CATEGORIES = 'STREAM_CATEGORIES',
  STREAM_SUBCATEGORIES = 'STREAM_SUBCATEGORIES',
  STREAM_CHANNELS = 'STREAM_CHANNELS',
  STREAM_STARS = 'STREAM_STARS',
  STREAM_VIDEOS = 'STREAM_VIDEOS',
  STREAM_VIDEOS_ADS = 'STREAM_VIDEOS_ADS',

  MARKETING_SOCIAL = 'MARKETING_SOCIAL',
  MARKETING_MESSAGE_WHATSAPP = 'MARKETING_MESSAGE_WHATSAPP',

  HELP_FAQ_CATEGORIES = 'HELP_FAQ_CATEGORIES',
  HELP_FAQ_QUESTIONS = 'HELP_FAQ_QUESTIONS',
  HELP_REQUESTS = 'HELP_REQUESTS',

  SHOP_PRODUCTS = 'SHOP_PRODUCTS',

  SETTINGS = 'SETTINGS',
  HELP = 'HELP',
  EXIT = 'EXIT',
}



export default EDrawerMenu;
