import axios,
{
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import {
  storePersisted,
} from '../redux';

import {
  USER_LOGGED,
  USER_LOGOUT_STATE,
  USER_SET,
} from '../redux/reducers/user.store';

import {
  ETokenAsync,
} from '../shared/enums';

import AppEnvironments from '../shared/environments/application';

import {
  getLocalStorageItem,
} from '../shared/utils/local-storage.utils';



function ApiWS() {
  const baseURL = AppEnvironments().apiUrl;

  const typeWS = 'SITE'; // 'SITE' | 'MOBILE'
  let id: string | null;
  let token: string | null;


  const secretioAppServer = axios.create({
    baseURL,
  });



  secretioAppServer.interceptors.request.use((config: AxiosRequestConfig) => {
    if (typeWS === 'SITE') {
      id = getLocalStorageItem(ETokenAsync.USER_ID);
      token = getLocalStorageItem(ETokenAsync.USER_TOKEN);
    }
    // else if (typeWS === 'MOBILE') {
    //   token = AsyncStorage.getItem(ETokenAsync.TOKEN);
    //   console.log('MOBILE-TOKEN', token);
    // }
    // else {
    //   console.error('Error');
    // }

    if (id) {
      if (config.headers) {
        config.headers.id = id;
      }
    }

    if (token) {
      if (config.headers) {
        config.headers.authorization = token;
      }
    }

    return config;
  });



  secretioAppServer.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return response;
    },
    (error: any) => {
      if (!error?.response) {
        const response = {
          message: 'Internal server error',
          data: error,
        };

        throw response;
      }

      const { status } = error?.response;

      if (typeWS === 'SITE') {
        if (status === 401) {
          localStorage.clear();

          storePersisted.dispatch({ type: USER_SET, payload: {} });
          storePersisted.dispatch({ type: USER_LOGGED, payload: false });
          storePersisted.dispatch({ type: USER_LOGOUT_STATE, payload: false });
        }
      }

      throw error?.response?.data;
    },
  );



  return secretioAppServer;
};



export default ApiWS();
