export enum EPictureHighlightRelation {
  HIGHLIGHT_VERTICAL = 'HIGHLIGHT_VERTICAL',
  HIGHLIGHT_HORIZONTAL = 'HIGHLIGHT_HORIZONTAL',
  HIGHLIGHT_USER_UPGRADE = 'HIGHLIGHT_USER_UPGRADE',
  HIGHLIGHT_MODELE_UPGRADE = 'HIGHLIGHT_MODELE_UPGRADE',
  HIGHLIGHT_PREMIUN = 'HIGHLIGHT_PREMIUN',
}



export default EPictureHighlightRelation;
