import React from 'react';

import {
  ChooseBannerButton,
  ChooseBannerText,
} from './styled';



interface IProps {
  disabled?: boolean;
  text: string;
  activeOpacity?: number;

  onPress?: any;
}



const ButtonAddImage: React.FC<IProps> = (props: any) => {
  return (

    <ChooseBannerButton
      // disabled={props.disabled}
      // activeOpacity={props.activeOpacity}
      onClick={props.onPress}>

      <ChooseBannerText>
        {props.text}
      </ChooseBannerText>

    </ChooseBannerButton>

  );
};



ButtonAddImage.defaultProps = {
  activeOpacity: 0.7,
};



export default ButtonAddImage;
