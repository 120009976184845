import React from 'react';

import {
  ImageContentRightHome,
  WarningApproveText,
} from './styled';



const InfoPrincipal: React.FC = () => {
  return (

    <ImageContentRightHome>

      <WarningApproveText>
        Siga estas regras para acelerar o processo de aprovação.
      </WarningApproveText>


      Aceitaram
      Carregue uma foto sua nítida e de alta qualidade.
      Aproxime-se do espelho e esconda sua escova de dentes ao fazer selfies no banheiro.

      Não aceito
      Uso excessivo de Photoshop, texto adicionado, números de telefone, marcas d&apos;água, colagens ou bordas não são permitidos.
      Fotos que mostrem álcool, drogas, armas ou notas não são permitidas, assim como fotos de fezes, sangue, tortura ou outro tipo de violência.
      Fotos hardcore como foto de perfil principal (conteúdo sexual explícito, atos sexuais, ejaculação, uso ou representações de brinquedos e equipamentos BDSM). Você pode adicionar essas imagens à sua galeria Privada ou Premium.
      Fotos protegidas por direitos autorais não são permitidas, a menos que você nos envie um consentimento por escrito e assinado pelo proprietário dos direitos autorais.


    </ImageContentRightHome>

  );
};


export default InfoPrincipal;
