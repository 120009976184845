import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useParams,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  GetUserByUsernameNumberClient,
  LinkClientNumberUserGlobalAccount,
} from '../../../../apis/endpoints/app-global-profile.endpoints';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Content,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import RequiredText from '../../../../components/Titles/TextRequired';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  CardContactsForm,
  InputTextStyled,
} from './styled';



export interface IRouteParams {
  useralias: string;
}



const ModeleLinkAccountScreen: React.FC = () => {
  const dispatch = useDispatch();

  const route = useParams<IRouteParams>();
  const paramsRoute = route as IRouteParams;


  const { useralias } = paramsRoute;
  const userIdSearch = useralias;


  const formik = useRef<FormikProps<any>>(null);


  const [modeleFound, setModeleFound] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function resetForm() {
    setModeleFound(false);
    formik.current?.resetForm();
  }


  async function findUserToHighlights(values) {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      const response = await GetUserByUsernameNumberClient(values.useralias);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao salvar item');
        return;
      }

      setModeleFound(response?.data);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function mountPayload() {
    const payload: any = {
      useralias: userIdSearch,
      clientNumber: modeleFound?.clientNumber,
      email: modeleFound?.email,
      birthday: modeleFound?.birthday,
    };

    return payload;
  }


  async function linkAccountGlobalFunction() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      const payload = mountPayload();

      const response = await LinkClientNumberUserGlobalAccount(userIdSearch, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao salvar item');
        return;
      }

      showSnackBarProps(Colors.accept, 'Link feito com sucesso');
      resetForm();
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={(values) => {
        // KeyboardModal.dismiss();
        findUserToHighlights(values);
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        // setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <HeaderScreen
            leftIcon={
              <ButtonBack
                color={Colors.black}
                size={27}
              />
            }
            centerContent={
              <Title
                color={Colors.black}>
                Vincular perfil à conta
              </Title>
            }
          />



          <Content>

            {!mounted && (
              renderLoading()
            )}



            {mounted && (
              <ContentPadder>

                <CardContactsForm
                  backgroundColor={Colors.formBackground}
                  borderRadius={Sizes.cardRadius}>

                  <RequiredText>
                    * {'Campos obrigatórios'}
                  </RequiredText>



                  <InputTextStyled
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={`${'Nome de usuário'} *`}
                    placeholderText={`${'Nome de usuário'} *`}
                    errorText={errors.useralias}
                    countLimit={Values.nameMaxCount}
                    value={values.useralias}
                    onChange={handleChange('useralias')}
                    onBlur={handleBlur('useralias')}
                  />


                  <button
                    onClick={() => {
                      handleSubmit();
                    }}>
                    Buscar
                  </button>



                  {modeleFound && (
                    <div>

                      <p>
                        Nome público: {modeleFound?.publicName || '-'}
                      </p>

                      <p>
                        Nome de usuário: {modeleFound?.username || '-'}
                      </p>

                      <p>
                        Número de cliente: {modeleFound?.clientNumber || '-'}
                      </p>

                      <p>
                        E-mail: {modeleFound?.email || '-'}
                      </p>

                      <p>
                        Ativo: {modeleFound?.enabled ? 'Sim' : 'Não'}
                      </p>


                      <ButtonAction
                        disabled={!modeleFound?.enabled}
                        width={Sizes.buttonMinWidth}
                        title={'Vincular conta global'}
                        onPress={() => {
                          linkAccountGlobalFunction();
                        }}
                      />

                    </div>
                  )}

                </CardContactsForm>

              </ContentPadder>
            )}



            <FooterApp />

          </Content>

        </Screen>

      )}
    </Formik>

  );
};



export default ModeleLinkAccountScreen;
