import ApiWS from '../index';



export const GetAllModeles = async (payload: string) => {
  const url = '/support/modeles';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const GetModeleProfissionalPanel = async (useralias: any) => {
  const url = `/support/modeles/${useralias}`;

  const response = await ApiWS.get(url);
  return response.data;
};



export const CreateModeleProfile = async (payload: any) => {
  const url = '/support/modeles';

  const response = await ApiWS.post(url, payload);
  return response.data;
};



export const EditModeleMainProfile = async (useralias: string, payload: any) => {
  const url = `/support/modeles/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const ApproveModeleProfile = async (useralias: string) => {
  const url = `/support/modeles/visibility/${useralias}`;

  const response = await ApiWS.put(url);
  return response.data;
};



export const EditParamsModele = async (useralias: string, payload: any) => {
  const url = `/support/modeles/params/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const GetPrivacityModele = async (idUser: string) => {
  const url = `/support/modeles/privacity/${idUser}`;

  const response = await ApiWS.get(url);
  return response.data;
};



export const EditPrivacityModele = async (idUser: string, payload: any) => {
  const url = `/support/modeles/privacity/${idUser}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const EditRentItemModele = async (useralias: string, payload: any) => {
  const url = `/support/modeles/rent/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const GetRentAllServicesModele = async (useralias: string) => {
  const url = `/support/modeles/services/${useralias}`;

  const response = await ApiWS.get(url);
  return response.data;
};



export const EditRentServicesModele = async (idUser: string, payload: any) => {
  const url = `/support/modeles/services/${idUser}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const EditRentContactsModele = async (useralias: string, payload: any) => {
  const url = `/support/modeles/contacts/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const EditRentPaymentTypeModele = async (useralias: string, payload: any) => {
  const url = `/support/modeles/prices/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const EnableModeleUseraliasEdit = async (useralias: string) => {
  const url = `/support/modeles/enable-useralias/${useralias}`;

  const response = await ApiWS.put(url);
  return response.data;
};
