import React from 'react';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  FooterAppContainer,
  FooterLogoInserted,
  FooterLogoImageButton,
  FooterLogoImage,
  FooterTextYear,
  FooterListInline,
  FooterListButton,
  FooterListTextButton,
} from './styled';



const FooterApp: React.FC = () => {
  const genderColorChoose = Colors.secondaryDark;



  return (

    <FooterAppContainer>

      <FooterListInline>

        <FooterListButton>
          <FooterListTextButton
            color={genderColorChoose}>
            Sobre
          </FooterListTextButton>
        </FooterListButton>


        <FooterListButton>
          <FooterListTextButton
            color={genderColorChoose}>
            Termos e Políticas
          </FooterListTextButton>
        </FooterListButton>



        <FooterListButton>
          <FooterListTextButton
            color={genderColorChoose}>
            DMCA
          </FooterListTextButton>
        </FooterListButton>

      </FooterListInline>



      <FooterLogoInserted>

        <FooterTextYear>
          {`${new Date().getFullYear()} © Secretio`}
        </FooterTextYear>


        <FooterLogoImageButton>
          <FooterLogoImage
            alt={''}
            src={Images.logoIconApp}
          />
        </FooterLogoImageButton>

      </FooterLogoInserted>

    </FooterAppContainer>

  );
};



export default FooterApp;
