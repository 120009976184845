import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  ButtonHelp,
} from '../../Controls';

import {
  ContentInputFlex,
  ContentHeaderViewFlex,
  ContentHeaderTitle,
  ContentHelpButton,
} from './styled';



export interface IProps {
  title?: string;
  accept?: string;
  onHelpPress?: any;
  children?: any;
}



const HeaderHelp: React.FC<IProps> = (props: IProps) => {
  return (

    <ContentInputFlex>

      <ContentHeaderViewFlex>

        <ContentHeaderTitle>
          {props.title}
        </ContentHeaderTitle>


        <ContentHelpButton>
          {props.onHelpPress && (
            <ButtonHelp
              color={Colors.black}
              size={22}
              onPress={() => {
                if (props.onHelpPress) {
                  props.onHelpPress();
                }
              }}
            />
          )}
        </ContentHelpButton>

      </ContentHeaderViewFlex>


      {props.children}

    </ContentInputFlex>

  );
};



export default HeaderHelp;
