import ApiWS from '../index';



export const GetModeleReviews = async (idModele: string) => {
  const url = `/support/modeles/review/${idModele}`;

  const response = await ApiWS.get(url);
  return response.data;
};



export const ChangeVisibleReview = async (idComment: number) => {
  const url = `/support/modeles/review/visible/${idComment}`;

  const response = await ApiWS.put(url);
  return response.data;
};
