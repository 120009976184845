import React,
{
  useState,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

import {
  Content,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ButtonBack,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconMoney,
  IconRentOn,
  IconService,
} from '../../../../components/Icons';

import TabBarStyled from '../../../../components/TabBar';

import {
  Colors,
} from '../../../../shared/constants';

import TabPricesScreen from './Tabs/Prices';
import ModeleRentsEditScreen from './Tabs/Rents';
import ServicesScreen from './Tabs/Services';



export interface IRouteLocation {
  item?: any;
}



const RentProfileModeleScreen: React.FC = () => {
  const routeLocation = useLocation();
  const params = routeLocation?.state as IRouteLocation;

  const itemToEdit = params?.item;


  const accentGender = Colors.secondary;
  const iconTabBar = 23;


  const [stateIndex, setStateIndex] = useState(0);



  const screenTabs = [
    {
      visible: true,
      name: 'PREFERENCES',
      title: 'Acompanhante',
      icon: (
        <IconRentOn
          color={stateIndex === 0 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <ModeleRentsEditScreen
          modele={itemToEdit}
        />
      ),
    },
    {
      visible: true,
      name: 'SERVICES',
      title: 'Serviços',
      icon: (
        <IconService
          color={stateIndex === 1 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <ServicesScreen
          modele={itemToEdit}
        />
      ),
    },
    {
      visible: true,
      name: 'PRICES',
      title: 'Valores e Pagamentos',
      icon: (
        <IconMoney
          color={stateIndex === 2 ? accentGender : Colors.inputCount}
          size={iconTabBar}
        />
      ),
      component: (
        <TabPricesScreen
          modele={itemToEdit}
        />
      ),
    },
  ];



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonBack
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Title
            color={Colors.black}>
            Perfil Acompanhante
          </Title>
        }
        noSubToolbarPadding>


        <TabBarStyled
          color={accentGender}
          tabs={screenTabs}
          selected={stateIndex}
          onPress={(tab) => {
            setStateIndex(tab?.index);
          }}
        />

      </HeaderScreen>



      <Content>

        {stateIndex === 0 && screenTabs[0].component}

        {stateIndex === 1 && screenTabs[1].component}

        {stateIndex === 2 && screenTabs[2].component}

      </Content>

    </Screen>

  );
};



export default RentProfileModeleScreen;
