import React,
{
  useEffect,
  useState,
} from 'react';

import Color from 'color';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import { picureVisibilityGenders } from '../../../shared/arrays/Picture/visibility.array';

import {
  Colors,
  Sizes,
  Values,
} from '../../../shared/constants';

import {
  EPlansUser,
} from '../../../shared/enums';

import EAppMediaVisibility from '../../../shared/enums/Status/app-media-visibility.enum';
import EStatusVisible from '../../../shared/enums/Status/status-visibility.enum';

import UserHeaderInfo from '../../Details/UserHeaderInfo';

import {
  IconArchiveOff,
  IconArchiveOn,
  IconCheck,
  IconClose,
  IconDelete,
  IconInformation,
  IconMoney,
  IconPlay,
} from '../../Icons';

import {
  VideoBoxContainer,
  ImagePublishAllContainer,
  VideoBoxContent,
  LazyVideo,
  RibbonPhotoTypeMedia,
  RibbonPending,
  RibbonTopInfo,
  RibbonVideoDuration,
  RibbonHdVideoInserted,
  VideoIcon,
  CanBuyRibbon,
  ImageActionContent,
  ImageActionButton,
  ReviewActionsContainer,
  ReviewActionsButton,
  ReviewActionsIcon,
  ReviewActionsText,
} from './styled';



export interface IProps {
  image?: string;
  typeRelation?: string;
  visible?: EStatusVisible;
  visibility?: string;
  revised?: boolean;
  canBuy?: boolean;

  duration?: string;
  isHD?: boolean;

  modeleName?: string;
  modeleUsername?: string;
  modelePlan?: EPlansUser;
  modeleImage?: string;
  verified?: boolean;
  brandPromoter?: boolean;

  onUserPress?: any;
  onArchivePress?: any;
  onVisibleOrRevisedPress?: any;
  onEditPress?: any;
  onDeletePress?: any;
}



const CardPublishAntiFake: React.FC<IProps> = (props: IProps) => {
  const colorRevisedIcon = props.revised ? Color(Colors.accept).darken(0.5).toString() : Colors.secondaryDark;
  const sizeIcon = 18;


  const [videoSrc, setVideoSrc] = useState(props.image);
  const [isPlaying, setIsPlaying] = useState(false);

  const [loading, setLoading] = useState(false);



  function renderEditComments(enabled: boolean, color: string, title: string, icon: any, onPress: any) {
    return (

      <ReviewActionsButton
        disabled={!enabled}
        onClick={() => {
          if (enabled && onPress) {
            onPress();
          }
        }}>

        <ReviewActionsIcon>
          {icon}
        </ReviewActionsIcon>


        <ReviewActionsText
          color={color}>
          {title}
        </ReviewActionsText>

      </ReviewActionsButton>

    );
  }


  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };


  function renderStatusArchive() {
    if (props.visible === EStatusVisible.VISIBLE) {
      return {
        icon: (
          <IconArchiveOn
            color={Colors.danger}
            size={sizeIcon}
          />
        ),
        color: Colors.danger,
        text: 'Arquivar',
      };
    }

    if (props.visible === EStatusVisible.INVISIBLE || props.visible === EStatusVisible.ARCHIVED) {
      return {
        icon: (
          <IconArchiveOff
            color={Colors.secondaryDark}
            size={sizeIcon}
          />
        ),
        color: Colors.secondaryDark,
        text: 'Desarquivar',
      };
    }

    return {
      icon: (
        <IconInformation
          color={Colors.gray}
          size={sizeIcon}
        />
      ),
      color: Colors.gray,
      text: 'Ação',
    };
  }



  useEffect(() => {
    setLoading(true);
    setVideoSrc(props.image);

    setTimeout(() => {
      setLoading(false);
    }, Values.reRenderTime);
  }, [props.image]);



  return (

    <VideoBoxContainer>
      <ImagePublishAllContainer
        backgroundColor={Colors.cardBackground}
        borderRadius={Sizes.cardRadius}>

        <VideoBoxContent>

          {!loading && (
            <LazyVideo
              controls={isPlaying}
              autoPlay={isPlaying}
              playsInline
              muted>
              <source src={videoSrc} />
              Seu navegador não suporta a reprodução de vídeos HTML5.
            </LazyVideo>
          )}



          <RibbonPhotoTypeMedia>
            {picureVisibilityGenders?.find((item: IInputSelect) => item.value === props.visibility)?.label || '-'}
          </RibbonPhotoTypeMedia>



          {!props.revised && (
            <RibbonPending>
              Pendente de aprovação
            </RibbonPending>
          )}



          <RibbonTopInfo>
            {props.isHD && (
              <RibbonHdVideoInserted>
                HD
              </RibbonHdVideoInserted>
            )}

            <RibbonVideoDuration>
              {props.duration || '00:00'}
            </RibbonVideoDuration>
          </RibbonTopInfo>



          {!isPlaying && (
            <VideoIcon
              onClick={() => {
                togglePlay();
              }}>
              <IconPlay
                color={Colors.white}
                size={32}
              />
            </VideoIcon>
          )}



          {props.canBuy && (
            <CanBuyRibbon>
              <IconMoney
                color={Colors.white}
                size={18}
              />
            </CanBuyRibbon>
          )}



          {(props.visibility !== EAppMediaVisibility.INTERN && props.visible !== EStatusVisible.DELETED) && (
            <ImageActionContent>

              <ImageActionButton
                onClick={() => {
                  if (props.onDeletePress) {
                    props.onDeletePress();
                  }
                }}>
                <IconDelete
                  color={Colors.danger}
                  size={18}
                />
              </ImageActionButton>

            </ImageActionContent>
          )}


        </VideoBoxContent>



        {props.modeleName && props.modeleImage && props.modeleUsername && (
          <UserHeaderInfo
            modeleName={props.modeleName}
            modeleUsername={props.modeleUsername}
            modelePlan={props.modelePlan}
            modeleImage={props.modeleImage}
            verified={props.verified}
            brandPromoter={props.brandPromoter}
            onUserPress={() => {
              if (props.onUserPress) {
                props.onUserPress();
              }
            }}
          />
        )}



        <ReviewActionsContainer>

          {(props.visibility !== EAppMediaVisibility.INTERN && props.visible !== EStatusVisible.DELETED) && renderEditComments(
            true,
            renderStatusArchive()?.color,
            renderStatusArchive()?.text,
            renderStatusArchive()?.icon,
            () => {
              if (props.onArchivePress) {
                props.onArchivePress();
              }
            },
          )}


          {(props.visibility !== EAppMediaVisibility.INTERN && props.visible !== EStatusVisible.DELETED) && renderEditComments(
            true,
            colorRevisedIcon,
            props.revised
              ? 'Desrevisar'
              : 'Revisar item',
            props.revised
              ? (
                <IconClose
                  color={colorRevisedIcon}
                  size={sizeIcon}
                />
              )
              : (
                <IconCheck
                  color={colorRevisedIcon}
                  size={sizeIcon}
                />
              ),
            () => {
              if (props.onVisibleOrRevisedPress) {
                props.onVisibleOrRevisedPress();
              }
            },
          )}


          {(props.visibility === EAppMediaVisibility.INTERN || props.visible === EStatusVisible.DELETED) && renderEditComments(
            false,
            Colors.black,
            'Mídia deletada',
            <IconDelete
              color={Colors.black}
              size={sizeIcon}
            />,
            null,
          )}

        </ReviewActionsContainer>

      </ImagePublishAllContainer>
    </VideoBoxContainer>

  );
};



export default CardPublishAntiFake;
