import React from 'react';

import {
  Colors,
  Images,
  Sizes,
} from '../../../shared/constants';

import {
  validateString,
} from '../../../shared/utils/string.utils';

import {
  IconMediaVideoProduction,
  IconStar,
} from '../../Icons';

import {
  CardStarContainer,
  CardModelContainer,
  FooterVideoInfoButton,
  FooterVideoInfoImage,
  FooterVideoInfoContainer,
  VideoTextStarView,
  FooterVideoInfoText,
  VideoRateView,
  VideoRateText,
  FooterVideoActionView,
  VideoActionView,
  VideoItemActionTypeView,
  VideoActionTypeView,
  ModelInfoActionIcon,
  VideoActionTypeText,
} from './styled';



export interface IProps {
  alias?: string;
  image?: string;
  visible?: boolean;
  total?: number;
  rating?: number;
  onPress?: any;
}



const CardStars: React.FC<IProps> = (props: IProps) => {
  const starImage = validateString(props.image) || Images.avatarUserImage;



  return (

    <CardStarContainer>
      <CardModelContainer
        backgroundColor={Colors.white}
        borderRadius={Sizes.cardRadius}>

        <FooterVideoInfoButton
          onClick={() => {
            if (props.onPress) {
              props.onPress();
            }
          }}>

          <FooterVideoInfoImage
            alt={props.alias}
            src={starImage}
          />

        </FooterVideoInfoButton>



        <FooterVideoInfoContainer>

          <VideoTextStarView>

            <FooterVideoInfoText>
              {props.alias || '-'}
            </FooterVideoInfoText>



            <VideoRateView>
              <IconStar
                color={Colors.secondary}
                size={14}
              />

              <VideoRateText>
                {props.rating || '-'}
              </VideoRateText>

            </VideoRateView>

          </VideoTextStarView>



          <FooterVideoActionView>

            <VideoActionView>

              <VideoItemActionTypeView>

                <VideoActionTypeView>

                  <ModelInfoActionIcon>
                    <IconMediaVideoProduction
                      size={19}
                      color={Colors.gray}
                    />
                  </ModelInfoActionIcon>


                  <VideoActionTypeText>
                    {props.total || '-'}
                  </VideoActionTypeText>

                </VideoActionTypeView>

              </VideoItemActionTypeView>



              <p>
                Visível: {props.visible ? 'Sim' : 'Não'}
              </p>

            </VideoActionView>

          </FooterVideoActionView>

        </FooterVideoInfoContainer>

      </CardModelContainer>
    </CardStarContainer>

  );
};



export default CardStars;
