import React,
{
  useEffect,
} from 'react';
import './App.css';

import {
  Provider,
} from 'react-redux';

import {
  persistStore,
} from 'redux-persist';

import {
  PersistGate,
} from 'redux-persist/integration/react';

import * as PackageInfo from '../package.json';

import BadgeEnv from './components/Badges/BadgeEnv';

import Navigation from './navigation';

import {
  storePersisted,
} from './redux';


const persistor = persistStore(storePersisted);



const App: React.FC = () => {
  const projectPackage = PackageInfo;

  useEffect(() => {
    console.log(`Secretio Support Version: ${projectPackage.version}`);

    if (process.env.REACT_APP_NODE_ENV === 'LOCAL') {
      console.log(`Bool API: ${process.env.REACT_APP_NODE_ENV}`);
    }
  }, []);



  return (

    <Provider store={storePersisted}>
      <PersistGate loading={null} persistor={persistor}>

        <BadgeEnv />

        <Navigation />

      </PersistGate>
    </Provider>

  );
};



export default App;
