import {
  EAppTranslations,
  EAppTutorialTranslations,
  EButtonsTranslations,
  ECompanyTypesTranslations,
  ECountriesTranslations,
  EDrinksTranslations,
  EEventsTranslations,
  EInterpolatesTranslations,
  EKitchensTranslations,
  ELabelsTranslations,
  ELanguagesTranslations,
  EMenuOrderTranslations,
  EMessagesEmptysTranslations,
  EMessagesErrosTranslations,
  EMessagesHelpsTranslations,
  EMessagesQuestionsTranslations,
  EMessagesSuccessTranslations,
  EMusicsTranslations,
  EPaymentsTranslations,
  EReservesTranslations,
  ESocialsTranslations,
  ETimeTranslations,
  ETitlesTranslations,
  ESubtitlesTranslations,
  EValidationsTranslations,
  ECarnivalTranslations,
} from './translates.keys';



export const APP: Record<EAppTranslations, string> = {
  NAME_APP: 'Secretio Support',
  SHORTNAME_APP: 'Support',
  SLOGAN_APP: 'Voici les meilleures raisons de profiter de la nuit!',
  COMPANY_NAME: 'Secretio',
  COMPANY_APP: 'Pour profiter de la nuit!',
};


export const APP_TUTORIAL: Record<EAppTutorialTranslations, string> = {
  TUTORIAL_TITLE_1: 'Divertissement',
  TUTORIAL_TEXT_1: 'La manière la plus facile d\'aller à des fêtes, boîtes de nuit, pubs ou bars préférés avec vos amis!',
  TUTORIAL_TITLE_2: 'Praticité',
  TUTORIAL_TEXT_2: 'Le meilleur outil pour trouver où se déroulera votre prochaine Secretio!',
  TUTORIAL_TITLE_3: 'Prix',
  TUTORIAL_TEXT_3: 'Réductions et promotions pour toute votre Secretio!',
  TUTORIAL_TITLE_4: 'Prêt à profiter de la nuit?',
};


export const BUTTONS: Record<EButtonsTranslations, string> = {
  ACTION_ACCESS: 'Entrer',
  ACTION_ACCESS_TO_CONTINUE: 'Entrer pour continuer',
  ACTION_REGISTER: 'S\'inscrire',
  ACTION_REGISTER_USER: 'S\'inscrire',
  ACTION_REGISTER_COMPANY: 'S\'inscrire à l\'entreprise',
  ACTION_REGISTER_TO_CONTINUE: 'S\'inscrire pour continuer',
  ACTION_CANCEL: 'Annuler',
  ACTION_CONFIRM: 'Confirmer',
  ACTION_FORWARD: 'Avancer',
  ACTION_BACK: 'Revenir',
  ACTION_CLOSE: 'Fermer',
  ACTION_ASK_CHECKIN: 'Demander un Check-in',
  ACTION_CANCEL_CHECKIN: 'Annuler le Check-in',
  ACTION_CHECKIN_MANUAL: 'Check-in manuel',
  ACTION_CLICK_OPEN: 'Cliquez pour ouvrir',
  ACTION_CLICK_CLOSE: 'Cliquez pour fermer',
  ACTION_CLICK_CANCEL: 'Cliquez pour annuler',
  ACTION_MORE_DETAILS: 'Cliquez pour plus de détails',
  ACTION_LISTS_SEE: 'Voir les listes',
  ACTION_SEE_MENUSHEET: 'Voir la carte',
  ACTION_PAY: 'Payer',
  ACTION_PAY_APP: 'Paiement en ligne',
  ACTION_PAY_PRESENTIAL: 'Paiement sur place',
  ACTION_PAY_ORDERSHEET: 'Payer la note',
  ACTION_ADD_MORE_ITEMS: 'Ajouter plus d\'articles',
  ACTION_BAG_SEE: 'Voir le panier',
  ACTION_BAG_CLEAN: 'Vider le panier',
  ACTION_FINISH_ORDER: 'Finaliser la commande',
  ACTION_CLOSE_ORDERSHEET: 'Fermer la note',
  ACTION_SEE_WIFI: 'Voir le Wi-Fi',
  ACTION_NO: 'Non',
  ACTION_YES: 'Oui',
  ACTION_SEE_HISTORY: 'Voir l\'historique complet',
  ACTION_HISTORY: 'Historique complet',
  ACTION_SAVE: 'Enregistrer',
  ACTION_OPEN_LIST: 'Cliquez pour ouvrir la liste',
  ACTION_OPEN_GALLERY: 'Cliquez pour voir la galerie',
  ACTION_TRANSFER_ADD: 'Ajouter un compte pour le transfert',
  ACTION_COMPANY_ADD: 'Ajouter une entreprise',
  ACTION_USER_DISABLE: 'Désactiver le profil',
  ACTION_RECOVER_ACCESS: 'Récupérer l\'accès',
  ACTION_CARD_ADD: 'Ajouter une carte',
  ACTION_ADDRESS_REGISTER: 'Enregistrer l\'adresse',
  ACTION_CLICK_EDIT: 'Cliquez pour éditer',
  ACTION_LOCATION_ENABLED: 'Activer la localisation',
  ACTION_INVITE_LINK: 'Ajouter par lien',
  ACTION_ADD_PERSON: 'Ajouter une personne',
  ACTION_ADD_PEOPLE: 'Ajouter des personnes',
  ACTION_ADD_MANUAL: 'Ajouter manuellement',
  ACTION_LIST_PARTICIPATE: 'Participer à la liste',
  ACTION_LIST_LEAVE: 'Quitter la liste',
  ACTION_TICKET_SEE: 'Voir les billets',
  ACTION_COPY_PASSWORD: 'Cliquez pour copier le mot de passe',
  ACTION_COPY_LINK: 'Copier le lien',
  ACTION_SEE_DATES: 'Voir plus de dates',
  ACTION_DO_LOGIN: 'Se connecter',
  ACTION_TO_RESERVE: 'Réserver',
  ACTION_CANCEL_RESERVE: 'Annuler la réservation',
  ACTION_SEARCH: 'Rechercher',
  ACTION_RESEARCH: 'Refaire la recherche',
  ACTION_ADDRESS_SEE_YOURS: 'Cliquez pour voir vos adresses',
  ACTION_ADDRESS_SEE: 'Voir les adresses',
  ACTION_PAY_FORMS_SEE: 'Voir les modes de paiement',
  ACTION_OPEN_WAZE: 'Ouvrir avec Waze',
  ACTION_OPEN_UBER: 'Ouvrir avec Uber',
  ACTION_HOUR_SEE: 'Cliquez pour voir les horaires',
  ACTION_CLICK_TO_MANAGE: 'Cliquez pour gérer',
  ACTION_LOCATION_MY: 'Utiliser ma localisation actuelle',
  ACTION_PROFILE_SEE: 'Voir le profil',
  ACTION_CLICK_TO_ADD: 'Cliquez pour ajouter',
  ACTION_CLICK_TO_EDIT: 'Cliquez pour modifier',
  ACTION_TO_SEE_PROFILE: 'Cliquez pour voir votre profil',
  ACTION_SKIP: 'Passer',
  ACTION_COME_ON: 'Allons-y!',
  ACTION_PROFILE_MANAGE: 'Cliquez pour demander ce profil',
  ACTION_CHECK_ALL: 'Marquer tout',
  ACTION_UNCHECK_ALL: 'Tout déselectionner',
  ACTION_JOIN_VIA_LIST: 'Rejoindre via des listes',
  ACTION_SEE_AVAILABLES_TICKETS: 'Voir les billets disponibles',
  ACTION_ENTRANCE_IN_EVENT: 'Assister à l\'événement',
};


export const COMPANY_TYPES: Record<ECompanyTypesTranslations, string> = { // No translation for the proper noun "Casas Noturnas"
  EVENT_PRODUCER: 'Producteur d\'événements',
  EVENT_PRODUCERS: 'Producteurs d\'événements',
  BAR: 'Bar',
  BARS: 'Bars',
  PUB: 'Pub',
  PUBS: 'Pubs',
  NIGHT_CLUB: 'Casa Noturna',
  NIGHT_CLUBS: 'Casas Noturnas',
  RESTAURANT: 'Restaurant',
  RESTAURANTS: 'Restaurants',
  PIZZERIA: 'Pizzaria',
  PIZZERIAS: 'Pizzarias',
  STEAKHOUSE: 'Churrascaria',
  STEAKHOUSES: 'Churrascarias',
  HAMBURGUER: 'Hamburgueria',
  HAMBURGUERS: 'Hamburguerias',
  COFFEE_SHOP: 'Cafeteria',
  COFFEE_SHOPS: 'Cafeterias',
  CARNIVAL_PRODUCER: 'Producteur de carnavals',
  CARNIVAL_PRODUCERS: 'Producteurs de carnavals',
  PARTY_PRODUCER: 'Producteur de festivals',
  PARTY_PRODUCERS: 'Producteurs de festivals',
  GOVERN: 'Gouvernement',
  GOVERNS: 'Gouvernements',
};


export const CARNIVALS: Record<ECarnivalTranslations, string> = {
  CARNIVAL_VOTES: 'Votes du carnaval',
  CARNIVAL_INTEREST_POINTS: 'Points d\'intérêt',
  CARNIVAL_OFFICIAL: 'Carnaval officiel',
  CARNIVAL_MICARETA: 'Micareta',
  CIRCUIT_OFFICIAL: 'Circuit officiel',
  BLOC: 'Bloco',
  CIRCUIT: 'Circuit',
  CIRCUITS: 'Circuits',
  CABIN: 'Camarote',
  CABINS: 'Camarotes',
  POLICE: 'Police',
  BUS: 'Bus',
  TAXI: 'Taxi',
  BATHROOM: 'Salle de bain',
  FIREMAN: 'Pompier',
  SAVE_LIFE: 'Sauveteur',
  MEDICAL_POINT: 'Point médical',
  JUVENILE: 'Organisme Juvenil',
  OTHER_ATTRACTION: 'Autres attractions',
  AGENCY_FLOW: 'Agents de flux',
  AGENCY_PUBLIC: 'Organismes publics',
  CLEAN_HEALTH: 'Santé et propreté',
  AREA_RESERVED: 'Zones réservées',
};


export const COUNTRIES: Record<ECountriesTranslations, string> = {
  BRAZIL: 'Brésil',
  PORTUGAL: 'Portugal',
  UNITED_STATES: 'Etats-Unis',
  SPAIN: 'Espagne',
  ITALY: 'Italie',
  FANCE: 'France',
};


export const DRINKS: Record<EDrinksTranslations, string> = {
  WATER: 'Eau',
  SODA: 'Soda',
  VODKA: 'Vodka',
  BEER: 'Bière',
  WINE: 'Vin',
  TEA: 'Thé',
  COFFEE: 'Café',
  JUICE: 'Jus',
  WHISKY: 'Whisky',
  LIQUOR: 'Liqueur',
  COCKTAIL: 'Cocktail',
  CHAMPAGNE: 'Champagne',
  GIN: 'Gin',
  RUM: 'Rhum',
  ENERGY_DRINK: 'Boisson énergétique',
  SMOOTHIE: 'Smoothie',
  YERBA_MATE: 'Yerba Mate',
  SAKE: 'Saké',
  TEQUILA: 'Tequila',
};


export const EVENTS: Record<EEventsTranslations, string> = {
  EVENT_TYPE: 'Type d\'événement',
  EVENT_IDEAL_LGBT: 'Idéal pour le public LGBTQIA+',
  EVENT_ABOUT: 'À propos de l\'événement',
  EVENT_ATTRACTIONS: 'Attractions',
  EVENT_START: 'Début de l\'événement',
  EVENT_END: 'Fin de l\'événement',
  EVENT_OPENBAR: 'Boissons au OpenBar',
  EVENT_PARENTAL_RATING: 'Classification parentale',
  EVENT_PARENTAL_NONE: 'Sans classification',
  EVENT_PARENTAL_14: 'Pour les plus de 14 ans',
  EVENT_PARENTAL_16: 'Pour les plus de 16 ans',
  EVENT_PARENTAL_18: 'Pour les plus de 18 ans',
  EVENT_LIST_AVAILABLES: 'Listes publiques disponibles',
  ID_TICKET: 'ID du billet',
  TICKET: 'Billet',
  TICKETS: 'Billets',
  TICKETS_MY: 'Mes billets',
  TICKETS_AVAILABLE: 'Des billets sont disponibles',
  EVENT_FREE: 'Événement gratuit',
  EVENT_DATE: 'Date de l\'événement',
  EVENT_DATE_INITIAL: 'Début de l\'événement',
  EVENT_DATE_FINAL: 'Fin de l\'événement',
  TICKET_ENTRANCE_PRICE: 'Prix des billets et entrées',
  PARTY: 'Fête',
  NIGHT_PARTY: 'Soirée nocturne',
  NOT_CONFIRMED: 'Pas confirmé',
};


export const INTERPOLATES: Record<EInterpolatesTranslations, string> = {
  HELLO: 'Bonjour',
  PERSON_TO: 'Personne à',
  CODE_ABREV: 'Code:',
  FROM: 'De',
  TO: 'À',
  PAY_PRESENTIAL: 'Payer sur place',
  PAY_BY_APP: 'Payer par Secretio',
  PAY_TO: 'Payer à',
  TO_DO: 'À faire',
  HAVE_WIFI: 'Ici, il y a du Wi-Fi',
  INTERPOLATE_ORDERSHEET_CLOSING: 'En attente que le partenaire {company_name} finalise la commande',
  INTERPOLATE_ORDERSHEET_CLOSE: 'Cliquez sur "Payer la commande" pour payer directement par Secretio ou pour {company_name}',
  INTERPOLATE_ORDERSHEET_FINISH: 'Effectuez le paiement à {company_name} pour finaliser la commande',
  AT: 'à',
  OR: 'ou',
  AND: 'et',
  THERE_IS: 'Il y a',
  LAST_ORDER: 'Dernière commande',
  LAST_ORDER_THERE_IS: 'Dernière commande il y a',
  ACCESS_USER_REGISTER: 'En vous inscrivant sur Secretio, vous acceptez les',
  ACCESS_USER_TERMS_USE: 'Conditions d\'utilisation',
  ACCESS_USER_TERMS_PRIVACY: 'Politiques de confidentialité',
  INTERPOLATE_LIST_LIMIT: 'Limite de {listAdditionalNumber} invités atteinte!',
  INTERPOLATE_LIST_UNTIL_LIMIT: 'Jusqu\'à {listAdditionalNumber} personne(s) sur la liste par invité',
  PRICE_BETWEEN_MIN_MAX: 'Entre {minPrice} et {maxPrice}',
  PRICE_INSTALLMENT_VALUE: 'En jusqu\'à {installment} fois par carte',
  RESERVE_PEOPLE_LIMIT: 'Jusqu\'à {limitReserve} personne(s) par réservation',
  RESERVE_TABLE_AVAILABLE: 'Tables disponibles',
  RESERVE_PRICE_PAY: 'Montant de {price} pour la réservation',
  PAGE_VIEWS: 'Vues de cette page',
};


export const KITCHENS: Record<EKitchensTranslations, string> = {
  AFRICAN: 'Africaine',
  GERMAN: 'Allemande',
  ARABIC: 'Arabe',
  ARGENTINA: 'Argentine',
  DRINKS: 'Boissons',
  BRAZILIAN: 'Brésilienne',
  COFFEE: 'Cafétéria',
  MEATS: 'Viandes',
  CHINESE: 'Chinoise',
  COLOMBIAN: 'Colombienne',
  KOREAN: 'Coréenne',
  SPANISH: 'Espagnole',
  FRENCH: 'Française',
  SEAFOOD: 'Fruits de mer',
  INDIAN: 'Indienne',
  ITALIAN: 'Italienne',
  JAPANESE: 'Japonaise',
  MEDITERRANEAN: 'Méditerranéenne',
  MEXICAN: 'Mexicaine',
  FRIED_PASTRY: 'Pâtisserie frite',
  FISH: 'Poissons',
  PERUVIAN: 'Péruvienne',
  PIZZA: 'Pizza',
  PORTUGUESE: 'Portugaise',
  HEALTHY: 'Saine',
  JUICES: 'Jus',
  THAI: 'Thaïlandaise',
  VEGETARIAN: 'Végétarienne',
};


export const LABELS: Record<ELabelsTranslations, string> = {
  ALL: 'Tout',
  COMPANY: 'Entreprise',
  COMPANIES: 'Entreprises',
  COMPANIES_MY: 'Mes entreprises',
  PROFILE: 'Profil',
  PROFILES: 'Profils',
  PROFILES_MY: 'Mes profils',
  PERSONAL_DATA: 'Données personnelles',
  PERSONAL_INFORMATIONS: 'Informations personnelles',
  COUNTRY: 'Pays',
  FANTASY_NAME: 'Nom fantaisie (Nom public)',
  SOCIAL_REASON: 'Raison sociale',
  LOADING: 'Chargement',
  USER: 'Utilisateur',
  NAME: 'Nom',
  NAME_FULL: 'Nom complet',
  DATE_BORN: 'Date de naissance',
  GENRE: 'Genre',
  DDI_NUMBER_FIRST: 'DDI du téléphone principal',
  DDI_NUMBER_SECOND: 'DDI du téléphone secondaire',
  DDI: 'DDI',
  PERSONS: 'Personne(s)',
  USERNAME: 'Nom d\'utilisateur',
  USERNAME_COMPANY: 'Nom d\'utilisateur de l\'entreprise',
  EMAIL: 'E-mail',
  PASSWORD: 'Mot de passe',
  PASSWORD_CURRENT: 'Mot de passe actuel',
  PASSWORD_NEW: 'Nouveau mot de passe',
  PASSWORD_REPEAT: 'Répéter le nouveau mot de passe',
  PASSWORD_CONFIRM: 'Confirmer le mot de passe',
  CNPJ: 'CNPJ',
  CPF: 'CPF',
  FORMS: 'Formes',
  COMMENTS: 'Commentaires',
  OPTIONAL: 'Optionnel',
  PUBLIC_ID: 'Id public',
  PUBLIC_PARTNER_ID: 'Id public du partenaire',
  NUMBER_PRIMARY: 'Numéro principal',
  NUMBER_SECONDARY: 'Numéro secondaire',
  PARTNER_ABOUT: 'À propos du partenaire',
  PARTNER_SERVICES: 'Services disponibles',
  PARTNER_PHONES: 'Téléphones',
  PHONE_ONLY_ONE: 'Numéro avec DDD',
  PHONE_FIRST: 'Téléphone principal avec DDD',
  PHONE_SECOND: 'Téléphone secondaire avec DDD',
  PARTNER_RULES: 'Règles',
  PARTNER_TYPES_KITCHEN: 'Types de cuisine',
  PARTNER_OPEN_HOURS: 'Horaires d\'ouverture',
  WIFI: 'Wi-Fi',
  KIDS_AREA: 'Zone pour enfants',
  PARKING: 'Parking',
  SMOKING: 'Zone fumeurs',
  AIR_CONDITIONER: 'Climatisation',
  ACCESSIBILITY: 'Accessibilité',
  MORE_POPULAR: 'Plus populaires',
  NUMBER_OR_NAME: 'Nom ou numéro',
  INPUT_MODAL_ADD_USER_PLACEHOLDER: 'Rechercher par nom...',
  CONFIRM_CPF: 'Confirmer le CPF du titulaire',
  CONFIRM_CNPJ: 'Confirmer le CNPJ de l\'entreprise',
  CONFIRM_INFO: 'Confirmer l\'information',
  NICKNAME: 'Pseudo',
  DESCRIPTION: 'Description',
  FAVORED: 'Bénéficiaire',
  ACCOUNT_USE_MAIN: 'Utiliser ce compte comme actuel',
  ADDRESS_USE_MAIN: 'Utiliser cette adresse comme actuelle',
  INPUT_MODAL_ADD_USER_CARGO: 'Email ou nom d\'utilisateur',
  CODE: 'Code',
  DIGIT: 'Chiffre',
  ACCOUNT: 'Compte',
  ID: 'ID',
  ID_PRIVATE: 'ID privé',
  ID_PUBLIC: 'ID public',
  COMPANY_STATUS_APP: 'Situation sur Secretio',
  COMPANY_STATUS_ACTIVE: 'Entreprise active',
  COMPANY_STATUS_INACTIVE: 'Entreprise désactivée',
  PLACE_NAME: 'Nom du lieu',
  ZIP_CODE: 'Code postal',
  STREET: 'Rue', // 'Avenue / Rue',
  STREETS: 'Rues',
  DISTRICT: 'Quartier',
  DISTRICTS: 'Quartiers',
  ATTRACTIONS: 'Attractions',
  COMPLEMENT: 'Complément',
  CITY: 'Ville',
  CITIES: 'Villes',
  STATE: 'État',
  SOCIAL: 'Social',
  SLOGAN: 'Slogan',
  CELLPHONE: 'Portable',
  ABOUT: 'À propos',
  ABOUT_APP: 'À propos de Secretio',
  PROFILE_TYPE: 'Type de profil',
  PARTNER_TYPE: 'Type de partenaire',
  INFORMATIONS: 'Informations',
  CARD_HOLDER_NAME: 'Nom du titulaire de la carte',
  CARD_NICKNAME: 'Pseudo de la carte',
  MALE: 'Masculin',
  FEMALE: 'Féminin',
  SOCIAL_NAME: 'Nom social',
  ABOUT_ME: 'À propos de moi',
  LEVEL: 'Niveau',
  BIRTHDAY: 'Anniversaire',
  PICTURE: 'Photo',
  USER_PRE_REGISTER: 'Utilisateur pré-enregistré',
  DATAS: 'Données',
  SHOPS: 'Achats',
  OPTIONS: 'Options',
  ME_AND_GUESTS: 'Moi et les invités',
  ONLY_YOU: 'Uniquement vous',
  ONLY_FOR_ME: 'Uniquement pour moi',
  PEOPLE: 'personnes',
  RESERVE_DATE: 'Date de réservation',
  DATE_SELECTED: 'Date sélectionnée',
  ENVIRONMENT: 'Environnement',
  PEOPLE_NUMBER: 'Nombre de personnes',
  MORE_INFORMATIONS: 'Plus d\'informations',
  OBSERVATIONS: 'Observations',
  INITIAL_DATE: 'Date de début',
  INITIAL_HOUR: 'Heure de début',
  FINAL_DATE: 'Date de fin',
  FINAL_HOUR: 'Heure de fin',
  INITIAL_VALUE: 'Valeur initiale',
  FINAL_VALUE: 'Valeur finale',
  PARAMETERS: 'Paramètres',
  NAME_OR_USERNAME: 'Nom ou Nom d\'utilisateur',
  MODALITIES: 'Modalités',
  VERSION: 'Version',
  MY_ACCOUNT: 'Mon compte',
  ACCOUNT_SEE: 'Voir le compte',
  ACCOUNT_ACTIONS: 'Actions du compte',
  PAYMENTS_FORMS: 'Moyens de paiement',
  LOCATION: 'Localisation',
  LOCATION_NO_ADDRESS: 'Aucune information sur l\'adresse',
  TICKETS_SALES_UNTIL: 'Ventes jusqu\'à',
  PARTICIPATION: 'Participation',
  WAIT_YOU: 'Vous attend',
  ITEMS: 'Articles',
  HELP: 'Aide',
  TYPE: 'Type',
  CANCELED: 'Annulé',
  FINISHED: 'Terminé',
  OTHER: 'Autre',
  ADD: 'Ajouter',
  ACTUAL: 'Actuel',
  COUNTRIES: 'Pays',
  NO: 'Non',
  YES: 'Oui',
  LIST_NAME: 'Nom de la liste',
  GUIDES_INFORMATIONS: 'Guides et informations',
  PHONES_USEFUL: 'Téléphones utiles',
  CASADINHA: 'Casadinha',
  OPEN_BAR: 'Open Bar',
  LIST: 'Liste',
  VIP: 'VIP',
};


export const LANGUAGES: Record<ELanguagesTranslations, string> = {
  PT_BR: 'Portugais (Brésil)',
  EN_US: 'Anglais (États-Unis)',
  ES_AR: 'Espagnol (Espagne)',
  IT_IT: 'Italien (Italie)',
  FR_FR: 'Français (France)',
};


export const MENU_ORDER: Record<EMenuOrderTranslations, string> = {
  COMBO: 'Combo',
  ITEM_DOUBLE: 'Double portion',
  AVAILABLES: 'Disponibles',
  TABLE: 'Table',
  NUMBER: 'Numéro',
  NUMBER_TABLE_ENTRANCE: 'Numéro de table / entrée',
  TABLE_ENTRANCE: 'Table / entrée',
  MIN_ORDERSHEET: 'Commande minimum',
  WAITER_CALL: 'Appeler le serveur',
  WAITER_CALLING: 'Appel en cours',
  WAITER_GOING: 'Le serveur arrive',
  ORDERSHEET_CLOSING_ORDERSHEET: 'Fermeture de la commande',
  ORDERSHEET_CLOSED: 'Commande fermée',
  ORDERSHEET_OPENED: 'Commande ouverte',
  TOTAL_CONSUMPTION: 'Consommation totale',
  ALCOHOLIC: 'Alcoolique',
  SPICY: 'Épicé',
  VEGETARIAN: 'Végétarien',
  VEGAN: 'Végane',
  ORGANIC: 'Biologique',
  NO_LACTOSE: 'Sans lactose',
  NO_GLUTEN: 'Sans gluten',
  REQUESTS: 'Demandes',
  REQUESTS_MINIMAL: 'Demandes minimales',
  REQUESTS_UNTIL: 'Demandes jusqu\'à',
  DELIVERY: 'Livraison',
  REMOVAL_ITEM: 'Retrait',
  PRESENTIAL: 'Présentiel',
  ORDER_PLACED: 'Commande passée',
  ORDER_PREPARING: 'Préparation en cours',
  ORDER_TO_DELIVER: 'En cours de livraison',
  ORDER_REFUSED: 'Commande refusée',
};


export const MESSAGES_EMPTYS: Record<EMessagesEmptysTranslations, string> = {
  EMPTY_GALLERY_PHOTOS: 'Désolé, pas de médias de ce partenaire!',
  EMPTY_COMPANY_NOT_FOUND: 'Désolé, profil introuvable!',
  EMPTY_COMPANY_EVENTS: 'Pas d\'événements ici encore!',
  EMPTY_EVENTS_NOT_FOUND: 'Désolé, événement non disponible!',
  EMPTY_HIGHLIGHTS_ITEMS: 'Désolé, aucun point fort trouvé aujourd\'hui!',
  EMPTY_HIGHLIGHTS_EVENTS: 'Pas d\'événements ici encore!',
  EMPTY_HIGHLIGHTS_PARTNERS: 'Aucun partenaire sur Secretio ici!',
  EMPTY_CATEGORIES: 'Aucune catégorie',
  EMPTY_ITEMS: 'Désolé, pas d\'articles dans le menu!',
  EMPTY_MENUSHEET_CATEGORIES: 'Désolé, pas d\'articles dans cette catégorie!',
  EMPTY_NOTIFICATIONS: 'Pas de notifications ici encore!',
  EMPTY_ORDERSHEET_HISTORY: 'Aucun élément dans l\'historique pour le moment!',
  EMPTY_BANKS: 'Pas de banques ici, désolé!',
  EMPTY_COMPANIES_REGISTERED: 'Aucune entreprise enregistrée!',
  EMPTY_HOME: 'Aucune donnée à afficher!',
  EMPTY_FAVORITES: 'Pas de favoris ici!',
  EMPTY_PAYMENT: 'Aucune forme de paiement enregistrée!',
  EMPTY_ADDRESS: 'Aucune adresse enregistrée!',
  EMPTY_USER_DATA: 'Utilisateur sans données',
  EMPTY_USER_NO_RECOG: 'Utilisateur non identifié',
  EMPTY_LISTS: 'Aucune liste enregistrée',
  EMPTY_LISTS_PARTICIPATE: 'Aucune liste à laquelle participer pour le moment!',
  EMPTY_LISTS_FINISHED: 'Aucune liste à laquelle vous avez participé pour le moment!',
  EMPTY_TICKET: 'Pas de billets disponibles pour le moment!',
  EMPTY_TICKET_IN_EVENT: 'Pas de billets pour cet événement',
  EMPTY_EVENT_EXPLORE: 'Explorez d\'autres événements similaires',
  EMPTY_CARD_REGISTERED: 'Aucune carte enregistrée',
  EMPTY_CARD_NO_ADDED: 'Vous n\'avez pas encore ajouté de carte à votre compte',
  EMPTY_RESERVE_REGISTERED: 'Aucune réservation disponible ou enregistrée!',
  EMPTY_RESERVE_USER: 'Pas de réservations pour profiter de la nuit!',
  EMPTY_LINK_SEE: 'Aucun lien à visualiser',
  EMPTY_SEARCH: 'Désolé, rien trouvé dans la recherche!',
  EMPTY_ORDERS: 'Aucune commande',
  EMPTY_EVENT_FINISHED: 'L\'événement est déjà terminé!',
  EMPTY_PLACES: 'Aucun lieu enregistré pour le moment!',
  EMPTY_CARNIVAL_ATTRACTIONS: 'Désolé, aucune attraction aujourd\'hui!',
  EMPTY_CARNIVAL_NONE: 'Désolé, pas de carnaval ni de micarêta pour le moment!',
  EMPTY_CARNIVAL_DISTRICTS: 'Désolé, pas de fête dans les quartiers aujourd\'hui!',
  EMPTY_CARNIVAL_CABINS: 'Désolé, les cabines sont fermées aujourd\'hui!',
  EMPTY_CARNIVAL_LOCATIONS: 'Désolé, aucun emplacement ici!',
  EMPTY_CARNIVAL_STREETS: 'Désolé, pas de fête dans la rue aujourd\'hui!',
  EMPTY_CARNIVAL_INFOS: 'Désolé, aucune information ici!',
  EMPTY_PHONES: 'Désolé, pas de téléphones ici!',
  EMPTY_CARNIVAL_VOTES: 'Désolé, pas de votes pour le moment!',
  EMPTY_LANGUAGES: 'Aucune langue pour le moment!',
  EMPTY_LIST_AVAILABLE: 'Aucune liste disponible',
  EMPTY_TICKET_AVAILABLE: 'Il n\'y a pas de billets disponibles',
};


export const MESSAGES_ERRORS: Record<EMessagesErrosTranslations, string> = {
  ERROR: 'Erreur',
  ERROR_PAY: 'Erreur lors du paiement',
  ERROR_PAY_APP: 'Nous avons rencontré un problème, veuillez effectuer le paiement sur place',
  ERROR_CHECKIN_ASK: 'Impossible de demander le Check-in',
  ERROR_CHECKIN_CANCEL: 'Impossible d\'annuler le Check-in',
  ERROR_HAD: 'Une erreur s\'est produite',
  ERROR_SEARCH: 'Erreur lors de la recherche de données',
  TRY_AGAIN: 'Erreur, veuillez réessayer',
  ACCESS_DENIED: 'Aucune autorisation d\'accès pour cet utilisateur.',
  PASSWORD_WRONG: 'Nom d\'utilisateur et/ou mot de passe incorrect(s).',
  ERROR_PARAMS_INVALID: 'Paramètres invalides',
  ERROR_COMPANY_CREATE: 'Erreur lors de la création de l\'entreprise',
  ERROR_USER_CREATE: 'Erreur lors de la création du profil',
  ERROR_DATE: 'Erreur de date',
  ERROR_NO_DATA_FOUND: 'Données non trouvées, vérifiez les informations et réessayez.',
  ERROR_EMPTY_FIELDS: 'Remplissez correctement tous les champs avant de continuer',
  ERROR_ACCOUNT_TRANSFER: 'Erreur lors de la création du compte de transfert',
  ERROR_ACCOUNT_SEARCH: 'Erreur lors de la recherche de comptes de transfert',
  ERROR_ACCOUNT_MAIN_CHANGE: 'Erreur lors du changement de compte principal',
  ERROR_ACCOUNT_DELETE: 'Erreur lors de la suppression du compte',
  ERROR_SAVE: 'Erreur lors de l\'enregistrement des données!',
  ERROR_CREATE: 'Erreur lors de la création',
  ERROR_UPDATE: 'Erreur lors de la mise à jour',
  ERROR_USER_DISABLE: 'Erreur lors de la désactivation de l\'utilisateur.',
  ERROR_COMPANY_DISABLE: 'Erreur lors de la désactivation de l\'entreprise.',
  ERROR_MAKE_REGISTER: 'Erreur lors de l\'inscription',
  ERROR_PAYMENT_MAIN_CHOOSED: 'Erreur lors du choix du paiement principal',
  ERROR_PAYMENT_DELETE: 'Erreur lors de la suppression du paiement',
  ERROR_ADDRESS_DELETE: 'Erreur lors de la suppression de l\'adresse',
  ERROR_LIST_USER_ADDED: 'Erreur lors de l\'ajout d\'utilisateurs',
  ERROR_LIST_ENTER: 'Erreur lors de la tentative d\'inscription dans la liste',
  ERROR_LIST_GET_DATA: 'Erreur lors de l\'obtention des listes',
  ERROR_GET_DATA: 'Erreur lors de l\'obtention des données',
  ERROR_TICKET_UNAVAILABLES: 'Billets indisponibles',
  ERROR_LISTS_UNAVAILABLES: 'Listes indisponibles',
  ERROR_USER_DOESNOT_EXIST: 'L\'utilisateur n\'existe pas!',
  ERROR_USER_IN_LIST: 'L\'utilisateur est déjà dans la liste',
  ERROR_FAVORITE_ADD: 'Erreur lors de l\'ajout aux favoris',
  ERROR_FAVORITE_REMOVE: 'Erreur lors de la suppression des favoris',
};


export const MESSAGES_HELP: Record<EMessagesHelpsTranslations, string> = {
  HELP_INPUT_COMPANY: 'Entrez les données de l\'entreprise',
  HELP_INPUT_USER: 'Entrez les informations ci-dessous',
  HELP_INPUT_REQUIRED: 'Champs obligatoires',
  HELP_QR: 'Scannez le code QR du partenaire pour faire un Check-in, éviter les files d\'attente et accélérer votre entrée dans les lieux et événements!',
  HELP_CHECKIN: 'Pour faire un Check-in, saisissez l\'ID public du partenaire, par exemple le numéro de table, et attendez la confirmation de votre Check-in.',
  HELP_CHECKIN_WAIT: 'Attendez la confirmation du Check-in. Si vous le souhaitez, vous pouvez annuler la demande de Check-in à tout moment.',
  HELP_EVENT_RATING: 'Il incombe aux créateurs et producteurs de cet événement de le classer et de fournir ses informations.',
  HELP_EVENT_ALCOHOL: 'La vente de boissons alcoolisées est interdite aux moins de 18 ans.',
  HELP_PAY_UNAVAILABLE: 'Paiement en ligne indisponible. Aucune carte n\'est enregistrée',
  HELP_SUCCESS_ORDER_DONE: 'Vous pouvez suivre l\'état des articles directement dans l\'historique de la commande.',
  HELP_CHEKIN_HOW_TO: 'À la porte de l\'endroit ou à l\'entrée de l\'événement, montrez ce code QR pour valider les billets, les listes, les réservations et les demandes d\'entrée.',
  HELP_ORDERSHEET_CLOSING: 'En attente de fermeture',
  HELP_ORDERSHEET_THANKS: 'Merci d\'utiliser la Secretio',
  HELP_ORDERSHEET_PARTNER_CLOSE: 'Votre commande a été clôturée par le partenaire',
  HELP_ORDERSHEET_RECUSED: 'Commande refusée',
  HELP_ORDERSHEET_NOT_FOUND: 'Check-in non accepté ou introuvable',
  HELP_SOME: 'Une question quelconque?',
  HELP_TALK_TO_US: 'Parlez à la Secretio',
  HELP_ACCOUNT_TRANSFER: 'Pour que le transfert soit effectué, le compte bancaire à ajouter doit appartenir à la personne enregistrée ou à l\'entreprise.',
  HELP_ACCOUNT_BANK_RECEIVE: 'La banque réceptrice peut choisir dans quelle modalité créditer le transfert si ses comptes d\'épargne et courant ont le même numéro.',
  HELP_ACCOUNT_LIST_TRANSFER: 'Enregistrez un compte bancaire et transférez votre argent dessus.',
  HELP_ACCOUNT_LIST_TRANSFER_TEXT: 'Pour transférer des fonds sur votre compte bancaire, vous devez d\'abord enregistrer un compte bancaire à votre nom ou à celui de l\'entreprise.',
  HELP_PROFILE_PICTURE: 'Une photo aide les gens à vous reconnaître et vous permet de savoir quel compte est connecté.',
  HELP_LOGOTIPO_COMPANY_TEXT: 'Un logo aide à personnaliser le profil et permet de le trouver plus rapidement.',
  HELP_LOGOTIPO_COMPANY_DIMENSIONS: 'Nous recommandons une image carrée (1x1), d\'environ 400x400 pixels.',
  HELP_COMPANY_COMPLETE_PROFILE: 'Complétez le profil de votre entreprise pour utiliser les services de Secretio',
  HELP_USER_DISABLE: 'Si vous souhaitez désactiver votre compte, nous nous en chargerons pour vous. Nous tenons à informer nos utilisateurs que, même si le compte est désactivé, pour des raisons de sécurité, vous ne pourrez pas créer un autre compte sur notre plateforme, car il ne peut y avoir qu\'un seul compte par CPF.',
  HELP_USER_DISABLE_BACK: 'Vous pouvez revenir à tout moment en accédant à votre compte avec le mot de passe déjà enregistré, et si vous l\'oubliez, vous pouvez le réinitialiser.',
  HELP_COMPANY_DISABLE_BACK: 'Pour réactiver cette entreprise, vous devez contacter le support et demander l\'activation.',
  HELP_COMPANY_DISABLE_PLAN: 'Si cette entreprise a un plan, il sera également annulé!',
  HELP_WELCOME_TITLE: 'Bienvenue sur votre compte',
  HELP_WELCOME_DESCRIPTION: 'Gérez vos informations, votre vie privée et votre sécurité pour que Secretio réponde à vos besoins.',
  HELP_PASSWORD_NEW: 'Saisissez et confirmez votre nouveau mot de passe, c\'est avec lui que vous pourrez accéder à Secretio',
  HELP_PASSWORD_OLD: 'Saisissez votre mot de passe actuel pour que nous puissions confirmer que c\'est bien vous, puis saisissez et confirmez votre nouveau mot de passe',
  HELP_PASSWORD_REMENBER: 'Si vous ne vous souvenez pas de votre mot de passe actuel, vous devrez',
  HELP_PASSWORD_REDEFINE: 'le redéfinir',
  HELP_PASSWORD_FORGOT: 'Si vous avez oublié le mot de passe, saisissez ci-dessous l\'e-mail que vous avez utilisé pour vous inscrire à Secretio.',
  HELP_INVITE_PEOPLE: 'Vous pouvez inviter plus de personnes maintenant ou plus tard.',
  HELP_INVITE_LINK: 'Partagez ce lien avec vos invités pour qu\'ils ajoutent leur nom à cette liste.',
  HELP_LIST_HOW_INVITE: 'Saisissez le nom d\'utilisateur de votre invité ou, si la personne n\'est pas sur Secretio, saisissez son CPF.',
  HELP_LIST_HOW_FINISH: 'En ajoutant une personne, sa participation est automatiquement confirmée à la fin!',
  HELP_WIFI_NO_REGISTER: 'Pas de Wi-Fi enregistré, vous devez demander pour savoir s\'il est disponible.',
  HELP_RESERVE_MINUTES: 'Après 30 minutes de l\'heure réservée, en l\'absence de quelqu\'un pour valider la réservation, la réservation sera annulée et la table libérée.',
  HELP_RESERVE_AGE: 'Âge minimum payant : 4 ans.',
  HELP_RESERVE_TABLE: 'L\'option de regrouper les tables est à la charge du partenaire.',
  HELP_RESERVE_TAXES: 'Les frais éventuels tels que le stationnement ou l\'aire de jeux ne sont pas inclus dans le prix de la réservation.',
  HELP_RESERVE_CANCEL: 'Si personne ne peut venir, annulez la réservation à l\'avance. L\'annulation peut être faite jusqu\'à 3 heures avant l\'heure réservée, après quoi l\'annulation ne pourra plus être effectuée.',
  HELP_MINIMAL_AGE: 'À partir de 4 ans',
  HELP_RESERVE_OBS_EXAMPLE: 'Exemple : Anniversaire, Célébration',
  HELP_RESERVE_QUANTITY_PARTNER: 'La quantité de personnes dans la réservation sera la quantité acceptée, il revient au partenaire d\'identifier et de compter le nombre de personnes pour cette réservation et de décider s\'il acceptera plus de personnes que celles spécifiées dans la réservation.',
  HELP_SEARCH_BY_NAME: 'Recherchez par nom ou nom d\'utilisateur',
  HELP_SEARCH_PARAMETERS: 'Recherchez par paramètres',
  HELP_LOGIN_TO_SEE: 'Connectez-vous pour voir vos informations',
  HELP_INSERT_USER: 'Insérez l\'utilisateur',
  HELP_USER_HAVENT_DATA: 'Je n\'ai aucune information',
  HELP_COMPANY_QUANTITY_ACCEPT: 'L\'acceptation de la quantité de personnes est à la charge du partenaire. Il est responsable d\'identifier et de compter la quantité de personnes participantes et de décider s\'il acceptera plus de personnes que celles spécifiées.',
  HELP_PROFILE_VERIFY_UNMANAGE: 'Profil non géré. Soyez attentif et vérifiez toujours les informations et les valeurs sur place, car elles peuvent être obsolètes.',
  HELP_PROFILE_UNMANAGE: 'Le profil de cet endroit n\'a pas encore été demandé. Les propriétaires qui demandent le profil peuvent mettre à jour les informations, effectuer des ventes, créer des réservations, ajouter des photos et bien plus encore.',
  HELP_PROFILE_MANAGE: 'Une personne liée à cet endroit gère ce profil.',
  HELP_ORDERSHEET_DETAILS_ENABLED: 'Vous pouvez utiliser la consommation Secretio pour acheter et payer cet événement.',
  HELP_ORDERSHEET_DETAILS_DISABLED: 'La consommation est désactivée par le producteur, à votre arrivée sur place vous devez demander comment acheter et payer votre commande.',
};


export const MESSAGES_QUESTIONS: Record<EMessagesQuestionsTranslations, string> = {
  ANY_QUESTION: 'Toute question',
  ANY_QUESTION_CONTACT: 'contactez-nous',
  PAY_ATTENTION: 'Restez attentif',
  QUESTION_DELETE_CARD: 'Supprimer la carte?',
  QUESTION_DELETE_CARD_TEXT: 'Souhaitez-vous supprimer cette carte?',
  QUESTION_ORDERSHEET_CLOSE: 'Fermer la commande?',
  QUESTION_ADD_CREDIT_CARD: 'Aucune carte de crédit n\'est enregistrée sur votre compte, souhaitez-vous en ajouter?',
  QUESTION_ACCOUNT_MAIN_CHANGE: 'Changer de compte bancaire principal?',
  QUESTION_ACCOUNT_MAIN_CHANGE_TEXT: 'Souhaitez-vous définir ce compte comme principal?',
  QUESTION_ACCOUNT_DELETE: 'Supprimer le compte bancaire?',
  QUESTION_ACCOUNT_DELETE_TEXT: 'Souhaitez-vous supprimer ce compte bancaire?',
  QUESTION_USER_DISABLE: 'Êtes-vous sûr de vouloir désactiver votre compte?',
  QUESTION_COMPANY_DISABLE: 'Êtes-vous sûr de vouloir désactiver cette entreprise?',
  QUESTION_ADDRESS_REGISTER_TEXT: 'Pour ajouter une méthode de paiement, il est nécessaire d\'enregistrer une adresse principale. Voulez-vous le faire maintenant?',
  QUESTION_ADDRESS_DELETE: 'Supprimer l\'adresse?',
  QUESTION_ADDRESS_DELETE_TEXT: 'Souhaitez-vous supprimer cet élément?',
  QUESTION_BAG_ITEM_REMOVE: 'Voulez-vous retirer {itemSaleName} de votre panier?',
  QUESTION_BAG_CLEAR: 'Vider le panier?',
  QUESTION_EXIT: 'Voulez-vous quitter',
  QUESTION_FAVORITE_REMOVE: 'Supprimer des favoris?',
};


export const MESSAGES_SUCCESS: Record<EMessagesSuccessTranslations, string> = {
  SUCCESS: 'Succès',
  SUCCESS_CHECKIN_ASK: 'Check-in confirmé. Profitez-en!',
  SUCCESS_CHECKIN_CANCEL: 'Demande de check-in annulée!',
  SUCCESS_ADD_ITEM_BAG: 'Article ajouté avec succès!',
  SUCCESS_ORDER_DONE: 'Commande passée avec succès!',
  SUCCESS_PAYED: 'Payé!',
  SUCEESS_COMPANY_CREATE: 'Entreprise créée avec succès!',
  SUCEESS_COMPANY_CREATE_TEXT: 'Maintenant, vous devez attendre notre évaluation des données.',
  SUCEESS_USER_CREATE: 'Profil créé avec succès!',
  SUCEESS_USER_CREATE_TEXT: 'Nous avons envoyé un e-mail de confirmation à {user_email}. Ouvrez l\'e-mail, confirmez la création du profil et vous pourrez vous connecter en utilisant vos identifiants enregistrés. Votre numéro de client est également dans l\'e-mail.',
  SUCCESS_ACCOUNT_TRANSFER: 'Compte de transfert créé avec succès',
  SUCCESS_ACCOUNT_MAIN_CHANGE: 'Nouveau compte principal changé',
  SUCCESS_ACCOUNT_DELETE: 'Compte supprimé avec succès',
  SUCCESS_CREATE: 'Création réussie',
  SUCCESS_UPDATE: 'Mise à jour réussie',
  SUCCESS_USER_DISABLE: 'Utilisateur désactivé avec succès',
  SUCCESS_COMPANY_DISABLE: 'Entreprise désactivée avec succès',
  SUCCESS_PASSWORD_RECOVER: 'Demande de récupération de mot de passe effectuée avec succès!',
  SUCCESS_PASSWORD_RECOVER_TEXT: 'Nous avons envoyé un e-mail de confirmation à {user_email}. Ouvrez l\'e-mail, cliquez sur le lien et vous pourrez changer votre mot de passe.',
  SUCCESS_CARD_REGISTER: 'Carte enregistrée avec succès',
  SUCCESS_PAYMENT_MAIN_CHOOSED: 'Paiement principal choisi',
  SUCCESS_PAYMENT_DELETE: 'Paiement supprimé avec succès',
  SUCCESS_ADDRESS_DELETE: 'Adresse supprimée avec succès',
  SUCCESS_EMAIL_CHANGE: 'E-mail de changement envoyé avec succès',
  SUCCESS_LIST_USER_ADDED: 'Utilisateurs ajoutés avec succès',
  SUCCESS_PASSWORD_COPY: 'Mot de passe copié',
  SUCCESS_USER_ADDED: 'Utilisateur ajouté avec succès',
  SUCCESS_FAVORITE_ADD: 'Favori ajouté avec succès',
  SUCCESS_FAVORITE_REMOVE: 'Favori supprimé avec succès',
  SUCCESS_COPIED_ADDRESS: 'Adresse copié',
  SUCCESS_COPIED_LINK: 'Lien copié',
};


export const MUSICS: Record<EMusicsTranslations, string> = {
  EVENT_MUSICAL_GENRES: 'Genres Musicaux',
  MUSIC: 'Musique',
  ELECTRONIC: 'Électronique',
  FUNK: 'Funk',
  COUNTRY_MUSIC: 'Musique Country',
  POP: 'Pop',
  RAP: 'Rap',
  ROCK: 'Rock',
  MPB: 'MPB',
  AXE: 'Axé',
  SAMBA: 'Samba',
  REGGAE: 'Reggae',
  FORRO: 'Forró',
  GOSPEL: 'Gospel',
  HIP_HOP: 'Hip-Hop',
  JAZZ: 'Jazz',
  CLASSIC_MUSIC: 'Musique Classique',
  PAGODE: 'Pagode',
  BLUES: 'Blues',
};


export const PAYMENTS: Record<EPaymentsTranslations, string> = {
  BANK: 'Banque',
  AGENCY: 'Agence',
  CARD_SELECTED: 'Carte sélectionnée',
  DEBIT_CARD: 'Carte de débit',
  CREDIT_CARD: 'Carte de crédit',
  CREDIT: 'Crédit',
  DEBIT: 'Débit',
  TICKETS_CARD: 'Tickets',
  CASH: 'Espèces',
  BANK_CHECK: 'Chèque',
  PIX: 'Pix',
  ELO: 'Elo',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
  AMERICAN: 'American',
  DINERS: 'Diners',
  HIPERCARD: 'Hipercard',
  ALELO: 'Alelo',
  BEN: 'Ben',
  SODEXO: 'Sodexo',
  CARD_TICKET: 'Ticket',
  VR: 'Vr',
  CHANGE: 'Monnaie',
  DISCOUNT: 'Remise',
  TIP: 'Pourboire',
  TOTAL_PAY: 'Total payé',
  TOTAL: 'Total',
  SUBTOTAL: 'Sous-total',
  TAXES: 'Taxes et frais',
  TAX_DELIVERY: 'Frais de livraison',
  PARTNER_PAYMENTS_ON: 'Paiements acceptés sur place',
  WAITING_PAYMENT: 'En attente de paiement',
  ALL_BANKS: 'Toutes les banques',
  ACCOUNT_CURRENT: 'Compte courant',
  ACCOUNT_SAVING: 'Compte épargne',
  ACCOUNT_NICKNAME: 'Surnom du compte',
  ACCOUNT_BELONGING: 'Compte appartenant à',
  ACCOUNT_CURRENT_SAVING_SAME_NUMBER: 'Compte courant et compte épargne avec le même numéro',
  CARD_ACCEPTED: 'Cartes acceptées sur Secretio',
  PAYMENT_FORM: 'Moyen de paiement',
  CARD_NUMBER: 'Numéro de carte',
  DATE_VALID_UNTIL: 'Valide jusqu\'au',
  CARD: 'Carte',
  CARDS: 'Cartes',
  CARD_CVV: 'CVV',
  CARD_VALIDITY: 'Validité',
  CARD_CURRENCY: 'Carte actuelle',
  CARD_DEFINE_CURRENCY: 'Définir carte actuelle',
  CARD_DELETE: 'Supprimer la carte',
  CARD_CHOOSE_MAIN: 'Utiliser cette carte comme actuelle',
  CARD_CHOOSE_DEBIT_TOO: 'Si la charge sur crédit échoue, essayer avec la forme de débit (si l\'option est disponible)',
  CARD_CHOOSE_CREDIT_TOO: 'Si la charge sur débit échoue, essayer avec la forme de crédit (si l\'option est disponible)',
};


export const RESERVES: Record<EReservesTranslations, string> = {
  RESERVE_TO: 'Réserver Pour',
  RESERVE_PRICE: 'Coût de la Réservation',
  RESERVE_FREE: 'Réservation gratuite',
  RESERVED_TO: 'Réservé pour',
  RESERVED_AT: 'Réservé à',
  RESERVE_MADE_TO: 'Réservation effectuée par',
  RESERVE_HOUR: 'Heure de la réservation',
  RESERVE_PEOPLE: 'Personnes dans la réservation',
};


export const SOCIALS: Record<ESocialsTranslations, string> = {
  WHATSAPP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'X Twitter',
  YOUTUBE: 'Youtube',
  LINKEDIN: 'LinkedIn',
  CONTACT_US: 'Contactez-nous',
  SEND_MESSAGE: 'Envoyer un message',
};


export const TIME: Record<ETimeTranslations, string> = {
  DATE: 'Date',
  HOUR: 'Heure',
  DATE_HOUR: 'Date et Heure',
  DATES_AVAILABLE: 'Dates disponibles',
  OPENED_UNTIL: 'Ouvert jusqu\'à',
  OPENEDS: 'Ouverts',
  CLOSE_IN: 'Ferme dans',
  CLOSED: 'Fermé',
  CLOSEDS: 'Fermés',
  TODAY: 'Aujourd\'hui',
  SUNDAY: 'Dimanche',
  MONDAY: 'Lundi',
  TUESDAY: 'Mardi',
  WEDNESDAY: 'Mercredi',
  THURSDAY: 'Jeudi',
  FRIDAY: 'Vendredi',
  SATURDAY: 'Samedi',
  NEXT: 'Prochains',
  PAST: 'Passés',
  UPDATED_AT: 'Mis à jour',
};


export const TITLES: Record<ETitlesTranslations, string> = {
  HOME: 'Accueil',
  FAVORITES: 'Favoris',
  EXIT: 'Quitter',
  FORGOT_PASSWORD_QUESTION: 'Mot de passe oublié?',
  RECOVER_PASSWORD: 'Récupérer le mot de passe',
  REGISTER: 'S\'inscrire',
  ACCESS: 'Entrer',
  CHECKIN: 'Check-in',
  CHECKOUT: 'Check-Out',
  CHECKIN_WAIT: 'En attente de Check-in',
  QR_READER: 'Vous ne voulez pas perdre de temps?',
  QR_MY_CODE: 'Mon code QR',
  GALLERY: 'Galerie',
  COMPANY_DETAILS: 'Détails du partenaire',
  ALL_EVENTS: 'Tous les événements',
  LISTS: 'Listes',
  LISTS_EVENT: 'Listes de l\'événement',
  LISTS_ALL: 'Toutes les listes',
  LISTS_AVAILABLES: 'Listes disponibles',
  LISTS_SPECIALS: 'Listes spéciales',
  MENUSHEET: 'Menu',
  HIGHLIGHTS: 'Points forts',
  PARTNER: 'Partenaire',
  PARTNERS: 'Partenaires',
  PARTNERS_HIGHLIGHTS: 'Profils en vedette aujourd\'hui',
  EVENTS: 'Événements',
  EVENTS_HIGHTLIGHTS: 'Événements en vedette aujourd\'hui',
  EVENTS_MORE: 'Événements pour vous',
  EVENTS_STREET: 'Événements de rue',
  MAP: 'Carte',
  MAPS: 'Cartes',
  NOTIFICATIONS: 'Notifications',
  NOTIFICATIONS_CONFIG: 'Configurer les notifications',
  ORDERSHEET: 'Commande',
  ORDERSHEET_PAY: 'Payer la commande',
  PAYMENT_OPTIONS: 'Options de paiement',
  ORDERBAG: 'Panier',
  HISTORIC: 'Historique',
  COMPANY_NEW: 'Nouvelle entreprise',
  COMPANY_REGISTER: 'Enregistrer l\'entreprise',
  BANKS: 'Banques',
  MODAL_ADD_USER: 'Ajouter un utilisateur à l\'entreprise',
  ACCOUNT_TRANSFER: 'Comptes de transfert',
  ACCOUNT_TRANSFER_ADD: 'Ajouter un compte pour le transfert',
  COMPANY_ADDRESS_EDIT: 'Modifier l\'adresse de l\'entreprise',
  CONTACT: 'Contact',
  COMPANY_EDIT: 'Modifier l\'entreprise',
  COMPANY_DATAS: 'Données de l\'entreprise',
  PROFILE_PICTURE: 'Photo de profil',
  LOGOTIPO: 'Logotype',
  LOGOTIPO_COMPANY: 'Logotype du profil',
  ADDRESS: 'Adresse',
  ADDRESS_CREATE: 'Créer une adresse',
  ADDRESS_EDIT: 'Modifier l\'adresse',
  ADDRESSES: 'Adresses',
  USER_DISABLE: 'Désactiver le profil',
  COMPANY_DISABLE: 'Désactiver l\'entreprise',
  REDEFINE_PASSWORD: 'Redéfinir le mot de passe',
  CHANGE_PASSWORD: 'Changer le mot de passe',
  PREFERENCES: 'Préférences',
  SETTINGS: 'Paramètres',
  LANGUAGE: 'Langue',
  CARD_ADD: 'Ajouter une carte',
  ADDRESS_REGISTER: 'Enregistrer l\'adresse',
  PAYMENTS_SUBSCRIPTIONS: 'Paiements et abonnements',
  PROFILE_MY_ABOUT: 'À propos de mon profil',
  PROFILE_DETAILS: 'Détails du profil',
  PROFILE_INFORMATIOS: 'Informations du profil',
  PROFILE_PUBLIC: 'Profil public',
  PROFILE_MAIN: 'Le compte principal',
  PERMISSIONS_GROUP: 'Groupes et permissions',
  INVITE_TO_LIST: 'Inviter à la liste',
  INVITE_PEOPLE: 'Inviter des personnes',
  PARTICIPATE: 'Participer',
  LIST_PARTICIPATE: 'Listes participantes',
  DETAILS_ITEM: 'Détail de l\'article',
  MY_ORDERS: 'Mes commandes',
  RESERVES: 'Réservations',
  RESERVE_DETAILS: 'Détails de la réservation',
  SEARCH: 'Recherches',
  OTHER_INFORMATIONS: 'Autres informations',
  TERMS_POLICY: 'Termes et politiques',
  LICENCES: 'Licences',
  KNOW_APP: 'Connaître la Secretio',
  PROFILE_UNMANAGE: 'Profil non géré',
  PROFILE_MANAGE: 'Profil géré',
  SHARE: 'Partager',
};


export const SUBTITLES: Record<ESubtitlesTranslations, string> = {
  SUBTITLES_LOGOTIPO_COMPANY: 'Un logo aide à personnaliser votre entreprise',
  SUBTITLES_PROFILE_PICTURE: 'Une photo aide à personnaliser votre compte',
  SUBTITLES_PROFILE_ALL: 'Voyez comment vos différents profils apparaissent dans les services de Secretio',
  SUBTITLES_INFORMATIONS_COMPANY: 'Nom, à propos et plus',
  SUBTITLES_DATAS_COMPANY: 'Inscription et nom juridique, par exemple',
  SUBTITLES_COMPANY_CONTACT: 'Cliquez pour éditer vos contacts',
  SUBTITLES_COMPANY_TRANSFER_YES: 'Cliquez pour gérer les comptes de transfert de cette entreprise',
  SUBTITLES_COMPANY_TRANSFER_NO: 'Comptes de transfert non encore disponibles',
  SUBTITLES_COMPANY_ADDRESS: 'Cliquez pour éditer l\'adresse de l\'entreprise',
  SUBTITLES_ACCOUNT_TRANSFER_ADD: 'Secretio doit encore analyser votre inscription. Attendez un peu avant de réessayer!',
  SUBTITLES_SETTINGS: 'Paramètres de votre compte global',
  SUBTITLES_CARDS: 'Cartes de crédit', // 'Cartes de crédit et de débit',
  SUBTITLES_PROFILE_PUBLIC: 'Voici les informations qui apparaissent dans ce profil public',
  SUBTITLES_PERMISSIONS_GROUP: 'Ajoutez des personnes et gérez les autorisations',
  SUBTITLES_HIGHLIGHT_MAP: 'Autorisez l\'utilisation de la localisation pour voir les lieux à proximité',
  SUBTITLES_HIGHLIGHT_MAP_EXPERIENCE: 'Cela nous permet d\'améliorer votre expérience sur Secretio',
  SUBTITLES_LIST_IN: 'Vous êtes sur la liste',
  SUBTITLES_LIST_INSIDE: 'Vous êtes déjà sur la liste : {listName}',
  SUBTITLES_LIST_OUTSIDE: 'Cliquez sur le bouton Participer pour entrer dans la liste : {listName}',
  SUBTITLES_SEE_ITEMS_MY_LOCATION: 'Voir les événements et partenaires autour de moi',
  SUBTITLES_KNOW_APP: 'Menu, réservations, commandes, lien bio, billets et bien plus encore.',
};


export const VALIDATIONS: Record<EValidationsTranslations, string> = {
  ONLY_NUMBERS: 'Seuls les chiffres sont autorisés',
  VALIDATE_MIN_DIGITS: 'Doit contenir au moins {count} chiffres',
  VALIDATE_MAX_DIGITS: 'Doit contenir jusqu\'à {count} chiffres',
  VALIDATE_EXACT_DIGITS: 'Doit contenir exactement {count} chiffres',
  VALIDATE_MIN_CHAR: 'Doit contenir au moins {count} caractères',
  VALIDATE_MAX_CHAR: 'Doit contenir jusqu\'à {count} caractères',
  VALIDATE_EXACT_CHAR: 'Doit contenir exactement {count} caractères',
  VALIDATE_ALL_FIELDS: 'Remplissez tous les champs correctement avant de continuer',
  VALIDATE_ALL_SELECTED: 'Vérifiez que tous les champs sont sélectionnés',
  VALIDATE_HAS_USER: 'Il y a déjà un profil avec cet utilisateur/e-mail',
  VALIDATE_ONLY_ONE: 'Cochez seulement un',
  VALIDATE_ONE_MORE: 'Cochez un ou plusieurs',
  VALIDATE_EMAIL_REQUIRED: 'L\'e-mail est obligatoire',
  VALIDATE_EMAIL_VALID: 'Entrez une adresse e-mail valide',
  VALIDATE_EMAIL_DOMAIN: 'Domaine e-mail incomplet',
  VALIDATE_PASSWORD_REQUIRED: 'Le mot de passe est obligatoire',
  VALIDATE_ID_SHORT_REQUIRED: 'L\'ID public est obligatoire',
  VALIDATE_CPF_VALID: 'Veuillez entrer un CPF valide',
  VALIDATE_USER_CPF_USERNAME: 'Veuillez entrer un CPF valide ou un utilisateur avec uniquement des lettres, des chiffres, "_" et "."',
  VALIDATE_USERNAME_CHAR_SPECIAL: 'L\'utilisateur ne peut contenir que des lettres, des chiffres, "_" et "."',
  VALIDATE_USER_REQUIRED: 'L\'utilisateur est obligatoire',
};



const Translates = {
  ...APP,
  ...APP_TUTORIAL,
  ...BUTTONS,
  ...COMPANY_TYPES,
  ...CARNIVALS,
  ...COUNTRIES,
  ...DRINKS,
  ...EVENTS,
  ...INTERPOLATES,
  ...KITCHENS,
  ...LABELS,
  ...LANGUAGES,
  ...MENU_ORDER,
  ...MESSAGES_EMPTYS,
  ...MESSAGES_HELP,
  ...MESSAGES_ERRORS,
  ...MESSAGES_QUESTIONS,
  ...MESSAGES_SUCCESS,
  ...MUSICS,
  ...PAYMENTS,
  ...RESERVES,
  ...SOCIALS,
  ...TIME,
  ...TITLES,
  ...SUBTITLES,
  ...VALIDATIONS,
};



export default Translates;
