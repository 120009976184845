import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';

import {
  EDrawerMenu,
} from '../../shared/enums';



const INITIAL_STATE = {
  menuSelected: EDrawerMenu.HOME,

  modalLoading: false,

  brandPromoterModal: false,
  verifiedModal: false,

  drawer: false,
  snackBar: {
    visible: false,
    color: null,
    text: null,
  },
};



// Action Types

export const APP_MENU_SELECTED_ACTION = '@user/MENU_SELECTED_ACTION';

export const APP_MODAL_LOADING_ACTION = '@user/MODAL_ACTION';

export const USER_BRAND_PROMOTER_MODAL = '@user/USER_BRAND_PROMOTER_MODAL';
export const USER_VERIFIED_MODAL = '@user/USER_VERIFIED_MODAL';

export const APP_DRAWER_ACTION = '@user/DRAWER_ACTION';
export const APP_SNACKBAR_ACTION = '@user/SNACKBAR_ACTION';
export const APP_SNACKBAR_RESET = '@company/SNACKBAR_RESERT';
export const APP_RESET_APP = '@company/RESET_APP';



// Action Creators

export const menuSelectedAction = createAction<object>(APP_MENU_SELECTED_ACTION);

export const modalLoadingAction = createAction<object>(APP_MODAL_LOADING_ACTION);

export const brandPromoterModalAction = createAction<object>(USER_BRAND_PROMOTER_MODAL);
export const verifiedModalAction = createAction<object>(USER_VERIFIED_MODAL);

export const drawerAction = createAction<object>(APP_DRAWER_ACTION);
export const snackbarAction = createAction<object>(APP_SNACKBAR_ACTION);
export const snackbarResetAction = createAction<object>(APP_SNACKBAR_RESET);
export const resetAppAction = createAction<object>(APP_RESET_APP);



// Handlers

const menuSelectedReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  menuSelected: action.payload,
});


const modalLoadingReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  modalLoading: action.payload,
});


const openBrandPromoterReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  brandPromoterModal: action.payload,
});


const openVerifiedReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  verifiedModal: action.payload,
});


const drawerReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  drawer: action.payload,
});


const snackbarReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  snackBar: action.payload,
});


const snackbarResetReducer = (state = INITIAL_STATE) => ({
  ...state,
  snackBar: INITIAL_STATE.snackBar,
});


const resetAppStates = () => ({
  ...INITIAL_STATE,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [menuSelectedAction.type]: menuSelectedReducer,

  [modalLoadingAction.type]: modalLoadingReducer,

  [brandPromoterModalAction.type]: openBrandPromoterReducer,
  [verifiedModalAction.type]: openVerifiedReducer,

  [drawerAction.type]: drawerReducer,
  [snackbarAction.type]: snackbarReducer,
  [snackbarResetAction.type]: snackbarResetReducer,
  [resetAppAction.type]: resetAppStates,
});
