import React from 'react';

import {
  Overlay,
} from '../../../../../../components/Composh/web';

import HeaderModal from '../../../../../../components/Headers/HeaderModal';

import {
  Colors,
} from '../../../../../../shared/constants';

import {
  CategoryChooseContainer,
  TravelInfoContainer,
} from './styled';



export interface IProps {
  visible?: boolean;
  children?: any;
  onClose?: any;
}



const CreateModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onClose}>

      <CategoryChooseContainer>

        <HeaderModal
          title={'Criar viagem'}
          onPress={props.onClose}
        />


        <TravelInfoContainer>
          {props.children}
        </TravelInfoContainer>
      </CategoryChooseContainer>

    </Overlay>

  );
};



export default CreateModal;
