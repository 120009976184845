import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '../../../../../../redux';

import {
  ImageContentRightHome,
  WarningApproveText,
  WrapperItemContent,
  ApproveSplitView,
  ApproveSplitIconView,
  ApproveSplitText,
  ApproveItemInfoView,
  ApproveItemSplitText,
  ApproveSubItemSplitText,
} from './styled';



const RightImageInfo: React.FC = () => {
  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  return (

    <ImageContentRightHome>

      <WarningApproveText>
        Siga estas regras para acelerar o processo de aprovação.
      </WarningApproveText>


      <WrapperItemContent>
        <ApproveSplitView>
          <ApproveSplitIconView>

          </ApproveSplitIconView>

          <ApproveSplitText>
            Recomendado
          </ApproveSplitText>
        </ApproveSplitView>


        <ApproveItemInfoView>
          <ApproveItemSplitText>
            É recomendável que você defina uma foto principal do Público Geral (classificação G) para o seu perfil.
          </ApproveItemSplitText>

          <ApproveSubItemSplitText>
            Fotos com classificação G não devem retratar nudez, conteúdo sexual, violência, uso de drogas e álcool ou outros conteúdos que possam ser considerados ofensivos.
          </ApproveSubItemSplitText>

          <ApproveSubItemSplitText>
            Sua foto principal com classificação G será mostrada na página inicial do Secretio e em seus anúncios.
          </ApproveSubItemSplitText>
        </ApproveItemInfoView>
      </WrapperItemContent>



      <WrapperItemContent>
        <ApproveSplitView>
          <ApproveSplitIconView>

          </ApproveSplitIconView>

          <ApproveSplitText>
            Aceito
          </ApproveSplitText>
        </ApproveSplitView>


        <ApproveItemInfoView>
          <ApproveItemSplitText>
            Fotos nítidas e de alta qualidade suas.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Aproxime-se do espelho e esconda sua escova de dentes ao fazer selfies no banheiro.
          </ApproveItemSplitText>
        </ApproveItemInfoView>
      </WrapperItemContent>



      <WrapperItemContent>
        <ApproveSplitView>
          <ApproveSplitIconView>

          </ApproveSplitIconView>

          <ApproveSplitText>
            Não Aceito
          </ApproveSplitText>
        </ApproveSplitView>


        <ApproveItemInfoView>
          <ApproveItemSplitText>
            Fotos falsas ou que não sejam suas
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Fotos hardcore como foto pública.
          </ApproveItemSplitText>

          {projectParams?.showFanClub && (
            <ApproveSubItemSplitText>
              Pode adicionar essas imagens à galeria Privada ou do Fã-clube.
            </ApproveSubItemSplitText>
          )}

          <ApproveItemSplitText>
            Retoque excessivo do Photoshop, texto adicionado, números de telefone, marcas d`água, bandeiras de cartões, colagens ou bordas.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Fotos protegidas por direitos autorais, a menos que um consentimento por escrito e assinado do proprietário dos direitos autorais nos seja enviado.
          </ApproveItemSplitText>

          <ApproveItemSplitText>
            Fotos que retratam álcool, drogas, armas, fezes, sangue, tortura, notas ou outra violência.
          </ApproveItemSplitText>
        </ApproveItemInfoView>
      </WrapperItemContent>

    </ImageContentRightHome>

  );
};



export default RightImageInfo;
