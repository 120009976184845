import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import { GetAllGenderUser } from '../../../apis/endpoints/app-genders-highlights.endpoints';
import { Body, Content, SubTitle, Title } from '../../../components/Composh/web';
import { Screen } from '../../../components/Containers/ScreenContainer';
import { ContentRowToColumnPadder } from '../../../components/Contents/ContentPadder';
import { ButtonDrawer } from '../../../components/Controls';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import SubHeader from '../../../components/Headers/SubHeader';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import NameRoutes from '../../../navigation/names';
import { APP_MENU_SELECTED_ACTION, APP_SNACKBAR_ACTION } from '../../../redux/reducers/app-modals.store';
import { Colors, Sizes } from '../../../shared/constants';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';
import { treatErrorAndReturnColor, treatErrorAndReturnMessage } from '../../../shared/utils/errors.utils';
import { TableListButtonAction, TableListHeaderContainer } from '../../../styles/form.styled';
import {
  ProjectParamsContentCenterFlex,
  GendersWrapperItemContent,
  GenderItemTitle,
  ProjectParamsRightSuggestion,
  ProjectParamsCardRight,
  GendersFlexColumnsView,
  GenderItemLabel,
  GenderVerticalImage,
} from './styled';



const GendersHighlightsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const [showInfoHomem, setShowInfoHomem] = useState(false);
  const [usernameHomem, setUsernameHomem] = useState('');
  const [userInfoHomem, setUserInfoHomem] = useState<any>({});

  const [showInfoMulher, setShowInfoMulher] = useState(false);
  const [usernameMulher, setUsernameMulher] = useState('');
  const [userInfoMulher, setUserInfoMulher] = useState<any>({});

  const [showInfoHomemTrans, setShowInfoHomemTrans] = useState(false);
  const [usernameHomemTrans, setUsernameHomemTrans] = useState('');
  const [userInfoHomemTrans, setUserInfoHomemTrans] = useState<any>({});

  const [showInfoMulherTrans, setShowInfoMulherTrans] = useState(false);
  const [usernameMulherTrans, setUsernameMulherTrans] = useState('');
  const [userInfoMulherTrans, setUserInfoMulherTrans] = useState<any>({});

  const [totalModeles, setTotalModeles] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const handleSearchHomem = () => {
    setShowInfoHomem(true);
    setUserInfoHomem({ section: 'Homem', username: usernameHomem });
  };

  const handleSearchMulher = () => {
    setShowInfoMulher(true);
    setUserInfoMulher({ section: 'Mulher', username: usernameMulher });
  };

  const handleSearchHomemTrans = () => {
    setShowInfoHomemTrans(true);
    setUserInfoHomemTrans({ section: 'Homem Trans', username: usernameHomemTrans });
  };

  const handleSearchMulherTrans = () => {
    setShowInfoMulherTrans(true);
    setUserInfoMulherTrans({ section: 'Mulher Trans', username: usernameMulherTrans });
  };

  const handleImageUpload = (imageType: string, event: any, section: string) => {
    // Lógica para lidar com o upload da imagem para a seção especificada
  };

  const handleSave = (section: string) => {
    // Lógica para salvar as informações e imagens da seção especificada
    console.log(`Salvo! Seção: ${section}`);
  };



  function listHeaderComponent() {
    return (

      <SubHeader
        title={'Um título'}
        color={Colors.secondaryDark}>

        <TableListHeaderContainer>

          <TableListButtonAction
            title={'Criar/editar destaque'}
            onPress={() => {
              history.push(NameRoutes.GenderHighlightsFormScreen);
            }}
          />


          <TableListButtonAction
            title={'Filtros'}
            onPress={() => {
              // TODO
            }}
          />

        </TableListHeaderContainer>

      </SubHeader>

    );
  }



  async function loadAllRents() {
    setLoading(true);

    try {
      const response = await GetAllGenderUser();
      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      setTotalModeles(responseModele?.users);
    }
    catch (error: any) {
      console.error(error);

      setTotalModeles([]);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.GENRES_ADS });
    loadAllRents();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.black}>
              Gêneros [Destaques]
            </Title>

            <SubTitle
              color={Colors.gray}>
              App
            </SubTitle>
          </Body>
        }
      />



      <Content>

        {loading && (
          <LoadingScreen
            type={'SCREEN'}
            color={Colors.secondary}
          />
        )}



        {listHeaderComponent()}

        {!loading && (
          <ContentRowToColumnPadder>

            <ProjectParamsContentCenterFlex
              backgroundColor={Colors.formBackground}
              borderRadius={Sizes.cardRadius}>

              {[
                {
                  gender: 'MAN',
                },
                {
                  gender: 'WOMAN',
                },
                {
                  gender: 'TMAN',
                },
                {
                  gender: 'TWOMAN',
                },
              ].map((item, index) => (

                <GendersWrapperItemContent>

                  <GendersFlexColumnsView>

                    <GenderItemTitle>
                      {item?.gender}
                    </GenderItemTitle>

                  </GendersFlexColumnsView>



                  <GendersFlexColumnsView>

                    <GenderItemLabel>
                      Imagem Vertical
                    </GenderItemLabel>


                    <GenderVerticalImage
                    />

                  </GendersFlexColumnsView>



                  <GendersFlexColumnsView>

                    <GenderItemLabel>
                      Imagem horizontal
                    </GenderItemLabel>


                    <GenderVerticalImage
                    />

                  </GendersFlexColumnsView>



                  <GendersFlexColumnsView>

                    <GenderItemLabel>
                      Anuncio usuario pequeno
                    </GenderItemLabel>


                    <GenderVerticalImage
                    />

                  </GendersFlexColumnsView>



                  <GendersFlexColumnsView>

                    <GenderItemLabel>
                      Anuncio modelo pequeno
                    </GenderItemLabel>


                    <GenderVerticalImage
                    />

                  </GendersFlexColumnsView>



                  <GendersFlexColumnsView>

                    <GenderItemLabel>
                      Imagem para premium
                    </GenderItemLabel>


                    <GenderVerticalImage
                    />

                  </GendersFlexColumnsView>

                </GendersWrapperItemContent>

              ))}

            </ProjectParamsContentCenterFlex>



            <ProjectParamsRightSuggestion>

              <ProjectParamsCardRight
                backgroundColor={Colors.formBackground}
                borderRadius={Sizes.cardRadius}>

                {totalModeles?.length === 0 && (
                  <p>
                    Sem dados
                  </p>
                )}


                {totalModeles?.length > 0 && totalModeles.map((itemGender: any) => (
                  <div style={{ display: 'flex' }}>
                    <p>
                      {itemGender?.alias}
                    </p>
                    <p>
                      {itemGender?.useralias}
                    </p>
                    <p>
                      {itemGender?.gender}
                    </p>

                    <a>
                      Ativar
                    </a>
                  </div>
                ))}

              </ProjectParamsCardRight>

            </ProjectParamsRightSuggestion>

          </ContentRowToColumnPadder>
        )}



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default GendersHighlightsScreen;
