import ApiWS from '../index';



export const GetStreamStars = async (payload: any) => {
  const url = '/support/stream/stars';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};
