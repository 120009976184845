import ApiWS from '../index';



export const GetAllIDeletedMedias = async (payload: string) => {
  const url = '/support/project/deleted-medias';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const DeleteModeleImage = async (idMedia: string) => {
  const url = `/support/project/deleted-medias/${idMedia}`;

  const response = await ApiWS.delete(url);
  return response.data;
};
