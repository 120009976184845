import styled from 'styled-components';

import {
  Card,
} from '../../../components/Composh/web';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
  backgroundColor?: string;
}



export const ProjectParamsContentCenterFlex = styled(Card.View)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  width: 100%;
  padding: ${Sizes.paddingFlatList}px;

  @media ${PlatformDevices.maxLaptop} {
    margin-bottom: 35px;
  }
`;

export const GendersWrapperItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

export const GendersFlexColumnsView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const GenderItemTitle = styled.p`
  font-size: 22px;
  font-weight: bold;
`;

export const GenderItemLabel = styled.p`  
  font-size: 13px;
`;

export const GenderVerticalImage = styled.img`  
  width: 100px;
  height: 100px;
  background-color: ${Colors.black};
`;



export const ProjectParamsRightSuggestion = styled.div`
  display: flex;
  flex-direction: column;
  width: 370px;
  margin-left: ${40 - Sizes.paddingFlatList}px;

  @media ${PlatformDevices.maxLaptop} {
    width: 100%;
    min-width: 100%;
    margin-left: 0;
  }
`;

export const ProjectParamsCardRight = styled(Card.View)`
  display: flex;
  flex-direction: column;
  padding: ${Sizes.paddingFlatList}px;
`;
