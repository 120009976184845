import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetStreamStars,
} from '../../../../apis/endpoints/stream-stars.endpoints';

import {
  IPagination,
} from '../../../../apis/interfaces/App/pagination.interface';

import CardStars from '../../../../components/Cards/CardStars';

import {
  Body,
  Content,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import SubHeader from '../../../../components/Headers/SubHeader';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import TableChildren from '../../../../components/Table/TableChildren';

import NameRoutes from '../../../../navigation/names';

import {
  APP_MENU_SELECTED_ACTION,
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import EDrawerMenu from '../../../../shared/enums/App/drawer-menu.enum';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  TableListHeaderContainer,
  TableListButtonAction,
} from '../../../../styles/form.styled';

import {
  AllModelesRightContent,
  AllModelesFlatList,
} from './styled';



const StarsShowAllScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const perPageArray = [18, 30, 42];

  const [starsArray, setStarsArray] = useState<Array<any>>([]);

  const [pageSelected, setPageSelected] = useState<number>(Values.firstPage);
  const [tableProviders, setTableProviders] = useState<any>(null);
  const [modelesPagination, setModelesPagination] = useState<IPagination | null>(null);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getStarsModeles = async () => {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      const payload = {
        pageNumber: tableProviders?.pageNumber || Values.firstPage,
        itemsPerPage: tableProviders?.itemsPerPage || perPageArray[0],
        // sortOrder: '',
        // sortColumn: '',
      } as any;

      // if (genderChoose) {
      //   payload.gender = genderChoose;
      // };

      const response = await GetStreamStars(payload);

      if (!Array.isArray(response?.data) || !response?.data) {
        setStarsArray([]);
        return;
      }

      setStarsArray(response?.data);
      setPageSelected(response?.pagination?.pageCurrent);
      setModelesPagination(response?.pagination);
    }
    catch (error: any) {
      console.error(error);
      setStarsArray([]);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  function listHeaderComponent() {
    return (

      <SubHeader
        title={'Um título'}
        color={Colors.secondaryDark}>

        <TableListHeaderContainer>

          <TableListButtonAction
            title={'Criar perfil'}
            onPress={() => {
              history.push(NameRoutes.CreateModeleFormScreen);
            }}
          />


          <TableListButtonAction
            title={'Filtros'}
            onPress={() => {
              if (!loading) {
                // TODO
              }
            }}
          />

        </TableListHeaderContainer>

      </SubHeader>

    );
  }


  function renderItem(item: any, index: number) {
    return (

      <CardStars
        key={index}
        image={item?.picture}
        visible={item?.visible}
        alias={item?.alias}
        total={item?.streamVideosTotal}
        rating={item?.streamAverageRating}

      // rent={item?.params?.rent}
      // fanClub={item?.params?.fanClub}
      // live={item?.params?.live}
      // massage={item?.params?.massage}

      // verified={item?.verified}
      // promoter={item?.brandPromoter}
      // exclusive={item?.exclusive}
      // pornstar={item?.pornstar}

      // about={item?.appAbout?.aboutMe}
      // gender={item?.gender}
      // visible={item?.visible}
      // status={item?.status}
      // plan={item?.plan?.planCurrent}

      // onPress={() => {
      //   // history.push(NameRoutes.PanelModeleScreen.replace(
      //   //   ':useralias',
      //   //   item?.useralias,
      //   // ));
      // }}
      // onAntiFakePress={() => {
      //   // history.push(NameRoutes.AntiFakeAllScreen);
      // }}
      // onPicturePress={() => {
      //   // history.push(NameRoutes.PicturesAproveAllScreen);
      // }}
      // onVideoPress={() => {
      //   // history.push(NameRoutes.VideosAproveAllScreen);
      // }}
      // onReviewsPress={() => {
      //   // history.push({
      //   //   pathname: NameRoutes.ReviewModeleScreen.replace(
      //   //     ':useralias',
      //   //     item?.useralias,
      //   //   ),
      //   // });
      // }}
      />

    );
  };


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.secondary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={'Images.logoIconAppWhite'}
        title={'Não há modelos ainda!'}
      />

    );
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.STREAM_STARS });
  }, []);


  useEffect(() => {
    getStarsModeles();
  }, [tableProviders]);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonDrawer
            color={Colors.black}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.black}>
              Estrelas
            </Title>

            <SubTitle
              color={Colors.gray}>
              Stream
            </SubTitle>
          </Body>
        }
      />



      <Content>

        <ContentPadder>

          {listHeaderComponent()}



          <AllModelesRightContent
            backgroundColor={Colors.formBackground}
            borderRadius={Sizes.cardRadius}>

            <TableChildren
              headerTopBorder
              headerBottomBorder
              color={Colors.white}
              accentColor={Colors.secondaryDark}
              sizePerPageList={perPageArray}
              paginationProviders={(providers) => {
                // setPageSelected(providers?.page);
                setTableProviders({
                  pageNumber: providers?.page,
                  itemsPerPage: providers?.size,
                });
              }}
              paginationProps={{
                pageCurrent: pageSelected,
                pagesTotal: modelesPagination?.pagesTotal,
                from: modelesPagination?.fromCount,
                to: modelesPagination?.toCount,
                dataSize: modelesPagination?.itemsTotal,
              }}>

              <AllModelesFlatList
                data={starsArray}
                renderItem={renderItem}
                listLoadingComponent={renderLoading()}
                listEmptyComponent={listEmptyComponent()}
              />

            </TableChildren>

          </AllModelesRightContent>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default StarsShowAllScreen;
