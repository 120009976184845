import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useParams,
} from 'react-router-dom';

import {
  AddLotUserMedias,
} from '../../../../apis/endpoints/users-medias.endpoint';

import ButtonAction from '../../../../components/Buttons/ButtonAction';
import CardPublishMediaLot from '../../../../components/Cards/CardPublishMediaLot';

import {
  Body,
  SubTitle,
  Content,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../../components/Titles/SectionTitle';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
  Sizes,
  Values,
} from '../../../../shared/constants';

import EAppMediaTypeRelation from '../../../../shared/enums/Picture/app-media-type-relation.enum';
import EAppMediaVisibility from '../../../../shared/enums/Status/app-media-visibility.enum';

import {
  TranslateConfig,
} from '../../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  CardLotMediasForm,
  CardLotMediasHeader,
  CardLotMediasAddHeader,
  CardLotMediasDragView,
  CardLotMediasInput,
  CardLotMediasAddBody,
  CardLotMediasContent,
} from './styled';



export interface IRouteParams {
  useralias: string;
}



const ModeleMediasLotScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const route = useParams<IRouteParams>();
  const paramsRoute = route as IRouteParams;

  const { useralias } = paramsRoute;
  const userIdSearch = useralias;


  const [mounted, setMounted] = useState(false);

  const [mediaFiles, setMediaFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  const formatMedias = (arrayReceived: Array<any>) => {
    const formattedObjFileArray = arrayReceived.map((file: any) => {
      if (String(file.type).includes('image')) {
        const returnItemEdited = {
          name: file?.name,
          type: file?.type,
          image: file,
          picture: URL.createObjectURL(file),
          typeRelation: EAppMediaTypeRelation.GALLERY_IMAGES,
          visibility: EAppMediaVisibility.PUBLIC,
          canBuy: false,
        };
        return returnItemEdited;
      }

      if (String(file.type).includes('video')) {
        const returnItemEdited = {
          name: file.name,
          type: file.type,
          image: file,
          picture: URL.createObjectURL(file),
          typeRelation: EAppMediaTypeRelation.GALLERY_VIDEOS,
          visibility: EAppMediaVisibility.PUBLIC,
          canBuy: false,
        };
        return returnItemEdited;
      }
    });

    return formattedObjFileArray;
  };


  const handleFileChange = (event: any) => {
    const files = event.target.files;
    const newMediaFiles = Array.from(files);

    const formattedMediaFiles = formatMedias(newMediaFiles);

    setMediaFiles([...mediaFiles, ...formattedMediaFiles]);
  };


  const addMediaFiles = (files: any) => {
    const newMediaFiles = Array.from(files);

    const formattedMediaFiles = formatMedias(newMediaFiles);

    setMediaFiles([...mediaFiles, ...formattedMediaFiles]);
  };


  const handleUpload = async () => {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await AddLotUserMedias(userIdSearch, mediaFiles);
      const responseData = response?.data;

      // Verifique se todas as mídias foram salvas com sucesso
      if (responseData?.failedMedias?.length > 0) {
        showSnackBarProps(Colors.danger, 'Nem todas as mídias foram salvas');
        const remainingMediaFiles = mediaFiles.filter((file) => !responseData.savedMedias.includes(file?.name));
        setMediaFiles(remainingMediaFiles);
      }
      else {
        showSnackBarProps(Colors.accept, 'Todas as mídias foram salvas com sucesso!');
        setMediaFiles([]);
      }
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  const handleDrop = (event: any) => {
    event.preventDefault();
    setIsDragging(false); // Parar o estado de arrastar

    const files = event.dataTransfer.files;
    addMediaFiles(files);
  };


  const handleDragEnter = (event: any) => {
    event.preventDefault();
    setIsDragging(true);
  };


  const handleDragLeave = (event: any) => {
    event.preventDefault();
    setIsDragging(false);
  };


  const handleDelete = (index: number) => {
    if (index >= 0 && index < mediaFiles.length) {
      const updatedMediaFiles = [...mediaFiles];

      updatedMediaFiles.splice(index, 1);

      setMediaFiles(updatedMediaFiles);
    }
    else {
      console.error('Índice inválido fornecido para remoção de mídia.');
      showSnackBarProps(Colors.danger, 'Erro ao deletar item');
    }
  };


  function renderItem(item: any, index: number) {
    return (

      <CardPublishMediaLot
        key={index}
        type={item?.type}

        media={item?.picture}
        typeRelation={item?.typeRelation}
        visibility={item?.visibility}

        duration={item?.duration}
        isHD={item?.isHD}

        canBuy={item?.canBuy}

        // onEditPress={() => {
        //   props.tabEditPress(item?.id);
        // }}
        onDeletePress={() => {
          handleDelete(index);
        }}
      />

    );
  }


  function listDragComponent() {
    return (

      <EmptyContent
        image={Images.noImage}
        title={'Solte os itens aqui para serem adicionados!'}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noImage}
        title={'Sem mídias a serem adicionadas!'}
        buttonText={'Adicionar mídias'}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet>
        <title>
          {`Adicionar mídias em lote | ${translate(TranslateConfig.NAME_APP)}`}
        </title>
      </Helmet>



      <HeaderScreen
        leftIcon={
          <ButtonBack
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.black}>
              Adicionar mídias em lote
            </Title>

            <SubTitle
              color={Colors.gray}>
              @{userIdSearch || '-'}
            </SubTitle>
          </Body>
        }
      />



      <Content>

        {!mounted && (
          renderLoading()
        )}



        {mounted && (
          <ContentPadder>

            <CardLotMediasForm
              backgroundColor={Colors.formBackground}
              borderRadius={Sizes.cardRadius}>

              <CardLotMediasHeader>
                <SectionTitle
                  title={`Mídias: ${mediaFiles?.length}`}
                />


                <CardLotMediasAddHeader htmlFor={'ABC'}>
                  <ButtonAction
                    width={Sizes.buttonMinWidth - 30}
                    title={'Adicionar mídias'}
                  />
                </CardLotMediasAddHeader>
              </CardLotMediasHeader>



              <CardLotMediasDragView
                onDragOver={(event) => event.preventDefault()}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}>

                <CardLotMediasInput
                  multiple
                  type={'file'}
                  id={'ABC'}
                  onChange={handleFileChange}
                />


                {isDragging && listDragComponent()}


                {!isDragging && mediaFiles?.length === 0 && (
                  <CardLotMediasAddBody htmlFor={'ABC'}>
                    {listEmptyComponent()}
                  </CardLotMediasAddBody>
                )}


                {!isDragging && mediaFiles?.length > 0 && (
                  <CardLotMediasContent>
                    {mediaFiles.map((file, index) => (
                      renderItem(file, index)
                    ))}
                  </CardLotMediasContent>
                )}

              </CardLotMediasDragView>



              <ContentButtonForm>
                <ButtonAction
                  width={Sizes.buttonMinWidth}
                  title={'Enviar Mídias'}
                  onPress={() => {
                    handleUpload();
                  }}
                />
              </ContentButtonForm>

            </CardLotMediasForm>

          </ContentPadder>
        )}



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default ModeleMediasLotScreen;
