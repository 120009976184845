import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';



export const mannequinArray: Array<IInputSelect> = [
  { id: 1, label: '30 cm', value: 'MANNEQUIN_30CM' },
  { id: 2, label: '31 cm', value: 'MANNEQUIN_31CM' },
  { id: 3, label: '32 cm', value: 'MANNEQUIN_32CM' },
  { id: 4, label: '33 cm', value: 'MANNEQUIN_33CM' },
  { id: 5, label: '34 cm', value: 'MANNEQUIN_34CM' },
  { id: 6, label: '35 cm', value: 'MANNEQUIN_35CM' },
  { id: 7, label: '36 cm', value: 'MANNEQUIN_36CM' },
  { id: 8, label: '37 cm', value: 'MANNEQUIN_37CM' },
  { id: 9, label: '38 cm', value: 'MANNEQUIN_38CM' },
  { id: 10, label: '39 cm', value: 'MANNEQUIN_39CM' },
  { id: 11, label: '40 cm', value: 'MANNEQUIN_40CM' },
  { id: 12, label: '41 cm', value: 'MANNEQUIN_41CM' },
  { id: 13, label: '42 cm', value: 'MANNEQUIN_42CM' },
  { id: 14, label: '43 cm', value: 'MANNEQUIN_43CM' },
  { id: 15, label: '44 cm', value: 'MANNEQUIN_44CM' },
  { id: 16, label: '45 cm', value: 'MANNEQUIN_45CM' },
  { id: 17, label: '46 cm', value: 'MANNEQUIN_46CM' },
  { id: 18, label: '47 cm', value: 'MANNEQUIN_47CM' },
  { id: 19, label: '48 cm', value: 'MANNEQUIN_48CM' },
  { id: 20, label: '49 cm', value: 'MANNEQUIN_49CM' },
  { id: 21, label: '50 cm', value: 'MANNEQUIN_50CM' },
  { id: 22, label: '51 cm', value: 'MANNEQUIN_51CM' },
  { id: 23, label: '52 cm', value: 'MANNEQUIN_52CM' },
  { id: 24, label: '53 cm', value: 'MANNEQUIN_53CM' },
  { id: 25, label: '54 cm', value: 'MANNEQUIN_54CM' },
  { id: 26, label: '55 cm', value: 'MANNEQUIN_55CM' },
  { id: 27, label: '56 cm', value: 'MANNEQUIN_56CM' },
  { id: 28, label: '57 cm', value: 'MANNEQUIN_57CM' },
  { id: 29, label: '58 cm', value: 'MANNEQUIN_58CM' },
  { id: 30, label: '59 cm', value: 'MANNEQUIN_59CM' },
  { id: 31, label: '60 cm', value: 'MANNEQUIN_60CM' },
  { id: 32, label: '61 cm', value: 'MANNEQUIN_61CM' },
  { id: 33, label: '62 cm', value: 'MANNEQUIN_62CM' },
  { id: 34, label: '63 cm', value: 'MANNEQUIN_63CM' },
  { id: 35, label: '64 cm', value: 'MANNEQUIN_64CM' },
  { id: 36, label: '65 cm', value: 'MANNEQUIN_65CM' },
  { id: 37, label: '66 cm', value: 'MANNEQUIN_66CM' },
  { id: 38, label: '67 cm', value: 'MANNEQUIN_67CM' },
  { id: 39, label: '68 cm', value: 'MANNEQUIN_68CM' },
  { id: 40, label: '69 cm', value: 'MANNEQUIN_69CM' },
  { id: 41, label: '70 cm', value: 'MANNEQUIN_70CM' },
];
