import React,
{
  useState,
} from 'react';

import {
  Colors,
  Values,
} from '../../shared/constants';

import TableFooter from './Sections/TableFooter';
import TableHeader from './Sections/TableHeader';
import TableTotal from './Sections/TableTotal';

import {
  TableNextStyled,
} from './styled';



export interface IProps {
  headerTopBorder?: boolean;
  headerBottomBorder?: boolean;
  noQuantity?: boolean;

  color?: string;
  accentColor?: string;

  sizePerPageList?: Array<number>;
  // baseProps?: any;
  paginationProviders?: (providers: {
    size: number;
    page: number;
  }) => void;
  paginationProps?: any;
  // paginationTableProps?: any;

  children?: any;
}



const TableChildren: React.FC<IProps> = (props: IProps) => {
  const colorNormalTable = props.color || Colors.white;
  const colorAccentTable = props.accentColor || Colors.black;
  const totalColor = Colors.black;


  const sizePerPage = props.sizePerPageList || [10, 20, 30];

  const [selected, setSelected] = useState(sizePerPage[0]);



  function customTotal(from: any, to: any, size: any) {
    return (

      <TableTotal
        color={totalColor}
        from={from}
        to={to}
        size={size}
      />

    );
  }


  const handleSizePerPage = (newSizePerPage: number, pageNumber: number) => {
    const sendProviders = {
      size: newSizePerPage,
      page: pageNumber,
    };

    props.paginationProviders(sendProviders);
  };


  const options = {
    ...props.paginationProps,
    paginationTotalRenderer: customTotal,
  };



  return (

    <TableNextStyled>

      {!props.noQuantity && (
        <TableHeader
          {...props.paginationProps}
          color={colorNormalTable}
          accentColor={colorAccentTable}
          bottomBorder={props.headerBottomBorder}
          options={options}
          maxPagesToShow={Values.pagesPagination}
          sizePerPage={sizePerPage}
          sizeSelected={selected}
          onPress={(quantity: number) => {
            if (quantity !== selected) {
              setSelected(quantity);
              handleSizePerPage(quantity, 1);
            }
          }}
          pageSelected={(sizeString) => {
            const sizeNumeric = Number(sizeString);
            handleSizePerPage(selected, sizeNumeric);
          }}
        />
      )}



      {props.children && props.children}



      <TableFooter
        {...props.paginationProps}
        topBorder={props.headerTopBorder}
        color={colorNormalTable}
        accentColor={colorAccentTable}
        maxPagesToShow={Values.pagesPagination}
        pageSelected={(sizeString) => {
          const sizeNumeric = Number(sizeString);
          handleSizePerPage(selected, sizeNumeric);
        }}
      />

    </TableNextStyled>

  );
};



export default TableChildren;
