import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useLocation,
  useParams,
} from 'react-router-dom';

import {
  GetModeleProfissionalPanel,
} from '../../../../apis/endpoints/modeles.endpoints';

import {
  ArchiveModeleImage,
  ChangeRevisedModeleImage,
  DeleteModeleImage,
} from '../../../../apis/endpoints/publish-images.endpoints';

import AlertMessage from '../../../../components/Alerts';

import {
  Body,
  Container,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ButtonBack,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconMediaPhoto,
  IconMediaVideo,
  IconProfile,
} from '../../../../components/Icons';

import TabBarStyled from '../../../../components/TabBar';

import {
  PROJECT_CONFIGS_MODULES_SET,
} from '../../../../redux/reducers/app-configs.store';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
} from '../../../../shared/constants';

import EAppMediaTypeRelation from '../../../../shared/enums/Picture/app-media-type-relation.enum';

import EResponseStatus from '../../../../shared/enums/Response/response-status.enum';
import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import RightImageInfo from './Infos/InfoImages';
import InfoPrincipal from './Infos/InfoPrincipal';
import RightVideoInfo from './Infos/InfoVideos';

import MediasAddModal from './Modals/MediasAdd';
import MediasInfoModal from './Modals/MediasInfo';

import TabAddImages from './Tabs/TabImages';
import TabPrincipal from './Tabs/TabPrincipal';
import TabAddVideos from './Tabs/TabVideos';



export interface IRouteParams {
  useralias: string;
}


export interface IRouteLocation {
  tab?: EAppMediaTypeRelation;
}



const ModeleMediaAddScreen: React.FC = () => {
  const dispatch = useDispatch();

  const route = useParams<IRouteParams>();
  const paramsRoute = route as IRouteParams;

  const routeLocation = useLocation();
  const paramsState = routeLocation?.state as IRouteLocation;
  const paramTab = paramsState?.tab;


  const { useralias } = paramsRoute;
  const userToGet = useralias;


  const accentGender = Colors.secondary;

  const [showInfoModal, setShowInfoModal] = useState<'PRINCIPAL' | 'PHOTOS' | 'VIDEOS' | null>(null);

  const [stateIndex, setStateIndex] = useState(0);

  const [modeleObject, setModeleObject] = useState<any>(null);

  const [showImageModal, setShowImageModal] = useState(false);
  const [showCoverModal, setShowCoverModal] = useState(false);

  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const [openArchiveModal, setOpenArchiveModal] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<string>('');



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function setAtualTab() {
    if (paramTab && paramTab === EAppMediaTypeRelation.GALLERY_IMAGES) {
      setStateIndex(1);
      return;
    }

    if (paramTab && paramTab === EAppMediaTypeRelation.GALLERY_VIDEOS) {
      setStateIndex(2);
      return;
    }

    setStateIndex(0);
  }


  function appProjectParamsValues(dataParams: any) {
    dispatch({ type: PROJECT_CONFIGS_MODULES_SET, payload: dataParams });
  }


  async function getActualModele() {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await GetModeleProfissionalPanel(userToGet);
      const responseData = response?.data;

      if (!responseData) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao buscar dados');
        return;
      }

      appProjectParamsValues(responseData?.configs?.modules);

      setModeleObject(responseData?.modele);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function archiveMedia(idMedia: string) {
    setOpenArchiveModal(null);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await ArchiveModeleImage(idMedia);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer atualização');
        return;
      }

      if (response?.status === EResponseStatus.WARNING) {
        console.error('Error', response);
        showSnackBarProps(Colors.attention, response?.messages[0] || 'Atenção as informações');
        return;
      }

      showSnackBarProps(Colors.accept, 'Atualização realizada com sucesso');
      await getActualModele();
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function changeRevisedMedia(idMedia: string) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await ChangeRevisedModeleImage(idMedia);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer atualização');
        return;
      }

      if (response?.status === EResponseStatus.WARNING) {
        console.error('Error', response);
        showSnackBarProps(Colors.attention, response?.messages[0] || 'Atenção as informações');
        return;
      }

      showSnackBarProps(Colors.accept, 'Atualização realizada com sucesso');
      await getActualModele();
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function deleteMedia(idMedia: string) {
    setOpenDeleteModal(null);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await DeleteModeleImage(idMedia);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao deletar comentário');
        return;
      }

      if (response?.status === EResponseStatus.WARNING) {
        console.error('Error', response);
        showSnackBarProps(Colors.attention, response?.messages[0] || 'Atenção as informações');
        return;
      }

      showSnackBarProps(Colors.accept, 'Comentário deletado com sucesso');
      await getActualModele();
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }



  const screenTabs = [
    {
      visible: true,
      name: 'PRINCIPAL',
      title: 'Mídias do perfil',
      icon: (
        <IconProfile
          color={stateIndex === 0 ? accentGender : Colors.inputCount}
          size={25}
        />
      ),
      component: (
        <TabPrincipal
          modeleReceived={modeleObject}
          onImagePress={() => {
            setShowImageModal(true);
          }}
          onCoverPress={() => {
            setShowCoverModal(true);
          }}
          onShowModal={() => {
            setShowInfoModal('PRINCIPAL');
          }}
        />
      ),
    },
    {
      visible: true,
      name: 'PHOTOS',
      title: 'Fotos',
      icon: (
        <IconMediaPhoto
          color={stateIndex === 1 ? accentGender : Colors.inputCount}
          size={25}
        />
      ),
      component: (
        <TabAddImages
          modeleReceived={modeleObject}
          onOpenPhotoModal={() => {
            setShowPhotoModal(true);
          }}
          onShowModal={() => {
            setShowInfoModal('PHOTOS');
          }}
          tabArchivePress={(idMedia: string) => {
            setOpenArchiveModal(idMedia);
          }}
          tabVisibleOrRevisedPress={(idMedia: string) => {
            changeRevisedMedia(idMedia);
          }}
          tabEditPress={() => {
            // setShowInfoModal('PHOTOS');
          }}
          tabDeletePress={(idMedia: string) => {
            setOpenDeleteModal(idMedia);
          }}
        />
      ),
    },
    {
      visible: true,
      name: 'VIDEOS',
      title: 'Vídeos',
      icon: (
        <IconMediaVideo
          color={stateIndex === 2 ? accentGender : Colors.inputCount}
          size={25}
        />
      ),
      component: (
        <TabAddVideos
          modeleReceived={modeleObject}
          onOpenVideoModal={() => {
            setShowVideoModal(true);
          }}
          onShowModal={() => {
            setShowInfoModal('VIDEOS');
          }}
          tabArchivePress={(idMedia: string) => {
            setOpenArchiveModal(idMedia);
          }}
          tabVisibleOrRevisedPress={(idMedia: string) => {
            changeRevisedMedia(idMedia);
          }}
          tabEditPress={() => {
            // setShowInfoModal('PHOTOS');
          }}
          tabDeletePress={(idMedia: string) => {
            setOpenDeleteModal(idMedia);
          }}
        />
      ),
    },
  ];



  useEffect(() => {
    setAtualTab();
    getActualModele();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          leftIcon={
            <ButtonBack
              color={Colors.black}
              size={27}
            />
          }
          centerContent={
            <Body>
              <Title
                color={Colors.black}>
                Mídias
              </Title>

              <SubTitle
                color={Colors.gray}>
                @{useralias || '-'}
              </SubTitle>
            </Body>
          }
          noSubToolbarPadding>


          <TabBarStyled
            tabs={screenTabs}
            selected={stateIndex}
            color={accentGender}
            onPress={(tab) => {
              setStateIndex(tab?.index);
            }}
          />

        </HeaderScreen>



        {stateIndex === 0 && screenTabs[0].component}

        {stateIndex === 1 && screenTabs[1].component}

        {stateIndex === 2 && screenTabs[2].component}



        {/*
      <ol>
        <li><strong>Fotos fakes não serão aceitas</strong>, o anúncio será excluído sem aviso prévio caso seja constatado o envio de fotos falsas.</li>
        <li>Mulheres trans <strong>OPERADAS</strong> também são aceitas, porém é necessário que seja informado para que seja postado no anúncio a informação. Caso não seja informado a anunciante é banida do site.</li>
        <li>Lembrando que, <strong>abominamos anunciantes fakes</strong>, sendo assim, deixamos mais uma vez bem claro que, caso em algum momento, mesmo que a anunciante seja pagante <strong>(ANÚNCIO DESTAQUE) </strong>e tenha enviado <strong>DOCUMENTO COM FOTO + SELFIE COM DOCUMENTO</strong>, mas, posteriormente seja constatado o uso de fotos fakes, de outra anunciante, modelos, atrizes, ou qualquer outra pessoa que não seja a própria anunciante (você), a anunciante será <strong>EXCLUÍDA</strong> e <strong>BANIDA</strong> do nosso site.<br />
          Certo de sua compreensão e colaboração, daremos seguimento ao seu anúncio.</li>
      </ol>
      */}

      </Container>



      {/* Image Picture */}
      <MediasAddModal
        idUser={userToGet}
        type={EAppMediaTypeRelation.USER_IMAGE}
        visible={showImageModal}
        title={'Adicionar imagem'}
        accept={'image/jpeg, image/png'}
        onClose={(status: boolean) => {
          setShowImageModal(false);

          if (status) {
            getActualModele();
          }
        }}
      />



      <MediasAddModal
        idUser={userToGet}
        type={EAppMediaTypeRelation.USER_COVER}
        visible={showCoverModal}
        title={'Adicionar imagem'}
        accept={'image/jpeg, image/png'}
        onClose={(status: boolean) => {
          setShowCoverModal(false);

          if (status) {
            getActualModele();
          }
        }}
      />



      <MediasAddModal
        idUser={userToGet}
        type={EAppMediaTypeRelation.GALLERY_IMAGES}
        visible={showPhotoModal}
        title={'Adicionar imagem'}
        accept={'image/jpeg, image/png'} // '.jpg, .jpeg, .bmp, .png'
        onClose={(status: boolean) => {
          setShowPhotoModal(false);

          if (status) {
            getActualModele();
          }
        }}
      />



      <MediasAddModal
        idUser={userToGet}
        type={EAppMediaTypeRelation.GALLERY_VIDEOS}
        visible={showVideoModal}
        title={'Adicionar vídeo'}
        accept={'video/*'}
        onClose={(status: boolean) => {
          setShowVideoModal(false);

          if (status) {
            getActualModele();
          }
        }}
      />



      <AlertMessage
        visible={Boolean(openArchiveModal)}
        title={'Deseja editar esse item?'}
        cancelText={'Não'}
        cancelPress={() => {
          setOpenArchiveModal(null);
        }}
        okText={'Sim'}
        okPress={() => {
          archiveMedia(openArchiveModal);
        }}
      />



      <AlertMessage
        visible={Boolean(openDeleteModal)}
        title={'Deseja deletar esse item?'}
        cancelText={'Não'}
        cancelPress={() => {
          setOpenDeleteModal(null);
        }}
        okText={'Sim'}
        okPress={() => {
          deleteMedia(openDeleteModal);
        }}
      />



      <MediasInfoModal
        visible={showInfoModal !== null}
        onClose={() => {
          setShowInfoModal(null);
        }}>

        {showInfoModal === 'PRINCIPAL' && (
          <InfoPrincipal />
        )}

        {showInfoModal === 'PHOTOS' && (
          <RightImageInfo />
        )}

        {showInfoModal === 'VIDEOS' && (
          <RightVideoInfo />
        )}

      </MediasInfoModal>

    </Screen>

  );
};



export default ModeleMediaAddScreen;
