const environment = {
  production: false,
  environment: 'LOCAL',
  title: 'Local Environment Heading',
  apiAccountGlobalUrl: 'http://localhost:3333',
  apiUrl: 'http://localhost:6960',
  // apiUrl: 'http://192.168.1.104:6960',
};



export default environment;
