import ApiWS from '../index';



export const GetAllReviewsModeles = async (payload: string) => {
  const url = '/support/project/review-modele';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const ArchiveModeleReview = async (idComment: number) => {
  const url = `/support/project/review-modele/archive/${idComment}`;

  const response = await ApiWS.put(url);
  return response.data;
};



export const ChangeRevisedModeleReview = async (idComment: number, useralias: string) => {
  const url = `/support/project/review-modele/revised/${useralias}/${idComment}`;

  const response = await ApiWS.put(url);
  return response.data;
};



export const DeleteModeleReview = async (idComment: number) => {
  const url = `/support/project/review-modele/${idComment}`;

  const response = await ApiWS.delete(url);
  return response.data;
};
