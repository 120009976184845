import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export interface IProps {
  backgroundColor?: string;
}



export const RentServicesContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RentServicesHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
`;

export const RentServicesTitle = styled.p`
  width: fit-content;
  margin-right: 16px;
  color: ${Colors.black};
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
`;

export const RentServicesDivider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.toolbarLine};
`;

export const RentServicesItemsContent = styled.div`
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 40px; */

  
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  /* margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px; */
`;
