import React from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  IconAdd,
  IconBack,
  IconClose,
  IconDrawer,
  IconHelp,
} from '../../components/Icons';

import {
  APP_DRAWER_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  ButtonControl,
} from './styled';



interface IProps {
  close?: boolean;
  expanded?: boolean;
  onPress?: any;

  color?: string;
  size?: number;
}



export const ButtonAdd: React.FC<IProps> = (props: any) => {
  const className = { ...props } as any;


  return (

    <ButtonControl
      className={className?.className}
      onClick={props.onPress}>

      <IconAdd
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};



export const ButtonBack: React.FC<IProps> = (props: any) => {
  const navigation = useHistory();



  function chooseIcon() {
    if (props.close) {
      return (
        <IconClose
          color={props.color}
        />
      );
    }
    else {
      return (
        <IconBack
          color={props.color}
        />
      );
    }
  }

  function haveOnPress() {
    if (!props.onPress) {
      navigation.goBack();
    }
    else {
      props.onPress();
    }
  }


  return (

    <ButtonControl
      onClick={() => {
        haveOnPress();
      }}>

      {chooseIcon()}

    </ButtonControl>

  );
};



export const ButtonDrawer: React.FC<IProps> = (props: any) => {
  const dispatch = useDispatch();



  function openMenuPage() {
    dispatch({ type: APP_DRAWER_ACTION, payload: true });
  }


  return (

    <ButtonControl
      onClick={() => {
        openMenuPage();
      }}>

      <IconDrawer
        color={props.color}
      />

    </ButtonControl>

  );
};



export const ButtonHelp: React.FC<IProps> = (props: any) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      <IconHelp
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};
