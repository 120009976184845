import _avatarModeleImage from '../assets/avatars/avatar-modele.png';
import _avatarUserImage from '../assets/avatars/avatar-user.png';
import _noImage from '../assets/avatars/no-image-cover.png';
import _noVideo from '../assets/avatars/no-video-cover.png';


import _backgroundApp from '../assets/backgrounds/background-app.png';
import _backgroundDot from '../assets/backgrounds/background-dot.png';
import _loginImageBackground from '../assets/backgrounds/background-login.png';
import _bannerDetails from '../assets/backgrounds/banner-details.png';
import _rankingContents from '../assets/backgrounds/ranking-contents.png';


import _rankingHeaderYearly from '../assets/banners/ranking-header-yearly.png';



import _countryFlagAfghanistan from '../assets/flags/flag-afghanistan.png';
import _countryFlagAlandIslands from '../assets/flags/flag-aland-islands.png';
import _countryFlagAlbania from '../assets/flags/flag-albania.png';
import _countryFlagAlgeria from '../assets/flags/flag-algeria.png';
import _countryFlagAmericanSamoa from '../assets/flags/flag-american-samoa.png';
import _countryFlagAndorra from '../assets/flags/flag-andorra.png';
import _countryFlagAngola from '../assets/flags/flag-angola.png';
import _countryFlagAnguilla from '../assets/flags/flag-anguilla.png';
import _countryFlagAntarctica from '../assets/flags/flag-antarctica.png';
import _countryFlagAntiguaAndBarbuda from '../assets/flags/flag-antigua-and-barbuda.png';
import _countryFlagArgentina from '../assets/flags/flag-argentina.png';
import _countryFlagArmenia from '../assets/flags/flag-armenia.png';
import _countryFlagAruba from '../assets/flags/flag-aruba.png';
import _countryFlagflagAscensionIsland from '../assets/flags/flag-ascension-island.png';
import _countryFlagAustralia from '../assets/flags/flag-australia.png';
import _countryFlagAustria from '../assets/flags/flag-austria.png';
import _countryFlagAzerbaijan from '../assets/flags/flag-azerbaijan.png';
import _countryFlagBahamas from '../assets/flags/flag-bahamas.png';
import _countryFlagBahrain from '../assets/flags/flag-bahrain.png';
import _countryFlagBangladesh from '../assets/flags/flag-bangladesh.png';
import _countryFlagBarbados from '../assets/flags/flag-barbados.png';
import _countryFlagBelarus from '../assets/flags/flag-belarus.png';
import _countryFlagBelgium from '../assets/flags/flag-belgium.png';
import _countryFlagBelize from '../assets/flags/flag-belize.png';
import _countryFlagBenin from '../assets/flags/flag-benin.png';
import _countryFlagBermuda from '../assets/flags/flag-bermuda.png';
import _countryFlagBhutan from '../assets/flags/flag-bhutan.png';
import _countryFlagBiot from '../assets/flags/flag-biot.png';
import _countryFlagBolivia from '../assets/flags/flag-bolivia.png';
import _countryFlagBonaire from '../assets/flags/flag-bonaire.png';
import _countryFlagBosnian from '../assets/flags/flag-bosnian.png';
import _countryFlagBotswana from '../assets/flags/flag-botswana.png';
import _countryFlagBouvetIsland from '../assets/flags/flag-bouvet-island.png';
import _countryFlagBrazil from '../assets/flags/flag-brazil.png';
import _countryFlagBritishAntarcticTerritory from '../assets/flags/flag-british-antarctic-territory.png';
import _countryFlagBritishVirginIslands from '../assets/flags/flag-british-virgin-islands.png';
import _countryFlagBrunei from '../assets/flags/flag-brunei.png';
import _countryFlagBulgaria from '../assets/flags/flag-bulgaria.png';
import _countryFlagBurkinaFaso from '../assets/flags/flag-burkina-faso.png';
import _countryFlagBurma from '../assets/flags/flag-burma.png';
import _countryFlagBurundi from '../assets/flags/flag-burundi.png';
import _countryFlagCambodia from '../assets/flags/flag-cambodia.png';
import _countryFlagCameroon from '../assets/flags/flag-cameroon.png';
import _countryFlagCanada from '../assets/flags/flag-canada.png';
import _countryFlagCapeVerde from '../assets/flags/flag-cape-verde.png';
import _countryFlagCascadia from '../assets/flags/flag-cascadia.png';
import _countryFlagCaymanIslands from '../assets/flags/flag-cayman-islands.png';
import _countryFlagCentraAfricanRepublic from '../assets/flags/flag-centra-african-republic.png';
import _countryFlagChad from '../assets/flags/flag-chad.png';
import _countryFlagChile from '../assets/flags/flag-chile.png';
import _countryFlagChina from '../assets/flags/flag-china.png';
import _countryFlagChristmasIsland from '../assets/flags/flag-christmas-island.png';
import _countryFlagCocosIslands from '../assets/flags/flag-cocos-islands.png';
import _countryFlagColombia from '../assets/flags/flag-colombia.png';
import _countryFlagComoros from '../assets/flags/flag-comoros.png';
import _countryFlagCongoKinshasa from '../assets/flags/flag-congo-kinshasa.png';
import _countryFlagCongo from '../assets/flags/flag-congo.png';
import _countryFlagCookIslands from '../assets/flags/flag-cook-islands.png';
import _countryFlagCostaRica from '../assets/flags/flag-costa-rica.png';
import _countryFlagCroatian from '../assets/flags/flag-croatian.png';
import _countryFlagCuba from '../assets/flags/flag-cuba.png';
import _countryFlagCuracao from '../assets/flags/flag-curacao.png';
import _countryFlagCyprus from '../assets/flags/flag-cyprus.png';
import _countryFlagCzechRepublic from '../assets/flags/flag-czech-republic.png';
import _countryFlagDenmark from '../assets/flags/flag-denmark.png';
import _countryFlagDjibouti from '../assets/flags/flag-djibouti.png';
import _countryFlagDominica from '../assets/flags/flag-dominica.png';
import _countryFlagDominicanRepublic from '../assets/flags/flag-dominican-republic.png';
import _countryFlagEastTimor from '../assets/flags/flag-east-timor.png';
import _countryFlagEcuador from '../assets/flags/flag-ecuador.png';
import _countryFlagEgypt from '../assets/flags/flag-egypt.png';
import _countryFlagElSalvador from '../assets/flags/flag-el-salvador.png';
import _countryFlagEngland from '../assets/flags/flag-england.png';
import _countryFlagEquatorialGuinea from '../assets/flags/flag-equatorial-guinea.png';
import _countryFlagEritrea from '../assets/flags/flag-eritrea.png';
import _countryFlagEstonia from '../assets/flags/flag-estonia.png';
import _countryFlagEthiopia from '../assets/flags/flag-ethiopia.png';
import _countryFlagEuropeanUnion from '../assets/flags/flag-european-union.png';
import _countryFlagExYugoslavia from '../assets/flags/flag-ex-yugoslavia.png';
import _countryFlagFalklandIslands from '../assets/flags/flag-falkland-islands.png';
import _countryFlagFaroeIslands from '../assets/flags/flag-faroe-islands.png';
import _countryFlagFiji from '../assets/flags/flag-fiji.png';
import _countryFlagFinland from '../assets/flags/flag-finland.png';
import _countryFlagFrance from '../assets/flags/flag-france.png';
import _countryFlagFrenchGuiana from '../assets/flags/flag-french-guiana.png';
import _countryFlagFrenchPolynesia from '../assets/flags/flag-french-polynesia.png';
import _countryFlagFrenchSouthernTerritories from '../assets/flags/flag-french-southern-territories.png';
import _countryFlagGabon from '../assets/flags/flag-gabon.png';
import _countryFlagGambia from '../assets/flags/flag-gambia.png';
import _countryFlagGeorgia from '../assets/flags/flag-georgia.png';
import _countryFlagGermany from '../assets/flags/flag-germany.png';
import _countryFlagGhana from '../assets/flags/flag-ghana.png';
import _countryFlagGibraltar from '../assets/flags/flag-gibraltar.png';
import _countryFlagGreece from '../assets/flags/flag-greece.png';
import _countryFlagGreenland from '../assets/flags/flag-greenland.png';
import _countryFlagGrenada from '../assets/flags/flag-grenada.png';
import _countryFlagGuadeloupe from '../assets/flags/flag-guadeloupe.png';
import _countryFlagGuam from '../assets/flags/flag-guam.png';
import _countryFlagGuatemala from '../assets/flags/flag-guatemala.png';
import _countryFlagGuernsey from '../assets/flags/flag-guernsey.png';
import _countryFlagGuineaBissau from '../assets/flags/flag-guinea-bissau.png';
import _countryFlagGuinea from '../assets/flags/flag-guinea.png';
import _countryFlagGuyana from '../assets/flags/flag-guyana.png';
import _countryFlagHaiti from '../assets/flags/flag-haiti.png';
import _countryFlagFlagHeardIslandAndMcdonaldIslands from '../assets/flags/flag-heard-island-and-mcdonald-islands.png';
import _countryFlagHolySee from '../assets/flags/flag-holy-see.png';
import _countryFlagHonduras from '../assets/flags/flag-honduras.png';
import _countryFlagHongKong from '../assets/flags/flag-hong-kong.png';
import _countryFlagHungary from '../assets/flags/flag-hungary.png';
import _countryFlagIceland from '../assets/flags/flag-iceland.png';
import _countryFlagIndia from '../assets/flags/flag-india.png';
import _countryFlagIndonesia from '../assets/flags/flag-indonesia.png';
import _countryFlagIran from '../assets/flags/flag-iran.png';
import _countryFlagIraq from '../assets/flags/flag-iraq.png';
import _countryFlagIreland from '../assets/flags/flag-ireland.png';
import _countryFlagIsleOfMan from '../assets/flags/flag-isle-of-man.png';
import _countryFlagIsrael from '../assets/flags/flag-israel.png';
import _countryFlagItaly from '../assets/flags/flag-italy.png';
import _countryFlagIvoryCoast from '../assets/flags/flag-ivory-coast.png';
import _countryFlagJamaica from '../assets/flags/flag-jamaica.png';
import _countryFlagJanMayen from '../assets/flags/flag-jan-mayen.png';
import _countryFlagJapan from '../assets/flags/flag-japan.png';
import _countryFlagJarvisIsland from '../assets/flags/flag-jarvis-island.png';
import _countryFlagJersey from '../assets/flags/flag-jersey.png';
import _countryFlagJordan from '../assets/flags/flag-jordan.png';
import _countryFlagKazakhstan from '../assets/flags/flag-kazakhstan.png';
import _countryFlagKenya from '../assets/flags/flag-kenya.png';
import _countryFlagKiribati from '../assets/flags/flag-kiribati.png';
import _countryFlagKorea from '../assets/flags/flag-korea.png';
import _countryFlagKosovo from '../assets/flags/flag-kosovo.png';
import _countryFlagKuwait from '../assets/flags/flag-kuwait.png';
import _countryFlagKyrgyzstan from '../assets/flags/flag-kyrgyzstan.png';
import _countryFlagLaos from '../assets/flags/flag-laos.png';
import _countryFlagLatvia from '../assets/flags/flag-latvia.png';
import _countryFlagLebanon from '../assets/flags/flag-lebanon.png';
import _countryFlagLesotho from '../assets/flags/flag-lesotho.png';
import _countryFlagLiberia from '../assets/flags/flag-liberia.png';
import _countryFlagLibya from '../assets/flags/flag-libya.png';
import _countryFlagLiechtenstein from '../assets/flags/flag-liechtenstein.png';
import _countryFlagLithuania from '../assets/flags/flag-lithuania.png';
import _countryFlagLuxembourg from '../assets/flags/flag-luxembourg.png';
import _countryFlagMacau from '../assets/flags/flag-macau.png';
import _countryFlagMacedonia from '../assets/flags/flag-macedonia.png';
import _countryFlagMadagascar from '../assets/flags/flag-madagascar.png';
import _countryFlagMalawi from '../assets/flags/flag-malawi.png';
import _countryFlagMalaysia from '../assets/flags/flag-malaysia.png';
import _countryFlagMaldives from '../assets/flags/flag-maldives.png';
import _countryFlagMali from '../assets/flags/flag-mali.png';
import _countryFlagMalta from '../assets/flags/flag-malta.png';
import _countryFlagMarshallIslands from '../assets/flags/flag-marshall-islands.png';
import _countryFlagMartinique from '../assets/flags/flag-martinique.png';
import _countryFlagMauritania from '../assets/flags/flag-mauritania.png';
import _countryFlagMauritius from '../assets/flags/flag-mauritius.png';
import _countryFlagMayotte from '../assets/flags/flag-mayotte.png';
import _countryFlagMexico from '../assets/flags/flag-mexico.png';
import _countryFlagMicronesia from '../assets/flags/flag-micronesia.png';
import _countryFlagMoldova from '../assets/flags/flag-moldova.png';
import _countryFlagMonaco from '../assets/flags/flag-monaco.png';
import _countryFlagMongolia from '../assets/flags/flag-mongolia.png';
import _countryFlagMontenegro from '../assets/flags/flag-montenegro.png';
import _countryFlagMontserrat from '../assets/flags/flag-montserrat.png';
import _countryFlagMorocco from '../assets/flags/flag-morocco.png';
import _countryFlagMozambique from '../assets/flags/flag-mozambique.png';
import _countryFlagMyanmar from '../assets/flags/flag-myanmar.png';
import _countryFlagNamibia from '../assets/flags/flag-namibia.png';
import _countryFlagNauru from '../assets/flags/flag-nauru.png';
import _countryFlagNepal from '../assets/flags/flag-nepal.png';
import _countryFlagNetherlandsAntilles from '../assets/flags/flag-netherlands-antilles.png';
import _countryFlagNetherlands from '../assets/flags/flag-netherlands.png';
import _countryFlagNewCaledonia from '../assets/flags/flag-new-caledonia.png';
import _countryFlagNewZealand from '../assets/flags/flag-new-zealand.png';
import _countryFlagNicaragua from '../assets/flags/flag-nicaragua.png';
import _countryFlagNiger from '../assets/flags/flag-niger.png';
import _countryFlagNigeria from '../assets/flags/flag-nigeria.png';
import _countryFlagNiue from '../assets/flags/flag-niue.png';
import _countryFlagNorfolkIsland from '../assets/flags/flag-norfolk-island.png';
import _countryFlagNorthKorea from '../assets/flags/flag-north-korea.png';
import _countryFlagNorthernIreland from '../assets/flags/flag-northern-ireland.png';
import _countryFlagNorthernMarianaIslands from '../assets/flags/flag-northern-mariana-islands.png';
import _countryFlagNorway from '../assets/flags/flag-norway.png';
import _countryFlagOman from '../assets/flags/flag-oman.png';
import _countryFlagPakistan from '../assets/flags/flag-pakistan.png';
import _countryFlagPalau from '../assets/flags/flag-palau.png';
import _countryFlagPalestinianTerritory from '../assets/flags/flag-palestinian-territory.png';
import _countryFlagPanama from '../assets/flags/flag-panama.png';
import _countryFlagPapuaNewGuinea from '../assets/flags/flag-papua-new-guinea.png';
import _countryFlagParaguay from '../assets/flags/flag-paraguay.png';
import _countryFlagPeru from '../assets/flags/flag-peru.png';
import _countryFlagPhilippines from '../assets/flags/flag-philippines.png';
import _countryFlagPitcairn from '../assets/flags/flag-pitcairn.png';
import _countryFlagPoland from '../assets/flags/flag-poland.png';
import _countryFlagPortugal from '../assets/flags/flag-portugal.png';
import _countryFlagPuertoRico from '../assets/flags/flag-puerto-rico.png';
import _countryFlagQatar from '../assets/flags/flag-qatar.png';
import _countryFlagRepublicOfChina from '../assets/flags/flag-republic-of-china.png';
import _countryFlagReunion from '../assets/flags/flag-reunion.png';
import _countryFlagRomania from '../assets/flags/flag-romania.png';
import _countryFlagRussia from '../assets/flags/flag-russia.png';
import _countryFlagRwanda from '../assets/flags/flag-rwanda.png';
import _countryFlagSaintBarthelemy from '../assets/flags/flag-saint-barthelemy.png';
import _countryFlagSaintHelenaAndDependencies from '../assets/flags/flag-saint-helena-and-dependencies.png';
import _countryFlagSaintHelena from '../assets/flags/flag-saint-helena.png';
import _countryFlagSaintKittsAndNevis from '../assets/flags/flag-saint-kitts-and-nevis.png';
import _countryFlagSaintLucia from '../assets/flags/flag-saint-lucia.png';
import _countryFlagSaintMartin from '../assets/flags/flag-saint-martin.png';
import _countryFlagSaintPierreAndMiquelon from '../assets/flags/flag-saint-pierre-and-miquelon.png';
import _countryFlagSaintVincentAndTheGrenadines from '../assets/flags/flag-saint-vincent-and-the-grenadines.png';
import _countryFlagSamoa from '../assets/flags/flag-samoa.png';
import _countryFlagSanMarino from '../assets/flags/flag-san-marino.png';
import _countryFlagSaoTomeAndPrincipe from '../assets/flags/flag-sao-tome-and-principe.png';
import _countryFlagSaudiArabia from '../assets/flags/flag-saudi-arabia.png';
import _countryFlagScotland from '../assets/flags/flag-scotland.png';
import _countryFlagSenegal from '../assets/flags/flag-senegal.png';
import _countryFlagSerbia from '../assets/flags/flag-serbia.png';
import _countryFlagSeychelles from '../assets/flags/flag-seychelles.png';
import _countryFlagSierraLeone from '../assets/flags/flag-sierra-leone.png';
import _countryFlagSingapore from '../assets/flags/flag-singapore.png';
import _countryFlagSintEustatius from '../assets/flags/flag-sint-eustatius.png';
import _countryFlagSintMaarten from '../assets/flags/flag-sint-maarten.png';
import _countryFlagSlovakia from '../assets/flags/flag-slovakia.png';
import _countryFlagSlovenia from '../assets/flags/flag-slovenia.png';
import _countryFlagSMOM from '../assets/flags/flag-SMOM.png';
import _countryFlagSolomonIslands from '../assets/flags/flag-solomon-islands.png';
import _countryFlagSomalia from '../assets/flags/flag-somalia.png';
import _countryFlagSouthAfrica from '../assets/flags/flag-south-africa.png';
import _countryFlagSouthGeorgia from '../assets/flags/flag-south-georgia.png';
import _countryFlagSouthSudan from '../assets/flags/flag-south-sudan.png';
import _countryFlagSpain from '../assets/flags/flag-spain.png';
import _countryFlagSpm from '../assets/flags/flag-spm.png';
import _countryFlagSriLanka from '../assets/flags/flag-sri-lanka.png';
import _countryFlagSudan from '../assets/flags/flag-sudan.png';
import _countryFlagSuriname from '../assets/flags/flag-suriname.png';
import _countryFlagSvalbard from '../assets/flags/flag-svalbard.png';
import _countryFlagSwaziland from '../assets/flags/flag-swaziland.png';
import _countryFlagSweden from '../assets/flags/flag-sweden.png';
import _countryFlagSwitzerland from '../assets/flags/flag-switzerland.png';
import _countryFlagSyria from '../assets/flags/flag-syria.png';
import _countryFlagTaiwan from '../assets/flags/flag-taiwan.png';
import _countryFlagTajikistan from '../assets/flags/flag-tajikistan.png';
import _countryFlagTanzania from '../assets/flags/flag-tanzania.png';
import _countryFlagThailand from '../assets/flags/flag-thailand.png';
import _countryFlagTimorLeste from '../assets/flags/flag-timor-leste.png';
import _countryFlagTogo from '../assets/flags/flag-togo.png';
import _countryFlagTokelau from '../assets/flags/flag-tokelau.png';
import _countryFlagTonga from '../assets/flags/flag-tonga.png';
import _countryFlagTrinidadAndTobago from '../assets/flags/flag-trinidad-and-tobago.png';
import _countryFlagTunisia from '../assets/flags/flag-tunisia.png';
import _countryFlagTurkey from '../assets/flags/flag-turkey.png';
import _countryFlagTurkmenistan from '../assets/flags/flag-turkmenistan.png';
import _countryFlagTurksAndCaicosIslands from '../assets/flags/flag-turks-and-caicos-islands.png';
import _countryFlagTuvalu from '../assets/flags/flag-tuvalu.png';
import _countryFlagUganda from '../assets/flags/flag-uganda.png';
import _countryFlagUkraine from '../assets/flags/flag-ukraine.png';
import _countryFlagUnitedArabEmirates from '../assets/flags/flag-united-arab-emirates.png';
import _countryFlagUnitedKingdom from '../assets/flags/flag-united-kingdom.png';
import _countryFlagUnitedStates from '../assets/flags/flag-united-states.png';
import _countryFlagUruguay from '../assets/flags/flag-uruguay.png';
import _countryFlagUzbekistan from '../assets/flags/flag-uzbekistan.png';
import _countryFlagVanuatu from '../assets/flags/flag-vanuatu.png';
import _countryFlagVaticanCity from '../assets/flags/flag-vatican-city.png';
import _countryFlagVenezuela from '../assets/flags/flag-venezuela.png';
import _countryFlagVietnam from '../assets/flags/flag-vietnam.png';
import _countryFlagVirginIslands from '../assets/flags/flag-virgin-islands.png';
import _countryFlagWales from '../assets/flags/flag-wales.png';
import _countryFlagWallisAndFutuna from '../assets/flags/flag-wallis-and-futuna.png';
import _countryFlagWesternSahara from '../assets/flags/flag-western-sahara.png';
import _countryFlagYemen from '../assets/flags/flag-yemen.png';
import _countryFlagZambia from '../assets/flags/flag-zambia.png';
import _countryFlagZimbabwe from '../assets/flags/flag-zimbabwe.png';
import _world from '../assets/flags/world.png';


import _rankingStarsLeft from '../assets/frames/ranking-stars-left.png';
import _rankingStarsRight from '../assets/frames/ranking-stars-right.png';
import _rankingStarsSeparator from '../assets/frames/ranking-stars-separator.png';
import _rankingStripesLeft from '../assets/frames/ranking-stripes-left.png';
import _rankingStripesRight from '../assets/frames/ranking-stripes-right.png';


import _iconSymbolMan from '../assets/images/icon_symbol_man.png';
import _iconSymbolTransman from '../assets/images/icon_symbol_transman.png';
import _iconSymbolTranswoman from '../assets/images/icon_symbol_transwoman.png';
import _iconSymbolToman from '../assets/images/icon_symbol_woman.png';
import _logoBi from '../assets/images/logo_bi.png';
import _logoGay from '../assets/images/logo_gay.png';
import _logoHetero from '../assets/images/logo_hetero.png';
import _logoLesbian from '../assets/images/logo_lesbian.png';
import _logoTransman from '../assets/images/logo_transman.png';
import _logoTranswoman from '../assets/images/logo_transwoman.png';

import _rankingFirwork from '../assets/images/ranking-firwork.png';
import _rankingYearlyIcon from '../assets/images/ranking-yearly-icon.png';
import _shield1 from '../assets/images/shield-1.png';
import _shield2 from '../assets/images/shield-2.png';
import _shield3 from '../assets/images/shield-3.png';
import _star from '../assets/images/star.png';


import _suitClubs from '../assets/images/suit-clubs.png';
import _suitDiamonds from '../assets/images/suit-diamonds.png';
import _suitHearts from '../assets/images/suit-hearts.png';
import _suitSpades from '../assets/images/suit-spades.png';


import _trophy from '../assets/images/trophy.png';


import _medalBronze from '../assets/levels/medal-bronze.png';
import _medalDiamond from '../assets/levels/medal-diamond.png';
import _medalGold from '../assets/levels/medal-gold.png';
import _medalPremium from '../assets/levels/medal-premium.png';
import _medalSilver from '../assets/levels/medal-silver.png';
import _ribbonBronze from '../assets/levels/ribbon-bronze.png';
import _ribbonDiamond from '../assets/levels/ribbon-diamond.png';
import _ribbonGold from '../assets/levels/ribbon-gold.png';
import _ribbonPremium from '../assets/levels/ribbon-premium.png';
import _ribbonSilver from '../assets/levels/ribbon-silver.png';


import _logoIconApp from '../assets/logos/logo-icon.png';


import _flagDefaultCvv from '../assets/payments/flag_card_acnull_cvv.png';
import _flagDefault from '../assets/payments/flag_card_acnull.png';
import _flagCardAmerican from '../assets/payments/flag_card_american.png';
import _flagCardDiners from '../assets/payments/flag_card_diners.png';
import _flagCardElo from '../assets/payments/flag_card_elo.png';
import _flagCardHipercard from '../assets/payments/flag_card_hipercard.png';
import _flagCardMastercard from '../assets/payments/flag_card_mastercard.png';
import _flagCardVisa from '../assets/payments/flag_card_visa.png';
import _creditCardFrontDefault from '../assets/payments/flag_credit_card_acnull_front.png';
import _creditCardBackDefault from '../assets/payments/flag_credit_card_acnull_upback.png';
import _creditCardFrontAmerican from '../assets/payments/flag_credit_card_american_front.png';
import _creditCardBackAmerican from '../assets/payments/flag_credit_card_american_upback.png';
import _creditCardFrontDiners from '../assets/payments/flag_credit_card_diners_front.png';
import _creditCardBackDiners from '../assets/payments/flag_credit_card_diners_upback.png';
import _creditCardFrontElo from '../assets/payments/flag_credit_card_elo_front.png';
import _creditCardBackElo from '../assets/payments/flag_credit_card_elo_upback.png';
import _creditCardFrontHipercard from '../assets/payments/flag_credit_card_hipercard_front.png';
import _creditCardBackHipercard from '../assets/payments/flag_credit_card_hipercard_upback.png';
import _creditCardFrontMastercard from '../assets/payments/flag_credit_card_mastercard_front.png';
import _creditCardBackMastercard from '../assets/payments/flag_credit_card_mastercard_upback.png';
import _creditCardFrontVisa from '../assets/payments/flag_credit_card_visa_front.png';
import _creditCardBackVisa from '../assets/payments/flag_credit_card_visa_upback.png';
import _flagDiscount from '../assets/payments/flag_discount.png';
import _flagTypeCash from '../assets/payments/flag_type_cash.png';
import _flagTypeCheque from '../assets/payments/flag_type_cheque.png';
import _flagTypePix from '../assets/payments/flag_type_pix.png';
import _gencoin from '../assets/payments/gencoin.png';
import _logoAmerican from '../assets/payments/logo_american.png';
import _logoDiners from '../assets/payments/logo_diners.png';
import _logoElo from '../assets/payments/logo_elo.png';
import _logoHipercard from '../assets/payments/logo_hipercard.png';
import _logoMastercard from '../assets/payments/logo_mastercard.png';
import _logoVisa from '../assets/payments/logo_visa.png';



export const AVATARS = {
  avatarModeleImage:                _avatarModeleImage,
  avatarUserImage:                  _avatarUserImage,

  noImage:                          _noImage,
  noVideo:                          _noVideo,
};



export const BACKGROUNDS = {
  loginImageBackground:             _loginImageBackground,
  backgroundApp:                    _backgroundApp,

  backgroundDot:                    _backgroundDot,

  // backgroundIndex2:                 _backgroundIndex2,
  // backgroundIndex3:                 _backgroundIndex3,
  // backgroundPRO:                    _backgroundOrganizer,
  rankingStarsSeparator:            _rankingStarsSeparator,
  rankingContents:                  _rankingContents,

  rankingStarsLeft:                 _rankingStarsLeft,
  rankingStarsRight:                _rankingStarsRight,
  rankingStripesLeft:               _rankingStripesLeft,
  rankingStripesRight:              _rankingStripesRight,

  bannerDetails:                    _bannerDetails,
};


export const BANNERS = {
  rankingHeaderYearly:              _rankingHeaderYearly,
};


export const DRAWABLES = {
  rankingFirwork:                   _rankingFirwork,
  rankingYearlyIcon:                _rankingYearlyIcon,
  star:                             _star,
  trophy:                           _trophy,
};


export const FLAGS_COUNTRY = {
  world:                            _world,

  countryFlagAfghanistan:           _countryFlagAfghanistan,
  countryFlagAlandIslands:          _countryFlagAlandIslands,
  countryFlagAlbania:               _countryFlagAlbania,
  countryFlagAlgeria:               _countryFlagAlgeria,
  countryFlagAmericanSamoa:         _countryFlagAmericanSamoa,
  countryFlagAndorra:               _countryFlagAndorra,
  countryFlagAngola:                _countryFlagAngola,
  countryFlagAnguilla:              _countryFlagAnguilla,
  countryFlagAntarctica:            _countryFlagAntarctica,
  countryFlagAntiguaAndBarbuda:     _countryFlagAntiguaAndBarbuda,
  countryFlagArgentina:             _countryFlagArgentina,
  countryFlagArmenia:               _countryFlagArmenia,
  countryFlagAruba:                 _countryFlagAruba,
  countryFlagflagAscensionIsland:   _countryFlagflagAscensionIsland,
  countryFlagAustralia:             _countryFlagAustralia,
  countryFlagAustria:               _countryFlagAustria,
  countryFlagAzerbaijan:            _countryFlagAzerbaijan,
  countryFlagBahamas:               _countryFlagBahamas,
  countryFlagBahrain:               _countryFlagBahrain,
  countryFlagBangladesh:            _countryFlagBangladesh,
  countryFlagBarbados:              _countryFlagBarbados,
  countryFlagBelarus:               _countryFlagBelarus,
  countryFlagBelgium:               _countryFlagBelgium,
  countryFlagBelize:                _countryFlagBelize,
  countryFlagBenin:                 _countryFlagBenin,
  countryFlagBermuda:               _countryFlagBermuda,
  countryFlagBhutan:                _countryFlagBhutan,
  countryFlagBiot:                  _countryFlagBiot,
  countryFlagBolivia:               _countryFlagBolivia,
  countryFlagBonaire:               _countryFlagBonaire,
  countryFlagBosnian:               _countryFlagBosnian,
  countryFlagBotswana:              _countryFlagBotswana,
  countryFlagBouvetIsland:          _countryFlagBouvetIsland,
  countryFlagBrazil:                _countryFlagBrazil,
  countryFlagBritishAntarcticTerritory:           _countryFlagBritishAntarcticTerritory,
  countryFlagBritishVirginIslands:          _countryFlagBritishVirginIslands,
  countryFlagBrunei:          _countryFlagBrunei,
  countryFlagBulgaria:          _countryFlagBulgaria,
  countryFlagBurkinaFaso:           _countryFlagBurkinaFaso,
  countryFlagBurma:           _countryFlagBurma,
  countryFlagBurundi:           _countryFlagBurundi,
  countryFlagCambodia:          _countryFlagCambodia,
  countryFlagCameroon:          _countryFlagCameroon,
  countryFlagCanada:          _countryFlagCanada,
  countryFlagCapeVerde:           _countryFlagCapeVerde,
  countryFlagCascadia:          _countryFlagCascadia,
  countryFlagCaymanIslands:           _countryFlagCaymanIslands,
  countryFlagCentraAfricanRepublic:           _countryFlagCentraAfricanRepublic,
  countryFlagChad:          _countryFlagChad,
  countryFlagChile:           _countryFlagChile,
  countryFlagChina:           _countryFlagChina,
  countryFlagChristmasIsland:           _countryFlagChristmasIsland,
  countryFlagCocosIslands:          _countryFlagCocosIslands,
  countryFlagColombia:          _countryFlagColombia,
  countryFlagComoros:           _countryFlagComoros,
  countryFlagCongoKinshasa:           _countryFlagCongoKinshasa,
  countryFlagCongo:           _countryFlagCongo,
  countryFlagCookIslands:           _countryFlagCookIslands,
  countryFlagCostaRica:           _countryFlagCostaRica,
  countryFlagCroatian:          _countryFlagCroatian,
  countryFlagCuba:          _countryFlagCuba,
  countryFlagCuracao:           _countryFlagCuracao,
  countryFlagCyprus:          _countryFlagCyprus,
  countryFlagCzechRepublic:           _countryFlagCzechRepublic,
  countryFlagDenmark:           _countryFlagDenmark,
  countryFlagDjibouti:          _countryFlagDjibouti,
  countryFlagDominica:          _countryFlagDominica,
  countryFlagDominicanRepublic:           _countryFlagDominicanRepublic,
  countryFlagEastTimor:           _countryFlagEastTimor,
  countryFlagEcuador:           _countryFlagEcuador,
  countryFlagEgypt:           _countryFlagEgypt,
  countryFlagElSalvador:          _countryFlagElSalvador,
  countryFlagEngland:           _countryFlagEngland,
  countryFlagEquatorialGuinea:          _countryFlagEquatorialGuinea,
  countryFlagEritrea:           _countryFlagEritrea,
  countryFlagEstonia:           _countryFlagEstonia,
  countryFlagEthiopia:          _countryFlagEthiopia,
  countryFlagEuropeanUnion:           _countryFlagEuropeanUnion,
  countryFlagExYugoslavia:          _countryFlagExYugoslavia,
  countryFlagFalklandIslands:           _countryFlagFalklandIslands,
  countryFlagFaroeIslands:          _countryFlagFaroeIslands,
  countryFlagFiji:          _countryFlagFiji,
  countryFlagFinland:           _countryFlagFinland,
  countryFlagFrance:          _countryFlagFrance,
  countryFlagFrenchGuiana:          _countryFlagFrenchGuiana,
  countryFlagFrenchPolynesia:           _countryFlagFrenchPolynesia,
  countryFlagFrenchSouthernTerritories:           _countryFlagFrenchSouthernTerritories,
  countryFlagGabon:           _countryFlagGabon,
  countryFlagGambia:          _countryFlagGambia,
  countryFlagGeorgia:           _countryFlagGeorgia,
  countryFlagGermany:           _countryFlagGermany,
  countryFlagGhana:           _countryFlagGhana,
  countryFlagGibraltar:           _countryFlagGibraltar,
  countryFlagGreece:          _countryFlagGreece,
  countryFlagGreenland:           _countryFlagGreenland,
  countryFlagGrenada:           _countryFlagGrenada,
  countryFlagGuadeloupe:          _countryFlagGuadeloupe,
  countryFlagGuam:          _countryFlagGuam,
  countryFlagGuatemala:           _countryFlagGuatemala,
  countryFlagGuernsey:          _countryFlagGuernsey,
  countryFlagGuineaBissau:          _countryFlagGuineaBissau,
  countryFlagGuinea:          _countryFlagGuinea,
  countryFlagGuyana:          _countryFlagGuyana,
  countryFlagHaiti:           _countryFlagHaiti,
  countryFlagFlagHeardIslandAndMcdonaldIslands:           _countryFlagFlagHeardIslandAndMcdonaldIslands,
  countryFlagHolySee:           _countryFlagHolySee,
  countryFlagHonduras:          _countryFlagHonduras,
  countryFlagHongKong:          _countryFlagHongKong,
  countryFlagHungary:           _countryFlagHungary,
  countryFlagIceland:           _countryFlagIceland,
  countryFlagIndia:           _countryFlagIndia,
  countryFlagIndonesia:           _countryFlagIndonesia,
  countryFlagIran:          _countryFlagIran,
  countryFlagIraq:          _countryFlagIraq,
  countryFlagIreland:           _countryFlagIreland,
  countryFlagIsleOfMan:           _countryFlagIsleOfMan,
  countryFlagIsrael:          _countryFlagIsrael,
  countryFlagItaly:           _countryFlagItaly,
  countryFlagIvoryCoast:          _countryFlagIvoryCoast,
  countryFlagJamaica:           _countryFlagJamaica,
  countryFlagJanMayen:          _countryFlagJanMayen,
  countryFlagJapan:           _countryFlagJapan,
  countryFlagJarvisIsland:          _countryFlagJarvisIsland,
  countryFlagJersey:          _countryFlagJersey,
  countryFlagJordan:          _countryFlagJordan,
  countryFlagKazakhstan:          _countryFlagKazakhstan,
  countryFlagKenya:           _countryFlagKenya,
  countryFlagKiribati:          _countryFlagKiribati,
  countryFlagKorea:           _countryFlagKorea,
  countryFlagKosovo:          _countryFlagKosovo,
  countryFlagKuwait:          _countryFlagKuwait,
  countryFlagKyrgyzstan:          _countryFlagKyrgyzstan,
  countryFlagLaos:          _countryFlagLaos,
  countryFlagLatvia:          _countryFlagLatvia,
  countryFlagLebanon:           _countryFlagLebanon,
  countryFlagLesotho:           _countryFlagLesotho,
  countryFlagLiberia:           _countryFlagLiberia,
  countryFlagLibya:           _countryFlagLibya,
  countryFlagLiechtenstein:           _countryFlagLiechtenstein,
  countryFlagLithuania:           _countryFlagLithuania,
  countryFlagLuxembourg:          _countryFlagLuxembourg,
  countryFlagMacau:           _countryFlagMacau,
  countryFlagMacedonia:           _countryFlagMacedonia,
  countryFlagMadagascar:          _countryFlagMadagascar,
  countryFlagMalawi:          _countryFlagMalawi,
  countryFlagMalaysia:          _countryFlagMalaysia,
  countryFlagMaldives:          _countryFlagMaldives,
  countryFlagMali:          _countryFlagMali,
  countryFlagMalta:           _countryFlagMalta,
  countryFlagMarshallIslands:           _countryFlagMarshallIslands,
  countryFlagMartinique:          _countryFlagMartinique,
  countryFlagMauritania:          _countryFlagMauritania,
  countryFlagMauritius:           _countryFlagMauritius,
  countryFlagMayotte:           _countryFlagMayotte,
  countryFlagMexico:          _countryFlagMexico,
  countryFlagMicronesia:          _countryFlagMicronesia,
  countryFlagMoldova:           _countryFlagMoldova,
  countryFlagMonaco:          _countryFlagMonaco,
  countryFlagMongolia:          _countryFlagMongolia,
  countryFlagMontenegro:          _countryFlagMontenegro,
  countryFlagMontserrat:          _countryFlagMontserrat,
  countryFlagMorocco:           _countryFlagMorocco,
  countryFlagMozambique:          _countryFlagMozambique,
  countryFlagMyanmar:           _countryFlagMyanmar,
  countryFlagNamibia:           _countryFlagNamibia,
  countryFlagNauru:           _countryFlagNauru,
  countryFlagNepal:           _countryFlagNepal,
  countryFlagNetherlandsAntilles:           _countryFlagNetherlandsAntilles,
  countryFlagNetherlands:           _countryFlagNetherlands,
  countryFlagNewCaledonia:          _countryFlagNewCaledonia,
  countryFlagNewZealand:          _countryFlagNewZealand,
  countryFlagNicaragua:           _countryFlagNicaragua,
  countryFlagNiger:           _countryFlagNiger,
  countryFlagNigeria:           _countryFlagNigeria,
  countryFlagNiue:          _countryFlagNiue,
  countryFlagNorfolkIsland:           _countryFlagNorfolkIsland,
  countryFlagNorthKorea:          _countryFlagNorthKorea,
  countryFlagNorthernIreland:           _countryFlagNorthernIreland,
  countryFlagNorthernMarianaIslands:          _countryFlagNorthernMarianaIslands,
  countryFlagNorway:          _countryFlagNorway,
  countryFlagOman:          _countryFlagOman,
  countryFlagPakistan:          _countryFlagPakistan,
  countryFlagPalau:           _countryFlagPalau,
  countryFlagPalestinianTerritory:          _countryFlagPalestinianTerritory,
  countryFlagPanama:          _countryFlagPanama,
  countryFlagPapuaNewGuinea:          _countryFlagPapuaNewGuinea,
  countryFlagParaguay:          _countryFlagParaguay,
  countryFlagPeru:          _countryFlagPeru,
  countryFlagPhilippines:           _countryFlagPhilippines,
  countryFlagPitcairn:          _countryFlagPitcairn,
  countryFlagPoland:          _countryFlagPoland,
  countryFlagPortugal:          _countryFlagPortugal,
  countryFlagPuertoRico:          _countryFlagPuertoRico,
  countryFlagQatar:           _countryFlagQatar,
  countryFlagRepublicOfChina:           _countryFlagRepublicOfChina,
  countryFlagReunion:           _countryFlagReunion,
  countryFlagRomania:           _countryFlagRomania,
  countryFlagRussia:          _countryFlagRussia,
  countryFlagRwanda:          _countryFlagRwanda,
  countryFlagSaintBarthelemy:           _countryFlagSaintBarthelemy,
  countryFlagSaintHelenaAndDependencies:          _countryFlagSaintHelenaAndDependencies,
  countryFlagSaintHelena:           _countryFlagSaintHelena,
  countryFlagSaintKittsAndNevis:          _countryFlagSaintKittsAndNevis,
  countryFlagSaintLucia:          _countryFlagSaintLucia,
  countryFlagSaintMartin:           _countryFlagSaintMartin,
  countryFlagSaintPierreAndMiquelon:          _countryFlagSaintPierreAndMiquelon,
  countryFlagSaintVincentAndTheGrenadines:          _countryFlagSaintVincentAndTheGrenadines,
  countryFlagSamoa:           _countryFlagSamoa,
  countryFlagSanMarino:           _countryFlagSanMarino,
  countryFlagSaoTomeAndPrincipe:          _countryFlagSaoTomeAndPrincipe,
  countryFlagSaudiArabia:           _countryFlagSaudiArabia,
  countryFlagScotland:          _countryFlagScotland,
  countryFlagSenegal:           _countryFlagSenegal,
  countryFlagSerbia:          _countryFlagSerbia,
  countryFlagSeychelles:          _countryFlagSeychelles,
  countryFlagSierraLeone:           _countryFlagSierraLeone,
  countryFlagSingapore:           _countryFlagSingapore,
  countryFlagSintEustatius:           _countryFlagSintEustatius,
  countryFlagSintMaarten:           _countryFlagSintMaarten,
  countryFlagSlovakia:          _countryFlagSlovakia,
  countryFlagSlovenia:          _countryFlagSlovenia,
  countryFlagSMOM:          _countryFlagSMOM,
  countryFlagSolomonIslands:          _countryFlagSolomonIslands,
  countryFlagSomalia:           _countryFlagSomalia,
  countryFlagSouthAfrica:           _countryFlagSouthAfrica,
  countryFlagSouthGeorgia:          _countryFlagSouthGeorgia,
  countryFlagSouthSudan:          _countryFlagSouthSudan,
  countryFlagSpain:           _countryFlagSpain,
  countryFlagSpm:           _countryFlagSpm,
  countryFlagSriLanka:          _countryFlagSriLanka,
  countryFlagSudan:           _countryFlagSudan,
  countryFlagSuriname:          _countryFlagSuriname,
  countryFlagSvalbard:          _countryFlagSvalbard,
  countryFlagSwaziland:           _countryFlagSwaziland,
  countryFlagSweden:          _countryFlagSweden,
  countryFlagSwitzerland:           _countryFlagSwitzerland,
  countryFlagSyria:           _countryFlagSyria,
  countryFlagTaiwan:          _countryFlagTaiwan,
  countryFlagTajikistan:          _countryFlagTajikistan,
  countryFlagTanzania:          _countryFlagTanzania,
  countryFlagThailand:          _countryFlagThailand,
  countryFlagTimorLeste:          _countryFlagTimorLeste,
  countryFlagTogo:          _countryFlagTogo,
  countryFlagTokelau:           _countryFlagTokelau,
  countryFlagTonga:           _countryFlagTonga,
  countryFlagTrinidadAndTobago:           _countryFlagTrinidadAndTobago,
  countryFlagTunisia:           _countryFlagTunisia,
  countryFlagTurkey:          _countryFlagTurkey,
  countryFlagTurkmenistan:          _countryFlagTurkmenistan,
  countryFlagTurksAndCaicosIslands:           _countryFlagTurksAndCaicosIslands,
  countryFlagTuvalu:          _countryFlagTuvalu,
  countryFlagUganda:          _countryFlagUganda,
  countryFlagUkraine:           _countryFlagUkraine,
  countryFlagUnitedArabEmirates:          _countryFlagUnitedArabEmirates,
  countryFlagUnitedKingdom:           _countryFlagUnitedKingdom,
  countryFlagUnitedStates:          _countryFlagUnitedStates,
  countryFlagUruguay:           _countryFlagUruguay,
  countryFlagUzbekistan:          _countryFlagUzbekistan,
  countryFlagVanuatu:           _countryFlagVanuatu,
  countryFlagVaticanCity:           _countryFlagVaticanCity,
  countryFlagVenezuela:           _countryFlagVenezuela,
  countryFlagVietnam:           _countryFlagVietnam,
  countryFlagVirginIslands:           _countryFlagVirginIslands,
  countryFlagWales:           _countryFlagWales,
  countryFlagWallisAndFutuna:           _countryFlagWallisAndFutuna,
  countryFlagWesternSahara:           _countryFlagWesternSahara,
  countryFlagYemen:           _countryFlagYemen,
  countryFlagZambia:          _countryFlagZambia,
  countryFlagZimbabwe:          _countryFlagZimbabwe,
};


// export const ERRORS = {
//   error403:                         _error403,
//   error404:                         _error404,
//   error500:                         _error500,
// };



export const GENDER = {
  // noModeleMan:                      _noModeleMan,
  // noModeleTransMan:                 _noModeleTransMan,
  // noModeleTransWoman:               _noModeleTransWoman,
  // noModeleWoman:                    _noModeleWoman,

  suitClubs:                        _suitClubs,
  suitDiamonds:                     _suitDiamonds,
  suitHearts:                       _suitHearts,
  suitSpades:                       _suitSpades,

  // infinite:                         _infinite,
};


// export const IMAGES = {
//   linkExtensions:                   _linkExtensions,
// };


export const ITEMS = {
  iconSymbolMan:                    _iconSymbolMan,
  iconSymbolTransman:               _iconSymbolTransman,
  iconSymbolTranswoman:             _iconSymbolTranswoman,
  iconSymbolToman:                  _iconSymbolToman,

  logoBi:                           _logoBi,
  logoGay:                          _logoGay,
  logoHetero:                       _logoHetero,
  logoLesbian:                      _logoLesbian,
  logoTransman:                     _logoTransman,
  logoTranswoman:                   _logoTranswoman,

  shield1:_shield1,
shield2:_shield2,
shield3:_shield3,
};



export const ITEMS_EMPTY = {
  // checkinEmpty:                     _checkinEmpty,
  noBankAccount:                    _avatarUserImage, // _noBankAccount,
  // noEventsPast:                     _noEventsPast,
  // noEventsSketch:                   _noEventsSketch,
  // noEventsUpcoming:                 _noEventsUpcoming,
  // noOrderCategories:                _noOrderCategories,
  // noOrderItems:                     _noOrderItems,
  // noRules:                          _noRules,
  // noBar:                            _noBar,
};



export const LEVELS = {
  medalPremium:                     _medalPremium,
  medalBronze:                      _medalBronze,
  medalSilver:                      _medalSilver,
  medalGold:                        _medalGold,
  medalDiamond:                     _medalDiamond,

  ribbonPremium:                    _ribbonPremium,
  ribbonBronze:                     _ribbonBronze,
  ribbonSilver:                     _ribbonSilver,
  ribbonGold:                       _ribbonGold,
  ribbonDiamond:                    _ribbonDiamond,
};


export const LOGOS = {
  logoIconApp:                      _logoIconApp,
  
  //   logoPROIconColor:                 _logoPROIconColor,
  
  //   logoTvIconWhite:                  _logoTvIconWhite,
  
  //   logoPersasNameWhite:              _logoPersasNameWhite,
  //   logoPersasIconWhite:              _logoPersasIconWhite,
};



export const PAYMENTS = {
  gencoin:                          _gencoin,

  flagDiscount:                     _flagDiscount,

  flagTypeCash:                     _flagTypeCash,
  flagTypeCheque:                   _flagTypeCheque,
  flagTypePix:                      _flagTypePix,

  flagDefault:                      _flagDefault,
  flagDefaultCvv:                   _flagDefaultCvv,
  creditCardFrontDefault:           _creditCardFrontDefault,
  creditCardBackDefault:            _creditCardBackDefault,

  logoAmerican:                     _logoAmerican,
  flagCardAmerican:                 _flagCardAmerican,
  creditCardFrontAmerican:          _creditCardFrontAmerican,
  creditCardBackAmerican:           _creditCardBackAmerican,

  logoDiners:                       _logoDiners,
  flagCardDiners:                   _flagCardDiners,
  creditCardFrontDiners:            _creditCardFrontDiners,
  creditCardBackDiners:             _creditCardBackDiners,

  logoElo:                          _logoElo,
  flagCardElo:                      _flagCardElo,
  creditCardFrontElo:               _creditCardFrontElo,
  creditCardBackElo:                _creditCardBackElo,

  logoHipercard:                    _logoHipercard,
  flagCardHipercard:                _flagCardHipercard,
  creditCardFrontHipercard:         _creditCardFrontHipercard,
  creditCardBackHipercard:          _creditCardBackHipercard,

  logoMastercard:                   _logoMastercard,
  flagCardMastercard:               _flagCardMastercard,
  creditCardFrontMastercard:        _creditCardFrontMastercard,
  creditCardBackMastercard:         _creditCardBackMastercard,

  logoVisa:                         _logoVisa,
  flagCardVisa:                     _flagCardVisa,
  creditCardFrontVisa:              _creditCardFrontVisa,
  creditCardBackVisa:               _creditCardBackVisa,
};



const Images = {
  ...AVATARS,
  ...BACKGROUNDS,
  ...BANNERS,
  ...DRAWABLES,
  ...FLAGS_COUNTRY,
  // ...ERRORS,
  ...GENDER,
  // ...IMAGES,
  ...ITEMS,
  ...ITEMS_EMPTY,
  ...LEVELS,
  ...LOGOS,
  ...PAYMENTS,
};



export default Images;
