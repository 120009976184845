import React from 'react';

import Breadcrumb from '../../Breadcrumb';

import {
  SubToolbarContainer,
  ButtonsSubtoolbarBreadcrumb,
  ButtonsSubtoolbarTitle,
  ButtonsSubtoolbarContent,
} from './styled';



export interface IProps {
  title?: string;
  color?: string;
  children?: any;
}



const SubHeader: React.FC<IProps> = (props: IProps) => {
  return (

    <SubToolbarContainer>

      <ButtonsSubtoolbarBreadcrumb>

        <ButtonsSubtoolbarTitle
          color={props.color}>
          {props.title || '-'}
        </ButtonsSubtoolbarTitle>

        <Breadcrumb
        />

      </ButtonsSubtoolbarBreadcrumb>



      {props.children && (
        <ButtonsSubtoolbarContent>
          {props.children}
        </ButtonsSubtoolbarContent>
      )}

    </SubToolbarContainer>

  );
};



export default SubHeader;
