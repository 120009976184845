export const languagesUserArray = [
  { id: 0, label: 'Ingles', value: 'ENGLISH' },
  { id: 1, label: 'Espanhol', value: 'SPANISH' },
  { id: 2, label: 'Franceis', value: 'FRENCH' },
  { id: 3, label: 'Italiano', value: 'ITALIAN' },
  // { id: 4, label: 'Dutch', value: 'DUTCH' },
  { id: 5, label: 'Português', value: 'PORTUGUESE' },
  { id: 6, label: 'Alemão', value: 'GERMAN' },
  { id: 7, label: 'Russo', value: 'RUSSIAN' },
  { id: 8, label: 'Grego', value: 'GREEK' },
  // { id: 9, label: 'Mala', value: 'MALAY' },
  // { id: 10, label: 'Hungaro', value: 'HUNGARIAN' },
  { id: 11, label: 'Arabico', value: 'ARABIC' },
  // { id: 12, label: 'Hindi', value: 'HINDI' },
  // { id: 13, label: 'Bengali', value: 'BENGALI' },
  { id: 14, label: 'Mandarim', value: 'MANDARIN' },
  { id: 15, label: 'Japones', value: 'JAPANESE' },
];
