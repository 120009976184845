import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import {
  RentAttendanceTipsView,
  RentAttendanceTipsIcon,
  RentAttendanceTipsText,
} from './styled';



export interface IProps {
  hideBorder?: boolean;
  icon?: any;
  label?: string;
  color?: string;
  backgroundColor?: string;
  onPress?: any;
}



const ToggleGrid: React.FC<IProps> = (props: IProps) => {
  const className = { ...props } as any;



  return (

    <RentAttendanceTipsView
      className={className?.className}

      border={!props.hideBorder}
      backgroundColor={props.backgroundColor || Colors.transparent}
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <RentAttendanceTipsIcon>
        {props.icon}
      </RentAttendanceTipsIcon>

      <RentAttendanceTipsText
        color={props.color || Colors.white}>
        {props.label}
      </RentAttendanceTipsText>

    </RentAttendanceTipsView>

  );
};



export default ToggleGrid;
