import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useParams,
} from 'react-router-dom';

import {
  EditPrivacityModele,
  GetPrivacityModele,
} from '../../../../apis/endpoints/modeles.endpoints';

import {
  Content,
  Title,
} from '../../../../components/Composh/web';

import {
  Screen,
} from '../../../../components/Containers/ScreenContainer';

import {
  ContentPadder,
} from '../../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterApp from '../../../../components/Footers/FooterApp';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import InputSwitch from '../../../../components/Inputs/InputSwitch';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../../redux/reducers/app-modals.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../../shared/constants';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  FooterDescription,
} from '../../../../styles/footer.layout';

import {
  CardVisibilityForm,
  CardVisibilityView,
} from './styled';



export interface IRouteParams {
  useralias: string;
}



const VisibilityModeleScreen: React.FC = () => {
  const dispatch = useDispatch();

  const route = useParams<IRouteParams>();
  const paramsRoute = route as IRouteParams;


  const { useralias } = paramsRoute;
  const userIdSearch = useralias;


  const [showLastLogin, setShowLastLogin] = useState(false);
  const [showMyProfileSearch, setShowMyProfileSearch] = useState(false);
  const [showMyLocations, setShowMyLocations] = useState(false);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function setStatesParams(paramsReceived: any) {
    setShowLastLogin(paramsReceived?.showLastLogin);
    setShowMyProfileSearch(paramsReceived?.showMyProfileSearch);
    setShowMyLocations(paramsReceived?.showMyLocations);
  }


  async function getActualModele() {
    try {
      setLoading(true);

      const response = await GetPrivacityModele(userIdSearch);
      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      setStatesParams(responseModele);
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  async function saveItemGroup(payload: any) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await EditPrivacityModele(userIdSearch, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      setStatesParams(response?.data);

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
    }
    catch (error: any) {
      console.error(error);

      const messageError = treatErrorAndReturnMessage(error);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function updateForm() {
    await getActualModele();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }



  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        leftIcon={
          <ButtonBack
            color={Colors.black}
            size={27}
          />
        }
        centerContent={
          <Title
            color={Colors.black}>
            Visibilidade e privacidade
          </Title>
        }
      />



      <Content>

        {!mounted && (
          renderLoading()
        )}



        {mounted && (
          <ContentPadder>

            <CardVisibilityForm
              backgroundColor={Colors.formBackground}
              borderRadius={Sizes.cardRadius}>

              <CardVisibilityView>

                <InputSwitch
                  disabled={loading}
                  selected={showLastLogin}
                  title={'Último acesso'}
                  description={'Mostrar meu último acesso'}
                  onPress={() => {
                    if (!loading) {
                      saveItemGroup({ showLastLogin: !showLastLogin });
                    }
                  }}
                />

              </CardVisibilityView>



              <CardVisibilityView>

                <InputSwitch
                  disabled={loading}
                  selected={showMyProfileSearch}
                  title={'Perfil nas buscas'}
                  description={'Mostrar meu perfil nas buscas'}
                  onPress={() => {
                    if (!loading) {
                      saveItemGroup({ showMyProfileSearch: !showMyProfileSearch });
                    }
                  }}
                />

                <FooterDescription>
                  Esse parametro faz com que seu perfil seja mostrado ou não nos resultados de pesquisas como filtros ou buscas de perfis
                </FooterDescription>

              </CardVisibilityView>



              <CardVisibilityView>

                <InputSwitch
                  disabled={loading}
                  selected={showMyLocations}
                  title={'Minha localização/viagens'}
                  description={'Mostrar minha localização/viagens'}
                  onPress={() => {
                    if (!loading) {
                      saveItemGroup({ showMyLocations: !showMyLocations });
                    }
                  }}
                />

              </CardVisibilityView>

            </CardVisibilityForm>

          </ContentPadder>
        )}



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default VisibilityModeleScreen;
