import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const ChooseBannerButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${Sizes.imageLogoSize}px;
  height: 30px;
  margin-top: 15px;
  background-color: ${Colors.primary};
  border-radius: 15px;
`;

export const ChooseBannerText = styled.p`
  color: white;
  font-size: ${Sizes.imageLogoSize * 0.105}px;
`;
