import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
}



export const SubToolbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 15px;

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column;
  }
`;

export const ButtonsSubtoolbarBreadcrumb = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;

  @media ${PlatformDevices.maxTabletM} {
    margin-bottom: 5px;
  }
`;

export const ButtonsSubtoolbarTitle = styled.p`
  margin-bottom: 3px;
  color: ${(props: IProps) => props.color || Colors.black};
  font-size: 16px;
  font-weight: bold;
`;


export const ButtonsSubtoolbarContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 10px;
  }
`;
