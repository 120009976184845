import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
}



export const FooterAppContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  margin-top: 10px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: ${Colors.cardBackground};
  border-top-color: ${Colors.white};
  border-top-style: solid;
  border-top-width: 1px;
  box-shadow: 0 -1px 0 3px rgba(0,0,0,.1);
`;


export const FooterLogoInserted = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 12px;
`;

export const FooterLogoImageButton = styled.div`
  width: 30px;
  height: 30px;
`;

export const FooterLogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const FooterTextYear = styled.p`
  color: ${Colors.grayDark};
  font-size: 15px;
  text-align: center;

  @media ${PlatformDevices.maxTabletM} {
    display: none;
  }
`;

export const FooterListInline = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;



export const FooterListButton = styled.a`
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
  text-decoration: none;
`;

export const FooterListTextButton = styled.p`
  color: ${Colors.grayDark};
  font-size: 13px;
  transition: all 0.35s;

  ${FooterListButton}:hover && {
    color: ${(props: IProps) => props.color ? props.color : Colors.secondary};
  }
`;
