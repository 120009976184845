import {
  EAppTranslations,
  EAppTutorialTranslations,
  EButtonsTranslations,
  ECompanyTypesTranslations,
  ECountriesTranslations,
  EDrinksTranslations,
  EEventsTranslations,
  EInterpolatesTranslations,
  EKitchensTranslations,
  ELabelsTranslations,
  ELanguagesTranslations,
  EMenuOrderTranslations,
  EMessagesEmptysTranslations,
  EMessagesErrosTranslations,
  EMessagesHelpsTranslations,
  EMessagesQuestionsTranslations,
  EMessagesSuccessTranslations,
  EMusicsTranslations,
  EPaymentsTranslations,
  EReservesTranslations,
  ESocialsTranslations,
  ETimeTranslations,
  ETitlesTranslations,
  ESubtitlesTranslations,
  EValidationsTranslations,
  ECarnivalTranslations,
} from './translates.keys';



export const APP: Record<EAppTranslations, string> = {
  NAME_APP: 'Secretio Support',
  SHORTNAME_APP: 'Support',
  SLOGAN_APP: 'O Support da Secretio!',
  COMPANY_NAME: 'Secretio',
  COMPANY_APP: 'Sabemos fazer direito!',
};


export const APP_TUTORIAL: Record<EAppTutorialTranslations, string> = {
  TUTORIAL_TITLE_1: 'Diversão',
  TUTORIAL_TEXT_1: 'A maneira mais fácil de ir a festas, baladas, pubs ou à bares favoritos com seus amigos!',
  TUTORIAL_TITLE_2: 'Praticidade',
  TUTORIAL_TEXT_2: 'A melhor ferramenta para encontrar onde será sua próxima Secretio!',
  TUTORIAL_TITLE_3: 'Preços',
  TUTORIAL_TEXT_3: 'Descontos e Promoções em toda a sua Secretio!',
  TUTORIAL_TITLE_4: 'Pronto para curtir a noite?',
};


export const BUTTONS: Record<EButtonsTranslations, string> = {
  ACTION_ACCESS: 'Entrar',
  ACTION_ACCESS_TO_CONTINUE: 'Entrar para continuar',
  ACTION_REGISTER: 'Registrar',
  ACTION_REGISTER_USER: 'Registrar',
  ACTION_REGISTER_COMPANY: 'Registrar empresa',
  ACTION_REGISTER_TO_CONTINUE: 'Fazer registro para continuar',
  ACTION_CANCEL: 'Cancelar',
  ACTION_CONFIRM: 'Confirmar',
  ACTION_FORWARD: 'Avançar',
  ACTION_BACK: 'Voltar',
  ACTION_CLOSE: 'Fechar',
  ACTION_ASK_CHECKIN: 'Pedir por Check-in',
  ACTION_CANCEL_CHECKIN: 'Cancelar Check-in',
  ACTION_CHECKIN_MANUAL: 'Check-in manual',
  ACTION_CLICK_OPEN: 'Clique para abrir',
  ACTION_CLICK_CLOSE: 'Clique para fechar',
  ACTION_CLICK_CANCEL: ' Clique para cancelar',
  ACTION_MORE_DETAILS: 'Clique para mais detalhes',
  ACTION_LISTS_SEE: 'Ver Listas',
  ACTION_SEE_MENUSHEET: 'Ver cardápio',
  ACTION_PAY: 'Pagar',
  ACTION_PAY_APP: 'Pagamento online',
  ACTION_PAY_PRESENTIAL: 'Pagamento presencial',
  ACTION_PAY_ORDERSHEET: 'Pagar comanda',
  ACTION_ADD_MORE_ITEMS: 'Adicionar mais itens',
  ACTION_BAG_SEE: 'Ver Sacola',
  ACTION_BAG_CLEAN: 'Esvaziar sacola',
  ACTION_FINISH_ORDER: 'Finalizar pedido',
  ACTION_CLOSE_ORDERSHEET: 'Fechar comanda',
  ACTION_SEE_WIFI: 'Ver Wi-Fi',
  ACTION_NO: 'Não',
  ACTION_YES: 'Sim',
  ACTION_SEE_HISTORY: 'Ver Histórico Completo',
  ACTION_HISTORY: 'Histórico Completo',
  ACTION_SAVE: 'Salvar',
  ACTION_OPEN_LIST: 'Clique para abrir a lista',
  ACTION_OPEN_GALLERY: 'Clique para ver a galeria',
  ACTION_TRANSFER_ADD: 'Adicionar conta para repasse',
  ACTION_COMPANY_ADD: 'Adicionar empresa',
  ACTION_USER_DISABLE: 'Desativar perfil',
  ACTION_RECOVER_ACCESS: 'Recuperar acesso',
  ACTION_CARD_ADD: 'Adicionar cartão',
  ACTION_ADDRESS_REGISTER: 'Cadastrar endereço',
  ACTION_CLICK_EDIT: 'Clique para editar',
  ACTION_LOCATION_ENABLED: 'Ativar localização',
  ACTION_INVITE_LINK: 'Adicionar por Link',
  ACTION_ADD_PERSON: 'Adicionar pessoa',
  ACTION_ADD_PEOPLE: 'Adicionar pessoas',
  ACTION_ADD_MANUAL: 'Adicionar manualmente',
  ACTION_LIST_PARTICIPATE: 'Participar da lista',
  ACTION_LIST_LEAVE: 'Sair da lista',
  ACTION_TICKET_SEE: 'Ver Ingressos',
  ACTION_COPY_PASSWORD: 'Clique para copiar a senha',
  ACTION_COPY_LINK: 'Copiar link',
  ACTION_SEE_DATES: 'Ver mais datas',
  ACTION_DO_LOGIN: 'Fazer login',
  ACTION_TO_RESERVE: 'Reservar',
  ACTION_CANCEL_RESERVE: 'Cancelar reserva',
  ACTION_SEARCH: 'Buscar',
  ACTION_RESEARCH: 'Refazer Buscar',
  ACTION_ADDRESS_SEE_YOURS: 'Clique para ver seus endereços',
  ACTION_ADDRESS_SEE: 'Ver endereços',
  ACTION_PAY_FORMS_SEE: 'Ver formas de pagamentos',
  ACTION_OPEN_WAZE: 'Abrir com o Waze',
  ACTION_OPEN_UBER: 'Abrir com o Uber',
  ACTION_HOUR_SEE: 'Clique para ver horários',
  ACTION_CLICK_TO_MANAGE: 'Clique para gerenciar',
  ACTION_LOCATION_MY: 'Usar minha Localização Atual',
  ACTION_PROFILE_SEE: 'Ver Perfil',
  ACTION_CLICK_TO_ADD: 'Clique para Adicionar',
  ACTION_CLICK_TO_EDIT: 'Clique para Alterar',
  ACTION_TO_SEE_PROFILE: 'Clique para ver seu perfil',
  ACTION_SKIP: 'Pular',
  ACTION_COME_ON: 'Vamos lá!',
  ACTION_PROFILE_MANAGE: 'Clique para solicitar esse perfil',
  ACTION_CHECK_ALL: 'Marcar tudo',
  ACTION_UNCHECK_ALL: 'Desmarcar tudo',
  ACTION_JOIN_VIA_LIST: 'Ingressar por Listas',
  ACTION_SEE_AVAILABLES_TICKETS: 'Ver ingressos disponíveis',
  ACTION_ENTRANCE_IN_EVENT: 'Participar do evento',
};


export const COMPANY_TYPES: Record<ECompanyTypesTranslations, string> = {
  EVENT_PRODUCER: 'Produtor de eventos',
  EVENT_PRODUCERS: 'Produtores de eventos',
  BAR: 'Bar',
  BARS: 'Bares',
  PUB: 'Pub',
  PUBS: 'Pubs',
  NIGHT_CLUB: 'Casa Noturna',
  NIGHT_CLUBS: 'Casas Noturnas',
  RESTAURANT: 'Restaurante',
  RESTAURANTS: 'Restaurantes',
  PIZZERIA: 'Pizzaria',
  PIZZERIAS: 'Pizzarias',
  STEAKHOUSE: 'Churrascaria',
  STEAKHOUSES: 'Churrascarias',
  HAMBURGUER: 'Hamburgueria',
  HAMBURGUERS: 'Hamburguerias',
  COFFEE_SHOP: 'Cafeteria',
  COFFEE_SHOPS: 'Cafeterias',
  CARNIVAL_PRODUCER: 'Produtor de carnavais',
  CARNIVAL_PRODUCERS: 'Produtorer de carnavais',
  PARTY_PRODUCER: 'Produtor de festivais',
  PARTY_PRODUCERS: 'Produtores de festivais',
  GOVERN: 'Governo',
  GOVERNS: 'Governos',
};


export const CARNIVALS: Record<ECarnivalTranslations, string> = {
  CARNIVAL_VOTES: 'Votações do carnaval',
  CARNIVAL_INTEREST_POINTS: 'Pontos de Interesses',
  CARNIVAL_OFFICIAL: 'Carnaval oficial',
  CARNIVAL_MICARETA: 'Micareta',
  CIRCUIT_OFFICIAL: 'Circuito oficial',
  BLOC: 'Bloco',
  CIRCUIT: 'Circuito',
  CIRCUITS: 'Circuitos',
  CABIN: 'Camarote',
  CABINS: 'Camarotes',
  POLICE: 'Polícia',
  BUS: 'Ônibus',
  TAXI: 'Táxi',
  BATHROOM: 'Banheiro',
  FIREMAN: 'Bombeiro',
  SAVE_LIFE: 'Salva-vidas',
  MEDICAL_POINT: 'Ponto médico',
  JUVENILE: 'Orgão Juvenil',
  OTHER_ATTRACTION: 'Outras atrações',
  AGENCY_FLOW: 'Agentes de fluxo',
  AGENCY_PUBLIC: 'Órgãos públicos',
  CLEAN_HEALTH: 'Saúde e limpeza',
  AREA_RESERVED: 'Áreas reservadas',
};


export const COUNTRIES: Record<ECountriesTranslations, string> = {
  BRAZIL: 'Brasil',
  PORTUGAL: 'Portugal',
  UNITED_STATES: 'Estados Unidos',
  SPAIN: 'Espanha',
  ITALY: 'Itália',
  FANCE: 'França',
};


export const DRINKS: Record<EDrinksTranslations, string> = {
  WATER: 'Água',
  SODA: 'Refrigerante',
  VODKA: 'Vodka',
  BEER: 'Cerveja',
  WINE: 'Vinho',
  TEA: 'Chá',
  COFFEE: 'Café',
  JUICE: 'Suco',
  WHISKY: 'Whisky',
  LIQUOR: 'Licor',
  COCKTAIL: 'Coquetel',
  CHAMPAGNE: 'Champagne',
  GIN: 'Gin',
  RUM: 'Rum',
  ENERGY_DRINK: 'Energético',
  SMOOTHIE: 'Smoothie',
  YERBA_MATE: 'Mate',
  SAKE: 'Sake',
  TEQUILA: 'Tequila',
};


export const EVENTS: Record<EEventsTranslations, string> = {
  EVENT_TYPE: 'Tipo do evento',
  EVENT_IDEAL_LGBT: 'Ideal para o público LGBTQIA+',
  EVENT_ABOUT: 'Sobre o evento',
  EVENT_ATTRACTIONS: 'Atrações',
  EVENT_START: 'Início do evento',
  EVENT_END: 'Final do evento',
  EVENT_OPENBAR: 'Bebidas no OpenBar',
  EVENT_PARENTAL_RATING: 'Classificação indicativa',
  EVENT_PARENTAL_NONE: 'Classificação livre',
  EVENT_PARENTAL_14: 'Para maiores de 14 anos',
  EVENT_PARENTAL_16: 'Para maiores de 16 anos',
  EVENT_PARENTAL_18: 'Para maiores de 18 anos',
  EVENT_LIST_AVAILABLES: 'Há listas públicas disponíveis',
  ID_TICKET: 'Ingresso ID',
  TICKET: 'Ingresso',
  TICKETS: 'Ingressos',
  TICKETS_MY: 'Meus Ingressos',
  TICKETS_AVAILABLE: 'Há ingressos disponíveis',
  EVENT_FREE: 'Evento Gratuito',
  EVENT_DATE: 'Data do evento',
  EVENT_DATE_INITIAL: 'Início do evento',
  EVENT_DATE_FINAL: 'Final do evento',
  TICKET_ENTRANCE_PRICE: 'Valores de ingressos e entradas',
  PARTY: 'Festa',
  NIGHT_PARTY: 'Balada',
  NOT_CONFIRMED: 'Não Confirmado',
};


export const INTERPOLATES: Record<EInterpolatesTranslations, string> = {
  HELLO: 'Olá',
  PERSON_TO: 'Serve',
  CODE_ABREV: 'Cód.:',
  FROM: 'De',
  TO: 'Até',
  PAY_PRESENTIAL: 'Pagar presencial',
  PAY_BY_APP: 'Pagar pelo Secretio',
  PAY_TO: 'Pagar ao',
  TO_DO: 'A fazer',
  HAVE_WIFI: 'Aqui tem Wi-Fi',
  INTERPOLATE_ORDERSHEET_CLOSING: 'Aguardando o parceiro {company_name} finalizar a comanda',
  INTERPOLATE_ORDERSHEET_CLOSE: 'Clique em "Pagar comanda" para pagar direto pelo Secretio ou para {company_name}',
  INTERPOLATE_ORDERSHEET_FINISH: 'Faça o pagamento ao {company_name} para finalizar a comanda',
  AT: 'às',
  OR: 'ou',
  AND: 'e',
  THERE_IS: 'Há',
  LAST_ORDER: 'Último Pedido',
  LAST_ORDER_THERE_IS: 'Último Pedido há',
  ACCESS_USER_REGISTER: 'Ao se registrar no Secretio, você aceita os',
  ACCESS_USER_TERMS_USE: 'Termos de Uso',
  ACCESS_USER_TERMS_PRIVACY: 'Políticas de Privacidade',
  INTERPOLATE_LIST_LIMIT: 'Limite de {listAdditionalNumber} convidados atingido!',
  INTERPOLATE_LIST_UNTIL_LIMIT: 'Até {listAdditionalNumber} pessoa(s) na lista por convidado',
  PRICE_BETWEEN_MIN_MAX: 'Entre {minPrice} e {maxPrice}',
  PRICE_INSTALLMENT_VALUE: 'Em até {installment} vezes no cartão',
  RESERVE_PEOPLE_LIMIT: 'Até {limitReserve} pessoa(s) por reserva',
  RESERVE_TABLE_AVAILABLE: 'Mesas disponíveis',
  RESERVE_PRICE_PAY: 'Valor de {price} para a reserva',
  PAGE_VIEWS: 'Visualizações desta página',
};


export const KITCHENS: Record<EKitchensTranslations, string> = {
  AFRICAN: 'Africana',
  GERMAN: 'Alemã',
  ARABIC: 'Árabe',
  ARGENTINA: 'Argentina',
  DRINKS: 'Drinks',
  BRAZILIAN: 'Brasileira',
  COFFEE: 'Cafeteria',
  MEATS: 'Carnes',
  CHINESE: 'Chinesa',
  COLOMBIAN: 'Colombiana',
  KOREAN: 'Coreana',
  SPANISH: 'Espanhola',
  FRENCH: 'Francesa',
  SEAFOOD: 'Frutos do mar',
  INDIAN: 'Indiana',
  ITALIAN: 'Italiana',
  JAPANESE: 'Japonesa',
  MEDITERRANEAN: 'Mediterrânea',
  MEXICAN: 'Mexicana',
  FRIED_PASTRY: 'Pastel',
  FISH: 'Peixes',
  PERUVIAN: 'Peruana',
  PIZZA: 'Pizza',
  PORTUGUESE: 'Portuguesa',
  HEALTHY: 'Saudável',
  JUICES: 'Sucos',
  THAI: 'Tailandesa',
  VEGETARIAN: 'Vegetariana',
};


export const LABELS: Record<ELabelsTranslations, string> = {
  ALL: 'Tudo',
  COMPANY: 'Empresa',
  COMPANIES: 'Empresas',
  COMPANIES_MY: 'Minhas empresas',
  PROFILE: 'Perfil',
  PROFILES: 'Perfis',
  PROFILES_MY: 'Meus perfis',
  PERSONAL_DATA: 'Dados pessoais',
  PERSONAL_INFORMATIONS: 'Informações pessoais',
  COUNTRY: 'País',
  FANTASY_NAME: 'Nome fantasia (Nome público)',
  SOCIAL_REASON: 'Razão Social',
  LOADING: 'Carregando',
  USER: 'Usuário',
  NAME: 'Nome',
  NAME_FULL: 'Nome completo',
  DATE_BORN: 'Data de nascimento',
  GENRE: 'Gênero',
  DDI_NUMBER_FIRST: 'DDI do telefone principal',
  DDI_NUMBER_SECOND: 'DDI do telefone secundário',
  DDI: 'DDI',
  PERSONS: 'Pessoa (s)',
  USERNAME: 'Nome de usuário',
  USERNAME_COMPANY: 'Nome de usuário da empresa',
  EMAIL: 'E-mail',
  PASSWORD: 'Senha',
  PASSWORD_CURRENT: 'Senha atual',
  PASSWORD_NEW: 'Nova senha',
  PASSWORD_REPEAT: 'Repita a nova senha',
  PASSWORD_CONFIRM: 'Confirme a senha',
  CNPJ: 'CNPJ',
  CPF: 'CPF',
  FORMS: 'Formas',
  COMMENTS: 'Comentários',
  OPTIONAL: 'Opcional',
  PUBLIC_ID: 'Id público',
  PUBLIC_PARTNER_ID: 'Id público do parceiro',
  NUMBER_PRIMARY: 'Número principal',
  NUMBER_SECONDARY: 'Número secundário',
  PARTNER_ABOUT: 'Sobre o parceiro',
  PARTNER_SERVICES: 'Serviços disponíveis',
  PARTNER_PHONES: 'Telefones',
  PHONE_ONLY_ONE: 'Número com DDD',
  PHONE_FIRST: 'Telefone principal com DDD',
  PHONE_SECOND: 'Telefone secundário com DDD',
  PARTNER_RULES: 'Regras',
  PARTNER_TYPES_KITCHEN: 'Tipos de cozinha',
  PARTNER_OPEN_HOURS: 'Horário de funcionamento',
  WIFI: 'Wi-Fi',
  KIDS_AREA: 'Área Kids',
  PARKING: 'Estacionamento',
  SMOKING: 'Área de Fumantes',
  AIR_CONDITIONER: 'Ar Condicionado',
  ACCESSIBILITY: 'Acessibilidade',
  MORE_POPULAR: 'Mais populares',
  NUMBER_OR_NAME: 'Nome ou número',
  INPUT_MODAL_ADD_USER_PLACEHOLDER: 'Pesquisar nome...',
  CONFIRM_CPF: 'Confirmar CPF do titular',
  CONFIRM_CNPJ: 'Confirmar CNPJ da empresa',
  CONFIRM_INFO: 'Confirmar informação',
  NICKNAME: 'Apelido',
  DESCRIPTION: 'Descrição',
  FAVORED: 'Favorecido(a)',
  ACCOUNT_USE_MAIN: 'Usar esta conta como atual',
  ADDRESS_USE_MAIN: 'Usar este endereço como atual',
  INPUT_MODAL_ADD_USER_CARGO: 'Email ou nome de usuário',
  CODE: 'Código',
  DIGIT: 'Dígito',
  ACCOUNT: 'Conta',
  ID: 'ID',
  ID_PRIVATE: 'ID Privado',
  ID_PUBLIC: 'ID Público',
  COMPANY_STATUS_APP: 'Situação no Secretio',
  COMPANY_STATUS_ACTIVE: 'Empresa ativa',
  COMPANY_STATUS_INACTIVE: 'Empresa desabilitada',
  PLACE_NAME: 'Nome do local',
  ZIP_CODE: 'CEP',
  STREET: 'Rua', // 'Avenida / Rua',
  STREETS: 'Ruas',
  DISTRICT: 'Bairro',
  DISTRICTS: 'Bairros',
  ATTRACTIONS: 'Atrações',
  COMPLEMENT: 'Complemento',
  CITY: 'Cidade',
  CITIES: 'Cidades',
  STATE: 'Estado',
  SOCIAL: 'Social',
  SLOGAN: 'Slogan',
  CELLPHONE: 'Celular',
  ABOUT: 'Sobre',
  ABOUT_APP: 'Sobre o Secretio',
  PROFILE_TYPE: 'Tipo de perfil',
  PARTNER_TYPE: 'Tipo do parceiro',
  INFORMATIONS: 'Informações',
  CARD_HOLDER_NAME: 'Nome do Titular',
  CARD_NICKNAME: 'Apelido do Cartão',
  MALE: 'Masculino',
  FEMALE: 'Feminino',
  SOCIAL_NAME: 'Nome social',
  ABOUT_ME: 'Sobre mim',
  LEVEL: 'Nível',
  BIRTHDAY: 'Aniversário',
  PICTURE: 'Foto',
  USER_PRE_REGISTER: 'Usuário pré-registrado',
  DATAS: 'Dados',
  SHOPS: 'Compras',
  OPTIONS: 'Opções',
  ME_AND_GUESTS: 'Eu e convidados',
  ONLY_YOU: 'Apenas você',
  ONLY_FOR_ME: 'Apenas para mim',
  PEOPLE: 'pessoas',
  RESERVE_DATE: 'Data da reserva',
  DATE_SELECTED: 'Data selecionada',
  ENVIRONMENT: 'Ambiente',
  PEOPLE_NUMBER: 'Número de pessoas',
  MORE_INFORMATIONS: 'Mais Informações',
  OBSERVATIONS: 'Observações',
  INITIAL_DATE: 'Data do início',
  INITIAL_HOUR: 'Hora do início',
  FINAL_DATE: 'Data do final',
  FINAL_HOUR: 'Hora do final',
  INITIAL_VALUE: 'Valor inicial',
  FINAL_VALUE: 'Valor final',
  PARAMETERS: 'Parâmetros',
  NAME_OR_USERNAME: 'Nome ou Nome de usuário',
  MODALITIES: 'Modalidades',
  VERSION: 'Versão',
  MY_ACCOUNT: 'Minha conta',
  ACCOUNT_SEE: 'Ver conta',
  ACCOUNT_ACTIONS: 'Ações da conta',
  PAYMENTS_FORMS: 'Formas de Pagamento',
  LOCATION: 'Localização',
  LOCATION_NO_ADDRESS: 'Sem Informações sobre o Endereço',
  TICKETS_SALES_UNTIL: 'Vendas até',
  PARTICIPATION: 'Participação',
  WAIT_YOU: 'Espera por você',
  ITEMS: 'Itens',
  HELP: 'Ajuda',
  TYPE: 'Tipo',
  CANCELED: 'Cancelado',
  FINISHED: 'Finalizado',
  OTHER: 'Outro',
  ADD: 'Adicionar',
  ACTUAL: 'Atual',
  COUNTRIES: 'Países',
  NO: 'Não',
  YES: 'Sim',
  LIST_NAME: 'Nome da lista',
  GUIDES_INFORMATIONS: 'Guias e informações',
  PHONES_USEFUL: 'Telefones úteis',
  CASADINHA: 'Casadinha',
  OPEN_BAR: 'Open Bar',
  LIST: 'Lista',
  VIP: 'VIP',
};


export const LANGUAGES: Record<ELanguagesTranslations, string> = {
  PT_BR: 'Português (Brasil)',
  EN_US: 'Inglês (USA)',
  ES_AR: 'Espanhol (Espanha)',
  IT_IT: 'Italiano (Itália)',
  FR_FR: 'Francês (França)',
};


export const MENU_ORDER: Record<EMenuOrderTranslations, string> = {
  COMBO: 'Combo',
  ITEM_DOUBLE: 'Item dobrado',
  AVAILABLES: 'Disponíveis',
  TABLE: 'Mesa',
  NUMBER: 'Número',
  NUMBER_TABLE_ENTRANCE: 'Número da mesa / entrada',
  TABLE_ENTRANCE: 'Mesa / entrada',
  MIN_ORDERSHEET: 'Pedido mínimo',
  WAITER_CALL: 'Chamar o garçom',
  WAITER_CALLING: 'Chamando garçom',
  WAITER_GOING: 'Garçom a caminho',
  ORDERSHEET_CLOSING_ORDERSHEET: 'Fechando comanda',
  ORDERSHEET_CLOSED: 'Comanda fechada',
  ORDERSHEET_OPENED: 'Comanda aberta',
  TOTAL_CONSUMPTION: 'Consumo total',
  ALCOHOLIC: 'Alcoólico',
  SPICY: 'Picante',
  VEGETARIAN: 'Vegetariano',
  VEGAN: 'Vegano',
  ORGANIC: 'Orgânico',
  NO_LACTOSE: 'Sem lactose',
  NO_GLUTEN: 'Sem glutem',
  REQUESTS: 'Pedidos',
  REQUESTS_MINIMAL: 'Pedidos minimos',
  REQUESTS_UNTIL: 'Pedidos Até',
  DELIVERY: 'Delivery',
  REMOVAL_ITEM: 'Retirada',
  PRESENTIAL: 'Presencial',
  ORDER_PLACED: 'Pedido realizado',
  ORDER_PREPARING: 'Preparando',
  ORDER_TO_DELIVER: 'A Entregar',
  ORDER_REFUSED: 'Pedido Recusado',
};


export const MESSAGES_EMPTYS: Record<EMessagesEmptysTranslations, string> = {
  EMPTY_GALLERY_PHOTOS: 'Não há imagens aqui, ainda!',
  EMPTY_COMPANY_NOT_FOUND: 'Desculpe, perfil não encontrado!',
  EMPTY_COMPANY_EVENTS: 'Não há eventos aqui, ainda!',
  EMPTY_EVENTS_NOT_FOUND: 'Desculpe, evento não disponível!',
  EMPTY_HIGHLIGHTS_ITEMS: 'Desculpe, nenhum destaque encontrado hoje!',
  EMPTY_HIGHLIGHTS_EVENTS: 'Não há eventos aqui, ainda!',
  EMPTY_HIGHLIGHTS_PARTNERS: 'Nenhum Parceiro está no Secretio aqui!',
  EMPTY_CATEGORIES: 'Sem categoria',
  EMPTY_ITEMS: 'Não há itens aqui, ainda!',
  EMPTY_MENUSHEET_CATEGORIES: 'Desculpe, sem itens nesta categoria!',
  EMPTY_NOTIFICATIONS: 'Não há notificações aqui, ainda!',
  EMPTY_ORDERSHEET_HISTORY: 'Sem itens no histórico, ainda!',
  EMPTY_BANKS: 'Sem bancos aqui, desculpe!',
  EMPTY_COMPANIES_REGISTERED: 'Não há empresas cadastradas!',
  EMPTY_HOME: 'Sem dados a mostrar!',
  EMPTY_FAVORITES: 'Não há favoritos aqui!',
  EMPTY_PAYMENT: 'Não há formas de pagamento cadastrado!',
  EMPTY_ADDRESS: 'Sem endereços cadastradas!',
  EMPTY_USER_DATA: 'Usuário sem dados',
  EMPTY_USER_NO_RECOG: 'Usuário não identificado',
  EMPTY_LISTS: 'Sem listas cadastradas',
  EMPTY_LISTS_PARTICIPATE: 'Nenhuma lista para participar, ainda!',
  EMPTY_LISTS_FINISHED: 'Nenhuma lista que você participou, ainda!',
  EMPTY_TICKET: 'Não há ingressos ainda!',
  EMPTY_TICKET_IN_EVENT: 'Não há ingressos neste evento',
  EMPTY_EVENT_EXPLORE: 'Explore outros eventos como esse',
  EMPTY_CARD_REGISTERED: 'Sem cartão cadastrado',
  EMPTY_CARD_NO_ADDED: 'Você ainda não adicionou um cartão a sua conta',
  EMPTY_RESERVE_REGISTERED: 'Sem reservas disponíveis ou cadastradas!',
  EMPTY_RESERVE_USER: 'Sem reservas ainda para curtir a noite!',
  EMPTY_LINK_SEE: 'Sem link para visualizar',
  EMPTY_SEARCH: 'Desculpe, nada encontrado na busca!',
  EMPTY_ORDERS: 'Sem Pedidos',
  EMPTY_EVENT_FINISHED: 'O evento já encerrou!',
  EMPTY_PLACES: 'Nenhum lugar cadastrado, ainda!',
  EMPTY_CARNIVAL_ATTRACTIONS: 'Desculpe, nenhuma atração hoje!',
  EMPTY_CARNIVAL_NONE: 'Desculpe, não há carnaval nem micaretas, ainda!',
  EMPTY_CARNIVAL_DISTRICTS: 'Desculpe, sem festa nos bairros hoje!',
  EMPTY_CARNIVAL_CABINS: 'Desculpe, os camarotes estão fechados hoje!',
  EMPTY_CARNIVAL_LOCATIONS: 'Desculpe, nenhuma localização aqui!',
  EMPTY_CARNIVAL_STREETS: 'Desculpe, sem festa na rua hoje!',
  EMPTY_CARNIVAL_INFOS: 'Desculpe, sem nenhum guia aqui!',
  EMPTY_PHONES: 'Telefone não disponível',
  EMPTY_CARNIVAL_VOTES: 'Desculpe, sem votações, ainda!',
  EMPTY_LANGUAGES: 'Nenhuma linguagem, ainda!',
  EMPTY_LIST_AVAILABLE: 'Não há listas disponíveis',
  EMPTY_TICKET_AVAILABLE: 'Não há ingressos disponíveis',
};


export const MESSAGES_ERRORS: Record<EMessagesErrosTranslations, string> = {
  ERROR: 'Erro',
  ERROR_PAY: 'Erro ao pagar',
  ERROR_PAY_APP: 'Tivemos um problema, faça o pagamento no estabelecimento',
  ERROR_CHECKIN_ASK: 'Não foi possível solicitar o Check-in',
  ERROR_CHECKIN_CANCEL: 'Não foi possível cancelar o Check-in',
  ERROR_HAD: 'Houve um erro',
  ERROR_SEARCH: 'Erro ao buscar dados',
  TRY_AGAIN: 'Erro, tente novamente',
  ACCESS_DENIED: 'Não existe permissão de acesso para esse usuário.',
  PASSWORD_WRONG: 'Usuário e/ou senha inválidos.',
  ERROR_PARAMS_INVALID: 'Parametros inválidos',
  ERROR_COMPANY_CREATE: 'Erro ao criar empresa',
  ERROR_USER_CREATE: 'Erro ao criar perfil',
  ERROR_DATE: 'Erro na data',
  ERROR_NO_DATA_FOUND: 'Dados não encontrados, verifique as informações e tente novamente.',
  ERROR_EMPTY_FIELDS: 'Preencha todos os campos corretamente antes de continuar',
  ERROR_ACCOUNT_TRANSFER: 'Erro ao criar conta de repasse',
  ERROR_ACCOUNT_SEARCH: 'Erro ao buscar contas de repasse',
  ERROR_ACCOUNT_MAIN_CHANGE: 'Houve um erro ao mudar conta',
  ERROR_ACCOUNT_DELETE: 'Houve um erro ao deletar conta',
  ERROR_SAVE: 'Erro ao salvar dados!',
  ERROR_CREATE: 'Erro ao criar',
  ERROR_UPDATE: 'Erro ao atualizar',
  ERROR_USER_DISABLE: 'Erro ao desabilitar usuário.',
  ERROR_COMPANY_DISABLE: 'Erro ao desabilitar empresa.',
  ERROR_MAKE_REGISTER: 'Erro ao fazer o cadastro',
  ERROR_PAYMENT_MAIN_CHOOSED: 'Houve um erro ao escolher o pagamento principal',
  ERROR_PAYMENT_DELETE: 'Houve um erro ao deletar o pagamento',
  ERROR_ADDRESS_DELETE: 'Houve um erro ao deletar sucesso',
  ERROR_LIST_USER_ADDED: 'Erro ao adicionar usuários',
  ERROR_LIST_ENTER: 'Erro ao tentar ingressar na lista',
  ERROR_LIST_GET_DATA: 'Erro ao obter listas',
  ERROR_GET_DATA: 'Erro ao obter dados',
  ERROR_TICKET_UNAVAILABLES: 'Ingressos indisponíveis',
  ERROR_LISTS_UNAVAILABLES: 'Listas indisponíveis',
  ERROR_USER_DOESNOT_EXIST: 'Usuário não existe!',
  ERROR_USER_IN_LIST: 'Usuário já está na lista',
  ERROR_FAVORITE_ADD: 'Erro ao adicionar aos favoritos',
  ERROR_FAVORITE_REMOVE: 'Erro ao remover aos favoritos',
};


export const MESSAGES_HELP: Record<EMessagesHelpsTranslations, string> = {
  HELP_INPUT_COMPANY: 'Insira os dados da empresa',
  HELP_INPUT_USER: 'Insira as informações abaixo',
  HELP_INPUT_REQUIRED: 'Campos obrigatórios',
  HELP_QR: 'Leia o QR Code do parceiro para fazer Check-in e evitar filas e agilizar suas entradas a lugares e eventos!',
  HELP_CHECKIN: 'Para fazer Check-in é só inserir o ID Público do Parceiro, o Número da mesa por exemplo e esperar a confirmação do seu Check-in.',
  HELP_CHECKIN_WAIT: 'Aguarde a confirmação do Check-in. Caso queira, você pode cancelar o pedido de Check-in a qualquer momento.',
  HELP_EVENT_RATING: 'É de responsabilidade dos idealizadores e produtores deste evento a sua classificação e as suas informações.',
  HELP_EVENT_ALCOHOL: 'A venda de bebidas alcoólicas é proibida para menores de 18 anos de idade.',
  HELP_PAY_UNAVAILABLE: 'Pagamento online indisponível. Não há cartões cadastrados',
  HELP_SUCCESS_ORDER_DONE: 'Você pode acompanhar o estado dos itens direto no histórico da comanda.',
  HELP_CHEKIN_HOW_TO: 'Na porta do local ou da entrada do evento mostre esse Qr Code para validar ingressos, listas, reservas e pedidos de entrada.',
  HELP_ORDERSHEET_CLOSING: 'Aguardando fechamento',
  HELP_ORDERSHEET_THANKS: 'Obrigado por utilizar o Secretio',
  HELP_ORDERSHEET_PARTNER_CLOSE: 'Sua comanda foi encerrada pelo parceiro',
  HELP_ORDERSHEET_RECUSED: 'Pedido de comanda recusado',
  HELP_ORDERSHEET_NOT_FOUND: 'Check-in não aceito ou não encontrado',
  HELP_SOME: 'Alguma dúvida?',
  HELP_TALK_TO_US: 'Fale com o Secretio',
  HELP_ACCOUNT_TRANSFER: 'Para que o repasse seja realizado, a conta bancária a ser inserida deve pertencer a pessoa cadastrada ou a empresa.',
  HELP_ACCOUNT_BANK_RECEIVE: 'O banco recebedor pode optar em qual modalidade creditar o repasse caso suas contas poupança e corrente tenham o mesmo número.',
  HELP_ACCOUNT_LIST_TRANSFER: 'Cadastre uma conta bancária e transfira seu dinheiro para ela.',
  HELP_ACCOUNT_LIST_TRANSFER_TEXT: 'Para transferir valores para sua conta bancária, é necessário cadastrar uma conta bancária de sua titularidade ou da empresa.',
  HELP_PROFILE_PICTURE: 'Uma foto ajuda as pessoas a reconhecerem você e permite que você saiba qual conta está conectada.',
  HELP_LOGOTIPO_COMPANY_TEXT: 'Um logotipo ajuda a personalizar o perfil e permite que ele seja encontrado mais rápido.',
  HELP_LOGOTIPO_COMPANY_DIMENSIONS: 'Recomendamos uma imagem quadrada (1x1), de tamanho aproximadamente de 400x400.',
  HELP_COMPANY_COMPLETE_PROFILE: 'Complete o perfil da sua empresa para utilizar os serviços do Secretio',
  HELP_USER_DISABLE: 'Se você deseja desativar a sua conta, cuidaremos disso para você. Gostamos de avisar a nossos usuários que, mesmo que a conta seja desativada, por questões de segurança, você não conseguirá criar outra conta em nossa plataforma, já que só pode haver uma conta por CPF.',
  HELP_USER_DISABLE_BACK: 'Você pode voltar a qualquer momento acessando sua conta com a senha já cadastrada, e se caso esquecer, pode redefini-la.',
  HELP_COMPANY_DISABLE_BACK: 'Para ativar novamente essa empresa, você deve entrar em contato com o suporte e pedir a ativação.',
  HELP_COMPANY_DISABLE_PLAN: 'Caso esta empresa tenha algum plano, também sera cancelado!',
  HELP_WELCOME_TITLE: 'Bem-vindo a sua Conta',
  HELP_WELCOME_DESCRIPTION: 'Gerencie suas informações, privacidade e segurança para que o Secretio atenda suas necessidades.',
  HELP_PASSWORD_NEW: 'Insira e confirme sua nova senha, é a partir dela que você poderá acessar o Secretio',
  HELP_PASSWORD_OLD: 'Insira sua senha atual para que possamos confirmar que é você, logo depois insira e confirme sua nova senha',
  HELP_PASSWORD_REMENBER: 'Caso não lembre sua senha atual você precisará',
  HELP_PASSWORD_REDEFINE: 'redefini-la',
  HELP_PASSWORD_FORGOT: 'Caso você tenha esquecido a senha, digite abaixo o E-mail que voce utilizou para se cadastrar no Secretio.',
  HELP_INVITE_PEOPLE: 'Você pode convidar mais pessoas agora ou depois.',
  HELP_INVITE_LINK: 'Compartilhe esse link com seus convidados para eles adicionarem o nome deles nessa lista.',
  HELP_LIST_HOW_INVITE: 'Insira o Nome de Usuário do seu convidado ou caso a pessoa não esteja no Secretioa, insira o CPF dela.',
  HELP_LIST_HOW_FINISH: 'Ao adicionar uma pessoa, automaticamente sua participação está confirmada ao finalizar!',
  HELP_WIFI_NO_REGISTER: 'Sem Wi-fi cadastrado, você deve perguntar para saber se o Wi-fi está disponível.',
  HELP_RESERVE_MINUTES: 'Após 30 minutos do horário reservado, caso não haja ninguém para validar a reserva, a reserva será finalizada e a mesa liberada.',
  HELP_RESERVE_AGE: 'A idade Mínima pagante é 4 anos.',
  HELP_RESERVE_TABLE: 'A opção de juntar mesas fica a cargo do Parceiro.',
  HELP_RESERVE_TAXES: 'Possíveis taxas como por exemplo estacionamento ou espaço infantil não estão incluidos no preço da reserva.',
  HELP_RESERVE_CANCEL: 'Se ninguém puder comparecer, cancele a reserva com antecedência. O cancelamento pode ser feito até 3 horas antes do horário reservado, após esse horário o cancelamento não poderá ser mais feito.',
  HELP_MINIMAL_AGE: 'Com no mínimo 4 anos',
  HELP_RESERVE_OBS_EXAMPLE: 'Exemplo: Aniversário, Comemoração',
  HELP_RESERVE_QUANTITY_PARTNER: 'A quantidade de pessoas na reserva será a quantidade aceita, fica a cargo do parceiro identificar e contar a quantidade de pessoas para essa reserva e se irá aceitar mais que na reserva',
  HELP_SEARCH_BY_NAME: 'Busque por nome ou nome de usuário',
  HELP_SEARCH_PARAMETERS: 'Busque por parâmetros',
  HELP_LOGIN_TO_SEE: 'Entre para ver suas informações',
  HELP_INSERT_USER: 'Insira o usuário',
  HELP_USER_HAVENT_DATA: 'Não tenho nenhuma informação',
  HELP_COMPANY_QUANTITY_ACCEPT: 'A aceitação da quantidade de pessoas fica a cargo do parceiro. Ele é responsável de identificar e contar a quantidade de pessoas participantes e se irá aceitar mais pessoa que o descrito.',
  HELP_PROFILE_VERIFY_UNMANAGE: 'Perfil não gerenciado. Fique atento e verifique sempre no local as informações e os valores pois podem estar desatualizados.',
  HELP_PROFILE_UNMANAGE: 'O perfil deste local ainda não foi solicitado. Os proprietários que solicitam o perfil podem atualizar as informações, fazer vendas, criar reservas, adicionar fotos e muito mais.',
  HELP_PROFILE_MANAGE: 'Uma pessoa ligada a este local gerencia este perfil.',
  HELP_ORDERSHEET_DETAILS_ENABLED: 'Você pode usar a consumação do Secretio para comprar e pagar nesse evento.',
  HELP_ORDERSHEET_DETAILS_DISABLED: 'A consumação está desativado pelo produtor, ao chegar no local você deve perguntar como comprar e pagar sua comanda.',
};


export const MESSAGES_QUESTIONS: Record<EMessagesQuestionsTranslations, string> = {
  ANY_QUESTION: 'Qualquer dúvida',
  ANY_QUESTION_CONTACT: 'contate-nos',
  PAY_ATTENTION: 'Fique Atento',
  QUESTION_DELETE_CARD: 'Deletar cartão?',
  QUESTION_DELETE_CARD_TEXT: 'Você gostaria de deletar esse cartão?',
  QUESTION_ORDERSHEET_CLOSE: 'Fechar comanda?',
  QUESTION_ADD_CREDIT_CARD: 'Nenhum cartão de crédito na sua conta, deseja adicionar ?',
  QUESTION_ACCOUNT_MAIN_CHANGE: 'Mudar conta bancária principal?',
  QUESTION_ACCOUNT_MAIN_CHANGE_TEXT: 'Você gostaria de colocar essa conta como principal?',
  QUESTION_ACCOUNT_DELETE: 'Deletar conta bancária?',
  QUESTION_ACCOUNT_DELETE_TEXT: 'Você gostaria de deletar essa conta bancária?',
  QUESTION_USER_DISABLE: 'Tem certeza que deseja desativar sua conta?',
  QUESTION_COMPANY_DISABLE: 'Tem certeza que deseja desativar essa empresa?',
  QUESTION_ADDRESS_REGISTER_TEXT: 'Para adicionar um método de pagamento é necessário cadastrar um endereço principal. Fazer isso agora?',
  QUESTION_ADDRESS_DELETE: 'Deletar endereço?',
  QUESTION_ADDRESS_DELETE_TEXT: 'Você gostaria de deletar esse item?',
  QUESTION_BAG_ITEM_REMOVE: 'Deseja remover {itemSaleName} do seu carrinho?',
  QUESTION_BAG_CLEAR: 'Esvaziar a sacola?',
  QUESTION_EXIT: 'Deseja sair',
  QUESTION_FAVORITE_REMOVE: 'Remover favorito?',
};


export const MESSAGES_SUCCESS: Record<EMessagesSuccessTranslations, string> = {
  SUCCESS: 'Sucesso',
  SUCCESS_CHECKIN_ASK: 'Check-in confirmado. Aproveite!',
  SUCCESS_CHECKIN_CANCEL: 'Pedido de checkin cancelado!',
  SUCCESS_ADD_ITEM_BAG: 'Item adicionado com sucesso!',
  SUCCESS_ORDER_DONE: 'Pedido Feito!',
  SUCCESS_PAYED: 'Pago!',
  SUCEESS_COMPANY_CREATE: 'Empresa criada com sucesso!',
  SUCEESS_COMPANY_CREATE_TEXT: 'Agora você deve esperar nossa avaliação dos dados.',
  SUCEESS_USER_CREATE: 'Perfil criado com sucesso!',
  SUCEESS_USER_CREATE_TEXT: 'Enviamos um e-mail de confirmação para {user_email}. Abra o e-mail, confirme a criação do perfil e você poderá entrar usando suas credenciais cadastradas. Seu número de cliente também está no e-mail',
  SUCCESS_ACCOUNT_TRANSFER: 'Conta de repasse criada com sucesso',
  SUCCESS_ACCOUNT_MAIN_CHANGE: 'Nova conta principal mudada',
  SUCCESS_ACCOUNT_DELETE: 'Conta deletada com sucesso',
  SUCCESS_CREATE: 'Criado com sucesso',
  SUCCESS_UPDATE: 'Atualização realizada com sucesso',
  SUCCESS_USER_DISABLE: 'Usuário desativado com sucesso',
  SUCCESS_COMPANY_DISABLE: 'Empresa desabilitada com sucesso',
  SUCCESS_PASSWORD_RECOVER: 'Pedido de recuperar senha feito com sucesso!',
  SUCCESS_PASSWORD_RECOVER_TEXT: 'Enviamos um e-mail de confirmação para {user_email}. Abra o e-mail, clique no link e você poderá mudar sua senha.',
  SUCCESS_CARD_REGISTER: 'Cartão cadastrado com sucesso',
  SUCCESS_PAYMENT_MAIN_CHOOSED: 'Pagamento principal escolhido',
  SUCCESS_PAYMENT_DELETE: 'Pagamento deletado com sucesso',
  SUCCESS_ADDRESS_DELETE: 'Endereço deletado com sucesso',
  SUCCESS_EMAIL_CHANGE: 'Email de troca enviado com sucesso',
  SUCCESS_LIST_USER_ADDED: 'Usuários adicionado com sucesso',
  SUCCESS_PASSWORD_COPY: 'Senha copiada',
  SUCCESS_USER_ADDED: 'Usuário adicionado com sucesso',
  SUCCESS_FAVORITE_ADD: 'Favorito adicionado com sucesso',
  SUCCESS_FAVORITE_REMOVE: 'Favorito removido com sucesso',
  SUCCESS_COPIED_ADDRESS: 'Endereço copiado',
  SUCCESS_COPIED_LINK: 'Link copiado',
};


export const MUSICS: Record<EMusicsTranslations, string> = {
  EVENT_MUSICAL_GENRES: 'Gêneros Musicais',
  MUSIC: 'Música',
  ELECTRONIC: 'Eletrônica',
  FUNK: 'Funk',
  COUNTRY_MUSIC: 'Sertanejo',
  POP: 'Pop',
  RAP: 'Rap',
  ROCK: 'Rock',
  MPB: 'MPB',
  AXE: 'Axé',
  SAMBA: 'Samba',
  REGGAE: 'Reggae',
  FORRO: 'Forró',
  GOSPEL: 'Gospel',
  HIP_HOP: 'Hip-Hop',
  JAZZ: 'Jazz',
  CLASSIC_MUSIC: 'Música clássica',
  PAGODE: 'Pagode',
  BLUES: 'Blues',
};


export const PAYMENTS: Record<EPaymentsTranslations, string> = {
  BANK: 'Banco',
  AGENCY: 'Agência',
  CARD_SELECTED: 'Cartão selecionado',
  DEBIT_CARD: 'Cartão de Débito',
  CREDIT_CARD: 'Cartão de Crédito',
  CREDIT: 'Crédito',
  DEBIT: 'Débito',
  TICKETS_CARD: 'Tickets',
  CASH: 'Dinheiro',
  BANK_CHECK: 'Cheque',
  PIX: 'Pix',
  ELO: 'Elo',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
  AMERICAN: 'American',
  DINERS: 'Diners',
  HIPERCARD: 'Hipercard',
  ALELO: 'Alelo',
  BEN: 'Ben',
  SODEXO: 'Sodexo',
  CARD_TICKET: 'Ticket',
  VR: 'Vr',
  CHANGE: 'Troco',
  DISCOUNT: 'Desconto',
  TIP: 'Gorgeta',
  TOTAL_PAY: 'Total Pago',
  TOTAL: 'Total',
  SUBTOTAL: 'Subtotal',
  TAXES: 'Taxas e encargos',
  TAX_DELIVERY: 'Taxa de entrega',
  PARTNER_PAYMENTS_ON: 'Pagamentos aceitos no local',
  WAITING_PAYMENT: 'Esperando pagamento',
  ALL_BANKS: 'Todos os bancos',
  ACCOUNT_CURRENT: 'Conta Corrente',
  ACCOUNT_SAVING: 'Conta Poupança',
  ACCOUNT_NICKNAME: 'Apelido da conta',
  ACCOUNT_BELONGING: 'Conta pertencente ao',
  ACCOUNT_CURRENT_SAVING_SAME_NUMBER: 'Conta corrente e poupança com mesmo número',
  CARD_ACCEPTED: 'Cartões aceitos no Secretio',
  PAYMENT_FORM: 'Forma de pagamento',
  CARD_NUMBER: 'Número do Cartão',
  DATE_VALID_UNTIL: 'Válido até',
  CARD: 'Cartão',
  CARDS: 'Cartões',
  CARD_CVV: 'CVV',
  CARD_VALIDITY: 'Validade',
  CARD_CURRENCY: 'Cartão atual',
  CARD_DEFINE_CURRENCY: 'Definir cartão atual',
  CARD_DELETE: 'Apagar Cartão',
  CARD_CHOOSE_MAIN: 'Usar este cartão como atual',
  CARD_CHOOSE_DEBIT_TOO: 'Se a cobrança no crédito falhar, tentar na forma débito (caso a opção esteja disponível)',
  CARD_CHOOSE_CREDIT_TOO: 'Se a cobrança no débito falhar, tentar na forma crédito (caso a opção esteja disponível)',
};


export const RESERVES: Record<EReservesTranslations, string> = {
  RESERVE_TO: 'Reserva Para',
  RESERVE_PRICE: 'Valor da Reserva',
  RESERVE_FREE: 'Reserva grátis',
  RESERVED_TO: 'Reservado para',
  RESERVED_AT: 'Reservado às',
  RESERVE_MADE_TO: 'Reserva feita por',
  RESERVE_HOUR: 'Horário da reserva',
  RESERVE_PEOPLE: 'Pessoas na reserva',
};


export const SOCIALS: Record<ESocialsTranslations, string> = {
  WHATSAPP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'X Twitter',
  YOUTUBE: 'Youtube',
  LINKEDIN: 'LinkedIn',
  CONTACT_US: 'Entre em contato',
  SEND_MESSAGE: 'Mande uma mensagem',
};


export const TIME: Record<ETimeTranslations, string> = {
  DATE: 'Data',
  HOUR: 'Hora',
  DATE_HOUR: 'Data e Hora',
  DATES_AVAILABLE: 'Datas disponíveis',
  OPENED_UNTIL: 'Aberto até',
  OPENEDS: 'Abertos',
  CLOSE_IN: 'Fecha em',
  CLOSED: 'Fechado',
  CLOSEDS: 'Fechados',
  TODAY: 'Hoje',
  SUNDAY: 'Domingo',
  MONDAY: 'Segunda',
  TUESDAY: 'Terça',
  WEDNESDAY: 'Quarta',
  THURSDAY: 'Quinta',
  FRIDAY: 'Sexta',
  SATURDAY: 'Sábado',
  NEXT: 'Próximos',
  PAST: 'Passados',
  UPDATED_AT: 'Atualizado em',
};


export const TITLES: Record<ETitlesTranslations, string> = {
  HOME: 'Início',
  FAVORITES: 'Favoritos',
  EXIT: 'Sair',
  FORGOT_PASSWORD_QUESTION: 'Esqueceu a senha?',
  RECOVER_PASSWORD: 'Recuperar senha',
  REGISTER: 'Registro',
  ACCESS: 'Entrar',
  CHECKIN: 'Check-in',
  CHECKOUT: 'Check-Out',
  CHECKIN_WAIT: 'Aguardando Check-in',
  QR_READER: 'Não quer perder tempo?',
  QR_MY_CODE: 'Meu código QR',
  GALLERY: 'Galeria',
  COMPANY_DETAILS: 'Detalhes do parceiro',
  ALL_EVENTS: 'Todos os eventos',
  LISTS: 'Listas',
  LISTS_EVENT: 'Listas do evento',
  LISTS_ALL: 'Todas as listas',
  LISTS_AVAILABLES: 'Listas disponíveis',
  LISTS_SPECIALS: 'Listas especiais',
  MENUSHEET: 'Cardápio',
  HIGHLIGHTS: 'Destaques',
  PARTNER: 'Parceiro',
  PARTNERS: 'Parceiros',
  PARTNERS_HIGHLIGHTS: 'Perfis em destaques hoje',
  EVENTS: 'Eventos',
  EVENTS_HIGHTLIGHTS: 'Eventos em destaque hoje',
  EVENTS_MORE: 'Eventos para você',
  EVENTS_STREET: 'Eventos de rua',
  MAP: 'Mapa',
  MAPS: 'Mapas',
  NOTIFICATIONS: 'Notificações',
  NOTIFICATIONS_CONFIG: 'Configurar notificações',
  ORDERSHEET: 'Comanda',
  ORDERSHEET_PAY: 'Pagar Comanda',
  PAYMENT_OPTIONS: 'Opções de pagamento',
  ORDERBAG: 'Sacola',
  HISTORIC: 'Histórico',
  COMPANY_NEW: 'Nova empresa',
  COMPANY_REGISTER: 'Registrar empresa',
  BANKS: 'Bancos',
  MODAL_ADD_USER: 'Adicionar usuario á empresa',
  ACCOUNT_TRANSFER: 'Contas para repasse',
  ACCOUNT_TRANSFER_ADD: 'Adicionar conta para repasse',
  COMPANY_ADDRESS_EDIT: 'Editar endereço da empresa',
  CONTACT: 'Contato',
  COMPANY_EDIT: 'Editar empresa',
  COMPANY_DATAS: 'Dados da empresa',
  PROFILE_PICTURE: 'Foto do perfil',
  LOGOTIPO: 'Logotipo',
  LOGOTIPO_COMPANY: 'Logotipo do perfil',
  ADDRESS: 'Endereço',
  ADDRESS_CREATE: 'Criar endereço',
  ADDRESS_EDIT: 'Editar endereço',
  ADDRESSES: 'Endereços',
  USER_DISABLE: 'Desativar perfil',
  COMPANY_DISABLE: 'Desativar empresa',
  REDEFINE_PASSWORD: 'Redefinir senha',
  CHANGE_PASSWORD: 'Alterar senha',
  PREFERENCES: 'Preferências',
  SETTINGS: 'Configurações',
  LANGUAGE: 'Língua',
  CARD_ADD: 'Adicionar cartão',
  ADDRESS_REGISTER: 'Cadastrar endereço',
  PAYMENTS_SUBSCRIPTIONS: 'Pagamentos e Assinaturas',
  PROFILE_MY_ABOUT: 'Sobre meu perfil',
  PROFILE_DETAILS: 'Detalhes do perfil',
  PROFILE_INFORMATIOS: 'Informações do perfil',
  PROFILE_PUBLIC: 'Perfil público',
  PROFILE_MAIN: 'A conta principal',
  PERMISSIONS_GROUP: 'Grupos e permissões',
  INVITE_TO_LIST: 'Convidar para a lista',
  INVITE_PEOPLE: 'Convidar Pessoas',
  PARTICIPATE: 'Participar',
  LIST_PARTICIPATE: 'Listas participantes',
  DETAILS_ITEM: 'Detalhe do Item',
  MY_ORDERS: 'Meus pedidos',
  RESERVES: 'Reservas',
  RESERVE_DETAILS: 'Detalhes da reserva',
  SEARCH: 'Buscas',
  OTHER_INFORMATIONS: 'Outras Informações',
  TERMS_POLICY: 'Termos e Políticas',
  LICENCES: 'Licenças',
  KNOW_APP: 'Conheça o Secretio',
  PROFILE_UNMANAGE: 'Perfil não gerenciado',
  PROFILE_MANAGE: 'Perfil gerenciado',
  SHARE: 'Compartilhar',
};


export const SUBTITLES: Record<ESubtitlesTranslations, string> = {
  SUBTITLES_LOGOTIPO_COMPANY: 'A logo ajuda a personalizar sua empresa',
  SUBTITLES_PROFILE_PICTURE: 'Uma foto ajuda a personalizar sua conta',
  SUBTITLES_PROFILE_ALL: 'Veja como seus diferentes perfis aparecem nos Serviços do Secretio',
  SUBTITLES_INFORMATIONS_COMPANY: 'Nome, sobre e mais',
  SUBTITLES_DATAS_COMPANY: 'Inscrição e nome jurídico por exemplo',
  SUBTITLES_COMPANY_CONTACT: 'Clique para editar seus contatos',
  SUBTITLES_COMPANY_TRANSFER_YES: 'Clique para gerenciar as contas de repasse dessa empresa',
  SUBTITLES_COMPANY_TRANSFER_NO: 'Contas de repasse ainda não disponível',
  SUBTITLES_COMPANY_ADDRESS: 'Clique para editar o endereço da empresa',
  SUBTITLES_ACCOUNT_TRANSFER_ADD: 'A Secretio ainda precisa analisar seu cadastro, aguarde mais um pouco para tentar de novo!',
  SUBTITLES_SETTINGS: 'Configurações da sua conta global',
  SUBTITLES_CARDS: 'Cartões de crédito', // 'Cartões de crédito e débito',
  SUBTITLES_PROFILE_PUBLIC: 'Essas são as informações que aparecem neste perfil que é público',
  SUBTITLES_PERMISSIONS_GROUP: 'Adicione pessoas e gerencie permissões',
  SUBTITLES_HIGHLIGHT_MAP: 'Permita a utilização da localização para ver locais próximos',
  SUBTITLES_HIGHLIGHT_MAP_EXPERIENCE: 'Assim conseguimos melhorar sua experiência no Secretio',
  SUBTITLES_LIST_IN: 'Você está na lista',
  SUBTITLES_LIST_INSIDE: 'Você já está na lista: {listName}',
  SUBTITLES_LIST_OUTSIDE: 'Clique no botão parcipar para entrar na lista: {listName}',
  SUBTITLES_SEE_ITEMS_MY_LOCATION: 'Ver eventos e parceiros ao meu redor',
  SUBTITLES_KNOW_APP: 'Cardápio, reservas, comandas, linkbio, ingressos e muito mais.',
};


export const VALIDATIONS: Record<EValidationsTranslations, string> = {
  ONLY_NUMBERS: 'Apenas Números',
  VALIDATE_MIN_DIGITS: 'Deve conter pelo menos {count} dígitos',
  VALIDATE_MAX_DIGITS: 'Deve conter até {count} dígitos',
  VALIDATE_EXACT_DIGITS: 'Precisa conter {count} dígitos',
  VALIDATE_MIN_CHAR: 'Deve conter pelo menos {count} caracteres',
  VALIDATE_MAX_CHAR: 'Deve conter até {count} caracteres',
  VALIDATE_EXACT_CHAR: 'Precisa conter {count} caracteres',
  VALIDATE_ALL_FIELDS: 'Preencha todos os campos corretamente antes de continuar',
  VALIDATE_ALL_SELECTED: 'Verifique se todos os campos estão selecionados',
  VALIDATE_HAS_USER: 'Já há um perfil com esse usuário/email',
  VALIDATE_ONLY_ONE: 'Marque apenas um',
  VALIDATE_ONE_MORE: 'Marque um ou vários',
  VALIDATE_EMAIL_REQUIRED: 'O e-mail é obrigatório',
  VALIDATE_EMAIL_VALID: 'Digite um e-mail válido',
  VALIDATE_EMAIL_DOMAIN: 'Domínio de e-mail incompleto',
  VALIDATE_PASSWORD_REQUIRED: 'A Senha é obrigatória',
  VALIDATE_ID_SHORT_REQUIRED: 'O ID público é obrigatório',
  VALIDATE_CPF_VALID: 'Insira um CPF válido',
  VALIDATE_USER_CPF_USERNAME: 'Insira um CPF válido ou um usuário com apenas letras, números, "_" e "."',
  VALIDATE_USERNAME_CHAR_SPECIAL: 'Usuário só pode conter letras, números, "_" e "."',
  VALIDATE_USER_REQUIRED: 'O Usuário é obrigatório',
};



const Translates = {
  ...APP,
  ...APP_TUTORIAL,
  ...BUTTONS,
  ...COMPANY_TYPES,
  ...CARNIVALS,
  ...COUNTRIES,
  ...DRINKS,
  ...EVENTS,
  ...INTERPOLATES,
  ...KITCHENS,
  ...LABELS,
  ...LANGUAGES,
  ...MENU_ORDER,
  ...MESSAGES_EMPTYS,
  ...MESSAGES_HELP,
  ...MESSAGES_ERRORS,
  ...MESSAGES_QUESTIONS,
  ...MESSAGES_SUCCESS,
  ...MUSICS,
  ...PAYMENTS,
  ...RESERVES,
  ...SOCIALS,
  ...TIME,
  ...TITLES,
  ...SUBTITLES,
  ...VALIDATIONS,
};



export default Translates;
