import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const GCPictureViewImageContainer = styled.div`
  overflow: hidden;
  width: 250px;
  height: 250px;
  border-radius: 10px;
`;


export const PictureContentView = styled.label`
  width: 100%;
  height: 100%;
`;



export const NoPictureView = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  border-color: ${Colors.secondary};
  border-style: solid;
  border-width: 4px;
`;


export const CardImportInput = styled.input`
  display: none;
  width: 100%;
  height: 100%;
`;

export const CardImportInputContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const CardImportIconView = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
`;

export const NoPictureText = styled.p`
  color: ${Colors.secondary};
  font-size: 18px;
  text-align: center;
`;



export const GCPictureImage = styled.video`
  width: 100%;
  height: 100%;
  background-color: gray;
  object-fit: cover;
`;

export const PictureImageChangeText = styled.p`
  position: absolute;
  bottom: 0;
  right: 0;
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  background-color: ${Colors.secondary};
  color: ${Colors.black};
  font-size: 14px;
  border-top-left-radius: 10px;
`;
