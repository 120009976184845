import ApiWS from '../index';



export const GetHelpFaqQuestions = async (payload: any) => {
  const url = '/support/project/help/faq-questions';

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const CreateHelpFaqQuestions = async (payload: any) => {
  const url = '/support/project/help/faq-questions';

  const response = await ApiWS.post(url, payload);
  return response.data;
};



export const EditHelpFaqQuestions = async (id: string, payload: any) => {
  const url = `/support/project/help/faq-questions/${id}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const DeleteHelpFaqQuestions = async (id: any) => {
  const url = `/support/project/help/faq-questions/${id}`;

  const response = await ApiWS.put(url);
  return response.data;
};
