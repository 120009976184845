import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import ButtonPanelHover from '../ButtonPanelHover';



export const PanelLocationsContainer = styled(ButtonPanelHover)`
  min-width: 230px;

  @media ${PlatformDevices.maxLaptop} {
    min-width: 160px;
  }
`;



export const PanelLocationsView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const PanelLocationsIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const PanelLocationsTitle = styled.h3`
  color: ${Colors.black};
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
`;



export const PanelLocationsInfoView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
`;

export const PanelLocationsIconView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

export const PanelLocationsFlag = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
`;

export const PanelLocationsText = styled.p`
  color: ${Colors.inputLabel};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
`;
